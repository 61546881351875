import {
  SB__INIT,
  SB__TRANSITION_CHANGE,
  SB__ACTIVATE,
  SB__CLOSE,
  SB__DEACTIVATE,
  SB__OPEN,
  SB__SEND_MESSAGE,
  SB__CLOSE_TIMER_OFF,
  SB__CLOSE_TIMER_ON,
  SB__DIMENSIONS_SET,
  SB__DIMENSIONS_RESET,
  SB__OPEN_TIMER_ON,
  SB__OPEN_TIMER_OFF
} from '../actionConstants';

export function initSb(payload) {
  return dispatch => {
    dispatch({
      type: SB__INIT,
      payload
    });
  };
}

export function openSb() {
  return dispatch => {
    dispatch({
      type: SB__OPEN
    });
  };
}

export function closeSb(payload) {
  return dispatch => {
    dispatch({
      type: SB__CLOSE,
      payload
    });
  };
}

export function activateSb(payload) {
  return dispatch => {
    dispatch({
      type: SB__ACTIVATE,
      payload
    });
  };
}

export function deactivateSb() {
  return dispatch => {
    dispatch({
      type: SB__DEACTIVATE
    });
  };
}

export function sendMessageSb(payload) {
  return dispatch => {
    dispatch({
      type: SB__SEND_MESSAGE,
      payload
    });
  };
}

export function initCloseTimerSb() {
  return dispatch => {
    dispatch({
      type: SB__CLOSE_TIMER_ON
    });
  };
}

export function deactivateCloseTimerSb() {
  return dispatch => {
    dispatch({
      type: SB__CLOSE_TIMER_OFF
    });
  };
}

export function transitionChangeSb(payload) {
  return dispatch => {
    dispatch({
      type: SB__TRANSITION_CHANGE,
      payload
    });
  };
}

export function setDimensionSb() {
  return dispatch => {
    dispatch({
      type: SB__DIMENSIONS_SET
    });
  };
}

export function resetDimensionSb() {
  return dispatch => {
    dispatch({
      type: SB__DIMENSIONS_RESET
    });
  };
}

export function onOpenTimerSb() {
  return dispatch => {
    dispatch({
      type: SB__OPEN_TIMER_ON
    });
  };
}

export function offOpenTimerSb() {
  return dispatch => {
    dispatch({
      type: SB__OPEN_TIMER_OFF
    });
  };
}
