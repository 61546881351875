import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { prop, ifNotProp, switchProp } from 'styled-tools';
import {
  getImageDimensions,
  stylesAlignment,
  convertHexToRgba,
  isEmpty
} from 'utils/helpers';
import { CTA_WIDTH, PADDING } from '../../CallToAction/frameWrapper';
import SETTING from 'helpers/mobile-desktop-settings';

const _animationInProgress = false;
const _speechBubbleFloatingButtonDimensionsRequest = {
  path: null,
  inProgress: false
};

const open = () => {
  return keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;
};

const close = () => {
  return keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
};

const onEntering = () => {
  return css``;
};

const onEntered = () => {
  return css``;
};

const onExiting = () => {
  return css``;
};

const onExited = () => {
  if (_animationInProgress) return _animationInProgress;
  return css``;
};

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{"box-shadow": string}}
   */
  getShadow() {
    const { settings = {} } = this;
    const shadow = settings.fb_shadow ? +settings.fb_shadow : 0;
    const color = settings.cb_shadow_color;
    return { 'box-shadow': `0 0 ${shadow}px ${convertHexToRgba(color, 80)}` };
  }

  /**
   *
   * @param props
   * @returns {*}
   * @private
   */
  _getSpeechBubbleFloatingButton(props = {}) {
    let data;
    const { direction } = props;
    const { settings = {}, parentState = {} } = this;
    const useSpeechBubbleButton = settings.sb2_enabled === 'on';
    const useSpeechBubbleMobileButton =
      SETTING('FB_ONLINE_BUTTON', settings) === 'on';
    const isHorizontal = direction === 'horizontal';
    const isVertical = direction === 'vertical';
    const isPathInactive =
      settings.sb2_fb_standard_button !== 'on' &&
      isEmpty(settings.sb2_fb_custom_button);

    if (isPathInactive) {
      return { width: '100px', height: '100px' };
    }

    const speechBubbleFloatingButtonPath =
      (() => {
        if (SETTING('SB2_FB_STANDARD_BUTTON', settings) === 'on') {
          return (
            SETTING('SB2_FB_STANDARD_BUTTON_MODIFIED', settings) ||
            SETTING('SB2_FB_STANDARD_BUTTON_TYPE', settings)
          );
        } else {
          return SETTING('SB2_FB_CUSTOM_BUTTON', settings);
        }
      })() || '';

    if (!useSpeechBubbleButton && !useSpeechBubbleMobileButton) return;
    const sb2FbSize = SETTING('SB2_FB_SIZE', settings);
    const sb2FbSvgWidth = SETTING('SB2_FB_SVG_WIDTH', settings);
    const sb2FbSvgHeight = SETTING('SB2_FB_SVG_HEIGHT', settings);

    if (
      speechBubbleFloatingButtonPath.length > 0 &&
      speechBubbleFloatingButtonPath.substr(-4, 4) === '.svg'
    ) {
      const svgAdminsWidth = (sb2FbSvgWidth && Number(sb2FbSvgWidth)) || 100;
      const svgAdminsHeight = (sb2FbSvgHeight && Number(sb2FbSvgHeight)) || 100;

      if (sb2FbSize) {
        if (isHorizontal) {
          data = {
            width: Math.floor((2 * sb2FbSize * svgAdminsWidth) / 100) + 'px'
          };
        }
        if (isVertical) {
          data = {
            height: Math.floor((2 * sb2FbSize * svgAdminsHeight) / 100) + 'px'
          };
        }
      } else {
        if (isHorizontal) {
          data = { width: svgAdminsWidth + 'px' };
        }
        if (isVertical) {
          data = { height: svgAdminsHeight + 'px' };
        }
      }
    } else {
      const dimensions = parentState.get().speechBubbleFloatingButtonDimensions;

      if (
        _speechBubbleFloatingButtonDimensionsRequest.inProgress &&
        _speechBubbleFloatingButtonDimensionsRequest.path ===
          speechBubbleFloatingButtonPath
      )
        return;

      if (
        !dimensions ||
        _speechBubbleFloatingButtonDimensionsRequest.path !==
          speechBubbleFloatingButtonPath
      ) {
        _speechBubbleFloatingButtonDimensionsRequest.path = speechBubbleFloatingButtonPath;
        _speechBubbleFloatingButtonDimensionsRequest.inProgress = true;

        getImageDimensions(speechBubbleFloatingButtonPath).then(dimensions => {
          _speechBubbleFloatingButtonDimensionsRequest.inProgress = false;
          parentState.set({ speechBubbleFloatingButtonDimensions: dimensions }); // async operation
        });
        return;
      }

      if (sb2FbSize) {
        if (isHorizontal) {
          data = {
            width: Math.floor((2 * sb2FbSize * dimensions.width) / 100) + 'px'
          };
        }
        if (isVertical) {
          data = {
            height: Math.floor((2 * sb2FbSize * dimensions.height) / 100) + 'px'
          };
        }
      } else {
        if (isHorizontal) {
          data = { width: `${Math.floor(dimensions.width)}px` };
        }
        if (isVertical) {
          data = { height: `${Math.floor(dimensions.height)}px` };
        }
      }
    }

    return data;
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  getWidth(props = {}) {
    return this._getSpeechBubbleFloatingButton({
      direction: 'horizontal'
    });
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  getHeight(props = {}) {
    return this._getSpeechBubbleFloatingButton({
      direction: 'vertical'
    });
  }

  /**
   *
   * @param props
   * @returns {*}
   */

  getAlignment(props = {}) {
    const { settings = {} } = this;
    const { width = 0, height = 0 } = props;

    if (
      !(
        (settings.fb_alignment_x || settings.cb_alignment_x) &&
        (settings.fb_alignment_y || settings.cb_alignment_y)
      )
    )
      return;

    const x = settings.fb_alignment_x || settings.cb_alignment_x;
    const y = settings.fb_alignment_y || settings.cb_alignment_y;
    const fbOffsetHorizontal = SETTING('FB_OFFSET_HORIZONTAL', settings);
    const fbOffsetVertical = SETTING('FB_OFFSET_VERTICAL', settings);

    let hOffset = fbOffsetHorizontal ? `${fbOffsetHorizontal}px` : 0;
    const vOffset = fbOffsetVertical ? `${fbOffsetVertical}px` : 0;

    if (x === 'middle') {
      hOffset = Math.round(((CTA_WIDTH + PADDING) / 2) * -1);
    }

    return stylesAlignment({
      x,
      y,
      hOffset,
      vOffset,
      element: { width, height }
    });
  }

  /**
   *
   * @returns {*}
   */
  getSlideOn() {
    const { settings = {} } = this;
    return settings.fb_slide_on;
  }

  /**
   *
   * @returns {*}
   */
  getOfflineSlideOn() {
    const { settings = {} } = this;
    return settings.fb_offline_slide_on;
  }

  /**
   *
   * @returns {*}
   */
  getSlideOff() {
    const { settings = {} } = this;
    return settings.fb_slide_off;
  }

  /**
   *
   * @returns {*}
   */
  getOfflineSlideOff() {
    const { settings = {} } = this;
    return settings.fb_offline_slide_off;
  }

  /**
   *
   * @returns {*}
   */
  getMoveSpeed() {
    const { settings = {} } = this;
    return +settings.fb_move_speed || +settings.cb_move_speed || 500;
  }

  /**
   *
   * @returns {*}
   */
  getOfflineMoveSpeed() {
    const { settings = {} } = this;
    return +settings.fb_offline_move_speed || +settings.cb_move_speed || 500;
  }
}

/**
 *
 * @param props
 * @returns {{} & {transitionState} & {_shadow: {"box-shadow"}, widthBtn: *, heightBtn: *, align: *, slideOn: *, slideOff: *, slideAlign: *, slideSpeed: *}}
 */
const frameWrapperFormatProps = (props = {}) => {
  const {
    settings,
    operatorOnline,
    transitionState,
    speechBubble,
    parentState,
    element,
    sbParentState,
    setDimensionSb,
    uiLocked
  } = props;
  const Styles = new StylesConstructor({ settings, parentState, element });
  const _btnWidth = Styles.getWidth({
    open: speechBubble.open,
    operatorOnline
  });
  const _btnHeight = Styles.getHeight({
    open: speechBubble.open,
    operatorOnline
  });
  const _align = Styles.getAlignment({
    mode: '',
    width: (_btnWidth || {}).width,
    height: (_btnHeight || {}).height
  });
  const style = {
    pointerEvents: uiLocked ? 'none' : 'auto'
  };
  const internalProps = {
    _btnWidth,
    _btnHeight,
    _align,
    parentState,
    sbParentState,
    setDimensionSb,
    element,
    _shadow: Styles.getShadow(),
    _slideOn: operatorOnline ? Styles.getSlideOn() : Styles.getOfflineSlideOn(),
    _slideOff: operatorOnline
      ? Styles.getSlideOff()
      : Styles.getOfflineSlideOff(),
    _slideSpeed: operatorOnline
      ? Styles.getMoveSpeed()
      : Styles.getOfflineMoveSpeed(),
    style
  };

  return Object.assign({}, { transitionState }, internalProps);
};

const FrameWrapper = styled.div.attrs({
  style: {}
})`
  position: fixed;
  z-index: 2147483647 !important;
  border-radius: 6px;
  ${prop('styles')};
  ${ifNotProp(
    '_btnWidth',
    css`
      display: none;
    `
  )}
  ${prop('_btnWidth')}
  ${prop('_btnHeight')}
  ${prop('_align')}
  ${prop('_shadow')}
  ${prop('style')};
  ${switchProp('transitionState', {
    entered: props => onEntered(props),
    entering: props => onEntering(props),
    exited: props => onExited(props),
    exiting: props => onExiting(props)
  })};
`;

export { FrameWrapper, frameWrapperFormatProps };
