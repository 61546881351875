import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, ifNotProp, prop } from 'styled-tools';
import { convertHexToRgba, gradientToString } from 'utils/helpers';
import { ClockIcon, WarningIcon } from 'common/svg';
import { messageStatuses } from 'config/constants';
import { DEFAULT_OPERATOR_AVATAR } from '../../../../config/constants';

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{'backgroundColor': *}|{'backgroundImage': string, background: string}}
   */
  getBackground(isTypeUser) {
    const { settings = {} } = this;
    let background = {};

    if (isTypeUser) {
      if (
        settings.cb_visitor_message_background_color_from &&
        settings.cb_visitor_message_background_color_to
      ) {
        const cbColorV = gradientToString(
          [
            settings.cb_visitor_message_background_color_to,
            settings.cb_visitor_message_background_color_from
          ],
          'top'
        );
        background = { backgroundImage: cbColorV, background: cbColorV };
      } else if (
        settings.cb_visitor_message_background_color_from ||
        settings.cb_visitor_message_background_color_to
      ) {
        const cbColorV =
          settings.cb_visitor_message_background_color_to ||
          settings.cb_visitor_message_background_color_from;
        background = { backgroundColor: cbColorV };
      }
    } else {
      if (
        settings.cb_message_background_color_from &&
        settings.cb_message_background_color_to
      ) {
        const cbColorV = gradientToString(
          [
            settings.cb_message_background_color_to,
            settings.cb_message_background_color_from
          ],
          'top'
        );
        background = { backgroundImage: cbColorV, background: cbColorV };
      } else if (
        settings.cb_message_background_color_from ||
        settings.cb_message_background_color_to
      ) {
        const cbColorV =
          settings.cb_message_background_color_to ||
          settings.cb_message_background_color_from;
        background = { backgroundColor: cbColorV };
      }
    }

    return background;
  }

  /**
   *
   * @returns {{'background-color': *}|{'background-image': string, background: string}}
   **/
  getBorder(isTypeUser) {
    const { settings = {} } = this;
    let border = {};

    if (isTypeUser) {
      if (
        settings.cb_visitor_message_background_color_from &&
        settings.cb_visitor_message_background_color_to
      ) {
        border = {
          borderColor: `transparent ${settings.cb_visitor_message_background_color_to} transparent transparent`
        };
      } else if (
        settings.cb_visitor_message_background_color_from ||
        settings.cb_visitor_message_background_color_to
      ) {
        const cbColorV =
          settings.cb_visitor_message_background_color_to ||
          settings.cb_visitor_message_background_color_from;
        border = {
          borderColor: `transparent ${cbColorV} transparent transparent`
        };
      }
    } else {
      if (
        settings.cb_message_background_color_from &&
        settings.cb_message_background_color_to
      ) {
        border = {
          borderColor: `transparent ${settings.cb_message_background_color_to} transparent transparent`
        };
      } else if (
        settings.cb_message_background_color_from ||
        settings.cb_message_background_color_to
      ) {
        const cbColorV =
          settings.cb_message_background_color_to ||
          settings.cb_message_background_color_from;
        border = {
          borderColor: `transparent ${cbColorV} transparent transparent`
        };
      }
    }

    return border;
  }

  /**
   *
   **/
  getColor(isTypeUser) {
    const { settings = {} } = this;
    const color = isTypeUser
      ? settings.cb_visitor_text_color || '#212325'
      : settings.cb_text_color || '#212325';

    return { color };
  }

  /**
   *
   **/
  getOperatorAvatarBorderColor() {
    const { settings = {} } = this;
    let border = {};

    if (
      settings.cb_message_background_color_from &&
      settings.cb_message_background_color_to
    ) {
      const borderColor = convertHexToRgba(
        settings.cb_message_background_color_to,
        80
      );
      border = { borderColor: borderColor };
    }

    return border;
  }
}

const OperatorAvatar = styled.div`
  ${ifProp(
    'src',
    css`
      background-image: url(${prop('src')});
    `,
    css``
  )};
  background-size: cover;
  float: left;
  width: 28px;
  height: 28px;
  margin: 6px 10px 0 0;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
  border: 1px solid;
  ${prop('style')}
`;

const Message = styled.div`
  border: 1px solid #4d4d4d;
  margin-bottom: 5px;
  position: relative;
  display: inline-block;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 80%;
  word-break: break-word;
  overflow: visible;

  &:after {
    content: ' '; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  ${prop('style')};
`;

const MessageDate = styled.p`
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.6em;
  font-weight: bold;
  margin-bottom: 5px;
  ${ifNotProp(
    'isTypeUser',
    css`
      margin-left: 40px;
    `
  )}
  ${ifNotProp(
    'useOperatorAvatar',
    css`
      margin-left: 0;
    `
  )}
`;

const Wrapper = styled.div`
  font-size: 14px;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  width: 100%;
  ${prop('style')};
`;

const MessageText = styled.p`
  text-align: left;
`;

const MessageWrapper = styled.div`
  width: calc(100% - 30px);
  padding: 0 15px;
  transition: 0.12s padding ease-out;
  ${ifProp(
    'isOperatorTyping',
    css`
      padding-bottom: 50px;
    `
  )}
`;

const MessageStatusIcon = styled.i`
  display block;
  position absolute;
  right -16px;
  top 14px;
  opacity .7;
  svg {
    width 15px;
    fill #ffffff;
  }
`;

/**
 *
 * @param message
 * @param operatorInfo
 * @param operatorOnline
 * @param settings
 * @returns {*}
 * @constructor
 */
const MessageComp = ({
  message,
  operatorInfo,
  operatorOnline,
  settings = {}
}) => {
  const Styles = new StylesConstructor({ settings });
  const isTypeUser = message._isClientOwner;
  const useOperatorAvatar =
    settings.cb_message_operator_icon_enabled === 'on' &&
    operatorInfo &&
    !isTypeUser &&
    operatorInfo.avatar !== DEFAULT_OPERATOR_AVATAR;
  const wrapStyle = {
    textAlign: isTypeUser ? 'right' : 'left'
  };
  const avatarStyle = {
    ...Styles.getOperatorAvatarBorderColor()
  };
  const msgStyle = {
    ...Styles.getBackground(isTypeUser),
    ...Styles.getBorder(isTypeUser),
    ...Styles.getColor(isTypeUser)
  };
  const showMessageDate = message.last ? (
    <MessageDate isTypeUser={isTypeUser} useOperatorAvatar={useOperatorAvatar}>
      {message.date}
    </MessageDate>
  ) : null;

  let msgIcon = null;
  if (isTypeUser) {
    switch (message.status) {
      case messageStatuses.MSG_STATUS_NEW:
        msgIcon = <ClockIcon />;
        break;
      case messageStatuses.MSG_STATUS_ERROR:
        msgIcon = <WarningIcon />;
        break;
      default:
        msgIcon = null;
    }
  }

  return (
    <Wrapper style={wrapStyle}>
      {useOperatorAvatar && (
        <OperatorAvatar src={operatorInfo.avatar} style={avatarStyle} />
      )}
      <Message style={msgStyle}>
        <MessageText useAvatar={useOperatorAvatar}>{message.text}</MessageText>
        {msgIcon && <MessageStatusIcon>{msgIcon}</MessageStatusIcon>}
      </Message>
      {showMessageDate}
    </Wrapper>
  );
};

export {
  MessageWrapper,
  MessageComp,
  StylesConstructor as MessageStylesConstructor
};
