import React from 'react';
import styled from 'styled-components';
import { Cloud1, Cloud2 } from './commonComponents';
import { PlaneTemplate } from './customTemplate';

const Container = styled.div`
  width: ${props => 180 * props.koef}px;
  height: ${props => 150 * props.koef}px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const Lamp = styled.img`
  width: ${props => 35 * props.koef}px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation-delay: 1s;
  animation: bounceIn 1s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;
    
    @keyframes bounceIn {
    0% {
      opacity: 0;
      transform: scale(0.3) translate3d(0,0,0);
    }
    50% {
      opacity: 0.9;
      transform: scale(1.1);
    }
    80% {
      opacity: 1;
      transform: scale(0.89);
    }
    100% {
      opacity: 1;
      transform: scale(1) translate3d(0,0,0);
    }
`;

const LampLine1 = styled.img`
  width: ${props => 2 * props.koef}px;
  height: ${props => 15 * props.koef}px;
  background-color: #ffe9dc;
  position: absolute;
  transform-origin: center bottom;
  left: ${props => 7 * props.koef}px;
  top: ${props => 4 * props.koef}px;
  transform: scale(1) rotate(-35deg);
  opacity: 0;
  animation-delay: 2.2s;
  animation: opacityOn 1s normal forwards step-end;

  @keyframes opacityOn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const LampLine2 = styled.img`
  width: ${props => 2 * props.koef}px;
  height: ${props => 15 * props.koef}px;
  background-color: #ffe9dc;
  position: absolute;
  transform-origin: center bottom;

  transform: scale(1);
  top: 0;
  left: ${props => 25 * props.koef}px;
  animation: opacityOn 1.1s normal forwards step-end;
  opacity: 0;
`;

const LampLine3 = styled.img`
  width: ${props => 2 * props.koef}px;
  height: ${props => 15 * props.koef}px;
  background-color: #ffe9dc;
  position: absolute;
  transform-origin: center bottom;

  transform: scale(1) rotate(35deg);
  left: ${props => 40 * props.koef}px;
  top: ${props => 5 * props.koef}px;
  animation-delay: 2.4s;
  animation: opacityOn 1.2s normal forwards step-end;
  opacity: 0;
`;

const LampContainer = styled.div`
  width: ${props => 50 * props.koef}px;
  height: ${props => 70 * props.koef}px;
  position: absolute;
  bottom: ${props => 90 * props.koef}px;
  right: ${props => 75 * props.koef}px;
  z-index: 1;
`;

export const GirlTemplate = ({ koef }) => {
  return (
    <Container koef={koef}>
      <Cloud1
        koef={koef}
        src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/cloud-1.png"
      />
      <Cloud2
        koef={koef}
        src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/cloud-2.png"
      />
      <PlaneTemplate koef={koef} />
      <LampContainer koef={koef}>
        <Lamp
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/lamp-blue.png"
        />
        <LampLine1
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/lamp-line-blue.png"
        />
        <LampLine2
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/lamp-line-blue.png"
        />
        <LampLine3
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/lamp-line-blue.png"
        />
      </LampContainer>
    </Container>
  );
};
