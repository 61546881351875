const roomErrorMessage = 'Property `room` not exists';
/**
 *
 * @param room
 * @returns {any}
 */
export function getRoomStoredData(room) {
  let data = {};
  if (!room) throw new Error(roomErrorMessage);
  data = JSON.parse(getItem(room));
  return data;
}

/**
 *
 * @param room
 * @param obj
 */
export function setRoomStoredData(room, obj) {
  if (!room) throw new Error(roomErrorMessage);
  const existingData = getRoomStoredData(room);
  const newData = { ...existingData, ...obj };
  setItem(room, JSON.stringify(newData));
}

/**
 *
 * @param room
 */
export function removeRoomStoredData(room) {
  if (!room) throw new Error(roomErrorMessage);
  removeItem(room);
}

/**
 *
 */
export function clearStorage() {
  clear();
}

/**
 *
 * @param key
 * @returns {string}
 */
export function getItem(key) {
  return localStorage.getItem(key);
}

/**
 *
 * @param key
 * @param data
 */
export function setItem(key, data) {
  localStorage.setItem(key, data);
}

/**
 *
 * @param key
 */
export function removeItem(key) {
  localStorage.removeItem(key);
}

/**
 *
 */
export function clear() {
  localStorage.clear();
}
