import {
  SB__INIT,
  SB__OPEN,
  SB__CLOSE,
  SB__ACTIVATE,
  SB__DEACTIVATE,
  SB__SEND_MESSAGE,
  SB__DIMENSIONS_SET,
  SB__DIMENSIONS_RESET,
  SB__TRANSITION_CHANGE,
  SYSTEM__CLEAR,
  CONTACT_VIA__SET_SHOW_MODE
} from '../actionConstants';
import {
  statementsInIntegers,
  CONTACT_VIA_SHOW_MODES
} from '../../config/constants';

const updateState = function(state = {}, newState = {}) {
  return Object.assign({}, state, newState);
};

const init = {
  active: statementsInIntegers.DISABLED,
  open: false,
  dimensionsSet: false,
  onceOpened: false,
  sbNextOpenDate: false,
  stage: null
};

export default function(state = init, action) {
  const activateSB = () =>
    updateState(state, {
      active: action.payload
        ? statementsInIntegers.ENABLED
        : statementsInIntegers.DISABLED
    });

  switch (action.type) {
    case SB__INIT:
      return activateSB();

    case SB__ACTIVATE:
      return state.active !== statementsInIntegers.DISABLED_ON_DEMAND
        ? activateSB()
        : state;

    case SB__DEACTIVATE:
      return updateState(state, {
        active: statementsInIntegers.DISABLED_ON_DEMAND
      });

    case SB__OPEN:
      if (state.active === statementsInIntegers.ENABLED) {
        return updateState(state, {
          open: true,
          onceOpened: true
        });
      }
      return state;

    case SB__CLOSE:
      return updateState(state, {
        open: false
      });

    case SB__DIMENSIONS_SET:
      return updateState(state, {
        dimensionsSet: true
      });

    case SB__DIMENSIONS_RESET:
      return updateState(state, {
        dimensionsSet: false
      });

    case SB__SEND_MESSAGE:
      if (state.active) {
        return updateState(state, {
          open: false,
          active: statementsInIntegers.DISABLED
        });
      }
      return state;

    case SB__TRANSITION_CHANGE:
      return updateState(state, {
        stage: action.payload.stage
      });

    case CONTACT_VIA__SET_SHOW_MODE:
      if (action.payload.showMode === CONTACT_VIA_SHOW_MODES.INITIAL) {
        return state;
      }
      if (state.active !== statementsInIntegers.ENABLED || !state.open) {
        return state;
      }
      return updateState(state, {
        open: false
      });

    case SYSTEM__CLEAR:
      return updateState(state, init);

    default:
      return state;
  }
}
