const msgTypes = {
  CLIENT_USER_ADD: 'user_add',
  CLIENT_ROOM_CONNECT: 'room_connect',
  CLIENT_ROOM_DISCONNECT: 'room_disconnect',
  CLIENT_MESSAGE_ADD: 'message_add',
  CLIENT_OFFLINE_MESSAGE_ADD: 'offline_message_add',
  CLIENT_USER_TYPING: 'user_typing',
  CLIENT_CHAT_BOX_OPEN: 'chat_box_open',
  CLIENT_CHAT_BOX_CLOSE: 'chat_box_close',
  CLIENT_CHAT_HISTORY_SEND: 'chat_history_send',
  CLIENT_DETAILS_FORM_SEND: 'details_form_send',
  CLIENT_TYPING_START: 'typing_start',
  CLIENT_TYPING_STOP: 'typing_stop',
  CLIENT_SPEECH_BUBBLE_OPEN: 'speech_bubble_open',
  CLIENT_TRACK_VISITOR: 'track_visitor',

  SERVER_USER_CONNECTED: 'user_connected', // Server has connected the user
  SERVER_DISCONNECT: 'disconnect', // When server has closed connection
  SERVER_RECONNECT: 'reconnect', // When server has opened connection again
  SERVER_USER_REJECTED: 'user_rejected', // Server has rejected the user
  SERVER_USER_NEW: 'user_new',
  SERVER_ROOM_CONNECTED: 'room_connected', // Server has joined the user to room
  SERVER_ROOM_REJECTED: 'room_rejected', // Server has rejected the user for this room
  SERVER_ROOM_DISCONNECTED: 'room_disconnected', // Server has disconnected the room
  SERVER_ROOM_DISCONNECT_REJECTED: 'room_disconnect_rejected', // Server has rejected disconnection for this room
  SERVER_ROOM_JOINED: 'room_joined', // When operator has joined the room
  SERVER_ROOM_LEFT: 'room_left', // When operator has left the room
  SERVER_MESSAGE_ADDED: 'message_added', // When the user successfully sent a message
  SERVER_MESSAGE_REJECTED: 'message_rejected', // When the user message has been rejected
  SERVER_MESSAGE_NEW: 'message_new', // When the user receive a new message
  SERVER_OFFLINE_MESSAGE_ADDED: 'offline_message_added', // When the user successfully sent an offline message
  SERVER_OFFLINE_MESSAGE_REJECTED: 'offline_message_rejected', // When the user offline message request has been rejected
  SERVER_CHAT_ASSIGNED: 'chat_assigned', // When chat has been assigned to operator
  SERVER_CHAT_REASSIGNED: 'chat_reassigned', // When chat has been reassigned to operator
  SERVER_CHAT_UNASSIGNED: 'chat_unassigned', // When chat has been unassigned
  SERVER_CHAT_CLOSING: 'chat_closing', // When an operator has closed the chat
  SERVER_CHAT_CHANGE: 'chat_change', // When chat id has been changed
  SERVER_CHAT_PUSHED: 'chat_pushed', // When operator directly change location for visitor
  SERVER_CHAT_BOX_OPEN_REJECTED: 'chat_box_open_rejected', // When the user open message has been rejected
  SERVER_CHAT_BOX_OPENED: 'chat_box_opened', // When the user successfully open the chat box
  SERVER_CHAT_BOX_CLOSE_REJECTED: 'chat_box_close_rejected', // When the user close message has been rejected
  SERVER_CHAT_BOX_CLOSED: 'chat_box_closed', // When the user successfully close the chat box
  SERVER_CHAT_HISTORY_SEND_REJECTED: 'chat_history_send_rejected', // When the user chat history request has been rejected
  SERVER_CHAT_HISTORY_SENT: 'chat_history_sent', // When the user successfully requested chat history
  SERVER_DETAILS_FORM_PUSHED: 'details_form_pushed', // When a details form was pushed by the operator
  SERVER_DETAILS_FORM_SEND_REJECTED: 'details_form_send_rejected', // When the user details form request has been rejected
  SERVER_DETAILS_FORM_SENT: 'details_form_sent', // When the user successfully sent details form
  SERVER_TYPING_START: 'typing_start', // When operator start typing message
  SERVER_TYPING_STOP: 'typing_stop', // When operator stop typing message
  SERVER_SPEECH_BUBBLE_OPEN_REJECTED: 'speech_bubble_open_rejected', // When the user speech bubble has been rejected
  SERVER_TRACK_VISITOR_CONFIRMED: 'track_visitor_confirmed', // When track visitor has been confirmed
  SERVER_TRACK_VISITOR_REJECTED: 'track_visitor_rejected', // When track visitor has been rejected
  SERVER_TEAM_ONLINE_STATUS: 'team_online_status', // For obtaining the online status of provided teams.
  SERVER_NO_ANSWER_TIMEOUT: 'no_answer_timeout' // When there is no answer from an operator and LAM timeout has been reached.
};

const chatTypes = {
  STATUS_NEW: 'new',
  STATUS_ACTIVE: 'active',
  STATUS_SUSPENDED: 'suspended',
  STATUS_CLOSED: 'closed',
  ERROR_CHAT_INACTIVE: 'chat_inactive',
  ACTIVE_ROOMS_MAX_QUANTITY: 3
};

const eventTypes = {
  BOX_OPEN: 'vc.box.open',
  BOX_OPENED: 'vc.box.opened',
  BOX_CLOSE: 'vc.box.close',
  BOX_CLOSED: 'vc.box.closed',
  OPERATOR_TYPING_START: 'vc.operator.typing.start',
  OPERATOR_TYPING_STOP: 'vc.operator.typing.stop',
  VISITOR_TYPING_START: 'vc.visitor.typing.start',
  VISITOR_TYPING_STOP: 'vc.visitor.typing.stop',
  CHAT_ASSIGNED: 'vc.chat.assigned',
  CHAT_UNASSIGNED: 'vc.chat.unassigned',
  CHAT_CLOSE: 'vc.chat.close'
};

const server = process.env.API_URL;
const apiVersion = process.env.API_VERSION || 'v1';
const http = {
  API_URL: `${server}/${apiVersion}`,
  MESSAGES: 'messages',
  CHAT_SERVER_SCHEME: process.env.CHAT_SERVER_SCHEME || 'https'
};

const NODE_ENV = process.env.NODE_ENV;
const LOG_LEVEL = process.env.LOG_LEVEL;
const VERSION = process.env.VERSION;
const BUILD_DATE = process.env.BUILD_DATE;

const PUBLIC_SITE_URL = process.env.PUBLIC_SITE_URL || 'https://visitor.chat';
const DEFAULT_OPERATOR_AVATAR = process.env.DEFAULT_OPERATOR_AVATAR;

const transitionStages = {
  ENTER: 'enter',
  ENTERED: 'entered',
  EXIT: 'exit',
  EXITED: 'exited'
};

const RETRY_REMOTE_REQUEST_MS = 60000;
const OVERLAY_LIFESPAN_MS = 5000;

const statementsInIntegers = {
  DISABLED_ON_DEMAND: -1,
  DISABLED: 0,
  ENABLED: 1
};

const messageStatuses = {
  MSG_STATUS_NEW: 'new',
  MSG_STATUS_SENT: 'sent',
  MSG_STATUS_ERROR: 'error'
};

const FETCH_STATE = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

const CONTACT_VIA_HEIGHT = 30;

const CONTACT_VIA_SHOW_MODES = {
  INITIAL: 'INITIAL',
  SMS: 'SMS',
  WHATSAPP: 'WHATSAPP'
};

const defaultTimeout = 4 * 60 * 60 * 1000; // 4h in ms
function timeout(envValue) {
  return envValue ? parseInt(envValue) : defaultTimeout;
}

const SB_OPEN_TIMEOUT = timeout(process.env.SB_OPEN_TIMEOUT);
const MB_OPEN_TIMEOUT = timeout(process.env.MB_OPEN_TIMEOUT);
const CTA_OPEN_TIMEOUT = timeout(process.env.CTA_OPEN_TIMEOUT);
const MB_INITIAL_MESSAGE_TIMEOUT = timeout(
  process.env.MB_INITIAL_MESSAGE_TIMEOUT
);

const IS_MOBILE_WIDTH = window.innerWidth <= 500;

export {
  msgTypes,
  eventTypes,
  chatTypes,
  transitionStages,
  http,
  RETRY_REMOTE_REQUEST_MS,
  OVERLAY_LIFESPAN_MS,
  statementsInIntegers,
  messageStatuses,
  SB_OPEN_TIMEOUT,
  MB_OPEN_TIMEOUT,
  CTA_OPEN_TIMEOUT,
  NODE_ENV,
  LOG_LEVEL,
  VERSION,
  BUILD_DATE,
  MB_INITIAL_MESSAGE_TIMEOUT,
  PUBLIC_SITE_URL,
  FETCH_STATE,
  CONTACT_VIA_HEIGHT,
  CONTACT_VIA_SHOW_MODES,
  DEFAULT_OPERATOR_AVATAR,
  IS_MOBILE_WIDTH
};
