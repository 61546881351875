// https://reactjs.org/docs/javascript-environment-requirements.html
import 'raf/polyfill';
import 'custom-event-polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import './app.scss';

import App from './containers/App';
import store from './store';
import { getRandomString, eventParser } from './utils/helpers';
import { initMediator } from './services/mediator';
import { NODE_ENV } from 'config/constants';
import logger from './utils/logger';
smoothscroll.polyfill();

const rootElementId = `vcRoot${getRandomString(12)}`;
const el = document.createElement('div');
el.setAttribute('id', rootElementId);
el.setAttribute('class', ['vc_root']);
document.getElementsByTagName('body')[0].appendChild(el);
const MOUNT_NODE = document.getElementById(rootElementId);
export const root = MOUNT_NODE;

/**
 * App Runner
 */
runApp({
  root: MOUNT_NODE
});

function runApp({ root }) {
  attachGlobalListeners();
  initMediator();
  coreRender({
    root
  });
}

/**
 * Root render
 * @param {object} - list of properties (root - future parent DOM element for the App)
 */
function coreRender({ root }) {
  if (NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept();
    }
    logger.info('Looks like we are in development mode!');
  }

  return render(
    <Provider store={store}>
      <App />
    </Provider>,
    root
  );
}

/**
 * Attach listeners for iframe events
 */
function attachGlobalListeners() {
  window.onunhandledrejection = event => {
    if (!window.VC_APP_INITALIZED) {
      return logger.info(
        'Unable to locate settings for VisitorChat service. If you believe this to be a mistake, please contact your account manager.'
      );
    }
    logger.error(
      `[VC] Unhandled promise rejection. Reason: ${event.reason} ${event}`
    );
  };

  // add iframe listener
  if (window.addEventListener) {
    // For standards-compliant web browsers
    window.addEventListener(
      'message',
      event => {
        eventParser(event);
      },
      false
    );
  } else {
    window.attachEvent('onmessage', event => {
      eventParser(event);
    });
  }
}
