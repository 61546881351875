import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Cloud1, Cloud2 } from './commonComponents';
import { PlaneTemplate } from './customTemplate';

const Container = styled.div`
  width: ${props => 180 * props.koef}px;
  height: ${props => 150 * props.koef}px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const Road = styled.img`
  width: 100%;
  height: ${props => 3 * props.koef}px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 8;
`;

const Building = styled.img`
  width: ${props => 130 * props.koef}px;
  position: absolute;
  bottom: ${props => 3 * props.koef}px;
  right: 0;
  transition: all 600ms;
  transition-delay: 600ms;
  filter: drop-shadow(-10px -3px 0 rgba(0,0,0,0.04));
  animation: moveBuildingLeft 0.7s;

  @keyframes moveBuildingLeft {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0);
  }
`;

const Car = styled.img`
  width: ${props => 150 * props.koef}px;
  position: absolute;
  bottom: ${props => 3 * props.koef}px;
  right: ${props => 30 * props.koef}px;
  transition: all 600ms;
  transition-delay: 1000ms;
  filter: drop-shadow(-10px -3px 0 rgba(0,0,0,0.04));
  opacity: 0;
  animation: moveCarLeft 0.7s normal forwards linear;
  animation-delay: 0.7s;
  
  @keyframes moveCarLeft {
    0% {
    transform: translateX(130%);
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
`;

const Man = styled.img`
  width: ${props => 55 * props.koef}px;
  position: absolute;
  bottom: 5px;
  right: ${props => 60 * props.koef}px;
  transform-origin: center bottom;
  transition: all 800ms;
  transition-delay: 1500  ms;
  filter: drop-shadow(-10px -3px 0 rgba(0,0,0,0.04));
  opacity: 0;
  animation: moveManLeft 0.7s normal forwards linear;
  animation-delay: 1.4s;
  
  @keyframes moveManLeft {
  0% {
    transform: rotateX(-180deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const CarTemplate = ({ koef }) => {
  return (
    <Fragment>
      <Road
        koef={koef}
        src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/road-blue-auto.png"
      />
      <Container koef={koef}>
        <Cloud1
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/cloud-1.png"
        />
        <Cloud2
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/cloud-2.png"
        />
        <PlaneTemplate koef={koef} />
        <Building
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/buildings-blue-auto.svg"
        />
        <Car
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/car-blue-auto.svg"
        />
        <Man
          koef={koef}
          src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/man-blue-auto.svg"
        />
      </Container>
    </Fragment>
  );
};
