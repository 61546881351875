let mediator = null;

export class Mediator {
  constructor(props = {}) {
    this._components = {};

    Object.keys(props).forEach(propName => {
      this[`_${propName}`] = props[propName];
    });
  }

  /**
   *
   * @param {string} name - name (key) of the component
   * @param {*} component - component with his methods
   */
  attachComponent = (name, component) => {
    this._components[name] = component;
  };

  /**
   *
   * @param {string} name - name (key) of the component
   */
  detachComponent = name => {
    delete this._components[name];
  };

  /**
   * Check exists or not the component
   * @param {string} name - name (key) of the component
   * @returns {boolean}
   */
  isExistsComponent = name => {
    return !!this._components[name];
  };

  /**
   *
   * @param {string} subject - who is calling action
   * @param {string} object - by who are performed this action
   * @param {*} action - the custom action
   */
  toDo = (subject, object, action) => {
    if (typeof (this._components[object] || {}).subscriber === 'function') {
      this._components[object].subscriber({ subject, object, action });
    }
  };
}

/*
 * Initialization
 * @param {object} props - some properties
 */
function initMediator(props) {
  mediator = new Mediator(props);
}

/*
 * Return instance of Axios
 * @returns {object}
 */
function getMediator() {
  return mediator;
}

export { initMediator, getMediator };
