import React from 'react';
//
import { LiComp, UlComp } from './TabsStyle';
import { getLightOrDarkByHex, gradientToString } from 'utils/helpers';

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{"borderColor": string}|string}
   */
  getBorderColor() {
    const { settings = {} } = this;
    let borderColor = {};

    if (settings.cb_color_from && settings.cb_color_to) {
      borderColor = {
        borderColor: `transparent ${settings.cb_color_to} transparent transparent`
      };
    } else {
      const cbColorV = settings.cb_color_from || settings.cb_color_to;
      borderColor = {
        borderColor: `transparent ${cbColorV} transparent transparent`
      };
    }

    return borderColor;
  }

  /**
   *
   * @returns {{"borderColor": string}}
   */
  getTabCloseButtonColor() {
    const { settings = {} } = this;
    let borderColor = { borderColor: '#000' };

    if (settings.cb_color_from && settings.cb_color_to) {
      borderColor = {
        borderColor: getLightOrDarkByHex(settings.cb_color_to)
          ? '#000000'
          : '#ffffff'
      };
    }

    return borderColor;
  }

  /**
   *
   * @returns {{"background": string}}
   */
  getActiveButtonBackground() {
    const { settings = {} } = this;
    let background = {};

    if (settings.cb_color_from && settings.cb_color_to) {
      background = {
        background: gradientToString(
          [settings.cb_color_to, settings.cb_color_from],
          'left'
        )
      };
    }

    return background;
  }
}

/**
 *
 * @param tabs
 * @param global
 * @param onSelect
 * @returns {*}
 */
const index = ({ data = {}, global, onSelect, onDelete }) => {
  const { settings } = global;
  const Styles = new StylesConstructor({ settings });
  const TAB_HEIGHT = 65; // approximate height in PX
  const APPROXIMATE_HEADER_HEIGHT = 70;
  const maxTabs = Math.floor(
    (+settings.cb_size_height - APPROXIMATE_HEADER_HEIGHT) / TAB_HEIGHT
  );
  let roomNames = Object.keys(data);
  const ulСompStyle = {
    top: `${APPROXIMATE_HEADER_HEIGHT}px`,
    ...Styles.getBorderColor()
  };

  if (roomNames.length < 2) return null;
  if (roomNames > maxTabs) roomNames = roomNames.slice(0, maxTabs);

  return (
    <UlComp style={ulСompStyle}>
      {roomNames.map((roomName, index) => (
        <LiComp
          key={`room_tab_${index}`}
          className={`${index > 0 ? 'tab_not-first' : 'tab_first'} ${
            data[roomName].isActive ? 'active' : ''
          }`}
          active={data[roomName].isActive}
          isNew={data[roomName].unreadMessages > 0} // data[roomName].status === chatTypes.STATUS_NEW
          onClick={() => onSelect({ index, room: roomName })}
          closeButtonColor={Styles.getTabCloseButtonColor()}
          activeButtonBackground={Styles.getActiveButtonBackground()}
        >
          {data[roomName].closable ? (
            <span
              className="icon_up close"
              onClick={event => {
                event.stopPropagation();
                onDelete({ index, room: roomName });
              }}
            >
              x
            </span>
          ) : (
            <span className="icon_up">&nbsp;</span>
          )}
          <span className="label">{index + 1}</span>
        </LiComp>
      ))}
    </UlComp>
  );
};

export default index;
