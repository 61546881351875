import { fbAdhoc } from '../../utils/adhoc';
import {
  CTA__ACTIVATE,
  CTA__CLOSE,
  CTA__DEACTIVATE,
  CTA__OPEN,
  CTA__TRANSITION_CHANGE
} from '../actionConstants';

export function openCta() {
  return (dispatch, getState) => {
    const { chatBox, callToAction } = getState();
    const { getFBAvailability } = fbAdhoc();
    const fbIsAvailable = getFBAvailability({ store: { getState } });

    if (
      !fbIsAvailable ||
      (chatBox.onceOpened && !callToAction.showPermanently)
    ) {
      return;
    }

    dispatch({
      type: CTA__OPEN
    });
  };
}

export function closeCta(payload) {
  return dispatch => {
    dispatch({
      type: CTA__CLOSE,
      payload
    });
  };
}

export function activateCTA(showPermanently) {
  return dispatch => {
    dispatch({
      type: CTA__ACTIVATE,
      payload: {
        showPermanently: showPermanently
      }
    });
  };
}

export function deactivateCTA() {
  return dispatch => {
    dispatch({
      type: CTA__DEACTIVATE
    });
  };
}

export function transitionChangeCTA(payload) {
  return dispatch => {
    dispatch({
      type: CTA__TRANSITION_CHANGE,
      payload
    });
  };
}
