import React, { Component } from 'react';
//
import {
  Form,
  SendMessageWrap,
  FieldWrapper,
  EmptyPanel,
  PolicyText
} from './../sms/smsStyle';
import { CbSendMsgField } from 'components/ChatBox/components/Forms/common/TextareaField';
import SendBtn from 'components/ChatBox/components/Forms/common/SendBtn';
import RadioButton from 'components/Common/RadioButton';
import { getProp } from 'utils/helpers';
import { getMediator } from 'services/mediator';
import { PUBLIC_SITE_URL } from 'config/constants';

export const COMPONENT_NAME = 'contactVia:whatsappForm';
const INITIAL_STATE = {
  messageFocused: false,
  message: '',
  policy: false,
  messageError: false,
  policyError: false
};

class WhatsappForm extends Component {
  constructor(props) {
    super(props);
    this._componentName = props.componentName || COMPONENT_NAME;
    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    getMediator().attachComponent(this._componentName, this);
  }

  componentWillUnmount() {
    getMediator().detachComponent(this._componentName);
  }

  /**
   * Incoming events handler
   * @param props
   */
  subscriber = (props = {}) => {
    const { action = {} } = props;

    if (action.type === 'reset') {
      this.reset();
    }
  };

  handleFocus = type => this.setState(state => ({ [type]: !state[type] }));
  handleMessageFocus = () => this.handleFocus('messageFocused');

  focusHandler = e => {
    this.handleMessageFocus();
    this.props.onFocus(e);
  };

  blurHandler = e => {
    this.handleMessageFocus();
    this.props.onBlur(e);
  };

  changeHandler = (e, fieldName) => {
    const value = getProp(e, 'target.value');
    const updateData = {
      [fieldName]: value
    };

    if (value && this.state[`${fieldName}Error`]) {
      updateData[`${fieldName}Error`] = false;
    }

    this.setState(updateData);
  };

  setPolicy = statement => {
    const upData = { policy: statement };
    const { policyError } = this.state;

    if (policyError && statement) {
      upData.policyError = false;
    }
    this.setState(upData);
  };

  validateHandler = () => {
    const { message, policy } = this.state;
    const messageError = (value => {
      if (typeof value !== 'string') {
        return true;
      }
      if (value.length === 0) {
        return true;
      }
      return false;
    })(message);
    const policyError = (value => {
      return !value;
    })(policy);
    const anError = messageError || policyError;

    this.setState({ messageError });
    this.setState({ policyError });

    return anError;
  };

  onSubmitHandler = (e = {}) => {
    if (typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    if (this.validateHandler()) {
      return;
    }

    const { message } = this.state;
    const values = {
      message
    };

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit({ values });
    }
  };

  reset = () => {
    this.setState(Object.assign({}, INITIAL_STATE));
  };

  render() {
    const { bgColor } = this.props;
    const { messageFocused, message, messageError, policyError } = this.state;
    const color = 'black';

    return (
      <Form
        onSubmit={this.onSubmitHandler}
        onKeyDown={e => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            this.onSubmitHandler();
          }
        }}
      >
        <SendMessageWrap
          bgColor={messageFocused ? 'white' : bgColor}
          color={messageFocused ? 'black' : 'white'}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          hasError={!!messageError}
        >
          <CbSendMsgField
            name="message"
            type="text"
            placeholder="Your message"
            koef={1}
            color={message && 'black'}
            wrapper={{
              width: '80%'
            }}
            input={{
              onChange: e => this.changeHandler(e, 'message'),
              value: message
            }}
          />
        </SendMessageWrap>
        <button
          style={{
            width: '100%',
            border: 'none',
            backgroundColor: '#efefef',
            height: '40px',
            cursor: 'pointer',
            fontWeight: 600
          }}
        >
          Send Whatsapp
        </button>
        <FieldWrapper style={{ position: 'relative', zIndex: 2 }}>
          <EmptyPanel
            className={`empty-panel row-privacy-policy ${
              policyError ? 'has-error' : ''
            }`}
          >
            <RadioButton color={color} onChange={e => this.setPolicy(e)} />
            <PolicyText activeColor={color}>
              I have read and accept&nbsp;
              <a
                href={`${PUBLIC_SITE_URL}/live-chat-privacy`}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Privacy Policy
              </a>
            </PolicyText>
          </EmptyPanel>
        </FieldWrapper>
      </Form>
    );
  }
}

export default WhatsappForm;
