import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';
//
import { HEADER_OPERATOR_BLOCK_WIDTH } from './components/Header/headerStyle';

export default styled.div`
  position: absolute;
  height: 80px;
  cursor: move;
  ${ifProp(
    'useOffset',
    css`
      margin-left: ${HEADER_OPERATOR_BLOCK_WIDTH}px;
      width: calc(88% - ${HEADER_OPERATOR_BLOCK_WIDTH}px);
    `,
    css`
      width: 85%;
    `
  )};

  ${ifProp(
    { showingBackButton: false },
    css`
      width: 85%;
    `,
    css`
      width: 70%;
      left: 15%;
    `
  )};
`;
