import {
  CB__ACTIVATE,
  CB__CLEAR,
  CB__CLOSE,
  CB__DEACTIVATE,
  CB__INIT,
  CB__OPEN,
  CB__TRANSITION_CHANGE,
  CB_SET_IS_SHOWING_LAM,
  MB__OPEN,
  SMS__OPEN
} from '../actionConstants';

export function activateCb() {
  return dispatch => {
    dispatch({
      type: CB__ACTIVATE
    });
  };
}

export function initCb(payload) {
  return dispatch => {
    dispatch({
      type: CB__INIT,
      payload
    });
  };
}

export function deactivateCb() {
  return dispatch => {
    dispatch({
      type: CB__DEACTIVATE
    });
  };
}

export function openCb(payload) {
  return dispatch => {
    dispatch({
      type: CB__OPEN,
      payload
    });
  };
}

export function openSms() {
  return dispatch => {
    dispatch({
      type: SMS__OPEN
    });
  };
}

export function closeCb() {
  return dispatch => {
    dispatch({
      type: CB__CLOSE
    });
  };
}

export function openMb() {
  return dispatch => {
    dispatch({
      type: MB__OPEN
    });
  };
}

export function transitionChangeCb(payload) {
  return dispatch => {
    dispatch({
      type: CB__TRANSITION_CHANGE,
      payload
    });
  };
}

export function clearCb() {
  return dispatch => {
    dispatch({
      type: CB__CLEAR
    });
  };
}

export function setIsShowingLam(active, canGoBack = true) {
  return dispatch => {
    dispatch({
      type: CB_SET_IS_SHOWING_LAM,
      payload: { active, canGoBack }
    });
  };
}
