import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Transition } from 'react-transition-group';
//
import { FrameWrapper, frameWrapperFormatProps } from './frameWrapper';
import FramedComponent from '../Common/framedComponent';
import {
  closeSb,
  sendMessageSb,
  initCloseTimerSb,
  deactivateCloseTimerSb,
  transitionChangeSb,
  setDimensionSb
} from '../../ducks/speechBubble/speechBubble.action';
import { getContainerSettings, getTemplate, calculateKoef } from './helper';
import {
  ContentWrapper,
  contentWrapperFormatProps,
  Container,
  OperatorContainer,
  OperatorInfo,
  OperatorAvatar,
  InitMessage,
  CloseBtn,
  GirlImg
} from './contentWrapper';

import SendMessageForm from '../ChatBox/components/Forms/sendMessage/sendMessageForm';
import ContactViaComp from 'components/ContactVia/contactViaComp';
import { setShowMode as contactViaSetShowMode } from 'ducks/contactVia/contactVia.action';
import {
  getCurrentTime,
  lightenDarkenColor,
  getLightOrDarkByHex,
  useHeap
} from '../../utils/helpers';
import FloatingButton from './FB';
import {
  CONTACT_VIA_SHOW_MODES,
  transitionStages,
  CONTACT_VIA_HEIGHT
} from '../../config/constants';
import SETTING from '../../helpers/mobile-desktop-settings';
import { getVcSetting, hasVcSettings } from '../../utils/getVCSettings';

const SEND_MESSAGE_FORM_COMPONENT_NAME = 'speechBubble:sendMessageForm';

class SpeechBubble extends PureComponent {
  timer = null;

  constructor(props) {
    super(props);
    const bckColor = this.getBackgroundColor();
    const bgIsLight = getLightOrDarkByHex(bckColor);

    this.GIRL_IMG_URL =
      'https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/illustration-blue.png';

    this.state = {
      msgInputFocus: false,
      bckColor,
      bgIsLight,
      girlOffset: null
    };
  }

  componentDidUpdate(prevProps) {
    const { speechBubble, handleSocketSpeechBubbleOpened } = this.props;

    if (!speechBubble.open && prevProps.speechBubble.open) {
      handleSocketSpeechBubbleOpened();
      this.takeFbMetaData();
    }
  }

  componentDidMount() {
    window.openCTATimeout = null;
  }

  parentState() {
    return {
      get: () => this.state,
      set: (newState, callback) => this.setState(newState, callback)
    };
  }

  takeFbMetaData = () => {
    const { settings } = this.props.global;
    const isFbEnabled = SETTING('FB_ONLINE_BUTTON', settings) === 'on';
    const isSbButtonEnabled = SETTING('SB2_FB_ENABLED', settings) === 'on';

    if (isSbButtonEnabled) {
      return;
    }

    const heap = useHeap().getHeap();

    this.setState(
      () => ({
        dimension: {
          width: isFbEnabled ? heap.fbBtnWidth : 0,
          height: isFbEnabled ? heap.fbBtnHeight : 0
        }
      }),
      this.props.setDimensionSb
    );
  };

  closeSbHandler = e => {
    this.props.closeSb();
    e.stopPropagation();
  };

  handleSubmit = (event = {}) => {
    const { value } = event;
    const { activeRoom } = this.props.global;
    const { handleSocketSendMessage, sendMessageSb } = this.props;

    if (!value) {
      return;
    }

    handleSocketSendMessage(value);

    sendMessageSb({
      message: {
        author: 'visitor',
        date: getCurrentTime(),
        text: value
      },
      room: activeRoom
    });
  };

  handleBlur = e => {
    if (!e.target.value) {
      this.props.initCloseTimerSb();
    }
    this._hasFocus = false;
    this.setState({
      msgInputFocus: !this.state.msgInputFocus
    });
  };

  handleFocus = e => {
    this.props.deactivateCloseTimerSb();
    this._hasFocus = true;
    this.setState({
      msgInputFocus: !this.state.msgInputFocus
    });
  };

  attachNode = node => {
    this._frameWrapper = node;
  };

  transitionEnterHandler = () => {
    this.props.transitionChangeSb({ stage: transitionStages.ENTER });
    clearTimeout(this.timer);
  };

  transitionEnteredHandler = () => {
    this.props.transitionChangeSb({ stage: transitionStages.ENTERED });
  };

  transitionExitHandler = (props = {}) => {
    this.props.transitionChangeSb({ stage: transitionStages.EXIT });
    if (props.timer) {
      this.timer = setTimeout(
        () => (this._frameWrapper.style.display = 'none'),
        props.timer
      );
    }
  };

  transitionExitedHandler = () => {
    this.props.transitionChangeSb({ stage: transitionStages.EXITED });
  };

  getBackgroundColor() {
    const { global = {} } = this.props;
    const { settings = {} } = global;
    return settings.cb_background_color || '#232323';
  }

  checkFormVisibility = () => {
    const {
      global,
      rooms,
      contactViaIsActive,
      contactViaShowMode
    } = this.props;
    // Get active room so we can check if the qualification process is active.
    const activeRoom = rooms.find(room => room.room === global.activeRoom);

    if (
      (contactViaIsActive &&
        contactViaShowMode !== CONTACT_VIA_SHOW_MODES.INITIAL) ||
      !activeRoom ||
      (activeRoom.qualificationProcess &&
        activeRoom.qualificationProcess.active)
    ) {
      return false;
    }

    return true;
  };

  checkContactViaComponentVisibility = () => {
    const { contactVia } = this.props;
    return contactVia.active;
  };

  getContactViaComponent = () => {
    const { contactVia, contactViaSetShowMode } = this.props;
    if (!this.checkContactViaComponentVisibility()) {
      return null;
    }

    return (
      <ContactViaComp
        showMode={contactVia.showMode}
        onChangeShowMode={contactViaSetShowMode}
        parentTarget="speechBubble"
      />
    );
  };

  render() {
    const contactVia = this.props.contactVia;
    const { settings, operatorOnline, uiLocked } = this.props.global;
    const isSbButtonEnabled = SETTING('SB2_FB_ENABLED', settings) === 'on';
    const { open, dimensionsSet } = this.props.speechBubble;
    const dimensionsSetForFB = isSbButtonEnabled ? dimensionsSet : true;
    const settingsSize = SETTING('SB2_SIZE', settings);
    const koef = calculateKoef(+settingsSize);
    settings.sb2_coef = koef;
    const { dimension, bckColor, bgIsLight } = this.state;
    const theme = SETTING('SB2_THEME', settings) || 'car';
    const operatorProps = {
      name: SETTING('SB2_OPERATOR_NAME', settings),
      avatar: SETTING('SB2_IMAGE', settings)
    };
    const containerSettings = getContainerSettings(settings);
    const companyName = SETTING('SB2_COMPANY_NAME', settings);

    let initialText = SETTING('SB2_INITIAL_MESSAGE', settings);
    // See if we have an override for the Speech Bubble text.
    if (hasVcSettings()) {
      const overriddenMessage = getVcSetting('sbText');
      if (overriddenMessage) {
        initialText = overriddenMessage;
      }
    }

    const hasFormVisibility = this.checkFormVisibility();
    const sb2FontColor = SETTING('SB2_FONT_COLOR', settings);
    const frameWrapperProps = (anyProps = {}) => {
      return frameWrapperFormatProps({
        settings,
        parentState: this.parentState(),
        dimension,
        operatorOnline,
        uiLocked,
        useContactVia: contactVia.active,
        hasFormVisibility,
        ...anyProps
      });
    };
    const contentWrapperProps = (() => {
      return contentWrapperFormatProps({
        settings,
        koef,
        useContactVia: contactVia.active,
        hasFormVisibility
      });
    })();
    const timeoutEnterExit = 400;
    const FbProps = {
      sbParentState: this.parentState()
    };
    /**
     * This is dirty hack for render girl img over iframe
     */
    const Girl =
      theme === 'girl' ? (
        <GirlImg
          src={this.GIRL_IMG_URL}
          koef={koef}
          girlOffset={this.state.girlOffset}
        />
      ) : null;

    return (
      <Fragment>
        <Transition
          in={open && dimensionsSetForFB}
          appear
          unmountOnExit
          onEnter={this.transitionEnterHandler}
          onExit={() => this.transitionExitHandler({ timer: timeoutEnterExit })}
          onEntered={this.transitionEnteredHandler}
          onExited={this.transitionExitedHandler}
          timeout={{ enter: timeoutEnterExit, exit: timeoutEnterExit }}
        >
          {transitionState => (
            <FrameWrapper
              id="sbElement"
              ref={this.attachNode}
              {...frameWrapperProps({ transitionState })}
            >
              {Girl}
              <FramedComponent title={'VC Speech Bubble'}>
                <ContentWrapper {...contentWrapperProps}>
                  <Container {...containerSettings}>
                    <CloseBtn
                      koef={koef}
                      color={sb2FontColor}
                      onClick={this.closeSbHandler}
                      onKeyDown={e =>
                        e.key === 'Enter' ? this.closeSbHandler(e) : true
                      }
                    />
                    <OperatorContainer koef={koef}>
                      <OperatorAvatar
                        url={operatorProps.avatar}
                        isOnline={operatorOnline}
                        koef={koef}
                      />
                      <OperatorInfo koef={koef}>
                        <p>{operatorProps.name}</p>
                        <span>{companyName}</span>
                      </OperatorInfo>
                    </OperatorContainer>
                    <InitMessage koef={koef}>
                      <p>{initialText}</p>
                    </InitMessage>
                    {getTemplate(theme, settings)}
                  </Container>
                  {hasFormVisibility && (
                    <SendMessageForm
                      componentName={SEND_MESSAGE_FORM_COMPONENT_NAME}
                      onSubmit={this.handleSubmit}
                      bgColor={
                        this.state.msgInputFocus
                          ? 'white'
                          : lightenDarkenColor(bckColor, bgIsLight ? -55 : -35)
                      }
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      hasFocus={this._hasFocus}
                      onTAChange={props => {}}
                      onTAResize={e => {
                        const height = e.target.clientHeight;
                        setTimeout(() =>
                          this.setState({
                            girlOffset:
                              height +
                              (contactVia.active ? CONTACT_VIA_HEIGHT - 2 : 0)
                          })
                        );
                      }}
                      message={'Enter your message'}
                      operatorOnline={operatorOnline}
                      settings={settings}
                      koef={koef}
                    />
                  )}
                  {this.getContactViaComponent()}
                </ContentWrapper>
              </FramedComponent>
            </FrameWrapper>
          )}
        </Transition>
        {isSbButtonEnabled && <FloatingButton {...FbProps} />}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const { global, speechBubble, rooms, contactVia } = state;
  return { global, speechBubble, rooms, contactVia };
};

const mapDispatchToProps = dispatch => {
  return {
    closeSb: bindActionCreators(closeSb, dispatch),
    sendMessageSb: bindActionCreators(sendMessageSb, dispatch),
    initCloseTimerSb: bindActionCreators(initCloseTimerSb, dispatch),
    deactivateCloseTimerSb: bindActionCreators(
      deactivateCloseTimerSb,
      dispatch
    ),
    transitionChangeSb: bindActionCreators(transitionChangeSb, dispatch),
    setDimensionSb: bindActionCreators(setDimensionSb, dispatch),
    contactViaSetShowMode: bindActionCreators(contactViaSetShowMode, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeechBubble);
