import React from 'react';
import PropTypes from 'prop-types';
import { FaFacebookMessenger } from 'react-icons/fa';
import styled from 'styled-components';

import { Footer } from '../../contactViaStyle';

const FbFooter = styled(Footer)`
  cursor: pointer;
  color: #fff;
  background-color: #0084ff;

  &:hover {
    background-color: #2093ff;
  }
  &:focus {
    background-color: #0084ff;
  }
`;

export default function FacebookButton({ isMulti, width = '50%' }) {
  return (
    <FbFooter
      className={isMulti ? 'multi' : 'single'}
      width={width}
      onClick={handleClick}
      tabIndex={0}
      onKeyDown={({ key }) => (key === 'Enter' ? handleClick() : true)}
    >
      <FaFacebookMessenger /> Messenger
    </FbFooter>
  );
}

function handleClick() {
  window.VisitorChatDestroy();
  window.FB.XFBML.parse();
}

FacebookButton.propTypes = {
  isMulti: PropTypes.bool,
  width: PropTypes.string
};
