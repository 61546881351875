import styled from 'styled-components';
import { prop } from 'styled-tools';

const ChatListWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  overflow-x: hidden;
  margin: 15px 0;
  width: 100%;

  position: relative;
  ${prop('_background')};
  ${prop('_borderColor')};

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-button {
    width: 13px;
    height: 13px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #666;
    border: 0px none #fff;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #fff;
    border-radius: 50px;
  }
`;

const ChatList = styled.div`
  margin: 0;
`;

const WaitingOperatorMessage = styled.p`
  position: relative;
  top: 150px;
  line-height: 1.5em;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-align: center;
  width: 100%;
`;

const ShortMessage = styled.div`
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  top: 180px;
  line-height: 1.5em;
  color: rgba(255, 255, 255, 0.4);
  font-size: 1.1em;
  text-align: center;
`;

export { ChatListWrapper, ChatList, WaitingOperatorMessage, ShortMessage };
