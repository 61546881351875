import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: ${props => 170 * props.koef}px;
  height: ${props => 152 * props.koef}px;
  position: absolute;
  right: 16px;
  bottom: 0;
  z-index: 1;
  text-align: center;
`;

const CustomImg = styled.img`
  max-width: calc(100% - 12px);
  max-height: calc(100% - 12px);
  padding-top: 6px;
`;

export const CustomTemplate = ({ url, koef }) => {
  return <Container koef={koef}>{url && <CustomImg src={url} />}</Container>;
};

const Plane = styled.img`
  width: ${props => 45 * props.koef}px;
  position: absolute;
  right: ${props => -10 * props.koef}px;
  bottom: ${props => 120 * props.koef}px;
  transform: translate(${props => 50 * props.koef}px, ${props =>
  50 * props.koef}px);
  opacity: 0;
  z-index: 2;
  
  animation: planeMove 2s linear;
  animation-delay: 5000ms;
  animation-fill-mode: forwards;
  
  @keyframes planeMove {
    0% {
      transform: translate(${props => 50 * props.koef}px, ${props =>
  50 * props.koef}px);
      opacity: 0;
    }
    20% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate(${props => -200 * props.koef}px, ${props =>
  -50 * props.koef}px);
    }
`;

export const PlaneTemplate = ({ koef }) => {
  return (
    <Plane
      koef={koef}
      src="https://s3-eu-west-1.amazonaws.com/visitor-chat/img/chat-box/speech-bubble/plane-blue.png"
    />
  );
};
