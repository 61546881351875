import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import { ExitIcon, CloseIcon } from 'common/svg';

export const HEADER_TITLE_BLOCK_HEIGHT = 72;
export const HEADER_OPERATOR_BLOCK_WIDTH = 52;
export const HEADER_TITLE_HEIGHT = 16;
export const HEADER_CLOSE_ICON_SIZE = 19;

const Header = styled.div`
  ${prop('_borderRadius')};
  ${prop('_backgroundColor')};
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

const HeaderImg = styled.div`
  width: 100%;
  height: 100%;
  max-height: ${HEADER_TITLE_BLOCK_HEIGHT}px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  ${prop('style')};
`;

const ImageWrapper = styled.div`
  float: left;
  position: relative;
  width: 100%;
  height: ${HEADER_TITLE_BLOCK_HEIGHT}px;
  overflow: hidden;
  ${prop('style')}
`;

const Title = styled.p`
  position: absolute;
  top: ${HEADER_TITLE_BLOCK_HEIGHT / 2 - HEADER_TITLE_HEIGHT / 2}px;
  width: 100%;
  line-height: ${HEADER_TITLE_HEIGHT}px;
  height: ${HEADER_TITLE_HEIGHT}px;
  z-index: 9999;
  font-weight: bold;
  font-size: 100%;
  font-family: Arial, serif;
  text-align: center;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${prop('style')}
`;

const Operator = styled.div`
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: ${HEADER_OPERATOR_BLOCK_WIDTH}px;
  font-family: Arial, serif;
  font-size: 0.8em;
  overflow: hidden;
`;

const OperatorAvatar = styled.div`
  ${ifProp(
    'src',
    css`
      background-image: url(${prop('src')});
    `,
    css`
      opacity: 0.25;
    `
  )};
  ${ifProp(
    'noDefault',
    css`
      background-image: none;
    `
  )};
  background-size: cover;
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
`;

const OperatorName = styled.p`
  width: ${HEADER_OPERATOR_BLOCK_WIDTH}px;
  text-align: center;
  color: #9c9c9c;
  font-size: 12px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  ${prop('style')}
`;

const CloseBtnWrapper = styled.div`
  ${ifProp(
    'active',
    css`
      display: table-cell;
    `,
    css`
      display: none;
    `
  )};
  position: relative;
  vertical-align: middle;
  text-align: right;
  cursor: pointer;
  width: ${HEADER_OPERATOR_BLOCK_WIDTH}px;
  height: ${HEADER_CLOSE_ICON_SIZE}px;
  border: none;
  transition: opacity 500ms;

  > * {
    margin-right: 4px;
    opacity: 0.3;
  }

  &:hover {
    > * {
      opacity: 1;
    }
  }
`;

const BackBtnWrapper = styled.div`
  cursor: pointer;
  opacity: 0.3;
  transition: opacity 500ms;
  z-index: 1;

  ${prop('style')};

  &:hover {
    opacity: 1;
  }
`;

const BackBtn = (props = {}) => {
  const { webSettings = [], color, size = 32, onClick = () => {} } = props;
  if (webSettings.length < 2) {
    return null;
  }
  const wrapperStyle = {
    width: `${size}px`,
    height: `${size}px`
  };
  return (
    <BackBtnWrapper
      style={wrapperStyle}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={({ key }) => (key === 'Enter' ? onClick() : true)}
    >
      <ExitIcon size={size} color={color} />
    </BackBtnWrapper>
  );
};

const CloseBtn = (props = {}) => {
  const { active, _color, width, onClick = () => {} } = props;
  return (
    <CloseBtnWrapper
      active={active}
      onClick={onClick}
      onKeyDown={e => (e.key === 'Enter' ? onClick(e) : true)}
      tabIndex={0}
    >
      <CloseIcon size={width} color={_color} />
    </CloseBtnWrapper>
  );
};

const GoBackButtonWrapper = styled.div`
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: ${HEADER_OPERATOR_BLOCK_WIDTH}px;
  font-family: Arial, serif;
  font-size: 0.8em;
  overflow: hidden;
`;
const GoBackButton = ({ show, onClickHandler, color, onKeyDown }) => {
  return (
    show && (
      <GoBackButtonWrapper
        onClick={onClickHandler}
        onKeyDown={onKeyDown}
        tabIndex={0}
      >
        <ExitIcon color={color} />
      </GoBackButtonWrapper>
    )
  );
};

const TitleBlock = styled.div`
  display: table;
  height: ${HEADER_TITLE_BLOCK_HEIGHT}px;
  width: calc(100% - 20px);
  ${prop('style')}
`;

const TitleBlockInner = styled.div`
  display: table-row;
`;

const Message = styled.div`
  ${ifProp(
    '_color',
    css`
      color: ${prop('_color')};
    `,
    css`
      color: #000000;
    `
  )};
  text-align: center;
  font-size: 14px;
  font-family: Arial, serif;
  padding: 15px 0 15px;
  margin: 0 10px;
  overflow: hidden;

  .dealername {
    display: inline-block;
    max-width: calc(100% - 36px);
    font-weight: 700;
  }

  p {
    line-height: 2em;
    max-height: 50px;
    overflow: hidden;
  }

  & > p:first-child {
    margin-top: -10px;
  }

  & > p:not(:first-child) {
    padding-top: 2px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

const MessageIconWrapper = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  left: 10px;
  top: -8px;

  svg {
    width: 100%;
    height: auto;

    & > path {
      fill: ${props => props.color || '#000000'};
    }

    & > g {
      fill: ${props => props.color || '#000000'};
    }
  }
`;

const HeaderWrapper = styled.div`
  animation-delay: 3s;
  animation: bounceHeader 1s ease-in-out;
  animation-fill-mode: forwards;
  opacity: 0;

  @keyframes bounceHeader {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

const RoomName = styled.p`
  margin: 0 10px;
  padding: 6px 0;
  opacity: 0.8;
  font-style: italic;
  color: #000000;
  text-align: center;
  font-size: 12px;
  font-family: Arial, serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  ${prop('style')}
`;

export {
  Header,
  Title,
  ImageWrapper,
  Operator,
  OperatorAvatar,
  OperatorName,
  CloseBtn,
  GoBackButton,
  Message,
  MessageIconWrapper,
  TitleBlock,
  TitleBlockInner,
  HeaderImg,
  BackBtn,
  HeaderWrapper,
  RoomName
};
