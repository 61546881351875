import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
//
import { CloseIcon } from 'common/svg';

const Header = styled.div`
  ${prop('_backgroundColor')};
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: none;
  height: 55px;
  width: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`;

const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-left: 12px;
  overflow: hidden;
  ${prop('style')}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 100%;
  font-family: Arial, serif;
  text-align: center;
  z-index: 9999;
  position: absolute;
  ${prop('style')}
`;

const CloseBtnWrapper = styled.div`
  ${ifProp(
    'active',
    css`
      display: block;
    `,
    css`
      display: none;
    `
  )};
  cursor: pointer;
  width: 28px;
  height: 28px;
  opacity: 0.3;
  border: none;
  margin: 17px 10px 0 0;
  transition: opacity 500ms;

  &:hover {
    opacity: 1;
  }
`;

const CloseBtn = (props = {}) => {
  const { active, _color, width, onClick = () => {} } = props;
  return (
    <CloseBtnWrapper
      active={active}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={({ key }) => (key === 'Enter' ? onClick() : true)}
    >
      <CloseIcon size={width} color={_color} />
    </CloseBtnWrapper>
  );
};

const TitleBlock = styled.div`
  padding-bottom: 10px;
  justify-content: left;
  flex-direction: row;
  display: flex;
  overflow: hidden;
  ${prop('style')}
`;

export { Header, Title, TitleWrapper, CloseBtn, TitleBlock };
