import React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const TextAreaWrap = styled.div`
  margin: 0;
  width: 100%;
  height: 100%;
  ${prop('style')}

  textarea {
    display: block;
    overflow: auto;
    box-sizing: border-box;
    border: none;
    outline: none;
    resize: none;
    border-radius: 5px;
    transition: background-color 300ms;
    min-height: ${props => 52 * props.koef}px;
    font-size: ${props => 16 * props.koef}px;
    background: none;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    width: 100%;
    padding: ${props => 15 * props.koef}px ${props => 12 * props.koef}px;

    :focus {
      color: black;
    }

    :disabled {
      cursor: not-allowed;
    }

    :required {
      box-shadow: none;
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    ::-webkit-scrollbar-button {
      width: 13px;
      height: 13px;
    }

    ::-webkit-scrollbar-corner {
      background: transparent;
    }

    ::-webkit-scrollbar-track {
      background: #666;
      border: 0px none #fff;
      border-radius: 50px;
    }

    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #fff;
      border-radius: 50px;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cccccc;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cccccc;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: #cccccc;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: #cccccc;
    }

    ::placeholder {
      color: #cccccc;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent !important;
    }

    &:focus::-moz-placeholder {
      color: transparent !important;
    }

    &:focus:-moz-placeholder {
      color: transparent !important;
    }
  }
`;

const CbSendMsgField = ({
  placeholder,
  input,
  onResize = null,
  koef,
  wrapper = {},
  maxRows = 6,
  disabled
}) => {
  const wrapperProps = { koef, ...wrapper };
  return (
    <TextAreaWrap {...wrapperProps}>
      <TextareaAutosize
        {...input}
        onResize={onResize}
        required="required"
        name="short-question"
        placeholder={placeholder}
        rows={1}
        maxRows={maxRows}
        disabled={disabled}
        maxLength={150}
      />
    </TextAreaWrap>
  );
};

const LmSendMsgField = ({ placeholder, input, koef = 1, wrapper = {} }) => {
  const wrapperProps = { koef, ...wrapper };
  return (
    <TextAreaWrap {...wrapperProps}>
      <TextareaAutosize
        required="required"
        type="text"
        name="short-question"
        placeholder={placeholder}
        rows={3}
        maxRows={3}
        {...input}
      />
    </TextAreaWrap>
  );
};

export { CbSendMsgField, LmSendMsgField };
