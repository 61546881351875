import React from 'react';
import styled from 'styled-components';
//
import TimerWrapper from './timerWrapper';
import SendHistoryForm from './sendHistoryForm';

const Wrapper = styled.div`
  width: 95%;
`;

class ChatListTimer extends React.Component {
  render() {
    const {
      remaining,
      settings = {},
      activeRoomInfo = {},
      handleSubmit
    } = this.props;
    const text = {
      upcoming: 'Чат будет завершен через {time} сек',
      coming: 'Сессия завершена'
    };

    return (
      <Wrapper>
        <TimerWrapper remaining={remaining} text={text}>
          {activeRoomInfo.isShownSendTranscriptForm && (
            <SendHistoryForm onSubmit={handleSubmit} settings={settings} />
          )}
        </TimerWrapper>
      </Wrapper>
    );
  }
}

export { ChatListTimer };
