import React, { Fragment } from 'react';
//
import {
  ChatList,
  WaitingOperatorMessage,
  ShortMessage,
  ChatListWrapper
} from './chatListStyle';
import { Spinner } from '../../../Common/spinner';
import { MessageWrapper, MessageComp } from './messageComp';
import { nl2br } from 'utils/helpers';
import { DomainQualificationProcessComp } from './domainQualificationComp';

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {string|null}
   */
  getWFVText() {
    const { settings = {} } = this;
    if (settings.mb_short_message && settings.mb_short_message.length > 0) {
      return settings.mb_short_message;
    }
    return null;
  }

  /**
   *
   * @returns {{"background-color": *}|{"background-image": string, background: string}}
   */
  getBackground() {
    const { settings = {} } = this;
    const background = {};

    if (settings.cb_background_image)
      background['background-image'] = `url(${settings.cb_background_image})`;
    background['background-repeat'] = 'no-repeat';
    background['background-position'] = 'center';
    return background;
  }

  /**
   *
   * @returns {{"border-color": string}|string}
   */
  getBorderColor() {
    const { settings = {} } = this;
    let borderColor = {};

    if (settings.cb_color_from && settings.cb_color_to) {
      borderColor = {
        'border-color': `transparent ${settings.cb_color_to} transparent transparent`
      };
    } else {
      const cbColorV = settings.cb_color_from || settings.cb_color_to;
      borderColor = {
        'border-color': `transparent ${cbColorV} transparent transparent`
      };
    }

    return borderColor;
  }

  getWaitingOperatorText() {
    const { settings = {} } = this;
    return settings.waiting_operator_text;
  }

  /**
   *
   * @returns {*}
   */
  getTheme() {
    const { settings = {} } = this;
    return settings.Theme;
  }
}

const getSpinner = (props = {}) => {
  const { settings, waitingOperator, Styles } = props;
  const spinnerCompProps = {
    type: 'circle',
    settings,
    style: {
      top: 50,
      height: 32,
      transform: 'scale(0.75)',
      color: '#ffffff'
    }
  };

  if (!waitingOperator) {
    return null;
  }

  return (
    <Fragment>
      <Spinner {...spinnerCompProps} />
      <WaitingOperatorMessage>
        {Styles.getWaitingOperatorText()}
      </WaitingOperatorMessage>
    </Fragment>
  );
};

/**
 * Get `title` view
 * @param {object} props - list of properties (settings, activeRoomInfo)
 * @returns {*}
 */
const getTitle = (props = {}) => {
  const { activeRoomInfo, settings } = props;
  const Styles = new StylesConstructor({ settings });
  const {
    waitingOperator,
    chatListMessages = [],
    qualificationProcess
  } = activeRoomInfo;

  return (() => {
    if (
      chatListMessages.length > 0 ||
      waitingOperator ||
      qualificationProcess.active
    ) {
      return null;
    }
    const wfvText = Styles.getWFVText();
    if (!wfvText) {
      return null;
    }
    return <ShortMessage>{nl2br(wfvText)}</ShortMessage>;
  })();
};

/**
 * Get ready for use `messages` view
 * @param {object} props - list of properties (settings, operators, operatorOnline, messages)
 * @returns {*}
 */
const getParseMessageList = (props = {}) => {
  const { settings, operators, operatorOnline, messages = [] } = props;
  if (messages.length >= 1) {
    return messages.map((item, i) => {
      return (
        <MessageComp
          key={i}
          operatorInfo={operators[item.operatorId]}
          operatorOnline={operatorOnline}
          message={item}
          settings={settings}
        />
      );
    });
  } else {
    return [];
  }
};

/**
 *
 * @param settings
 * @param waitingOperator
 * @param attachNode
 * @returns {*}
 */
const index = ({
  rooms,
  settings,
  activeRoomInfo,
  attachNode,
  operators,
  handleSocketGetTeamOnlineStatus
}) => {
  const Styles = new StylesConstructor({ settings });
  const {
    room,
    waitingOperator,
    operatorInfo,
    chatListMessages = [],
    operatorOnline,
    isOperatorTyping,
    qualificationProcess
  } = activeRoomInfo;

  const chatListWrapperProps = {
    _borderColor: Styles.getBorderColor(),
    _background: Styles.getBackground()
  };
  const showChatListMessages =
    !waitingOperator && (!!operatorInfo || operatorOnline);
  const messagesList = getParseMessageList({
    settings,
    operators,
    operatorOnline,
    messages: chatListMessages
  });
  const messages = (() => {
    if (!showChatListMessages) return [];
    return (
      <MessageWrapper isOperatorTyping={isOperatorTyping}>
        {messagesList}
      </MessageWrapper>
    );
  })();

  return (
    <ChatListWrapper tabIndex={0} ref={attachNode} {...chatListWrapperProps}>
      {getSpinner({ settings, waitingOperator, Styles })}
      {showChatListMessages && (
        <DomainQualificationProcessComp
          handleSocketGetTeamOnlineStatus={handleSocketGetTeamOnlineStatus}
          room={room}
          hasConnectedToOperator={operatorInfo.operatorId !== undefined}
          settings={settings}
          qualificationProcess={qualificationProcess}
          questions={settings.qualification_questions}
        />
      )}
      <ChatList>
        {getTitle({ settings, activeRoomInfo })}
        {messages}
      </ChatList>
    </ChatListWrapper>
  );
};

export default index;
