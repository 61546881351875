import React, { Component, Fragment } from 'react';
import FrameCom, { FrameContextConsumer } from 'react-frame-component';
import { StyleSheetManager } from 'styled-components';

export default class FramedComponent extends Component {
  render() {
    const style = { width: '100%', height: '100%' };
    const { title, children, attachNode = () => {}, ...rest } = this.props;
    const defaultStyle = `
      *{
        margin: 0 auto;
      }
      html, body, .frame-root, .frame-content {
        width: 100%;
        height: 100%;
      }
    `;
    const head = (
      <Fragment>
        <meta charSet="utf-8" />
        <style>{defaultStyle}</style>
        <title>{title}</title>
      </Fragment>
    );

    return (
      <FrameCom
        {...rest}
        style={style}
        scrolling="no"
        frameBorder="0"
        ref={attachNode}
        head={head}
        title={title}
      >
        <FrameContextConsumer>
          {frameContext => (
            <StyleSheetManager target={frameContext.document.head}>
              {children}
            </StyleSheetManager>
          )}
        </FrameContextConsumer>
      </FrameCom>
    );
  }
}
