import React, { Component } from 'react';
//
import { Form, Input, SendBtn } from './contentWrapper';
import { gradientToString, getProp } from 'utils/helpers';
import { getMediator } from 'services/mediator';

export const COMPONENT_NAME = 'chatBox__sendHistoryForm';

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{'background-color': *}|{'background-image': string, background: string}}
   */
  getBackground() {
    const { settings = {} } = this;
    let background = {};

    if (settings.cb_color_from && settings.cb_color_to) {
      const cbColorV = gradientToString(
        [settings.cb_color_to, settings.cb_color_from],
        'top'
      );
      background = { 'background-image': cbColorV, background: cbColorV };
    } else {
      const cbColorV = settings.cb_color_from || settings.cb_color_to;
      background = { 'background-color': cbColorV };
    }

    return background;
  }
}

const InputForm = (props = {}) => {
  const { input, placeholder = 'Enter your email' } = props;
  return (
    <Input
      {...input}
      required="required"
      type="email"
      name="short-question"
      placeholder={placeholder}
    />
  );
};

class SendHistoryForm extends Component {
  constructor(props) {
    super(props);

    this._componentName = COMPONENT_NAME;

    this.state = {
      value: ''
    };
  }

  componentDidMount() {
    getMediator().attachComponent(this._componentName, this);
  }

  componentWillUnmount() {
    getMediator().detachComponent(this._componentName);
  }

  /**
   * Incoming events handler
   * @param props
   */
  subscriber = (props = {}) => {
    const { subject, object, action = {} } = props;

    if (action.type === 'reset') {
      this.reset();
    }
  };

  changeHandler = e => {
    const value = getProp(e, 'target.value');
    this.setState({ value });
  };

  reset = () => {
    this.setState({ value: '' });
  };

  render() {
    const { settings, onSubmit } = this.props;
    const value = this.state.value;
    const Styles = new StylesConstructor({ settings });
    const sendBtnComProps = {
      backgroundImage: getProp(
        Styles.getBackground(),
        'background-image',
        'none'
      )
    };
    const placeholder = settings.email_field_text;

    return (
      <Form
        onSubmit={(e = {}) => {
          e.preventDefault();
          onSubmit({ value });
        }}
      >
        <InputForm
          name="email"
          type="text"
          placeholder={placeholder}
          input={{
            onChange: this.changeHandler,
            value: this.state.value
          }}
        />
        <SendBtn type="submit" alt="Send" style={sendBtnComProps}>
          send transcript
        </SendBtn>
      </Form>
    );
  }
}

export default SendHistoryForm;
