import axios from 'axios';
import { http } from '../config/constants';
import logger from '../utils/logger';

async function axiosGet(url) {
  try {
    logger.info(`Fetching settings from: ${url}`);
    const res = await axios.get(url);
    return res.data;
  } catch (error) {
    logger.error(error.toString());
    return null;
  }
}

/**
 * Send SMS
 * @param domain_id
 * @param phone
 * @param message
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function sendSMS(domainId, phone, message) {
  const url = `${http.API_URL}/sendSMS`;
  const payload = { domain_id: domainId, phone, message };

  try {
    return axios.post(url, payload);
  } catch (error) {
    logger.error(error.toString());
    return { status: 400 };
  }
}

/**
 * Get chat history
 * @param domain
 * @param chatId
 * @param callback
 * @returns {Promise<AxiosResponse<T>>}
 */
export async function getChatHistory(domain, chatId, callback = () => {}) {
  let history;
  try {
    history = await axios
      .get(`${http.API_URL}/messages?chatId=${chatId}&nocache=1`)
      .then(res => {
        callback(res);
        return res.data;
      });
  } catch (e) {
    callback({ error: e });
    throw e;
  }
  return history;
}

/**
 * @param listOfParams
 * @returns {Promise<Array>}
 */
export function getSettings(listOfParams = []) {
  const params = [
    `domain=${encodeURIComponent(
      listOfParams[0]?.domain ?? window.location.host
    )}`
  ];

  if (listOfParams.length !== 0) {
    const vcParams = [];

    if (listOfParams[0].accountId) {
      params.push(`super_id=${encodeURIComponent(listOfParams[0].accountId)}`);
    }

    listOfParams.forEach((item = {}) => {
      vcParams.push({
        id: item.id,
        name: item.name,
        url: location.href
      });
    });
    const paramsString = JSON.stringify(vcParams).replace(/\\/, '');
    logger.debug(paramsString);
    params.push(`data=${encodeURIComponent(paramsString)}`);
  }

  const url = `${http.API_URL}/webSettings?${params.join('&')}`;
  return axiosGet(url);
}
