import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LeaveMessageForm from './leaveMessageForm';
//
import { getLightOrDarkByHex, lightenDarkenColor } from 'utils/helpers';

class LeaveMessageComp extends Component {
  constructor(props) {
    super(props);

    const bckColor = this.getBackgroundColor();
    const bgIsLight = getLightOrDarkByHex(bckColor);

    this.state = {
      msgInputFocus: false,
      bgColor: lightenDarkenColor(bckColor, bgIsLight ? -55 : -35)
    };
  }

  getBackgroundColor() {
    const { settings = {} } = this.props;
    return settings.cb_background_color || '#232323';
  }

  handleMsgInputFocus = () => {
    this.setState({
      msgInputFocus: !this.state.msgInputFocus
    });
  };

  render() {
    const { settings = {}, operatorOnline, handleSubmit } = this.props;
    const { bgColor } = this.state;

    return (
      <LeaveMessageForm
        onSubmit={handleSubmit}
        onFocus={this.handleMsgInputFocus}
        onBlur={this.handleMsgInputFocus}
        stateFocus={this.state.msgInputFocus}
        placeholder={this.state.msgInputFocus ? null : 'Сообщение'}
        bgColor={bgColor}
        operatorOnline={operatorOnline}
        settings={settings}
      />
    );
  }
}

LeaveMessageComp.propTypes = {
  handleSubmit: PropTypes.func,
  operatorOnline: PropTypes.bool,
  settings: PropTypes.object
};

export default LeaveMessageComp;
