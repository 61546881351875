import styled, { css, keyframes } from 'styled-components';
import { prop, ifProp, switchProp } from 'styled-tools';
import mobileDevice from 'ismobilejs';
//
import { convertHexToRgba, stylesAlignment, getProp } from 'utils/helpers';
import { IS_MOBILE_WIDTH } from 'config/constants';

const ANIMATED_AREA = '320px';
const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;
let _animationInProgress = false;

const open = (props = {}) => {
  const { slideOn } = props;
  if (!slideOn) return;

  let slide;

  switch (slideOn) {
    case 'top': {
      slide = `translateY(-${ANIMATED_AREA})`;
      break;
    }
    case 'right': {
      slide = `translateX(${ANIMATED_AREA})`;
      break;
    }
    case 'bottom': {
      slide = `translateX(${ANIMATED_AREA})`;
      break;
    }
    case 'left': {
      slide = `translateX(-${ANIMATED_AREA})`;
      break;
    }
  }

  return keyframes`
  0% {
    opacity: 0;
    transform: ${slide};
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
};

const close = (props = {}) => {
  const { slideOff, element } = props;
  if (!slideOff) return;

  let translateYEndCoords;
  let translateXEndCoords;
  let originalTransform = {
    x: 0,
    y: 0
  };
  if (getProp(element, 'style.transform')) {
    const coords = element.style.transform.match(/[0-9]+/g);
    if ((coords || []).length > 1) {
      originalTransform = {
        x: `${coords[0]}px,`,
        y: `${coords[1]}px,`
      };
    }
  }

  switch (slideOff) {
    case 'top': {
      translateYEndCoords = `-${ANIMATED_AREA}`;
      break;
    }
    case 'right': {
      translateXEndCoords = ANIMATED_AREA;
      break;
    }
    case 'bottom': {
      translateYEndCoords = ANIMATED_AREA;
      break;
    }
    case 'left': {
      translateXEndCoords = `-${ANIMATED_AREA}`;
      break;
    }
  }

  return keyframes`
  0% {
    opacity: 1;
    transform: translateY(${originalTransform.y}) translateX(${
    originalTransform.x
  });
  }
  100% {
    opacity: 0;
    transform: ${
      translateYEndCoords ? 'translateY' : 'translateX'
    }(${translateYEndCoords || translateXEndCoords});
  }
`;
};

const onEntering = (props = {}) => {
  if (!props._slideOn) return;
  _animationInProgress = css`
    animation: ${open({ slideOn: props._slideOn, element: props.element })}
      ${props._slideSpeed}ms ease-in;
  `;
  setTimeout(() => (_animationInProgress = false), props._slideSpeed);
  return _animationInProgress;
};

const onEntry = () => {
  if (_animationInProgress) return _animationInProgress;
  return css`
    transform: translateY(0) translateX(0);
  `;
};

const onExiting = (props = {}) => {
  if (!props._slideOff) return;
  _animationInProgress = css`
    animation: ${close({ slideOff: props._slideOff, element: props.element })}
      ${props._slideSpeed}ms ease-in;
  `;
  setTimeout(() => (_animationInProgress = false), props._slideSpeed);
  return _animationInProgress;
};

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {string}
   */
  getShadow() {
    const { settings = {} } = this;
    const koef = +settings.cb_shadow;
    const color = settings.cb_shadow_color;
    const hShadow = 0;
    const vShadow = 0;
    const blur = (28 * 2 * (koef * 4)) / 100 + 'px';
    const spread = (10 * koef) / 100 + 'px';
    const vShadow1 = 0;
    const blur1 = (10 * 2 * koef) / 100 + 'px';
    const spread1 = koef / 100 + 'px';
    return `${hShadow} ${vShadow} ${blur} ${spread} ${convertHexToRgba(
      color,
      25
    )}, 0 ${vShadow1} ${blur1} ${spread1} ${convertHexToRgba(color, 22)}`;
  }

  /**
   *
   * @param props
   * @returns {string}
   */
  getHeight(props = {}) {
    const { settings = {} } = this;
    return isPhone ? '100%' : `${settings.cb_size_height}px`;
  }

  /**
   *
   * @param props
   * @returns {string}
   */
  getWidth(props = {}) {
    const { settings = {} } = this;
    return isPhone ? '100%' : `${settings.cb_size_width}px`;
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  getAlignment(props = {}) {
    const { settings = {} } = this;
    const { width, height } = props;

    if (isPhone) {
      return { left: 0, right: 0, top: 0, bottom: 0 };
    }

    if (!(settings.cb_alignment_x && settings.cb_alignment_y)) return;

    const x = settings.cb_alignment_x;
    const y = settings.cb_alignment_y;
    let hOffset = 0;
    let vOffset = 0;

    if (settings.cb_offset_horizontal) {
      hOffset = `${settings.cb_offset_horizontal}px`;
    }
    if (settings.cb_offset_vertical) {
      vOffset = `${settings.cb_offset_vertical}px`;
    }

    return stylesAlignment({
      x,
      y,
      hOffset,
      vOffset,
      element: { width, height }
    });
  }

  /**
   *
   * @returns {*}
   */
  getSlideOn() {
    const { settings = {} } = this;
    return settings.cb_slide_on;
  }

  /**
   *
   * @returns {*}
   */
  getSlideOff() {
    const { settings = {} } = this;
    return settings.cb_slide_off;
  }

  /**
   *
   * @returns {*}
   */
  getSlideSpeed() {
    const { settings = {} } = this;
    return +settings.cb_move_speed || 500;
  }

  /**
   *
   * @returns {string}
   */
  getBackgroundColor() {
    const { settings = {} } = this;
    let bckColor = settings.cb_background_color;
    let opacity = +settings.cb_background_transparency;

    if (!opacity) opacity = 90;
    if (isPhone) opacity = 100;
    if (!bckColor) bckColor = '#232323';

    return convertHexToRgba(bckColor, opacity);
  }

  /**
   *
   * @returns {*}
   */
  getBorderRadius() {
    return { borderRadius: !isPhone ? '6px' : 0 };
  }
}

const frameWrapperFormatProps = (props = {}) => {
  const { settings, transitionState, element } = props;
  const Styles = new StylesConstructor({ settings, element });
  const _width = Styles.getWidth();
  const _height = Styles.getHeight();

  const internalProps = {
    _width,
    _height,
    _boxShadow: Styles.getShadow(),
    _align: Styles.getAlignment({ width: _width, height: _height }),
    _slideOn: Styles.getSlideOn(),
    _slideOff: Styles.getSlideOff(),
    _slideSpeed: Styles.getSlideSpeed(),
    _backgroundColor: Styles.getBackgroundColor(),
    _borderRadius: Styles.getBorderRadius(),
    theme: settings.Theme,
    element
  };

  return Object.assign({}, { transitionState }, internalProps);
};

const FrameWrapper = styled.div`
  ${ifProp(
    '_height',
    css`
      height: ${prop('_height')};
    `
  )};
  ${ifProp(
    '_width',
    css`
      width: ${prop('_width')};
    `
  )};
  ${prop('_align')};
  ${switchProp('transitionState', {
    entered: props => onEntry(),
    entering: props => onEntering(props),
    exited: props => onEntry(),
    exiting: props => onExiting(props)
  })};

  & > iframe {
    ${ifProp(
      '_boxShadow',
      css`
        box-shadow: ${prop('_boxShadow')};
      `
    )};
    ${ifProp(
      '_backgroundColor',
      css`
        background-color: ${prop('_backgroundColor')};
      `
    )};
    ${prop('_borderRadius')};
    overflow: hidden;
  }

  position: fixed;
  overflow: visible;
  box-sizing: border-box;
  z-index: 9999999999999;
`;

// Theme: Modern
const modern = {};

// Theme by default
FrameWrapper.defaultProps = {
  theme: { modern }
};

export { frameWrapperFormatProps, FrameWrapper };
