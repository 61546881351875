import React from 'react';
import styled, { css } from 'styled-components';
//
import { Spinner } from '../../../Common/spinner';
import { OperatorAvatar } from '../../../SpeechBubble/contentWrapper';
import { getProp } from 'utils/helpers';
import {
  CONTACT_VIA_HEIGHT,
  DEFAULT_OPERATOR_AVATAR
} from '../../../../config/constants';
import { ifProp } from 'styled-tools';

const Wrapper = styled.div`
  position: absolute;
  left: 18px;
  transition: 0.25s opacity ease-in;

  ${ifProp(
    { contactViaIsActive: true },
    css`
      bottom: ${CONTACT_VIA_HEIGHT + 100}px;
    `,
    css`
      bottom: 100px;
    `
  )}
`;

const OperatorTyping = (props = {}) => {
  const {
    settings = {},
    operatorOnline,
    isOperatorTyping,
    operatorInfo = {},
    contactViaIsActive
  } = props;
  const avatar = getProp(operatorInfo, 'avatar');
  const operatorAvatarComProps = {
    operatorOnline,
    url: avatar,
    style: {
      width: '32px',
      height: '32px',
      left: 0
    }
  };
  const spinnerCompProps = {
    type: 'dots',
    settings,
    style: {
      left: '52px',
      bottom: 0,
      transform: 'scale(0.5)',
      color: '#ffffff'
    }
  };

  const isDefaultAvatar = avatar === DEFAULT_OPERATOR_AVATAR;

  return isOperatorTyping ? (
    <Wrapper contactViaIsActive={contactViaIsActive}>
      {avatar && !isDefaultAvatar && (
        <OperatorAvatar {...operatorAvatarComProps} />
      )}
      <Spinner {...spinnerCompProps} />
    </Wrapper>
  ) : null;
};

export default OperatorTyping;
