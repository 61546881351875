import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Transition } from 'react-transition-group';
import { DraggableCore } from 'react-draggable';
import mobileDevice from 'ismobilejs';
//
import store from '../../store';
import { FrameWrapper, frameWrapperFormatProps } from './frameWrapper';
import FakeDraggableArea from './fakeDraggableArea';
import FramedComponent from '../Common/framedComponent';
import {
  clearCb,
  closeCb,
  transitionChangeCb
} from 'ducks/chatBox/chatBox.action';
import { setActiveRoom, setWebSettingsIndex } from 'ducks/global/app.action';
import { activateRs, openRs } from 'ducks/roomsSelector/roomsSelector.action';
import { ContentWrapper, contentWrapperFormatProps } from './contentWrapper';
import Header from './components/Header/headerCom';
import ChatList from './components/ChatList/chatListComp';
import Footer from './components/Footer/footerCom';
import SendMessageComp from './components/Forms/sendMessage/sendMessageComp';
import DetailsForm from './components/Forms/details/detailsComp';
import LeaveMessageComp from './components/Forms/leaveMessage/leaveMessageComp';
import CustomizedContainer from 'components/Common/CustomizedContainer';
import fbChatInit from '../../utils/facebookChat';
import logger from '../../utils/logger';

import { COMPONENT_NAME as SEND_MESSAGE_FORM_COMPONENT_NAME } from './components/Forms/sendMessage/sendMessageForm';
import { COMPONENT_NAME as DETAILS_MESSAGE_FORM_COMPONENT_NAME } from './components/Forms/details/detailsForm';
import { COMPONENT_NAME as LEAVE_MESSAGE_FORM_COMPONENT_NAME } from './components/Forms/leaveMessage/leaveMessageForm';
import OperatorTyping from './components/OperatorTyping';
import {
  clearClosedChatTimer,
  clearLamTimer,
  deleteRoom,
  detailsFormSend,
  operatorAssignedChat,
  reinitRoomData,
  sendMessage,
  setShowLamTimer,
  setUserTyping,
  showSendTranscriptForm,
  unsetUserTyping
} from '../../ducks/global/rooms.action';
import { setShowMode as contactViaSetShowMode } from 'ducks/contactVia/contactVia.action';
import { chatAdHoc, fbAdhoc } from 'utils/adhoc';
import { ChatListTimer } from '../Common/timer/chatListTimer';
import Tabs from './components/Tabs/Tabs';
import {
  chatTypes,
  IS_MOBILE_WIDTH,
  transitionStages
} from '../../config/constants';
import {
  browserIsIE,
  getCurrentTime,
  getProp,
  getRoomNameFromLocation
} from 'utils/helpers';
import DraggableHelper from 'utils/draggable';
import { root } from '../../index';
import { getMediator } from 'services/mediator';
import { setIsShowingLam } from '../../ducks/chatBox/chatBox.action';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;

class ChatBox extends PureComponent {
  constructor(props) {
    super(props);
    this.timer = null;
    this._detailsFormIsSent = false;
    this._messages = {};
    this._chatAdHoc = chatAdHoc();
    this._fbAdHoc = fbAdhoc();
    this.state = {
      prevAvailablePosition: { x: 0, y: 0 },
      position: { x: 0, y: 0 },
      isDraggableNow: false,
      shouldBeOpenedRS: false
    };
    this._pageScrollY = null;
    this._deferredRsOpening = false;
  }

  componentDidMount() {
    if (getProp(this.props.global.settings, 'fbm_chat_enabled')) {
      if (window.VisitorChatInit) {
        logger.debug('Globals already loaded, initialising FacebookChat');
        this.initialiseFacebookChat();
      } else {
        logger.debug('Listening for global functions to load');
        document.addEventListener(
          'vcGlobalFuncRdy',
          this.initialiseFacebookChat
        );
      }
    }
    window.document.body.addEventListener(
      'touchmove',
      this.handlePreventTouchmoveWhenPanning,
      {
        passive: false
      }
    );
    window.addEventListener('resize', this.windowResizeHandler);
    this._draggable = new DraggableHelper({
      limiter: 'body',
      onDragStyle: { opacity: 0.8 },
      onStopStyle: { opacity: 1 }
    });
    getMediator().attachComponent('chatBox', this);
  }

  componentWillUnmount() {
    window.document.body.removeEventListener(
      'touchmove',
      this.handlePreventTouchmoveWhenPanning,
      {
        passive: false
      }
    );
    window.removeEventListener('resize', this.windowResizeHandler);
    getMediator().detachComponent('chatBox');
  }

  componentDidUpdate(prevProps) {
    const {
      chatBox,
      global,
      handleSocketChatBoxOpened,
      handleSocketChatBoxClosed
    } = this.props;

    if (prevProps.chatBox.open !== chatBox.open) {
      if (!prevProps.chatBox.open) {
        handleSocketChatBoxOpened(global.activeRoom);
      } else {
        handleSocketChatBoxClosed(global.activeRoom);
      }
    }

    if (chatBox.open) {
      this.chatListScrollHandler(this.props);
    }

    if (
      this._deferredRsOpening &&
      getProp(this.props, 'roomsSelector.active')
    ) {
      this.deferredRsOpeningHandler();
    }
  }

  initialiseFacebookChat = () => {
    const pageId = getProp(this.props.global.settings, 'fbm_page_id');
    const color = getProp(this.props.global.settings, 'fbm_color');
    logger.debug(
      `Initialising facebook chat with pageID: ${pageId}, color: ${color}`
    );
    fbChatInit(pageId, color);
  };

  pageScrollHandler = direction => {
    if (direction === 'initial') {
      this._pageScrollY = window.scrollY;
      window.scrollTo(0, 0);
    } else if (direction === 'remembered') {
      window.scrollTo(0, this._pageScrollY || 0);
      this._pageScrollY = null;
    }
  };

  deferredRsOpeningHandler = () => {
    this._deferredRsOpening = false;
    this.props.openRs(); // open Room Selector
    this.props.clearCb(); // clear store data for Chat Box (without that Floating Button will not be open when RS closing)
  };

  /**
   *
   * @param event
   */
  handlePreventTouchmoveWhenPanning = event => {
    if (this.state.isDraggableNow) {
      event.preventDefault();
    }
  };

  /**
   *
   */
  windowResizeHandler = () => {
    this._draggable.applyProperties({
      limiterBounds: { bottom: window.innerHeight }
    });
  };

  chatListScrollHandler = (nextProps = {}) => {
    const { global, rooms } = nextProps;
    if (!this._chatList) return;
    const activeRoomInfo =
      rooms.find(({ room }) => room === global.activeRoom) || {};
    const { chatListMessages, isOperatorTyping } = activeRoomInfo;

    if (!activeRoomInfo || !chatListMessages) return;

    if (isOperatorTyping) {
      return this.chatListScrollTo({ delay: 150 });
    }

    if (!this._messages[global.activeRoom])
      this._messages[global.activeRoom] = 0;
    if (this._messages[global.activeRoom] !== chatListMessages.length) {
      this._messages[global.activeRoom] = chatListMessages.length;
      return this.chatListScrollTo({ delay: 100 });
    }
  };

  chatListScrollTo = (props = {}) => {
    const { delay, offsetY = 0 } = props;
    const self = this;

    function scrollTo() {
      if (!self._chatList) return;
      if (browserIsIE() && typeof self._chatList.scrollTo !== 'function') {
        self._chatList.scrollTo = root.scrollTo;
      }
      if (typeof self._chatList.scrollTo === 'function') {
        self._chatList.scrollTo({
          top: self._chatList.scrollHeight + offsetY,
          behavior: 'smooth'
        });
      }
    }

    if (!delay) {
      scrollTo();
    } else {
      setTimeout(scrollTo, delay);
    }
  };

  /**
   *
   * @param index
   * @param room
   */
  handleTabSelect = ({ room }) => {
    if (!room) {
      return;
    }
    this.props.setActiveRoom(room, 'handleTabSelect');
  };

  /**
   *
   * @param index
   * @param room
   */
  handleTabDelete = ({ room }) => {
    if (!room) {
      return;
    }
    const { global, rooms = [], setActiveRoom, handleCloseRoom } = this.props;

    if (room === global.activeRoom && rooms.length > 1) {
      const firstNonDeletedMessage = rooms.filter(r => r.room !== room)[0];
      setActiveRoom(firstNonDeletedMessage.room);
    }

    handleCloseRoom(room);
  };

  handleUserTyping = args => {
    const activeRoomInfo = args[0];
    const eventData = args[1];
    const isStartTypingStatement = args[2];
    const {
      setUserTyping,
      unsetUserTyping,
      handleSocketSetTypingState
    } = this.props;

    if (isStartTypingStatement === true && !activeRoomInfo.isUserTyping) {
      setUserTyping(activeRoomInfo.room);
    }

    if (!isStartTypingStatement && activeRoomInfo.isUserTyping) {
      unsetUserTyping(activeRoomInfo.room);
    }

    handleSocketSetTypingState(eventData, isStartTypingStatement);
  };

  /**
   * Trying to validate `Details Form` (if exists) and after then to continue to send message
   * @param room
   * @param message
   * @param from
   */
  submitHandler = ({ room, event = {}, from }) => {
    const {
      activeRoomInfo = {},
      reinitRoomData,
      clearClosedChatTimer,
      handleSocketSendOfflineMessage,
      closeCb,
      handleSocketChatHistoryEnd,
      handleSocketChatNoAnswerTimeout
    } = this.props;
    const { timer, closingTime } = activeRoomInfo;

    if (timer) {
      if (closingTime === 0) {
        reinitRoomData();
      } else {
        clearClosedChatTimer();
      }
    }

    if (getMediator().isExistsComponent(DETAILS_MESSAGE_FORM_COMPONENT_NAME)) {
      getMediator().toDo('chatBox', DETAILS_MESSAGE_FORM_COMPONENT_NAME, {
        type: 'submit',
        onSuccess: values => {
          this.sendMessageFormMessenger({ room, message: event.value });
          this.detailsFormMessenger({ room, values });
        }
      });
      return;
    }

    if (from === 'sendMessageForm') {
      if (!event.value) {
        return;
      }

      this.sendMessageFormMessenger({ room, message: event.value });

      const activeRoom = this.props.rooms.find(r => r.room === room);
      if (
        activeRoom &&
        !activeRoom.operatorIsAssigned &&
        !activeRoom.showLamTimer
      ) {
        const showLamAfterSeconds = parseInt(
          getProp(this.props.global.settings, 'no_answer_show_lam_after')
        );
        if (showLamAfterSeconds) {
          const showLamTimer = setTimeout(() => {
            store.dispatch(setIsShowingLam(true, false));
            store.dispatch(clearLamTimer(room));
            handleSocketChatNoAnswerTimeout(room);
          }, showLamAfterSeconds * 1000);
          store.dispatch(setShowLamTimer(room, showLamTimer));
        }
      }
    }

    if (from === 'leaveMessageForm') {
      closeCb();
      handleSocketSendOfflineMessage(event.values);
      getMediator().toDo('chatBox', LEAVE_MESSAGE_FORM_COMPONENT_NAME, {
        type: 'reset'
      });
    }

    if (from === 'sendHistoryForm') {
      handleSocketChatHistoryEnd({
        room,
        emails: [event.value]
      });

      this.props.reinitRoomData(room);
      this.props.closeCb();
    }
  };

  /**
   * Send a message from `Send Message Form`
   * @param room
   * @param message
   */
  sendMessageFormMessenger = ({ room, message }) => {
    const { sendMessage, handleSocketSendMessage } = this.props;

    getMediator().toDo('chatBox', SEND_MESSAGE_FORM_COMPONENT_NAME, {
      type: 'reset'
    });
    handleSocketSendMessage(message);
    sendMessage(room, {
      author: 'visitor',
      date: getCurrentTime(),
      text: message
    });
  };

  /**
   * Send a delayed message from `Details Form`
   * @param room
   * @param values
   */
  detailsFormMessenger = ({ room, values = {} }) => {
    const { handleSocketSendDetailsMessage, detailsFormSend } = this.props;
    store.subscribe(() => {
      if (this._detailsFormIsSent) {
        return;
      }

      const { rooms = [] } = store.getState();
      const roomInfo = rooms.find((r = {}) => r.room === room) || {};
      const { operatorIsAssigned } = roomInfo;

      if (operatorIsAssigned) {
        this._detailsFormIsSent = true;
        handleSocketSendDetailsMessage({ room, ...values });
        detailsFormSend(room);
      }
    });
    getMediator().toDo('chatBox', DETAILS_MESSAGE_FORM_COMPONENT_NAME, {
      type: 'reset'
    });
  };

  attachNode = (node, name) => {
    this[`_${name}`] = node;
  };

  /**
   *
   * @param props
   * @returns {*}
   */
  getTabs = (props = {}) => {
    const { global, rooms = [] } = props;
    const tabsData = rooms.reduce((accumulator, currentValue, currentIndex) => {
      const currentRoom = rooms[currentIndex];
      const hasChatListMessages = this._chatAdHoc.clientMessageIsExists(
        currentRoom.chatListMessages
      );
      // Show only active rooms
      if (
        !hasChatListMessages &&
        currentRoom.room !== getRoomNameFromLocation()
      ) {
        return accumulator;
      }
      if (currentRoom.status === chatTypes.STATUS_CLOSED) {
        return accumulator;
      }

      const unreadMessages = this._fbAdHoc.getUnreadMessagesLength(currentRoom);

      accumulator[currentRoom.room] = {
        isActive: currentRoom.room === global.activeRoom,
        closable: currentRoom.room !== getRoomNameFromLocation(),
        status: currentRoom.status,
        unreadMessages
      };
      return accumulator;
    }, {});

    return (
      <Tabs
        data={tabsData}
        global={global}
        onSelect={this.handleTabSelect}
        onDelete={this.handleTabDelete}
      />
    );
  };

  /**
   *
   * @param activeRoomInfo
   * @returns {*}
   */
  getFormBody = ({ activeRoomInfo }) => {
    const { global = {}, rooms = [], contactVia, chatBox } = this.props;
    const { activeRoom, settings, operators, meta } = global;
    const {
      operatorOnline,
      operatorInfo,
      isOperatorTyping,
      timer,
      closingTime
    } = activeRoomInfo;
    const chatListProps = {
      rooms,
      settings,
      activeRoomInfo,
      operators,
      attachNode: node => this.attachNode(node, 'chatList'),
      handleSocketGetTeamOnlineStatus: this.props
        .handleSocketGetTeamOnlineStatus
    };
    const chatListTimerProps = {
      settings,
      activeRoomInfo,
      remaining: closingTime,
      handleSubmit: event =>
        this.submitHandler({
          room: activeRoom,
          event,
          from: 'sendHistoryForm'
        }),
      clearClosedChatTimer: event =>
        this.props.clearClosedChatTimer(activeRoom, event)
    };
    const detailsFormProps = {
      settings,
      activeRoomInfo,
      contactViaShowMode: contactVia.showMode
    };
    const sendMessageCompProps = {
      global,
      activeRoomInfo,
      rooms,
      handleUserTyping: (...props) =>
        this.handleUserTyping([activeRoomInfo, ...props]),
      handleSubmit: event =>
        this.submitHandler({
          room: activeRoom,
          event,
          from: 'sendMessageForm'
        }),
      clearClosedChatTimer: () => this.props.clearClosedChatTimer(activeRoom),
      operatorAssignedChat: () =>
        this.props.operatorAssignedChat({ room: activeRoom }),
      reinitRoomData: () => this.props.reinitRoomData(activeRoom),
      chatListScrollTo: this.chatListScrollTo,
      contactViaIsActive: contactVia.active,
      contactViaShowMode: contactVia.showMode,
      contactViaSetShowMode: this.props.contactViaSetShowMode,
      showFacebookButton: getProp(settings, 'fbm_chat_enabled'),
      whatsappNumber: getProp(meta, 'whatsapp_number', null)
    };
    const leaveMessageCompProps = {
      handleSubmit: event =>
        this.submitHandler({
          room: activeRoom,
          event,
          from: 'leaveMessageForm'
        }),
      settings,
      operatorOnline
    };

    if (!operatorOnline || chatBox.isShowingLam) {
      const hasDelayMessage =
        !chatBox.canGoBack &&
        settings.delay_no_fallback_text &&
        settings.delay_no_fallback_text.length;

      return (
        <Fragment>
          {hasDelayMessage && (
            <p style={{ marginTop: '25px' }}>
              {settings.delay_no_fallback_text}
            </p>
          )}
          <LeaveMessageComp {...leaveMessageCompProps} />
        </Fragment>
      );
    }
    // otherwise
    return (
      <Fragment>
        <ChatList {...chatListProps} />
        {timer && <ChatListTimer {...chatListTimerProps} />}
        {
          <OperatorTyping
            settings={settings}
            isOperatorTyping={isOperatorTyping}
            operatorInfo={operatorInfo}
            operatorOnline={operatorOnline}
            contactViaIsActive={contactVia.active}
          />
        }
        <CustomizedContainer style={{ width: '95%' }}>
          <DetailsForm {...detailsFormProps} />
        </CustomizedContainer>
        <CustomizedContainer style={{ width: '95%' }}>
          <SendMessageComp {...sendMessageCompProps} />
        </CustomizedContainer>
      </Fragment>
    );
  };

  transitionEnterHandler = () => {
    this.props.transitionChangeCb({ stage: transitionStages.ENTER });
    clearTimeout(this.timer);
  };

  transitionEnteredHandler = () => {
    this.props.transitionChangeCb({ stage: transitionStages.ENTERED });
    this.chatListScrollTo({ delay: 100 });

    if (isPhone) {
      this.pageScrollHandler('initial');
    }
  };

  transitionExitHandler = (props = {}) => {
    this.props.transitionChangeCb({ stage: transitionStages.EXIT });

    if (props.timer) {
      this.timer = setTimeout(
        () => (this._frameWrapper.style.display = 'none'),
        props.timer
      );
    }

    if (isPhone) {
      this.pageScrollHandler('remembered');
    }
  };

  transitionExitedHandler = () => {
    this.props.transitionChangeCb({ stage: transitionStages.EXITED });
    this._draggable.reset();
  };

  draggableProxyOnStart = (event, data) => {
    event.preventDefault();
    this.setState({ isDraggableNow: true });
    this._draggable.onStart(event, data);
  };

  draggableProxyOnStop = (event, data) => {
    this.setState({ isDraggableNow: false });
    this._draggable.onStop(event, data);
  };

  onBackHandler = () => {
    const {
      global,
      setWebSettingsIndex,
      activateRs,
      handleDisconnectApp,
      setActiveRoom,
      deleteRoom
    } = this.props;
    this._deferredRsOpening = true;
    deleteRoom(global.activeRoom); // remove last active room
    setActiveRoom(null); // reset active room
    setWebSettingsIndex({ index: null }); // unset already chosen value
    handleDisconnectApp(); // disconnect WS
    activateRs(); // return to live `Room Selector`
  };

  getContent = () => {
    const {
      global,
      rooms,
      chatBox,
      closeCb,
      handleSocketResetRoom
    } = this.props;
    const { settings, webSettings, uiLocked, chosenWebSettingsIndex } = global;
    const activeRoomInfo =
      rooms.find(({ room }) => room === global.activeRoom) || {};
    const DEFAULT_DELAY = 500;
    const timeoutEnterExit =
      +getProp(settings, 'cb_move_speed', '0') || DEFAULT_DELAY;
    const frameWrapperProps = (anyProps = {}) => {
      return frameWrapperFormatProps({
        settings,
        uiLocked,
        element: this._frameWrapper,
        ...anyProps
      });
    };
    const fakeDraggableAreaStyle = {
      useOffset: webSettings.length > 1 && !activeRoomInfo.operatorIsAssigned,
      showingBackButton: chatBox.isShowingLam
    };
    const headerProps = {
      closeCb,
      settings,
      webSettings,
      onBack: this.onBackHandler,
      chosenWebSettingsIndex,
      ...activeRoomInfo,
      isShowingLam: chatBox.isShowingLam,
      resetOnBack: !chatBox.canGoBack
        ? () => handleSocketResetRoom(activeRoomInfo.room)
        : null
    };
    const footerProps = {
      settings,
      privacyUrl: webSettings.length
        ? webSettings[chosenWebSettingsIndex || 0].privacy_url
        : undefined
    };
    const cbWrapperStyle = () => contentWrapperFormatProps({ settings });
    const transitionIsAvailable = (() => {
      let statement = chatBox.open;
      if (!chatBox.active) statement = false;
      return statement;
    })();

    return (
      <Transition
        in={transitionIsAvailable}
        appear
        unmountOnExit
        onEnter={this.transitionEnterHandler}
        onExit={() =>
          this.transitionExitHandler({ timer: timeoutEnterExit - 50 })
        }
        onEntered={this.transitionEnteredHandler}
        onExited={this.transitionExitedHandler}
        timeout={{ enter: timeoutEnterExit, exit: timeoutEnterExit }}
      >
        {transitionState => (
          <DraggableCore
            disabled={isPhone}
            onStart={this.draggableProxyOnStart}
            onDrag={this._draggable.onDrag}
            onStop={this.draggableProxyOnStop}
          >
            <FrameWrapper
              ref={el => (this._frameWrapper = el)}
              {...frameWrapperProps({ transitionState })}
            >
              <FakeDraggableArea {...fakeDraggableAreaStyle} />
              {this.getTabs({ global, rooms })}
              <FramedComponent title={'VC Chat Box'}>
                <ContentWrapper
                  {...cbWrapperStyle()}
                  onKeyDown={({ key }) => (key === 'Escape' ? closeCb() : true)}
                >
                  <Header {...headerProps} />
                  {this.getFormBody({ activeRoomInfo })}
                  <Footer {...footerProps} />
                </ContentWrapper>
              </FramedComponent>
            </FrameWrapper>
          </DraggableCore>
        )}
      </Transition>
    );
  };

  render() {
    const { global = {} } = this.props;
    if (global.connection) {
      return this.getContent();
    }

    return null;
  }
}

ChatBox.propTypes = {
  activateRs: PropTypes.func,
  activeRoomInfo: PropTypes.object,
  chatBox: PropTypes.object,
  clearCb: PropTypes.func,
  clearClosedChatTimer: PropTypes.func,
  closeCb: PropTypes.func,
  contactVia: PropTypes.object,
  contactViaSetShowMode: PropTypes.func,
  delay: PropTypes.func,
  deleteRoom: PropTypes.func,
  detailsFormSend: PropTypes.func,
  global: PropTypes.object,
  handleCloseRoom: PropTypes.func,
  handleDisconnectApp: PropTypes.func,
  handleSocketChatBoxClosed: PropTypes.func,
  handleSocketChatBoxOpened: PropTypes.func,
  handleSocketChatHistoryEnd: PropTypes.func,
  handleSocketSendDetailsMessage: PropTypes.func,
  handleSocketSendMessage: PropTypes.func,
  handleSocketSendOfflineMessage: PropTypes.func,
  handleSocketSetTypingState: PropTypes.func,
  offsetY: PropTypes.number,
  openRs: PropTypes.func,
  operatorAssignedChat: PropTypes.func,
  reinitRoomData: PropTypes.func,
  rooms: PropTypes.array,
  sendMessage: PropTypes.func,
  setActiveRoom: PropTypes.func,
  setWebSettingsIndex: PropTypes.func,
  setUserTyping: PropTypes.func,
  timer: PropTypes.func,
  transitionChangeCb: PropTypes.func,
  unsetUserTyping: PropTypes.func,
  handleSocketChatNoAnswerTimeout: PropTypes.func,
  handleSocketResetRoom: PropTypes.func
};

const mapStateToProps = state => {
  const { global, chatBox, rooms, roomsSelector, contactVia } = state;
  return { global, chatBox, rooms, roomsSelector, contactVia };
};

const mapDispatchToProps = dispatch => {
  return {
    clearCb: bindActionCreators(clearCb, dispatch),
    closeCb: bindActionCreators(closeCb, dispatch),
    setWebSettingsIndex: bindActionCreators(setWebSettingsIndex, dispatch),
    activateRs: bindActionCreators(activateRs, dispatch),
    openRs: bindActionCreators(openRs, dispatch),
    transitionChangeCb: bindActionCreators(transitionChangeCb, dispatch),
    reinitRoomData: bindActionCreators(reinitRoomData, dispatch),
    deleteRoom: bindActionCreators(deleteRoom, dispatch),
    detailsFormSend: bindActionCreators(detailsFormSend, dispatch),
    clearClosedChatTimer: bindActionCreators(clearClosedChatTimer, dispatch),
    showSendTranscriptForm: bindActionCreators(
      showSendTranscriptForm,
      dispatch
    ),
    setUserTyping: bindActionCreators(setUserTyping, dispatch),
    unsetUserTyping: bindActionCreators(unsetUserTyping, dispatch),
    setActiveRoom: bindActionCreators(setActiveRoom, dispatch),
    sendMessage: bindActionCreators(sendMessage, dispatch),
    operatorAssignedChat: bindActionCreators(operatorAssignedChat, dispatch),
    contactViaSetShowMode: bindActionCreators(contactViaSetShowMode, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);
