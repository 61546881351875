import React, { Component } from 'react';
//
import DetailsForm from './detailsForm';
import { CONTACT_VIA_SHOW_MODES } from 'config/constants';
import { chatAdHoc } from 'utils/adhoc';
import { emailRegex } from 'lib/validation';

class DetailsComp extends Component {
  _chatAdHoc = chatAdHoc();

  getFields = () => {
    const { settings = {} } = this.props;
    const fields = [];

    if (settings.details_form_name_enabled === 'on') {
      fields.push({
        name: 'name',
        placeholder: settings.details_form_name_caption,
        required: true
      });
    }

    if (settings.details_form_phone_enabled === 'on') {
      fields.push({
        name: 'phone',
        type: 'phone',
        placeholder: settings.details_form_phone_caption,
        required: true
      });
    }

    if (settings.details_form_email_enabled === 'on') {
      fields.push({
        name: 'email',
        type: 'email',
        validator: value => emailRegex.test(value),
        placeholder: settings.details_form_email_caption,
        required: true
      });
    }

    return fields;
  };

  checkVisibility = () => {
    const {
      settings = {},
      contactViaIsActive,
      contactViaShowMode,
      activeRoomInfo = {}
    } = this.props;
    const { detailsFormIsSent } = activeRoomInfo;
    const fields = this.getFields();
    const onlyFakeMessages = this._chatAdHoc.onlyFakeMessagedIsExists(
      activeRoomInfo.chatListMessages
    );

    if (detailsFormIsSent) {
      return false;
    }
    if (settings.details_form_wfv_enabled !== 'on') {
      return false;
    }
    if (fields.length === 0) {
      return false;
    }
    if (!onlyFakeMessages) {
      return false;
    }
    if (contactViaIsActive) {
      return false;
    }
    if (contactViaShowMode !== CONTACT_VIA_SHOW_MODES.INITIAL) {
      return false;
    }
    return true;
  };

  render() {
    const { settings = {} } = this.props;
    const isVisible = this.checkVisibility();
    const fields = this.getFields();

    if (!isVisible) {
      return null;
    }

    return <DetailsForm fields={fields} settings={settings} />;
  }
}

export default DetailsComp;
