import {
  FB__OPEN,
  FB__CLOSE,
  FB__TRANSITION_CHANGE,
  SYSTEM__CLEAR
} from '../actionConstants';

const updateState = function(state = {}, newState = {}) {
  return Object.assign({}, state, newState);
};

const init = {
  open: false,
  onceOpened: false,
  afterSB: false,
  stage: null
};

export default function(state = init, action) {
  switch (action.type) {
    case FB__OPEN:
      const { afterSB = false } = action.payload;
      return updateState(state, {
        onceOpened: true,
        open: true,
        afterSB
      });

    case FB__CLOSE:
      return updateState(state, {
        open: false
      });

    case FB__TRANSITION_CHANGE:
      return updateState(state, {
        stage: action.payload.stage
      });

    case SYSTEM__CLEAR:
      return updateState(state, init);

    default:
      return state;
  }
}
