import {
  ROOM__SEND_MESSAGE,
  ROOM__DELETE_FAKE_MESSAGES,
  GLOBAL__SET_ACTIVE_ROOM,
  CB__TRANSITION_CHANGE,
  ROOM__DELETE_MESSAGES_WITH_TEXT
} from '../ducks/actionConstants';
import { setReadMessagesLength } from '../ducks/global/rooms.action';
import { transitionStages } from '../config/constants';

export default store => next => action => {
  const { type, payload = {} } = action;
  const { rooms, global, chatBox } = store.getState();

  next(action);

  switch (type) {
    case CB__TRANSITION_CHANGE:
      {
        const roomInfo = rooms.find(r => r.room === global.activeRoom) || {};
        const { chatListMessages = [] } = roomInfo;

        if (payload.stage === transitionStages.ENTERED) {
          store.dispatch(
            setReadMessagesLength(global.activeRoom, chatListMessages.length)
          );
        }
      }
      break;

    case ROOM__SEND_MESSAGE:
      {
        const roomInfo = rooms.find(r => r.room === payload.room) || {};
        const { chatListMessages = [] } = roomInfo;

        if (type === ROOM__SEND_MESSAGE) {
          if (chatBox.open && payload.room === global.activeRoom) {
            store.dispatch(
              setReadMessagesLength(payload.room, chatListMessages.length + 1)
            );
          }
        }
      }
      break;

    case ROOM__DELETE_FAKE_MESSAGES:
      {
        const roomInfo = rooms.find(r => r.room === payload.room) || {};
        const { chatListMessages = [], lastReadMessagesLength } = roomInfo;
        const filteredChatListMessages = [
          ...chatListMessages.filter(m => !m.isFake)
        ];
        const diff = chatListMessages.length - filteredChatListMessages.length;

        store.dispatch(
          setReadMessagesLength(
            payload.room,
            lastReadMessagesLength - (diff > 0 ? diff : 0)
          )
        );
      }
      break;

    case ROOM__DELETE_MESSAGES_WITH_TEXT:
      {
        const roomInfo = rooms.find(r => r.room === payload.room) || {};
        const { chatListMessages = [], lastReadMessagesLength } = roomInfo;
        const filteredChatListMessages = [
          ...chatListMessages.filter(m => m.text !== payload.text)
        ];
        const diff = chatListMessages.length - filteredChatListMessages.length;

        store.dispatch(
          setReadMessagesLength(
            payload.room,
            lastReadMessagesLength - (diff > 0 ? diff : 0)
          )
        );
      }
      break;

    case GLOBAL__SET_ACTIVE_ROOM:
      if (action.payload.eventSource === 'handleTabSelect') {
        const roomInfo = rooms.find(r => r.room === payload.activeRoom) || {};
        const { chatListMessages = [] } = roomInfo;

        store.dispatch(
          setReadMessagesLength(payload.activeRoom, chatListMessages.length)
        );
      }
      break;
  }
};
