import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

export const Form = styled.form`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.85);
`;

export const SendMessageWrap = styled.div`
  background-color: ${prop('bgColor')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;
  max-height: 82px;
  box-sizing: border-box;
  position: relative;
  transition: background-color 500ms;
  margin: 4px 0;
  ${ifProp(
    'hasError',
    css`
      box-shadow: 0 0 3px 3px rgba(255, 0, 0, 0.4);
    `
  )};
  ${prop('style')}

  input, textarea {
    ${ifProp(
      'color',
      css`
        color: ${prop('color')};
      `
    )};
  }
`;

export const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${prop('style')}

  & .form-control-phone {
    height: 42px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const EmptyPanel = styled.div`
  width: 100%;
  height: 54px;
  background-color: #ffffff;

  &.has-error {
    box-shadow: 0 0 3px 3px rgba(255, 0, 0, 0.4);
  }

  .uie-radio-button {
    float: left;
    margin: 16px 0 0 12px;
  }
`;

export const PolicyText = styled.div`
  float: left;
  margin: 19px 0 0 10px;
  font-size: 12px;
  color: gray;

  & > a {
    ${ifProp(
      'activeColor',
      css`
        color: ${prop('activeColor')};
      `
    )};
  }
`;

export const StatusPanel = styled.div`
  width: 100%;
  background-color: #ffffff;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  text-align: center;
  color: black;
  font-size: 13px;

  .text {
    padding: 16px;
  }

  .navigation {
    display: block;
    padding: 0 16px 16px;
    font-weight: 700;
    cursor: pointer;
  }

  & > svg {
    padding-top: 16px;
  }
`;
