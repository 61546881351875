import styled from 'styled-components';
import { prop } from 'styled-tools';

const InputWrapper = styled.div`
  width: 100%;

  & > div {
    margin: 0 0 12px 0;
    width: 100%;
  }
  & input {
    ${prop('_style')};
  }
`;

const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export { InputWrapper, FieldWrapper };
