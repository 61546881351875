import winston from 'winston';
import { LOG_LEVEL, NODE_ENV } from '../config/constants';

const logger = winston.createLogger({
  level: LOG_LEVEL || 'debug',
  defaultMeta: { service: 'user-service' },
  silent: NODE_ENV === 'production'
});

logger.add(
  new winston.transports.Console({
    format: winston.format.combine(
      winston.format.colorize(),
      winston.format.timestamp({
        format: 'YYYY-MM-DD HH:mm:ss'
      }),
      winston.format.printf(
        info => `${info.timestamp} ${info.level}: ${info.message}`
      )
    )
  })
);

export default logger;
