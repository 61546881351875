import React from 'react';
import {
  Header,
  Title,
  CloseBtn,
  TitleWrapper,
  TitleBlock
} from './headerStyle';
import { gradientToString } from 'utils/helpers';

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{"background-image": string, background: string}}
   */
  getBackgroundColor() {
    const { settings } = this;
    if (
      !(
        settings.cb_title_background_color_from &&
        settings.cb_title_background_color_to
      )
    )
      return;
    const cbColorV = gradientToString(
      [
        settings.cb_title_background_color_to,
        settings.cb_title_background_color_from
      ],
      'top'
    );
    return { 'background-image': cbColorV, background: cbColorV };
  }

  /**
   *
   * @returns {*}
   */
  getTheme() {
    const { settings = {} } = this;
    return settings.Theme;
  }
}

const getHeaderBody = (props = {}) => {
  const { settings = {} } = props;
  const titleWrapperStyle = {
    marginTop: '5px'
  };
  const titleStyle = {
    top: '16px',
    left: 0,
    right: 0,
    textAlign: 'left',
    color: settings.header_text_color
  };
  return (
    <TitleWrapper style={titleWrapperStyle}>
      <Title style={titleStyle}>{settings.cb_selection_title}</Title>
    </TitleWrapper>
  );
};

/**
 *
 * @param props
 * @returns {*}
 */
const index = (props = {}) => {
  const { settings, onClose } = props;
  const Styles = new StylesConstructor({ settings });
  const headerStyle = {
    _backgroundColor: Styles.getBackgroundColor(),
    theme: Styles.getTheme()
  };
  const closeBtnProps = {
    onClick: onClose,
    active: true,
    _width: 28,
    _color: settings.header_text_color
  };

  return (
    <Header {...headerStyle}>
      <TitleBlock>
        {getHeaderBody(props)}
        <CloseBtn {...closeBtnProps} />
      </TitleBlock>
    </Header>
  );
};

export default index;
