import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const Form = styled.form`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
`;

const SendMessageWrap = styled.div`
  background-color: ${prop('bgColor')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  width: 95%;
  max-height: 82px;
  box-sizing: border-box;
  position: relative;
  transition: background-color 500ms;
  ${ifProp(
    'hasError',
    css`
      box-shadow: 0 0 3px 3px rgba(255, 0, 0, 0.4);
    `
  )};
  ${prop('style')}

  input, textarea {
    ${ifProp(
      'color',
      css`
        color: ${prop('color')};
      `
    )};
  }
`;

const FieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export { Form, SendMessageWrap, FieldWrapper };
