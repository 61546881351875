import React from 'react';

/**
 *
 * @param {object} props (color)
 * @returns {*}
 */
export const BuiltInIcon = (props = {}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill={props.color}
  >
    <path d="m 10,2.5 c 10,0 10,15 0,15 -10,0 -10,-15 0,-15 m 0,1.5 c -8,0 -8,12 0,12 8,0 8,-12 0,-12 z" />
    <circle cx="10" cy="10" r="1.5" />
    <path d="m 1,9.25 h 4.5 c 1 0, 1 1.5, 0 1.5 l -4.5,0 c -1 0, -1 -1.5, 0 -1.5 z" />
    <path d="m 14.5,9.25 h 4.5 c 1 0, 1 1.5, 0 1.5 l -4.5,0 c -1 0, -1 -1.5, 0 -1.5 z" />
    <path d="m 9.25,1 v 4.5 c 0 1, 1.5 1, 1.5 0 l 0,-4.5 c 0 -1, -1.5 -1, -1.5 0 z" />
    <path d="m 9.25,14.5 v 4.5 c 0 1, 1.5 1, 1.5 0 l 0,-4.5 c 0 -1, -1.5 -1, -1.5 0 z" />
  </svg>
);

export const ClockIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="m 10,2.5 c 10,0 10,15 0,15 -10,0 -10,-15 0,-15 m 0,1.5 c -8,0 -8,12 0,12 8,0 8,-12 0,-12 z" />
    <path d="m 9.25,5.5 0,4.5 c 0,1 1.5,1 1.5,0 l 0,-4.5 c 0,-1 -1.5,-1 -1.5,0 z" />
    <path d="m 5.5,9.25 4.5,0 c 1,0 1,1.5 0,1.5 l -4.5,0 c -1,0 -1,-1.5 0,-1.5 z" />
  </svg>
);

export const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path d="m 18.33,16.43 c 0.56,-0.98 0.57,-2.14 0.01,-3.11 L 12.7,3.57 c -0.56,-0.98 -1.57,-1.57 -2.69,-1.57 -1.13,0 -2.14,0.59 -2.69,1.56 l -5.64,9.77 c -0.56,0.98 -0.56,2.15 0.01,3.13 0.56,0.96 1.57,1.54 2.69,1.54 l 11.25,0 c 1.13,0 2.14,-0.59 2.71,-1.57 z m -1.22,-0.71 c -0.31,0.54 -0.87,0.86 -1.49,0.86 l -11.25,0 c -0.61,0 -1.16,-0.31 -1.47,-0.84 -0.31,-0.54 -0.31,-1.18 0,-1.72 L 8.53,4.26 c 0.31,-0.54 0.85,-0.85 1.47,-0.85 0.62,0 1.17,0.32 1.47,0.86 l 5.64,9.76 c 0.3,0.53 0.3,1.16 -0.01,1.69 z" />
    <path d="M 9,6.375 9,11.625 c 0,1.17 2,1.17 2,0 l 0,-5.25 c 0,-1.17 -2,-1.17 -2,0 z" />
    <circle r="1" cy="14.5" cx="10" />
  </svg>
);

/**
 * Get `exit` icon
 * @param size
 * @param color
 * @param transform
 * @returns {*}
 * @constructor
 */
export const ExitIcon = ({
  size = 24,
  color = '#000000',
  transform = 'rotate(180) translate(-24, -24)'
} = {}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    alt="Exit"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path
      fill={color}
      transform={transform}
      d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"
    />
  </svg>
);

/**
 *
 * @param {object} props = {
 *   size:     integer,
 *   color:    string
 * }
 * @returns {*}
 */
export const CheckmarkIcon = ({ color = '#000000', size = 24 } = {}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 19.419 20.264"
  >
    <path
      fill={color}
      d="M17.518,1.984c-1.467,0.653-7.186,9.141-9.909,12.863l-4.176-3.359c-0.506-0.373-1.597-0.589-1.997-0.045
      l-0.64,0.646c-0.572,0.82,0.368,1.896,1.05,2.594l5.741,5.38c0.521,0.458,1.609,0.102,2.179-0.719l0.484-0.699l0.002-0.001
      l8.533-15.447C19.184,2.433,20.5,0.549,17.518,1.984z"
    />
  </svg>
);

/**
 *
 * @param color
 * @param width
 * @param height
 * @returns {*}
 * @constructor
 */
export const MessageSuccessIcon = ({
  color = '#7CDF61',
  width = 64,
  height = 34
} = {}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-141.000000, -414.000000)">
        <g transform="translate(8.000000, 386.000000)">
          <g transform="translate(125.000000, 20.000000)">
            <rect
              id="Rectangle"
              fill="#FFFFFF"
              opacity="0"
              x="0"
              y="0"
              width="72"
              height="56"
            ></rect>
            <path
              d="M22.089183,16.3333333 L36.5,28.7967426 L50.910817,16.3333333 L22.089183,16.3333333 Z M52.6875,17.8699241 L37.2524775,31.2191327 C36.8194738,31.5936224 36.1805262,31.5936224 35.7475225,31.2191327 L20.3125,17.8699241 L20.3125,39.6666667 L52.6875,39.6666667 L52.6875,17.8699241 Z M20.3125,14 L52.6875,14 C53.9646585,14 55,15.0446689 55,16.3333333 L55,39.6666667 C55,40.9553311 53.9646585,42 52.6875,42 L20.3125,42 C19.0353415,42 18,40.9553311 18,39.6666667 L18,16.3333333 C18,15.0446689 19.0353415,14 20.3125,14 Z"
              id="sms"
              fill={color}
              fillRule="nonzero"
            ></path>
            <circle id="Oval" fill={color} cx="18" cy="18" r="10"></circle>
            <path
              d="M17.7474286,20.517318 L13.624695,17.2191312 C13.1934331,16.8741216 12.5641407,16.944043 12.2191312,17.375305 C11.8741216,17.8065669 11.944043,18.4358593 12.375305,18.7808688 L17.375305,22.7808688 C17.8424254,23.1545652 18.5309497,23.0372767 18.8479983,22.5299989 L23.8479983,14.5299989 C24.1407086,14.0616624 23.9983355,13.444712 23.5299989,13.1520017 C23.0616624,12.8592914 22.444712,13.0016645 22.1520017,13.4700011 L17.7474286,20.517318 Z"
              id="check"
              fill="#FFFFFF"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const MessageFailedIcon = ({
  color = '#FF0032',
  width = 47,
  height = 34
} = {}) => (
  <svg
    width={`${width}px`}
    height={`${height}px`}
    viewBox={`0 0 ${width} ${height}`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-141.000000, -414.000000)">
        <g transform="translate(8.000000, 386.000000)">
          <g transform="translate(125.000000, 20.000000)">
            <rect
              fill="#FFFFFF"
              opacity="0"
              x="0"
              y="0"
              width="72"
              height="56"
            ></rect>
            <path
              d="M22.089183,16.3333333 L36.5,28.7967426 L50.910817,16.3333333 L22.089183,16.3333333 Z M52.6875,17.8699241 L37.2524775,31.2191327 C36.8194738,31.5936224 36.1805262,31.5936224 35.7475225,31.2191327 L20.3125,17.8699241 L20.3125,39.6666667 L52.6875,39.6666667 L52.6875,17.8699241 Z M20.3125,14 L52.6875,14 C53.9646585,14 55,15.0446689 55,16.3333333 L55,39.6666667 C55,40.9553311 53.9646585,42 52.6875,42 L20.3125,42 C19.0353415,42 18,40.9553311 18,39.6666667 L18,16.3333333 C18,15.0446689 19.0353415,14 20.3125,14 Z"
              id="sms"
              fill={color}
              fillRule="nonzero"
            ></path>
            <circle fill={color} cx="18" cy="18" r="10"></circle>
            <path
              d="M19.2426407,17.8284271 L21.363961,19.9497475 C21.7544853,20.3402718 21.7544853,20.9734367 21.363961,21.363961 C20.9734367,21.7544853 20.3402718,21.7544853 19.9497475,21.363961 L17.8284271,19.2426407 L15.7071068,21.363961 C15.3165825,21.7544853 14.6834175,21.7544853 14.2928932,21.363961 C13.9023689,20.9734367 13.9023689,20.3402718 14.2928932,19.9497475 L16.4142136,17.8284271 L14.2928932,15.7071068 C13.9023689,15.3165825 13.9023689,14.6834175 14.2928932,14.2928932 C14.6834175,13.9023689 15.3165825,13.9023689 15.7071068,14.2928932 L17.8284271,16.4142136 L19.9497475,14.2928932 C20.3402718,13.9023689 20.9734367,13.9023689 21.363961,14.2928932 C21.7544853,14.6834175 21.7544853,15.3165825 21.363961,15.7071068 L19.2426407,17.8284271 Z"
              id="cross"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

/**
 *
 * @param {object} props (color, size)
 * @returns {*}
 * @constructor
 */
export const CloseIcon = (props = {}) => {
  const { color = '#000', size = 19 } = props;
  const viewBoxSize = typeof size === 'number' ? size : size.replace(/px$/, '');
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={size}
      height={size}
      fill={color}
      alt="Close Chat Window"
      enableBackground={`new 0 0 ${viewBoxSize} ${viewBoxSize}`}
    >
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
    </svg>
  );
};
