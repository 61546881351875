import {
  ROOM__ADDED,
  ROOM__UPDATE,
  ROOM__DELETED,
  ROOM__SET_READ_MESSAGES_LENGTH,
  ROOM__CONNECTED,
  ROOM__DISCONNECTED,
  ROOM__REINIT_DATA,
  ROOM__LOAD_HISTORY,
  ROOM__OPERATOR_UNASSIGNED_CHAT,
  ROOM__OPERATOR_ASSIGNED_CHAT,
  ROOM__OPERATOR_OFFLINE,
  ROOM__OPERATOR_ONLINE,
  ROOM__SEND_MESSAGE,
  ROOM__SET_OPERATOR_INFO,
  ROOM__OPERATOR_CLOSED_CHAT,
  ROOM__CLEAR_CLOSING_CHAT_TIMER,
  ROOM__SET_USER_TYPING,
  ROOM__UNSET_USER_TYPING,
  ROOM__SET_OPERATOR_TYPING,
  ROOM__UNSET_OPERATOR_TYPING,
  ROOM__STATUS_CHANGED,
  ROOM__CHAT_CHANGED,
  ROOM__SET_VISIBILITY,
  ROOM__SET_NEED_ANSWER,
  ROOM__SHOW_SEND_TRANSCRIPT_FORM,
  ROOM__SET_MB_INITIAL_MESSAGE_TIMESTAMP,
  ROOM__DELETE_FAKE_MESSAGES,
  ROOM__DELETE_MESSAGES_WITH_TEXT,
  ROOM__SAVE_SB_OPEN_TIMER_DATE,
  ROOM__SAVE_MB_OPEN_TIMER_DATE,
  ROOM__SAVE_CTA_OPEN_TIMER_DATE,
  ROOM__CHANGE_MESSAGE_STATUS,
  ROOM__CB_INITIAL_MESSAGE_IS_SENT,
  ROOM__DETAILS_FORM_SEND,
  GLOBAL__SET_ACTIVE_ROOM,
  ROOM__INITIALISE_QUALIFICATION_PROCESS,
  ROOM__QUALIFICATION_PUSH_HISTORY,
  ROOM__QUALIFICATION_COMPLETED,
  ROOM__QUALIFICATION_TEAM_STATUS,
  ROOM__QUALIFICATION_RESET,
  ROOM__SHOW_LAM_TIMER,
  ROOM__CLEAR_LAM_TIMER
} from '../actionConstants';

import { chatTypes } from '../../config/constants';
import { getChatHistory } from '../../utils/requests';
import logger from '../../utils/logger';

export function addRoom(room, params) {
  logger.debug(`Adding room ${JSON.stringify(room)}`);
  return (dispatch, getState) => {
    const { global, rooms = [] } = getState();
    logger.debug(`Have current rooms: ${JSON.stringify(rooms)}`);
    const maxVisibleRooms = chatTypes.ACTIVE_ROOMS_MAX_QUANTITY;
    let hasActiveRoom = false;
    const visibleRooms = rooms.reduce((accumulator, room) => {
      if (room.visible) accumulator += 1;
      if (room.room === global.activeRoom) hasActiveRoom = true;
      return accumulator;
    }, 0);
    logger.debug(`Visible rooms set to ${JSON.stringify(visibleRooms)}`);
    params.visible = visibleRooms <= maxVisibleRooms;

    dispatch({
      type: ROOM__ADDED,
      payload: { room, params }
    });

    if (params.visible && room && (!global.activeRoom || !hasActiveRoom)) {
      dispatch({
        type: GLOBAL__SET_ACTIVE_ROOM,
        payload: {
          activeRoom: room
        }
      });
    }
  };
}

export function updateRoom(room, params) {
  return dispatch => {
    return dispatch({
      type: ROOM__UPDATE,
      payload: { room, params }
    });
  };
}

export function deleteRoom(room) {
  return dispatch => {
    return dispatch({
      type: ROOM__DELETED,
      payload: { room }
    });
  };
}

export function loadRoomHistory(room, chatId) {
  return dispatch => {
    return getChatHistory(room, chatId).then((history = []) => {
      return dispatch({
        type: ROOM__LOAD_HISTORY,
        payload: {
          room,
          history
        }
      });
    });
  };
}

export function setChatId(room, chatId) {
  return dispatch => {
    dispatch({
      type: ROOM__CHAT_CHANGED,
      payload: { room, chatId }
    });
  };
}

export function reinitRoomData(room, data) {
  return dispatch => {
    dispatch({
      type: ROOM__REINIT_DATA,
      payload: { room, data }
    });
  };
}

export function connectRoom(room, chatId) {
  return dispatch => {
    dispatch({
      type: ROOM__CONNECTED,
      payload: { room, chatId }
    });
  };
}

export function disconnectRoom(room) {
  return dispatch => {
    dispatch({
      type: ROOM__DISCONNECTED,
      payload: { room }
    });
  };
}

export function setOperatorOnline(room, options) {
  return dispatch => {
    dispatch({
      type: ROOM__OPERATOR_ONLINE,
      payload: { room },
      options
    });
  };
}

export function setOperatorOffline(room) {
  return dispatch => {
    dispatch({
      type: ROOM__OPERATOR_OFFLINE,
      payload: { room }
    });
  };
}

export function setReadMessagesLength(room, length = 0) {
  return dispatch => {
    dispatch({
      type: ROOM__SET_READ_MESSAGES_LENGTH,
      payload: { room, length }
    });
  };
}

export function setStatus(room, status, eventSource) {
  return dispatch => {
    dispatch({
      type: ROOM__STATUS_CHANGED,
      payload: { room, status, eventSource }
    });
  };
}

export function sendMessage(room, message, options = {}) {
  return (dispatch, getState) => {
    dispatch({
      type: ROOM__SEND_MESSAGE,
      payload: { room, message, options }
    });
  };
}

export function setMsgStatus(payload) {
  return dispatch => {
    dispatch({
      type: ROOM__CHANGE_MESSAGE_STATUS,
      payload
    });
  };
}

export function operatorAssignedChat(payload) {
  return dispatch => {
    dispatch({
      type: ROOM__OPERATOR_ASSIGNED_CHAT,
      payload
    });
  };
}

export function operatorUnassignedChat(room) {
  return dispatch => {
    dispatch({
      type: ROOM__OPERATOR_UNASSIGNED_CHAT,
      payload: { room }
    });
  };
}

export function operatorClosedChat(room, info = {}) {
  return dispatch => {
    return new Promise(resolve => {
      setTimeout(resolve, info.closingTimeout ? info.closingTimeout * 1000 : 0);
      delete info.closingTimeout;
      delete info.departmentName;
      dispatch({
        type: ROOM__OPERATOR_CLOSED_CHAT,
        payload: { room, ...info }
      });
    });
  };
}

export function setOperatorInfo(room, operatorInfo) {
  return dispatch => {
    dispatch({
      type: ROOM__SET_OPERATOR_INFO,
      payload: { room, operatorInfo }
    });
  };
}

export function clearClosedChatTimer(room, data) {
  return dispatch => {
    dispatch({
      type: ROOM__CLEAR_CLOSING_CHAT_TIMER,
      payload: { room, ...data }
    });
  };
}

export function setOperatorTyping(room) {
  return dispatch => {
    dispatch({
      type: ROOM__SET_OPERATOR_TYPING,
      payload: { room }
    });
  };
}

export function unsetOperatorTyping(room) {
  return dispatch => {
    dispatch({
      type: ROOM__UNSET_OPERATOR_TYPING,
      payload: { room }
    });
  };
}

export function setUserTyping(room) {
  return dispatch => {
    dispatch({
      type: ROOM__SET_USER_TYPING,
      payload: { room }
    });
  };
}

export function unsetUserTyping(room) {
  return dispatch => {
    dispatch({
      type: ROOM__UNSET_USER_TYPING,
      payload: { room }
    });
  };
}

export function setNeedAnswer(payload) {
  return dispatch => {
    dispatch({
      type: ROOM__SET_NEED_ANSWER,
      payload
    });
  };
}

export function showSendTranscriptForm(payload) {
  return dispatch => {
    return new Promise(resolve => {
      setTimeout(resolve, 0);
      dispatch({
        type: ROOM__SHOW_SEND_TRANSCRIPT_FORM,
        payload
      });
    });
  };
}

export function setVisibility(room, visible) {
  return (dispatch, getState) => {
    dispatch({
      type: ROOM__SET_VISIBILITY,
      payload: {
        room,
        visible
      }
    });

    if (!visible) return;
    // If existing visible rooms more than can be
    const { rooms = [] } = getState();
    const maxVisibleRooms = chatTypes.ACTIVE_ROOMS_MAX_QUANTITY;
    const visibleRoomsInitData = {
      length: 0,
      firstVisibleRoom: null
    };
    const visibleRoomsData = rooms.reduce((accumulator, room) => {
      if (room.visible) {
        accumulator.length += 1;
        if (!accumulator.firstVisibleRoom) {
          accumulator.firstVisibleRoom = room.room;
        }
      }
      return accumulator;
    }, visibleRoomsInitData);

    if (visibleRoomsData.length > maxVisibleRooms) {
      dispatch({
        type: ROOM__SET_VISIBILITY,
        payload: {
          room: visibleRoomsData.firstVisibleRoom,
          visible: false
        }
      });
    }
  };
}

export function setMbInitialMessageTimestamp(room) {
  return dispatch => {
    dispatch({
      type: ROOM__SET_MB_INITIAL_MESSAGE_TIMESTAMP,
      payload: { room }
    });
  };
}

export function deleteFakeMessages(room) {
  return dispatch => {
    dispatch({
      type: ROOM__DELETE_FAKE_MESSAGES,
      payload: { room }
    });
  };
}

export function deleteMessagesWithText(room, text) {
  return dispatch => {
    dispatch({
      type: ROOM__DELETE_MESSAGES_WITH_TEXT,
      payload: { room, text }
    });
  };
}

export function setSbOpenTimerDate({ room, sbNextOpenDate }) {
  return dispatch => {
    dispatch({
      type: ROOM__SAVE_SB_OPEN_TIMER_DATE,
      payload: { room, sbNextOpenDate }
    });
  };
}

export function setMbOpenTimerDate({ room, mbNextOpenDate }) {
  return dispatch => {
    dispatch({
      type: ROOM__SAVE_MB_OPEN_TIMER_DATE,
      payload: { room, mbNextOpenDate }
    });
  };
}

export function setCtaOpenTimerDate({ room, ctaNextOpenDate }) {
  logger.debug(`Setting ctaNextOpenDate: ${ctaNextOpenDate}`);
  return dispatch => {
    dispatch({
      type: ROOM__SAVE_CTA_OPEN_TIMER_DATE,
      payload: { room, ctaNextOpenDate }
    });
  };
}

export function sendCbInitialMessage(payload) {
  return dispatch => {
    dispatch({
      type: ROOM__CB_INITIAL_MESSAGE_IS_SENT,
      payload
    });
  };
}

export function detailsFormSend(room) {
  return dispatch => {
    dispatch({
      type: ROOM__DETAILS_FORM_SEND,
      payload: { room }
    });
  };
}

export function initialiseQualificationProcess(room, teams) {
  return dispatch => {
    dispatch({
      type: ROOM__INITIALISE_QUALIFICATION_PROCESS,
      payload: { room, teams }
    });
  };
}

export function domainQualificationAnswerClicked(room, answer) {
  return dispatch => {
    dispatch({
      type: ROOM__QUALIFICATION_PUSH_HISTORY,
      payload: { room, item: answer }
    });
  };
}

export function domainQualificationPushQuestion(room, question) {
  return dispatch => {
    dispatch({
      type: ROOM__QUALIFICATION_PUSH_HISTORY,
      payload: { room, item: question }
    });
  };
}

export function domainQualificationCompleted(room) {
  return dispatch => {
    dispatch({
      type: ROOM__QUALIFICATION_COMPLETED,
      payload: { room }
    });
  };
}

export function domainQualificationReset(room) {
  return dispatch => {
    dispatch({
      type: ROOM__QUALIFICATION_RESET,
      payload: { room }
    });
  };
}

export function setDomainQualificationTeamStatus(payload) {
  return dispatch => {
    dispatch({
      type: ROOM__QUALIFICATION_TEAM_STATUS,
      payload
    });
  };
}

export function setShowLamTimer(room, timer) {
  return dispatch => {
    dispatch({
      type: ROOM__SHOW_LAM_TIMER,
      payload: { room, timer }
    });
  };
}

export function clearLamTimer(room) {
  return dispatch => {
    dispatch({
      type: ROOM__CLEAR_LAM_TIMER,
      payload: { room }
    });
  };
}
