/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @param props
   */
  getFBImg(props = {}) {
    const { settings = {} } = this;
    const { operatorOnline } = props;
    const floatingButtonPath =
      (() => {
        if (settings.fb_standard_button === 'on') {
          return (
            settings.fb_standard_button_modified ||
            settings.fb_standard_button_type
          );
        } else {
          return settings.fb_custom_button;
        }
      })() || '';
    const floatingButtonOfflinePath =
      (() => {
        if (settings.fb_offline_standard_button === 'on') {
          return (
            settings.fb_offline_standard_button_modified ||
            settings.fb_offline_standard_button_type
          );
        } else {
          return settings.fb_offline_custom_button;
        }
      })() || '';
    const floatingButtonBorder = settings.fb_border_color
      ? { 'border-color': settings.fb_border_color }
      : '';
    const floatingButtonOfflineBorder = settings.fb_offline_border_color
      ? { 'border-color': settings.fb_offline_border_color }
      : '';
    const data = {};

    if (operatorOnline) {
      if (settings.fb_svg_color) {
        data.backgroundImg = { 'background-color': settings.fb_svg_color };
      }
      data.imgSrc = floatingButtonPath;
      data.borderColor = floatingButtonBorder;
    } else {
      if (settings.fb_offline_svg_color) {
        data.backgroundImg = {
          'background-color': settings.fb_offline_svg_color
        };
      }
      data.imgSrc = floatingButtonOfflinePath;
      data.borderColor = floatingButtonOfflineBorder;
    }

    return data;
  }
}

export default StylesConstructor;
