import styled from 'styled-components';
import { prop } from 'styled-tools';
//
import { CONTACT_VIA_HEIGHT } from 'config/constants';

export const ComponentWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  ${prop('style')};

  .multi:first-child {
    border-radius: 0 0 0 5px;
  }

  .multi:last-child {
    border-radius: 0 0 5px 0;
  }

  .single {
    border-radius: 0 0 5px 5px;
  }
`;

export const Footer = styled.div`
  float: left;
  width: ${props => props.width};
  position: relative;
  z-index: 1;
  height: ${CONTACT_VIA_HEIGHT}px;
  text-align: center;
  font-size: 13px;
  line-height: ${CONTACT_VIA_HEIGHT}px;
  background-color: rgba(255, 255, 255, 0.85);
  color: #353535;
  font-weight: 700;
  cursor: pointer;

  svg {
    vertical-align: -4%;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    background-color: rgba(255, 255, 255, 0.95);
    -webkit-box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  }

  &:before {
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    right: 0;
  }

  &:after {
    content: '';
    clear: both;
    display: table;
  }
`;
