import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const Form = styled.form`
  background-color: ${prop('bgColor')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  transition: background-color 500ms;

  & textarea {
    ${ifProp(
      'hasFocus',
      css`
        color: #000000;
      `,
      css`
        color: #ffffff;
      `
    )};
  }
`;

export { Form };
