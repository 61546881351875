import styled, { css, keyframes } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
//
import { convertHexToRgba, stylesAlignment } from '../../utils/helpers';
import { calculateSBDimensions } from './helper';
import { CTA_WIDTH, PADDING } from '../CallToAction/frameWrapper';
import SETTING from '../../helpers/mobile-desktop-settings';
import { CONTACT_VIA_HEIGHT } from '../../config/constants';

export const SB_WIDTH = 380;
export const SB_HEIGHT = 250;

const transY100 = 'translateY(100%)';
const transYn100 = 'translateY(-100%)';
const transX100 = 'translateX(100%)';
const transXn100 = 'translateX(-100%)';

const open = (props = {}) => {
  const { slideOn, slideAlign } = props;
  if (!slideOn || !slideAlign) return;
  let slide;
  switch (slideOn) {
    case 'top': {
      slide = transYn100;
      break;
    }
    case 'bottom': {
      slide = transY100;
      break;
    }
    case 'left': {
      slide = transXn100;
      break;
    }
    case 'right': {
      slide = transX100;
      break;
    }
  }

  return keyframes`
  0% {
    opacity: 0;
    transform: ${slide};
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;
};

const close = (props = {}) => {
  const { slideOff, slideAlign } = props;
  if (!slideOff || !slideAlign) return;

  let slide;
  switch (slideOff) {
    case 'top': {
      slide = transY100;
      break;
    }
    case 'bottom': {
      slide = transYn100;
      break;
    }
    case 'left': {
      slide = transXn100;
      break;
    }
    case 'right': {
      slide = transX100;
      break;
    }
  }

  return keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: ${slide};
  }
`;
};

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {*}
   */
  getShadow() {
    const { settings = {} } = this;
    const color = settings.cb_shadow_color;
    return {
      'box-shadow': `0 0 ${settings.fb_shadow}px ${convertHexToRgba(color, 80)}`
    };
  }

  /**
   *
   * @param dimensions
   * @returns {{top, left, bottom, right}|*}
   */
  getAlignment(dimensions = {}) {
    const { settings = {} } = this;
    const fbIsAvailable = SETTING('FB_ONLINE_BUTTON', settings) === 'on';
    const isSbButtonEnabled = SETTING('SB2_FB_ENABLED', settings) === 'on';

    if (!(settings.fb_offline_alignment_x && settings.cb_alignment_x)) return;

    let hOffset = 0;
    let vOffset = 0;
    const girlLegsOffset = 12;
    let width = parseInt(dimensions.width) || 0;
    let height = parseInt(dimensions.height) || 0;
    const delta = fbIsAvailable ? 15 : 0;
    const fbOffsetH = +SETTING('FB_OFFSET_HORIZONTAL', settings);
    const fbOffsetV = +SETTING('FB_OFFSET_VERTICAL', settings);
    const sbFbDisabledHeight = +SETTING('SB2_FB_DISABLED_HEIGHT', settings);
    const sbFbDisabledWidth = +SETTING('SB2_FB_DISABLED_WIDTH', settings);
    const koef = settings.sb2_coef || 1;
    const x = settings.fb_alignment_x || 'right';
    const y = settings.fb_alignment_y || 'bottom';

    switch (true) {
      case y === 'bottom':
      case y === 'top':
        if (fbIsAvailable || isSbButtonEnabled) {
          vOffset = height + delta + fbOffsetV;
        } else if (sbFbDisabledHeight > 0) {
          vOffset = sbFbDisabledHeight;
        } else {
          vOffset = fbOffsetV;
        }
        break;

      case y === 'center':
        height = 2 * SB_HEIGHT * koef + height + delta;
        break;
    }

    switch (true) {
      case x === 'middle':
        width = -Math.round((CTA_WIDTH + PADDING) / 2);
        break;

      case x === 'right':
        if (fbIsAvailable || isSbButtonEnabled) {
          hOffset += fbOffsetH;
        } else if (sbFbDisabledWidth > 0) {
          hOffset += sbFbDisabledWidth;
        }

        if (SETTING('SB2_THEME', settings) === 'girl') {
          hOffset =
            fbOffsetH >= girlLegsOffset * koef
              ? fbOffsetH
              : girlLegsOffset * koef + fbOffsetH;
        }
        break;

      default:
        hOffset += fbOffsetH;
        break;
    }

    return stylesAlignment({
      x,
      y,
      hOffset: `${hOffset}px`,
      vOffset: `${vOffset}px`,
      element: { width: width, height: height }
    });
  }

  /**
   *
   * @returns {*}
   */
  getSlideOn() {
    const { settings = {} } = this;
    let slideOn;
    const y = settings.fb_alignment_y;
    switch (true) {
      case y === 'top':
        slideOn = 'top';
        break;
      case y === 'center':
      case y === 'bottom':
        slideOn = 'bottom';
        break;
    }
    return slideOn;
  }

  /**
   *
   * @returns {*}
   */
  getSlideOff() {
    const { settings = {} } = this;
    let slideOn;
    const y = settings.fb_alignment_y;
    switch (true) {
      case y === 'top':
        slideOn = 'bottom';
        break;
      case y === 'center':
      case y === 'bottom':
        slideOn = 'top';
        break;
    }
    return slideOn;
  }

  /**
   *
   * @returns {{x: *, y: *}}
   */
  getSlideAlignment() {
    const { settings = {} } = this;
    return {
      x: settings.fb_offline_alignment_x,
      y: settings.fb_offline_alignment_y
    };
  }
}

const frameWrapperFormatProps = (props = {}) => {
  const {
    settings,
    transitionState,
    parentState,
    dimension,
    uiLocked,
    useContactVia,
    hasFormVisibility
  } = props;
  const Styles = new StylesConstructor({ settings, parentState });
  const style = {
    pointerEvents: uiLocked ? 'none' : 'auto'
  };
  const offset = {
    width: 0,
    height: useContactVia ? CONTACT_VIA_HEIGHT : 0
  };

  if (!hasFormVisibility) {
    offset.height -= 50;
  }

  const internalProps = {
    _boxShadow: Styles.getShadow(),
    _sbAlign: Styles.getAlignment(dimension),
    _dimensions: calculateSBDimensions(settings, offset),
    _slideOn: Styles.getSlideOn(),
    _slideOff: Styles.getSlideOff(),
    _slideAlign: Styles.getSlideAlignment(),
    style
  };

  return { transitionState, ...internalProps };
};

const FrameWrapper = styled.div`
  position: fixed;
  transition: opacity 1s linear;
  box-sizing: border-box;
  z-index: 99999993;
  border-radius: 6px;

  ${prop('_dimensions')};
  ${prop('_sbAlign')};
  ${prop('_boxShadow')};
  ${prop('style')};
  ${switchProp('transitionState', {
    entered: css`
      transform: translateY(0);
    `,
    entering: props => {
      if (!props._slideOn) props._slideOn = 'bottom';
      return css`
        animation: ${open({
            slideOn: props._slideOn,
            slideAlign: props._slideAlign
          })}
          400ms linear;
      `;
    },
    exited: css`
      transform: translateY(0);
    `,
    exiting: props => {
      if (!props._slideOff) props._slideOff = 'top';
      return css`
        animation: ${close({
            slideOff: props._slideOff,
            slideAlign: props._slideAlign
          })}
          400ms linear;
      `;
    }
  })}
`;

export { FrameWrapper, frameWrapperFormatProps };
