import React from 'react';
import PropTypes from 'prop-types';
import { FaEnvelope } from 'react-icons/fa';

import { Footer } from '../../contactViaStyle';

export default function SmsButton({ isMulti, onClick, width = '50%' }) {
  return (
    <Footer
      className={isMulti ? 'multi' : 'single'}
      width={width}
      onClick={onClick}
      tabIndex={0}
      onKeyDown={({ key }) => (key === 'Enter' ? onClick() : true)}
    >
      <FaEnvelope /> SMS
    </Footer>
  );
}

SmsButton.propTypes = {
  isMulti: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string
};
