import {
  CONTACT_VIA__ACTIVATE,
  CONTACT_VIA__DEACTIVATE,
  CONTACT_VIA__SET_SHOW_MODE,
  SYSTEM__CLEAR
} from '../actionConstants';
import { CONTACT_VIA_SHOW_MODES } from 'config/constants';
import { getProp } from 'utils/helpers';

const updateState = function(state = {}, newState = {}) {
  return Object.assign({}, state, newState);
};

const init = {
  active: false,
  showMode: CONTACT_VIA_SHOW_MODES.INITIAL
};

export default function(state = init, action) {
  switch (action.type) {
    case CONTACT_VIA__ACTIVATE:
      return updateState(state, {
        active: true
      });

    case CONTACT_VIA__DEACTIVATE:
      return updateState(state, {
        active: false
      });

    case CONTACT_VIA__SET_SHOW_MODE:
      return updateState(state, {
        showMode: getProp(
          action,
          'payload.showMode',
          CONTACT_VIA_SHOW_MODES.INITIAL
        )
      });

    case SYSTEM__CLEAR:
      return updateState(state, init);

    default:
      return state;
  }
}
