import mobileDevice from 'ismobilejs';
import { IS_MOBILE_WIDTH } from 'config/constants';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;

const getSettingsString = type => {
  const lowerCaseSetting = type.toLowerCase();
  const list = lowerCaseSetting.split('_');
  return isPhone
    ? [...list.slice(0, 1), 'mobile', ...list.slice(1)].join('_')
    : lowerCaseSetting;
};

const SETTING = (type, settings) => settings[getSettingsString(type)] || '';
export default SETTING;
