import {
  RS__ACTIVATE,
  RS__DEACTIVATE,
  RS__OPEN,
  RS__CLOSE,
  RS__TRANSITION_CHANGE
} from '../actionConstants';

export function openRs() {
  return dispatch => {
    dispatch({
      type: RS__OPEN
    });
  };
}

export function closeRs() {
  return dispatch => {
    dispatch({
      type: RS__CLOSE
    });
  };
}

export function activateRs() {
  return dispatch => {
    dispatch({
      type: RS__ACTIVATE
    });
  };
}

export function deactivateRs() {
  return dispatch => {
    dispatch({
      type: RS__DEACTIVATE
    });
  };
}

export function transitionChangeRs(payload = {}) {
  return dispatch => {
    dispatch({
      type: RS__TRANSITION_CHANGE,
      payload
    });
  };
}
