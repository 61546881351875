import logger from './logger';

export const getVcSettings = () => window.LC_DATA || window.lc_data || null;

export const hasVcSettings = () => getVcSettings() !== null;

export const isNewSchema = () => {
  const settings = getVcSettings();

  return (
    settings &&
    !Array.isArray(settings) &&
    typeof settings === 'object' &&
    settings.id === undefined
  );
};

export const getRooms = () => {
  if (!hasVcSettings()) {
    return [];
  }

  const settings = getVcSettings();
  logger.debug(`Parsing local settings as: ${JSON.stringify(settings)}`);

  if (isNewSchema()) {
    return settings.rooms || Array.isArray(settings) ? settings : [settings];
  }

  return Array.isArray(settings) ? settings : [settings];
};

export const getVcSetting = (settingKey, defaultValue = null) => {
  if (!hasVcSettings()) {
    return defaultValue;
  }

  const settings = getVcSettings();

  return settings[settingKey] || defaultValue;
};
