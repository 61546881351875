import { isEmpty, getProp } from 'utils/helpers';
import {
  chatTypes,
  messageStatuses,
  MB_INITIAL_MESSAGE_TIMEOUT
} from '../../config/constants';
import {
  ROOM__ADDED,
  ROOM__UPDATE,
  ROOM__DELETED,
  ROOM__REINIT_DATA,
  ROOM__LOAD_HISTORY,
  ROOM__OPERATOR_ONLINE,
  ROOM__OPERATOR_OFFLINE,
  ROOM__CONNECTED,
  ROOM__DISCONNECTED,
  ROOM__SET_READ_MESSAGES_LENGTH,
  ROOM__OPERATOR_ASSIGNED_CHAT,
  ROOM__OPERATOR_UNASSIGNED_CHAT,
  ROOM__SEND_MESSAGE,
  ROOM__SET_OPERATOR_INFO,
  ROOM__OPERATOR_CLOSED_CHAT,
  ROOM__CLEAR_CLOSING_CHAT_TIMER,
  ROOM__SET_OPERATOR_TYPING,
  ROOM__UNSET_OPERATOR_TYPING,
  ROOM__SET_USER_TYPING,
  ROOM__UNSET_USER_TYPING,
  ROOM__STATUS_CHANGED,
  ROOM__CHAT_CHANGED,
  ROOM__SHOW_SEND_TRANSCRIPT_FORM,
  ROOM__SET_VISIBILITY,
  ROOM__SET_NEED_ANSWER,
  SB__SEND_MESSAGE,
  SYSTEM__CLEAR,
  ROOM__SET_MB_INITIAL_MESSAGE_TIMESTAMP,
  ROOM__DELETE_FAKE_MESSAGES,
  ROOM__DELETE_MESSAGES_WITH_TEXT,
  ROOM__SAVE_SB_OPEN_TIMER_DATE,
  ROOM__SAVE_MB_OPEN_TIMER_DATE,
  ROOM__SAVE_CTA_OPEN_TIMER_DATE,
  ROOM__CHANGE_MESSAGE_STATUS,
  ROOM__CB_INITIAL_MESSAGE_IS_SENT,
  ROOM__DETAILS_FORM_SEND,
  ROOM__INITIALISE_QUALIFICATION_PROCESS,
  ROOM__QUALIFICATION_PUSH_HISTORY,
  ROOM__QUALIFICATION_COMPLETED,
  ROOM__QUALIFICATION_TEAM_STATUS,
  ROOM__QUALIFICATION_RESET,
  ROOM__SHOW_LAM_TIMER,
  ROOM__CLEAR_LAM_TIMER
} from '../actionConstants';

export const initRoom = {
  room: '',
  operatorInfo: null,
  connected: false,
  operatorOnline: false,
  waitingOperator: false,
  operatorIsAssigned: false,
  operatorIsFakeAssigned: false,
  chatListMessages: [],
  lastReadMessagesLength: 0,
  closingTime: 0,
  isOperatorTyping: false,
  isUserTyping: false,
  timer: false,
  visible: false,
  domainId: null,
  accountId: null,
  accountPublicId: null,
  superId: null,
  groupId: null,
  chatId: null,
  dealerName: '',
  advertName: '',
  needAnswer: false,
  wasStored: false,
  sbNextOpenDate: null,
  mbNextOpenDate: null,
  ctaNextOpenDate: null,
  isShownSendTranscriptForm: false,
  status: chatTypes.STATUS_NEW,
  mbInitialMessageTimestamp: null,
  cbInitialMessageIsSent: false,
  detailsFormIsSent: false,
  contactViaIsVisible: false,
  qualificationProcess: {
    active: false,
    completed: false,
    history: [],
    teams: []
  },
  showLamTimer: null
};

function updateRoomState(state, room, cb) {
  return state.map(r => (r.room === room ? Object.assign({}, r, cb(r)) : r));
}

function prepareInternalMessage({
  author,
  type = false,
  isSystem,
  isFake,
  ...rest
} = {}) {
  const isSystemMsg = type ? type === 'system' : !!isSystem;
  const _isClientOwner = !isSystemMsg && author !== 'operator' && !isFake;
  return {
    ...rest,
    author,
    isFake,
    type: !isSystemMsg ? 'text' : 'system',
    _isClientOwner: _isClientOwner,
    _isServerOwner: !_isClientOwner,
    status: messageStatuses.MSG_STATUS_NEW
  };
}

export default function(state = [], { type, payload = {} }) {
  const { room, params } = payload;
  let currentRoom;
  let data;

  switch (type) {
    case ROOM__ADDED:
      return [...state, { ...initRoom, room, ...params }];

    case ROOM__UPDATE:
      return updateRoomState(state, room, () => ({ ...params }));

    case ROOM__DELETED:
      return [...state].reduce((accumulator, currentRoom) => {
        if (currentRoom.room !== room) {
          accumulator.push(currentRoom);
        }
        return accumulator;
      }, []);

    case ROOM__REINIT_DATA:
      return updateRoomState(state, room, currentRoom => {
        if (!currentRoom) return;
        const metaPropsNames = [
          'room',
          'chatId',
          'domainId',
          'accountId',
          'accountPublicId',
          'superId',
          'groupId',
          'dealerName',
          'advertName',
          'connected',
          'visible',
          'operatorOnline',
          'operatorInfo',
          'operatorIsAssigned',
          'operatorIsFakeAssigned'
        ];
        data = Object.keys(initRoom).reduce((accumulator, currentPropName) => {
          if (metaPropsNames.includes(currentPropName)) {
            accumulator[currentPropName] = currentRoom[currentPropName];
          } else {
            accumulator[currentPropName] = initRoom[currentPropName];
          }
          return accumulator;
        }, {});
        return Object.assign(data, { reinitialized: true }, payload.data);
      });

    case ROOM__STATUS_CHANGED:
      return updateRoomState(state, room, () => ({ status: payload.status }));

    case ROOM__LOAD_HISTORY: {
      currentRoom = state.find(r => r.room === room) || {};
      const chatListMessages = payload.history.length
        ? payload.history.map(el => {
            const _isClientOwner =
              el.type !== 'system' && isEmpty(el.operator_id);
            return {
              author: el.client_id ? 'visitor' : 'operator',
              date: new Date(el.created_at).toTimeString().split(' ')[0],
              last: el.last,
              text: el.message,
              type: el.type,
              operatorId: el.operator_id,
              _isClientOwner: _isClientOwner,
              _isServerOwner: !_isClientOwner
            };
          })
        : [];
      return updateRoomState(state, room, () => ({
        chatListMessages
      }));
    }

    case ROOM__CONNECTED:
      data = { connected: true, chatId: payload.chatId };
      currentRoom = state.find(r => r.room === room) || {};
      if (currentRoom.chatId && currentRoom.chatId !== payload.chatId) {
        const metaPropsNames = [
          'room',
          'domainId',
          'accountId',
          'accountPublicId',
          'superId',
          'groupId',
          'dealerName',
          'advertName'
        ];
        data = Object.keys(initRoom).reduce((accumulator, currentPropName) => {
          if (metaPropsNames.includes(currentPropName))
            accumulator[currentPropName] = initRoom[currentPropName];
          return accumulator;
        }, data);
      }
      return updateRoomState(state, room, () => data);

    case ROOM__DISCONNECTED:
      state.connected = false;
      return updateRoomState(state, room, () => ({ connected: false }));

    case ROOM__OPERATOR_ONLINE:
      return updateRoomState(state, room, () => ({
        operatorOnline: true
      }));

    case ROOM__OPERATOR_OFFLINE:
      return updateRoomState(state, room, () => ({ operatorOnline: false }));

    case ROOM__SET_READ_MESSAGES_LENGTH:
      return updateRoomState(state, room, () => ({
        lastReadMessagesLength: payload.length
      }));

    case ROOM__CHAT_CHANGED:
      return updateRoomState(state, room, () => ({
        chatId: payload.chatId,
        status: chatTypes.STATUS_NEW,
        reinitialized: false
      }));

    case ROOM__SEND_MESSAGE: {
      const isSystemMsg = getProp(payload, 'message.type', false)
        ? payload.message.type === 'system'
        : !!payload.message.isSystem;
      const _isClientOwner =
        !isSystemMsg &&
        payload.message.author !== 'operator' &&
        !payload.options.isFake;

      return updateRoomState(state, room, room => {
        const messages = [
          ...room.chatListMessages.map(msg => ({ ...msg, last: false }))
        ];
        let message = prepareInternalMessage({
          ...payload.message,
          last: true
        });
        if (payload.options) message = { ...message, ...payload.options };

        const updatedData = {
          chatListMessages: [...messages, message]
        };

        if (!message.isFake) {
          updatedData.waitingOperator =
            _isClientOwner &&
            !room.operatorIsAssigned &&
            !room.operatorIsFakeAssigned;
          if (room.timer) {
            updatedData.timer = false;
            updatedData.closingTime = 0;
          }
        }

        return updatedData;
      });
    }

    case ROOM__DELETE_FAKE_MESSAGES:
      return updateRoomState(state, room, room => {
        return {
          chatListMessages: [...room.chatListMessages.filter(m => !m.isFake)]
        };
      });

    case ROOM__DELETE_MESSAGES_WITH_TEXT:
      return updateRoomState(state, room, room => {
        return {
          chatListMessages: [
            ...room.chatListMessages.filter(m => m.text !== payload.text)
          ]
        };
      });

    case SB__SEND_MESSAGE: {
      const activeRoom = room || payload.room;
      return updateRoomState(state, activeRoom, room => {
        const messages = [
          ...room.chatListMessages.map(msg => ({ ...msg, last: false }))
        ];
        const message = prepareInternalMessage({
          ...payload.message,
          last: true
        });

        return {
          waitingOperator: !room.operatorIsAssigned,
          chatListMessages: [...messages, message]
        };
      });
    }

    case ROOM__CHANGE_MESSAGE_STATUS: {
      const messageText = getProp(payload, 'text');
      const status = getProp(payload, 'status', messageStatuses.MSG_STATUS_NEW);
      return updateRoomState(state, room, room => {
        const msgIndex = room.chatListMessages.findIndex(
          m =>
            m.status === messageStatuses.MSG_STATUS_NEW &&
            m._isClientOwner &&
            m.text === messageText
        );
        if (msgIndex === -1) return {};
        const chatListMessages = [...room.chatListMessages];
        chatListMessages.splice(msgIndex, 1, {
          ...chatListMessages[msgIndex],
          status
        });
        return { chatListMessages };
      });
    }

    case ROOM__SET_OPERATOR_INFO: {
      const { operatorInfo } = payload;
      return updateRoomState(state, room, () => ({ operatorInfo }));
    }

    case ROOM__CB_INITIAL_MESSAGE_IS_SENT: {
      const { cbInitialMessageIsSent = true } = payload;
      return updateRoomState(state, room, () => ({
        cbInitialMessageIsSent
      }));
    }

    case ROOM__OPERATOR_ASSIGNED_CHAT:
      return updateRoomState(state, room, () => payload);

    case ROOM__OPERATOR_UNASSIGNED_CHAT:
      return updateRoomState(state, room, () => ({
        operatorIsAssigned: false,
        operatorIsFakeAssigned: false
      }));

    case ROOM__OPERATOR_CLOSED_CHAT:
      return updateRoomState(state, room, () => ({
        timer: true,
        closingTime: payload.closeAt,
        status: payload.status || chatTypes.STATUS_SUSPENDED,
        operatorIsAssigned: false,
        needAnswer: false
      }));

    case ROOM__CLEAR_CLOSING_CHAT_TIMER:
      return updateRoomState(state, room, room => ({
        timer: false,
        closingTime: 0,
        operatorIsFakeAssigned: room.operatorIsAssigned,
        ...payload
      }));

    case ROOM__SET_NEED_ANSWER:
      return updateRoomState(state, room, () => ({
        needAnswer: payload.needAnswer
      }));

    case ROOM__SET_OPERATOR_TYPING:
      return updateRoomState(state, room, () => ({ isOperatorTyping: true }));

    case ROOM__SET_MB_INITIAL_MESSAGE_TIMESTAMP:
      return updateRoomState(state, room, () => ({
        mbInitialMessageTimestamp: Date.now() + MB_INITIAL_MESSAGE_TIMEOUT
      }));

    case ROOM__UNSET_OPERATOR_TYPING:
      return updateRoomState(state, room, () => ({ isOperatorTyping: false }));

    case ROOM__UNSET_USER_TYPING:
      return updateRoomState(state, room, () => ({ isUserTyping: false }));

    case ROOM__SET_USER_TYPING:
      return updateRoomState(state, room, () => ({ isUserTyping: true }));

    case ROOM__SHOW_SEND_TRANSCRIPT_FORM:
      return updateRoomState(state, room, () => ({
        isShownSendTranscriptForm: !!payload.show
      }));

    case ROOM__SET_VISIBILITY:
      return updateRoomState(state, room, () => ({ visible: payload.visible }));

    case ROOM__SAVE_SB_OPEN_TIMER_DATE:
      return updateRoomState(state, room, () => ({
        sbNextOpenDate: payload.sbNextOpenDate
      }));

    case ROOM__SAVE_MB_OPEN_TIMER_DATE:
      return updateRoomState(state, room, () => ({
        mbNextOpenDate: payload.mbNextOpenDate
      }));

    case ROOM__SAVE_CTA_OPEN_TIMER_DATE:
      return updateRoomState(state, room, () => ({
        ctaNextOpenDate: payload.ctaNextOpenDate
      }));

    case ROOM__DETAILS_FORM_SEND:
      return updateRoomState(state, room, () => ({ detailsFormIsSent: true }));

    case ROOM__INITIALISE_QUALIFICATION_PROCESS:
      return updateRoomState(state, room, r => {
        const qualificationProcess = r.qualificationProcess;
        qualificationProcess.active = true;
        qualificationProcess.teams = payload.teams;

        return { qualificationProcess };
      });

    case ROOM__QUALIFICATION_PUSH_HISTORY:
      return updateRoomState(state, room, r => {
        const qualificationProcess = r.qualificationProcess;
        qualificationProcess.history.push(payload.item);

        return { qualificationProcess };
      });

    case ROOM__QUALIFICATION_COMPLETED:
      return updateRoomState(state, room, r => {
        const qualificationProcess = r.qualificationProcess;
        qualificationProcess.completed = true;

        return { qualificationProcess };
      });

    case ROOM__QUALIFICATION_RESET:
      return updateRoomState(state, room, r => {
        const qualificationProcess = r.qualificationProcess;
        qualificationProcess.completed = false;
        qualificationProcess.active = true;
        qualificationProcess.history = [];

        return { qualificationProcess, chatListMessages: [] };
      });

    case ROOM__QUALIFICATION_TEAM_STATUS:
      // Update all rooms that have a qualification process and have this team.
      return state.map(r =>
        Object.assign(
          {},
          r,
          (r => {
            if (r.qualificationProcess && r.qualificationProcess.teams.length) {
              r.qualificationProcess.teams = r.qualificationProcess.teams.map(
                team => {
                  if (team.id === payload.teamId) {
                    team.online = payload.online;
                  }

                  return team;
                }
              );
            }

            return r;
          })(r)
        )
      );

    case ROOM__SHOW_LAM_TIMER:
      return updateRoomState(state, room, () => ({
        showLamTimer: payload.timer
      }));

    case ROOM__CLEAR_LAM_TIMER:
      return updateRoomState(state, room, r => {
        clearTimeout(r.showLamTimer);

        return { showLamTimer: null };
      });

    case SYSTEM__CLEAR:
      return [];

    default:
      return state;
  }
}
