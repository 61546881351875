import styled, { css, keyframes } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import mobileDevice from 'ismobilejs';
//
import {
  getImageDimensions,
  stylesAlignment,
  convertHexToRgba,
  getProp
} from 'utils/helpers';
import { CTA_WIDTH, PADDING } from '../CallToAction/frameWrapper';
import {
  statementsInIntegers,
  FETCH_STATE,
  IS_MOBILE_WIDTH
} from 'config/constants';

const DEFAULT_DELAY = 500;
const ANIMATED_AREA = '320px';
const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;
let _animationInProgress = false;

const _floatingButtonDimensionsRequest = {
  path: null,
  state: null
};

const open = (props = {}) => {
  const { slideOn, speechBubble = {}, floatingButton = {} } = props;
  const isSBEnabled = speechBubble.active === statementsInIntegers.ENABLED;
  if (!slideOn) return;

  let slide;

  if (!(isSBEnabled && floatingButton.afterSB)) {
    switch (slideOn) {
      case 'top': {
        slide = `translateY(-${ANIMATED_AREA})`;
        break;
      }
      case 'right': {
        slide = `translateX(${ANIMATED_AREA})`;
        break;
      }
      case 'bottom': {
        slide = `translateY(${ANIMATED_AREA})`;
        break;
      }
      case 'left': {
        slide = `translateX(-${ANIMATED_AREA})`;
        break;
      }
    }
  }

  return keyframes`
    0% {
      opacity: 0;
      transform: ${slide};
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  `;
};

const close = (props = {}) => {
  const { slideOff, element, speechBubble = {}, operatorOnline } = props;
  const isSBEnabled = speechBubble.active === statementsInIntegers.ENABLED;
  if (!slideOff) return;

  let slide;
  let originalTransform = {
    x: 0,
    y: 0
  };

  if (getProp(element, 'style.transform')) {
    const coords = element.style.transform.match(/[0-9]+/g);
    if ((coords || []).length > 1) {
      originalTransform = {
        x: `${coords[0]}px,`,
        y: `${coords[1]}px,`
      };
    }
  }

  if (!isSBEnabled || !operatorOnline) {
    switch (slideOff) {
      case 'top': {
        slide = `translateY(-${ANIMATED_AREA})`;
        break;
      }
      case 'right': {
        slide = `translateX(${ANIMATED_AREA})`;
        break;
      }
      case 'bottom': {
        slide = `translateY(${ANIMATED_AREA})`;
        break;
      }
      case 'left': {
        slide = `translateX(-${ANIMATED_AREA})`;
        break;
      }
    }
  }

  return keyframes`
    0% {
      opacity: 1;
      transform: translateY(${originalTransform.y}) translateX(${originalTransform.x});
    }
    100% {
      opacity: 0;
      transform: ${slide};
    }
  `;
};

const onEntering = (props = {}) => {
  if (!props._slideOn) return;
  const openProps = {
    slideOn: props._slideOn,
    speechBubble: props.speechBubble,
    floatingButton: props.floatingButton
  };
  _animationInProgress = css`
    animation: ${open(openProps)} ${props._slideSpeed}ms linear;
  `;
  setTimeout(() => (_animationInProgress = false), props._slideSpeed);
  return _animationInProgress;
};

const onEntered = () => {
  if (_animationInProgress) return _animationInProgress;
  return css`
    transform: translateY(0) translateX(0);
  `;
};

const onExiting = (props = {}) => {
  if (!props._slideOff) return;
  const closeProps = {
    slideOff: props._slideOff,
    element: props.element,
    speechBubble: props.speechBubble,
    operatorOnline: props.operatorOnline
  };
  _animationInProgress = css`
    animation: ${close(closeProps)} ${props._slideSpeed}ms linear;
  `;
  setTimeout(() => (_animationInProgress = false), props._slideSpeed);
  return _animationInProgress;
};

const onExited = () => {
  if (_animationInProgress) return _animationInProgress;
  return css`
    transform: translateY(0) translateX(0);
  `;
};

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{"box-shadow": string}}
   */
  getShadow() {
    const { settings = {} } = this;
    const shadow = settings.fb_shadow ? +settings.fb_shadow : 0;
    const color = settings.cb_shadow_color;
    return { 'box-shadow': `0 0 ${shadow}px ${convertHexToRgba(color, 80)}` };
  }

  /**
   *
   * @param props
   * @returns {*}
   * @private
   */
  _getFloatingButton(props = {}) {
    let data;
    let { direction, mode, dimensions } = props;
    const { settings = {}, parentState = {} } = this;
    const useOnlineButton = isPhone
      ? settings.fb_mobile_online_button === 'on'
      : settings.fb_online_button === 'on';
    const useOfflineButton = isPhone
      ? settings.fb_mobile_offline_button === 'on'
      : settings.fb_offline_button === 'on';
    const isHorizontal = direction === 'horizontal';
    const isVertical = direction === 'vertical';
    const keyPrefix = mode === 'offline' && useOfflineButton ? '_offline' : '';
    const floatingButtonPath =
      (() => {
        if (settings[`fb${keyPrefix}_standard_button`] === 'on') {
          return (
            settings[`fb${keyPrefix}_standard_button_modified`] ||
            settings[`fb${keyPrefix}_standard_button_type`]
          );
        } else {
          return settings[`fb${keyPrefix}_custom_button`];
        }
      })() || '';

    if (mode !== 'offline' && !useOnlineButton) return;
    if (mode === 'offline' && !useOfflineButton) return;

    if (/\.svg$/.test(floatingButtonPath)) {
      const defaultSvgWidth = 100;
      const defaultSvgHeight = 100;
      dimensions = {
        width: Number(settings[`fb${keyPrefix}_svg_width`]) || defaultSvgWidth,
        height:
          Number(settings[`fb${keyPrefix}_svg_height`]) || defaultSvgHeight
      };
    } else {
      dimensions = parentState.get().floatingButtonDimensions;
      if (
        _floatingButtonDimensionsRequest.state === FETCH_STATE.LOADING &&
        _floatingButtonDimensionsRequest.path === floatingButtonPath
      )
        return;
      if (
        !dimensions ||
        _floatingButtonDimensionsRequest.path !== floatingButtonPath
      ) {
        _floatingButtonDimensionsRequest.path = floatingButtonPath;
        _floatingButtonDimensionsRequest.state = FETCH_STATE.LOADING;
        getImageDimensions(floatingButtonPath).then(dimensions => {
          _floatingButtonDimensionsRequest.state = FETCH_STATE.SUCCESS;
          parentState.set({ floatingButtonDimensions: dimensions }); // async operation
        });
        return;
      }
    }

    if (isPhone && settings[`fb${keyPrefix}_mobile_size`]) {
      if (isHorizontal) {
        data = {
          width: `${Math.floor(
            (2 * settings[`fb${keyPrefix}_mobile_size`] * dimensions.width) /
              100
          )}px`
        };
      }
      if (isVertical) {
        data = {
          height: `${Math.floor(
            (2 * settings[`fb${keyPrefix}_mobile_size`] * dimensions.height) /
              100
          )}px`
        };
      }
    } else if (settings[`fb${keyPrefix}_size`]) {
      if (isHorizontal) {
        data = {
          width: `${Math.floor(
            (2 * settings[`fb${keyPrefix}_size`] * dimensions.width) / 100
          )}px`
        };
      }
      if (isVertical) {
        data = {
          height: `${Math.floor(
            (2 * settings[`fb${keyPrefix}_size`] * dimensions.height) / 100
          )}px`
        };
      }
    } else {
      if (isHorizontal) {
        data = { width: `${Math.floor(dimensions.width)}px` };
      }
      if (isVertical) {
        data = { height: `${Math.floor(dimensions.height)}px` };
      }
    }

    return data;
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  getWidth(props = {}) {
    let data;
    const defaultData = { width: 0 };
    const { operatorOnline, dimensions } = props;

    if (operatorOnline) {
      data = this._getFloatingButton({
        direction: 'horizontal',
        dimensions
      });
    } else {
      data = this._getFloatingButton({
        direction: 'horizontal',
        mode: 'offline',
        dimensions
      });
    }

    return data || defaultData;
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  getHeight(props = {}) {
    let data;
    const defaultData = { height: 0 };
    const { operatorOnline } = props;

    if (operatorOnline) {
      data = this._getFloatingButton({
        direction: 'vertical'
      });
    } else {
      data = this._getFloatingButton({
        direction: 'vertical',
        mode: 'offline'
      });
    }

    return data || defaultData;
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  getAlignment(props = {}) {
    const { settings = {} } = this;
    const { mode, width = 0, height = 0 } = props;
    const keyPrefix = mode === 'offline' ? '_offline' : '';
    if (
      !(
        (settings[`fb${keyPrefix}_alignment_x`] || settings.cb_alignment_x) &&
        (settings[`fb${keyPrefix}_alignment_y`] || settings.cb_alignment_y)
      )
    )
      return;

    const x = settings[`fb${keyPrefix}_alignment_x`] || settings.cb_alignment_x;
    const y = settings[`fb${keyPrefix}_alignment_y`] || settings.cb_alignment_y;
    let hOffset = 0;
    let vOffset = 0;

    if (isPhone) {
      if (settings[`fb_mobile${keyPrefix}_offset_horizontal`]) {
        hOffset = `${settings[`fb_mobile${keyPrefix}_offset_horizontal`]}px`;
      }
      if (settings[`fb_mobile${keyPrefix}_offset_vertical`]) {
        vOffset = `${settings[`fb_mobile${keyPrefix}_offset_vertical`]}px`;
      }
    } else {
      if (settings[`fb${keyPrefix}_offset_horizontal`]) {
        hOffset = `${settings[`fb${keyPrefix}_offset_horizontal`]}px`;
      }
      if (settings[`fb${keyPrefix}_offset_vertical`]) {
        vOffset = `${settings[`fb${keyPrefix}_offset_vertical`]}px`;
      }
    }

    if (x === 'middle') {
      hOffset = Math.round(((CTA_WIDTH + PADDING) / 2) * -1);
    }

    return stylesAlignment({
      x,
      y,
      hOffset,
      vOffset,
      element: { width, height }
    });
  }

  /**
   *
   * @returns {*}
   */
  getSlideOn() {
    const { settings = {} } = this;
    return settings.fb_slide_on;
  }

  /**
   *
   * @returns {*}
   */
  getOfflineSlideOn() {
    const { settings = {} } = this;
    return settings.fb_offline_slide_on;
  }

  /**
   *
   * @returns {*}
   */
  getSlideOff() {
    const { settings = {} } = this;
    return settings.fb_slide_off;
  }

  /**
   *
   * @returns {*}
   */
  getOfflineSlideOff() {
    const { settings = {} } = this;
    return settings.fb_offline_slide_off;
  }

  /**
   *
   * @returns {*}
   */
  getMoveSpeed() {
    const { settings = {} } = this;
    return +getProp(settings, 'fb_move_speed', '0') || DEFAULT_DELAY;
  }

  /**
   *
   * @returns {*}
   */
  getOfflineMoveSpeed() {
    const { settings = {} } = this;
    return +getProp(settings, 'fb_offline_move_speed', '0') || DEFAULT_DELAY;
  }
}

/**
 *
 * @param props
 * @returns {{} & {transitionState} & {_shadow: {"box-shadow"}, widthBtn: *, heightBtn: *, align: *, slideOn: *, slideOff: *, slideAlign: *, slideSpeed: *}}
 */
const frameWrapperFormatProps = (props = {}) => {
  const {
    settings,
    operatorOnline,
    transitionState,
    speechBubble,
    floatingButton,
    parentState,
    element,
    uiLocked,
    dimensions,
    onDone
  } = props;
  const Styles = new StylesConstructor({ settings, parentState, element });
  const _btnWidth = Styles.getWidth({
    open: speechBubble.open,
    operatorOnline,
    dimensions
  });
  const _btnHeight = Styles.getHeight({
    open: speechBubble.open,
    operatorOnline,
    dimensions
  });
  const _align = Styles.getAlignment({
    mode: !operatorOnline ? 'offline' : '',
    width: _btnWidth.width,
    height: _btnHeight.height
  });
  const dimensionsDone =
    _floatingButtonDimensionsRequest.state !== FETCH_STATE.LOADING;
  const style = {
    ...(uiLocked ? { pointerEvents: 'none' } : {}),
    ...(!dimensionsDone ? { visibility: 'hidden' } : {})
  };
  const internalProps = {
    _btnWidth,
    _btnHeight,
    _align,
    element,
    speechBubble,
    floatingButton,
    operatorOnline,
    _shadow: Styles.getShadow(),
    _slideOn: operatorOnline ? Styles.getSlideOn() : Styles.getOfflineSlideOn(),
    _slideOff: operatorOnline
      ? Styles.getSlideOff()
      : Styles.getOfflineSlideOff(),
    _slideSpeed: operatorOnline
      ? Styles.getMoveSpeed()
      : Styles.getOfflineMoveSpeed(),
    style
  };

  const preparedProps = Object.assign({}, { transitionState }, internalProps);
  if (typeof onDone === 'function') {
    onDone(preparedProps);
  }
  return preparedProps;
};

/**
 *
 */
const FrameWrapper = styled.div`
  position: fixed;
  border-radius: 6px;
  min-width: 20px;
  min-height: 20px;
  z-index: 2147483647 !important;
  
  ${prop('_btnWidth')}
  ${prop('_btnHeight')}
  ${prop('_align')}
  ${prop('_shadow')}
  ${switchProp('transitionState', {
    entered: props => onEntered(props),
    entering: props => onEntering(props),
    exited: props => onExited(props),
    exiting: props => onExiting(props)
  })};
  ${prop('style')}
`;

export { FrameWrapper, frameWrapperFormatProps };
