import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import mobileDevice from 'ismobilejs';
import { getLightOrDarkByHex } from '../../utils/helpers';
import { IS_MOBILE_WIDTH } from 'config/constants';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;
const isTablet = mobileDevice.tablet;

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {string}
   */
  getHeight() {
    const { settings = {} } = this;
    return isPhone || isTablet ? '100%' : `${settings.cb_size_height || 600}px`;
  }

  /**
   *
   * @returns {boolean}
   */
  getIsLight() {
    const { settings = {} } = this;
    const bckColor = settings.cb_background_color;
    return getLightOrDarkByHex(bckColor);
  }
}

const contentWrapperFormatProps = (props = {}) => {
  const { settings } = props;
  const Styles = new StylesConstructor({ settings });

  const internalProps = {
    _height: Styles.getHeight(),
    _isLight: Styles.getIsLight(),
    theme: settings.Theme
  };

  return Object.assign({}, internalProps);
};

const ContentWrapper = styled.div`
  ${ifProp(
    '_isLight',
    'color: rgba(0,0,0,0.7)',
    'color: rgba(255,255,255,0.4)'
  )};
  ${ifProp(
    '_height',
    css`
      height: ${prop('_height')};
    `
  )};
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
`;

export { contentWrapperFormatProps, ContentWrapper };
