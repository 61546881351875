import { getProp } from 'utils/helpers';
import { deactivateContactVia } from 'ducks/contactVia/contactVia.action';
import {
  CB__OPEN,
  CB__CLOSE,
  ROOM__SET_USER_TYPING,
  ROOM__UNSET_USER_TYPING,
  ROOM__SET_OPERATOR_TYPING,
  ROOM__UNSET_OPERATOR_TYPING,
  ROOM__OPERATOR_CLOSED_CHAT,
  ROOM__OPERATOR_ASSIGNED_CHAT,
  ROOM__OPERATOR_UNASSIGNED_CHAT,
  CB__TRANSITION_CHANGE,
  ROOM__SEND_MESSAGE,
  ROOM__LOAD_HISTORY
} from '../ducks/actionConstants';
import { eventTypes, chatTypes, transitionStages } from '../config/constants';

const {
  BOX_OPEN,
  BOX_OPENED,
  BOX_CLOSE,
  BOX_CLOSED,
  VISITOR_TYPING_START,
  VISITOR_TYPING_STOP,
  OPERATOR_TYPING_START,
  OPERATOR_TYPING_STOP,
  CHAT_ASSIGNED,
  CHAT_UNASSIGNED,
  CHAT_CLOSE
} = eventTypes;

/**
 *
 */
export default store => next => action => {
  switch (action.type) {
    case CB__OPEN:
      _CB__OPEN();
      break;
    case CB__CLOSE:
      _CB__CLOSE();
      break;
    case CB__TRANSITION_CHANGE:
      _CB__TRANSITION_CHANGE({ action });
      break;
    case ROOM__SET_USER_TYPING:
      _ROOM__SET_USER_TYPING();
      break;
    case ROOM__UNSET_USER_TYPING:
      _ROOM__UNSET_USER_TYPING();
      break;
    case ROOM__SET_OPERATOR_TYPING:
      _OPERATOR_TYPING_START();
      break;
    case ROOM__UNSET_OPERATOR_TYPING:
      _OPERATOR_TYPING_STOP();
      break;
    case ROOM__OPERATOR_CLOSED_CHAT:
      _ROOM__OPERATOR_CLOSED_CHAT({ action });
      break;
    case ROOM__OPERATOR_ASSIGNED_CHAT:
      _ROOM__OPERATOR_ASSIGNED_CHAT();
      break;
    case ROOM__OPERATOR_UNASSIGNED_CHAT:
      _ROOM__OPERATOR_UNASSIGNED_CHAT();
      break;
    case ROOM__SEND_MESSAGE:
    case ROOM__LOAD_HISTORY:
      disableContactVia({ store, action });
      break;
  }

  return next(action);
};

/**
 * On Chat Box open
 * @returns {*}
 * @private
 */
function _CB__OPEN() {
  eventTrigger(BOX_OPEN);
}

/**
 * On Chat Box close
 * @returns {*}
 * @private
 */
function _CB__CLOSE() {
  eventTrigger(BOX_CLOSE);
}

/**
 * Chat Box transition change of animation
 * @param action
 * @private
 */
function _CB__TRANSITION_CHANGE({ action }) {
  const stage = getProp(action, 'payload.stage');
  if (stage === transitionStages.ENTERED) {
    eventTrigger(BOX_OPENED);
  }
  if (stage === transitionStages.EXITED) {
    eventTrigger(BOX_CLOSED);
  }
}

/**
 * On User starts typing
 * @private
 */
function _ROOM__SET_USER_TYPING() {
  eventTrigger(VISITOR_TYPING_START);
}

/**
 * On User stops typing
 * @private
 */
function _ROOM__UNSET_USER_TYPING() {
  eventTrigger(VISITOR_TYPING_STOP);
}

/**
 * On Operator starts typing
 * @private
 */
function _OPERATOR_TYPING_START() {
  eventTrigger(OPERATOR_TYPING_START);
}

/**
 * On Operator stops typing
 * @private
 */
function _OPERATOR_TYPING_STOP() {
  eventTrigger(OPERATOR_TYPING_STOP);
}

/**
 * On operator closed chat
 * @param action
 * @private
 */
function _ROOM__OPERATOR_CLOSED_CHAT({ action }) {
  const status = getProp(action, 'payload.status');
  const departmentName = getProp(action, 'payload.departmentName');
  let data;

  if (departmentName) {
    data = {
      details: {
        department: departmentName
      }
    };
  }

  if (!status || status === chatTypes.STATUS_SUSPENDED) {
    eventTrigger(CHAT_CLOSE, data);
  }
}

/**
 * Operator is assigned to the chat
 * @private
 */
function _ROOM__OPERATOR_ASSIGNED_CHAT() {
  eventTrigger(CHAT_ASSIGNED);
}

/**
 * Operator is unassigned to the chat
 * @private
 */
function _ROOM__OPERATOR_UNASSIGNED_CHAT() {
  eventTrigger(CHAT_UNASSIGNED);
}

/**
 * Deactivation for the Contact Via component
 * @param store
 * @param action
 */
function disableContactVia({ store, action } = {}) {
  let doDeactivation = false;

  if (
    action.type === ROOM__SEND_MESSAGE &&
    !getProp(action, 'payload.options.isFake', true)
  ) {
    doDeactivation = true;
  }
  if (
    action.type === ROOM__LOAD_HISTORY &&
    getProp(action, 'payload.history', []).length > 0
  ) {
    doDeactivation = true;
  }

  if (doDeactivation) {
    store.dispatch(deactivateContactVia());
  }
}

/**
 * Dispatch global event
 * @param eventName
 * @param data
 * @param target
 */
function eventTrigger(eventName, data, target = document.body) {
  const event = new CustomEvent(eventName, data);
  target.dispatchEvent(event);
}
