import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Transition } from 'react-transition-group';
//
import { frameWrapperFormatProps } from './frameWrapper';
import { WrappedFrame } from './frameWrapperHOC';
import FramedComponent from '../../Common/framedComponent';
import { transitionChangeFb } from 'ducks/floatingButton/floatingButton.action';
import { FloatingButtonComm } from './floatingButton';
import {
  closeSb,
  sendMessageSb,
  setDimensionSb
} from 'ducks/speechBubble/speechBubble.action';
import SETTING from 'helpers/mobile-desktop-settings';

class FloatingButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  parentState() {
    return {
      get: () => this.state,
      set: newState => this.setState(newState)
    };
  }

  attachNode = node => {
    this._frameWrapper = node;
  };

  render() {
    const {
      global = {},
      rooms = [],
      speechBubble = {},
      sbParentState,
      setDimensionSb,
      closeSb
    } = this.props;
    const { open } = this.props.speechBubble;
    const { settings, uiLocked } = global;
    const activeRoomInfo =
      rooms.find(({ room }) => room === global.activeRoom) || {};
    const { operatorOnline } = activeRoomInfo;
    const frameWrapperProps = (anyProps = {}) => {
      return frameWrapperFormatProps({
        settings,
        parentState: this.parentState(),
        sbParentState,
        setDimensionSb,
        operatorOnline,
        speechBubble,
        uiLocked,
        ...anyProps
      });
    };

    const floatingButtonProps = {
      settings,
      operatorOnline,
      fbOpen: speechBubble.open,
      closeSb
    };
    const styles =
      SETTING('fb_online_button', settings) !== 'on'
        ? { visibility: 'hidden', 'z-index': -1 }
        : {};

    return (
      <Transition in={open} timeout={0} appear unmountOnExit>
        {transitionState => (
          <WrappedFrame
            id="sbfbElement"
            ref={this.attachNode}
            styles={styles}
            {...frameWrapperProps({
              transitionState,
              element: this._frameWrapper
            })}
          >
            <FramedComponent title={'VC Speech Bubble Floating Button'}>
              <FloatingButtonComm {...floatingButtonProps} />
            </FramedComponent>
          </WrappedFrame>
        )}
      </Transition>
    );
  }
}

export default connect(
  (
    { global, rooms, floatingButton, speechBubble, callToAction },
    { handleSocketSpeechBubbleOpened, handleSocketSendMessage }
  ) => {
    return {
      global,
      rooms,
      floatingButton,
      speechBubble,
      callToAction,
      handleSocketSpeechBubbleOpened,
      handleSocketSendMessage
    };
  },
  dispatch => {
    return {
      transitionChangeFb: bindActionCreators(transitionChangeFb, dispatch),
      closeSb: bindActionCreators(closeSb, dispatch),
      sendMessageSb: bindActionCreators(sendMessageSb, dispatch),
      setDimensionSb: bindActionCreators(setDimensionSb, dispatch)
    };
  }
)(FloatingButton);
