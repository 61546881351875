import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Transition } from 'react-transition-group';
//
import { FrameWrapper, frameWrapperFormatProps } from './frameWrapper';
import FramedComponent from '../Common/framedComponent';
import {
  closeFb,
  transitionChangeFb
} from '../../ducks/floatingButton/floatingButton.action';
import { FloatingButtonComm } from './floatingButton';
import { UnreadIcon } from './contentWrapper';
import { transitionStages } from '../../config/constants';
import { getProp, useHeap } from 'utils/helpers';
import { fbAdhoc } from 'utils/adhoc';
import SETTING from '../../helpers/mobile-desktop-settings';

class FloatingButton extends PureComponent {
  timer = null;

  constructor(props) {
    super(props);
    this.state = {};
    this._fbAdhoc = fbAdhoc();
    this._heap = useHeap();
  }

  parentState() {
    return {
      get: () => this.state,
      set: newState => this.setState(newState)
    };
  }

  attachNode = node => {
    this._frameWrapper = node;
  };

  transitionEnterHandler = () => {
    this.props.transitionChangeFb({ stage: transitionStages.ENTER });
    clearTimeout(this.timer);
  };

  transitionEnteredHandler = () => {
    this.props.transitionChangeFb({ stage: transitionStages.ENTERED });
  };

  transitionExitHandler = props => {
    this.props.transitionChangeFb({ stage: transitionStages.EXIT });
    if (props.timer) {
      this.timer = setTimeout(() => {
        if (this._frameWrapper) {
          this._frameWrapper.style.display = 'none';
        }
      }, props.timer);
    }
  };

  transitionExitedHandler = () => {
    this.props.transitionChangeFb({ stage: transitionStages.EXITED });
  };

  onClickClose = e => {
    this.props.closeFb(e);
  };

  onChangeWrapperProps = (props = {}) => {
    const fbBtnHeight = getProp(props, '_btnHeight.height');
    const fbBtnWidth = getProp(props, '_btnWidth.width');
    const heap = this._heap.getHeap();

    if (fbBtnHeight !== undefined && fbBtnHeight !== heap.fbBtnHeight) {
      this._heap.setValue('fbBtnHeight', fbBtnHeight);
    }

    if (fbBtnWidth !== undefined && fbBtnWidth !== heap.fbBtnWidth) {
      this._heap.setValue('fbBtnWidth', fbBtnWidth);
    }
  };

  render() {
    const {
      global = {},
      rooms = [],
      floatingButton = {},
      speechBubble = {},
      callToAction
    } = this.props;
    const { settings, uiLocked } = global;
    const activeRoomInfo =
      rooms.find(({ room }) => room === global.activeRoom) || {};
    const unreadMessages = this._fbAdhoc.getUnreadMessagesLength(
      activeRoomInfo
    );
    const { operatorOnline } = activeRoomInfo;
    const DEFAULT_DELAY = 500;
    const timeoutEnterExit = operatorOnline
      ? +getProp(settings, 'fb_move_speed', '0') || DEFAULT_DELAY
      : +getProp(settings, 'fb_offline_move_speed', '0') || DEFAULT_DELAY;
    const frameWrapperProps = (anyProps = {}) => {
      return frameWrapperFormatProps({
        settings,
        uiLocked,
        parentState: this.parentState(),
        operatorOnline,
        floatingButton,
        speechBubble,
        element: this._frameWrapper,
        onDone: this.onChangeWrapperProps,
        ...anyProps
      });
    };
    const floatingButtonProps = {
      settings,
      unreadMessages,
      operatorOnline,
      fbOpen: speechBubble.open,
      closeFb: this.onClickClose
    };
    const fbEnabled =
      SETTING(
        `FB_${operatorOnline ? 'ONLINE' : 'OFFLINE'}_BUTTON`,
        settings
      ) === 'on';
    const isFbCtaClosed = !speechBubble.open && !floatingButton.open;
    const showUnreadMessage =
      fbEnabled &&
      !isFbCtaClosed &&
      !speechBubble.open &&
      !callToAction.open &&
      unreadMessages > 0;

    return (
      <Transition
        in={floatingButton.open && fbEnabled}
        appear
        unmountOnExit
        onEnter={this.transitionEnterHandler}
        onExit={() =>
          this.transitionExitHandler({ timer: timeoutEnterExit - 50 })
        }
        onEntered={this.transitionEnteredHandler}
        onExited={this.transitionExitedHandler}
        timeout={timeoutEnterExit}
      >
        {transitionState => (
          <FrameWrapper
            id="fbElement"
            ref={this.attachNode}
            {...frameWrapperProps({ transitionState })}
          >
            {showUnreadMessage && <UnreadIcon>{unreadMessages}</UnreadIcon>}
            <FramedComponent title={'VC Floating Button'}>
              <FloatingButtonComm {...floatingButtonProps} />
            </FramedComponent>
          </FrameWrapper>
        )}
      </Transition>
    );
  }
}

export default connect(
  ({ global, rooms, floatingButton, speechBubble, callToAction }) => {
    return {
      global,
      rooms,
      floatingButton,
      speechBubble,
      callToAction
    };
  },
  dispatch => {
    return {
      closeFb: bindActionCreators(closeFb, dispatch),
      transitionChangeFb: bindActionCreators(transitionChangeFb, dispatch)
    };
  }
)(FloatingButton);
