import mobileDevice from 'ismobilejs';

import { GLOBAL__INIT_APP } from '../ducks/actionConstants';
import { getImageDimensions } from '../utils/helpers';
import { IS_MOBILE_WIDTH } from 'config/constants';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;

/**
 * Setting global timeout for frame elements.
 */
export default store => next => action => {
  switch (action.type) {
    case GLOBAL__INIT_APP:
      _GLOBAL__INIT_APP({ store, next, action });
      break;
    default:
      return next(action);
  }
};

/**
 * PRELOAD
 * @param store
 * @param next
 * @param action
 * @returns {*}
 * @private
 */
function _GLOBAL__INIT_APP({ store, next, action }) {
  const { payload } = action;
  prepareAndCacheFloatingButtonMetadata(payload);

  return next(action);
}

/**
 * Caching `FloatingButton` image
 * @param settings
 */
function prepareAndCacheFloatingButtonMetadata(settings = {}) {
  const useOfflineButton =
    settings[`fb${isPhone ? '_mobile' : ''}_offline_button`] === 'on';
  const useOnlineButton =
    settings[`fb${isPhone ? '_mobile' : ''}_button`] === 'on';
  const keyPrefixOffline = '_offline';
  const keyPrefixOnline = '_online';
  const floatingButtonPathOffline = prefix => {
    if (settings[`fb${prefix}_standard_button`] === 'on') {
      return (
        settings[`fb${prefix}_standard_button_modified`] ||
        settings[`fb${prefix}_standard_button_type`]
      );
    } else {
      return settings[`fb${prefix}_custom_button`];
    }
  };

  if (useOfflineButton) {
    getImageDimensions(floatingButtonPathOffline(keyPrefixOffline), {
      id: 'floatingButtonOfflineIMG'
    });
  }
  if (useOnlineButton) {
    getImageDimensions(floatingButtonPathOffline(keyPrefixOnline), {
      id: 'floatingButtonOnlineIMG'
    });
  }
}
