import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import mobileDevice from 'ismobilejs';
//
import { CloseIcon } from 'common/svg';
import { calculateSBDimensions } from './helper';
import SETTING from 'helpers/mobile-desktop-settings';
import { CONTACT_VIA_HEIGHT, IS_MOBILE_WIDTH } from 'config/constants';
import { openCb } from '../../ducks/chatBox/chatBox.action';
import store from '../../store';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;
const button_height = 72;

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {*}
   */
  getBorderColor() {
    const { settings = {} } = this;
    const color = (() => {
      if (SETTING('SB2_FB_COLOR', settings) === 'on') {
        return settings.fb_border_color;
      } else {
        return settings.sb2_border_color;
      }
    })();

    if (color) {
      return { border: `1px solid ${color}` };
    } else {
      return null;
    }
  }
}

const contentWrapperFormatProps = (props = {}) => {
  const { settings, koef, useContactVia, hasFormVisibility } = props;
  const Styles = new StylesConstructor({ settings });
  const offset = {
    width: 0,
    height: useContactVia ? CONTACT_VIA_HEIGHT : 0
  };

  if (!hasFormVisibility) {
    offset.height -= 50;
  }

  const internalProps = {
    _borderColor: Styles.getBorderColor(),
    _dimensions: calculateSBDimensions(settings, offset),
    koef
  };

  if (!hasFormVisibility) {
    internalProps.onClick = () => store.dispatch(openCb());
    internalProps.onKeyDown = ({ key }) =>
      key === 'Enter' ? store.dispatch(openCb()) : true;
    internalProps.tabIndex = 0;
  }

  return Object.assign({}, internalProps);
};

const ContentWrapper = styled.div`
  ${ifProp(
    '_borderColor',
    css`
      ${prop('_borderColor')}
    `
  )};
  ${prop('_dimensions')};
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  color: #555860;
  border-radius: 6px;
  z-index: 0;
  line-height: 1.3em;
  box-sizing: border-box;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  overflow: hidden;

  ${ifProp(
    { tabIndex: 0 },
    css`
      cursor: pointer;
    `
  )};

  form {
    width: 100% !important;
    z-index: 8;
    border-radius: 0 0 5px 5px;
    button {
      margin: 0 12px 0 0;
      padding: 0;
      width: auto;
      max-width: 62px;
      height: auto;
      max-height: 42px;
      bottom: 0;
      position: relative;
      text-align: right;
    }
    background-color: #ffffff;
    color: #000000;
    & > div textarea {
      color: #000000;
      font-size: 16px;
      min-height: 40px;
      line-height: 24px;
      :focus {
        color: #000000;
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: rgb(0, 0, 0, 0.5);
      }

      ::-moz-placeholder {
        /* Firefox 19+ */
        color: rgb(0, 0, 0, 0.5);
      }

      :-moz-placeholder {
        /* Firefox 18- */
        color: rgb(0, 0, 0, 0.5);
      }

      :-ms-input-placeholder {
        /* IE 10+ */
        color: rgb(0, 0, 0, 0.5);
      }

      ::placeholder {
        color: rgb(0, 0, 0, 0.5);
      }
    }
  }
`;

const Container = styled.div`
  width: 100%;
  flex: auto 1 1;
  position: relative;
  overflow: hidden;
  line-height: 1.3em;
  ${prop('color')};
  ${prop('background')};
  border-radius: 2px 2px 0 0;
`;

const OperatorContainer = styled.div`
  position: relative;
  display: flex;
  top: ${props => 16 * props.koef}px;
`;

const OperatorAvatar = styled.div`
  background-image: url("${prop('url')}");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  width: ${props => 46 * props.koef}px;
  height: ${props => 46 * props.koef}px;
  margin: 0;
  left: ${props => 16 * props.koef}px;
  border-radius: 3px;
  ${prop('style')}
  
  ${props =>
    props.isOnline
      ? css`
          &::before {
            content: '';
            width: ${props => 10 * props.koef}px;
            height: ${props => 10 * props.koef}px;
            border: 1px solid #fff;
            background-color: #7cf2c1;
            border-radius: 50%;
            position: absolute;
            right: ${props => -5 * props.koef}px;
            top: ${props => -3 * props.koef}px;
          }
        `
      : null};
`;

const OperatorInfo = styled.div`
  position: relative;
  margin: 0;
  display: block;
  text-align: left;
  line-height: 1;
  left: ${props => 26 * props.koef}px;

  p {
    font-weight: 600;
    font-size: ${props => 15 * props.koef}px;
    color: inherit;
    margin-top: ${props => 5 * props.koef}px;
    margin-bottom: ${props => 5 * props.koef}px;
  }

  span {
    opacity: 0.7;
    font-size: ${props => 12 * props.koef}px;
    color: inherit;
  }
`;

const InitMessage = styled.div`
  width: ${props => 180 * props.koef}px;
  font-size: ${props => 20 * props.koef}px;
  position: absolute;
  left: ${props => 16 * props.koef}px;
  top: ${props => 80 * props.koef}px;
  z-index: 2;
  word-wrap: break-word;
  word-break: break-word;
  max-height: ${props => 100 * props.koef}px;
  overflow: hidden;
  line-height: initial;
  white-space: pre-line;
  p {
    font-size: ${props => 16 * props.koef}px;
  }
`;

const CloseBtnWrapper = styled.div`
  width: ${props => 20 * props.koef}px;
  height: ${props => 20 * props.koef}px;
  position: absolute;
  right: ${props => 16 * props.koef}px;
  top: ${props => 10 * props.koef}px;
  z-index: 1;
  cursor: pointer;
  opacity: 1;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`;

const CloseBtn = (props = {}) => {
  const closeIconProps = {
    color: props.color || '#ffffff'
  };

  return (
    <CloseBtnWrapper {...props} tabIndex={0}>
      <CloseIcon {...closeIconProps} />
    </CloseBtnWrapper>
  );
};

const Form = styled.form`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  height: 19%;
  display: none;

  button {
    position: absolute;
    width: ${props => 25 * props.koef}px;
    height: ${props => 22 * props.koef}px;
    right: ${props => 22 * props.koef}px;
    bottom: ${props => 13 * props.koef}px;
    opacity: 0.5;
  }
`;

const Input = styled.input`
  color: #555860;
  font-size: ${props => 15 * props.koef}px;
  height: ${props => 48 * props.koef}px;
  padding-left: ${props => 30 * props.koef}px;
  width: ${props => 250 * props.koef}px;
  border: none;
  box-shadow: none;
  &:focus {
    outline: none;
  }
`;

const SendBtn = styled.button`
  position: absolute;
  width: ${props => 25 * props.koef}px;
  height: ${props => 22 * props.koef}px;
  right: ${props => 22 * props.koef}px;
  bottom: ${props => 13 * props.koef}px;
  cursor: pointer;
  border: none;
  color: inherit;
  opacity: 0.5;
  transition: opacity 200ms ease-in-out;
  outline: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDZweCIgaGVpZ2h0PSI0MHB4Ij48cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiICBmaWxsPSIjNTU1ODYwIiBkPSJNNDYuMDAwLDIwLjAwMCBMLTAuMDAwLC0wLjAwMCBMMTIuMDAwLDIwLjAwMCBMLTAuMDAwLDQwLjAwMCBMNDYuMDAwLDIwLjAwMCBaIi8+PC9zdmc+);
  background-size: cover;

  &:hover {
    opacity: 1;
    outline: none;
  }
`;

const OpenCbBtn = styled.div`
  cursor: pointer;
  width: 100%;
  height: ${props => button_height * props.koef}px;
  border: none;
  border-radius: 0 0 5px 5px;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
  padding: 0 ${props => 10 * props.koef}px;

  div,
  button {
    margin: 0;
  }
`;

const GirlImg = styled.img`
  width: ${props => 180 * props.koef}px;
  position: absolute;
  bottom: ${props =>
    isPhone ? button_height * props.koef : props.girlOffset + 1}px;
  right: ${props => -12 * props.koef}px;
  z-index: 1;
`;

export {
  ContentWrapper,
  contentWrapperFormatProps,
  Container,
  OperatorContainer,
  OperatorAvatar,
  OperatorInfo,
  InitMessage,
  CloseBtn,
  Form,
  Input,
  SendBtn,
  OpenCbBtn,
  GirlImg
};
