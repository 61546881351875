import logger from './logger';
// Nasty facebook SDK gubbins

export default async function fbChatInit(pageId, color) {
  logger.debug('Loading Facebook chat SDK');
  window.fbAsyncInit = function() {
    window.FB.init({
      xfbml: false,
      version: 'v5.0'
    });
    fireFbLoadedEvent();
  };

  document.addEventListener('fbSdkInit', initFbListeners);
  addDomElements(pageId, color);
}

function addDomElements(pageId, color) {
  if (!document.getElementById('fb-root')) {
    logger.debug('Insert Facebook chat DOM elements');
    const fbDiv = document.createElement('div');
    fbDiv.id = 'fb-root';

    const fbScript = document.createElement('script');
    fbScript.id = 'facebook-jssdk';
    fbScript.src =
      'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';

    const fbCustomerChat = document.createElement('div');
    fbCustomerChat.setAttribute('class', ['fb-customerchat']);
    fbCustomerChat.setAttribute('attribution', 'setup_tool');
    fbCustomerChat.setAttribute('page_id', pageId);
    fbCustomerChat.setAttribute('theme_color', color);

    document.getElementsByTagName('body')[0].appendChild(fbDiv);
    document.getElementsByTagName('body')[0].appendChild(fbCustomerChat);
    document.getElementsByTagName('body')[0].appendChild(fbScript);
  } else {
    logger.debug('Found fb_root element - not adding again');
  }
}

function fireFbLoadedEvent() {
  logger.debug('Facebook chat init complete');
  const fbSdkInit = new CustomEvent('fbSdkInit');
  document.dispatchEvent(fbSdkInit);
}

function initFbListeners() {
  logger.debug('Setting up VC listeners');
  window.FB.Event.subscribe('customerchat.show', handleVcChatClose);
  window.FB.Event.subscribe('customerchat.hide', handleFbChatClose);
  // Hide Facebook chat entirely when closed
  window.FB.Event.subscribe(
    'customerchat.show',
    window.FB.CustomerChat.showDialog
  );
  window.FB.Event.subscribe(
    'customerchat.dialogHide',
    window.FB.CustomerChat.hide
  );
}

function handleVcChatClose() {
  logger.debug('Opening Facebook message box');
  window.VisitorChatDestroy();
}

function handleFbChatClose() {
  logger.debug('Opening VC message box');
  window.VisitorChatInit();
}
