import React, { Component } from 'react';
import store from 'store';
import WhatsappForm from './whatsappForm';
import { StatusPanel } from './../sms/smsStyle';
import { MessageSuccessIcon } from 'common/svg';
import { getLightOrDarkByHex, lightenDarkenColor } from 'utils/helpers';

class WhatsappComp extends Component {
  static STATUS_FAILED = -1;
  static STATUS_INITIAL = 0;
  static STATUS_SUCCESS = 1;

  constructor(props) {
    super(props);

    const bckColor = WhatsappComp.getBackgroundColor();
    const bgIsLight = getLightOrDarkByHex(bckColor);

    this.state = {
      msgInputFocus: false,
      bgColor: lightenDarkenColor(bckColor, bgIsLight ? -55 : -35),
      completedWith: WhatsappComp.STATUS_INITIAL
    };
  }

  static getBackgroundColor() {
    const { global } = store.getState();
    const { settings = {} } = global;
    return settings.cb_background_color || '#232323';
  }

  handleMsgInputFocus = () => {
    this.setState({
      msgInputFocus: !this.state.msgInputFocus
    });
  };

  handleSubmit = async ({ values } = {}) => {
    const { message } = values;
    const url = 'https://wa.me/' + this.props.number + '?text=' + message;
    this.setState({ completedWith: WhatsappComp.STATUS_SUCCESS });
    window.open(url, '_blank').focus();
  };

  render() {
    const { global } = store.getState();
    const { settings = {} } = global;
    const { bgColor, completedWith } = this.state;

    if (completedWith === WhatsappComp.STATUS_INITIAL) {
      return (
        <WhatsappForm
          onSubmit={this.handleSubmit}
          onFocus={this.handleMsgInputFocus}
          onBlur={this.handleMsgInputFocus}
          stateFocus={this.state.msgInputFocus}
          placeholder={this.state.msgInputFocus ? null : 'your message'}
          bgColor={bgColor}
          settings={settings}
        />
      );
    }

    if (completedWith === WhatsappComp.STATUS_SUCCESS) {
      return (
        <StatusPanel>
          <MessageSuccessIcon />
          <p className="text">
            Thank you! <br />
            After your first message someone will be in touch shortly.
          </p>
        </StatusPanel>
      );
    }

    return null;
  }
}

export default WhatsappComp;
