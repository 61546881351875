import styled, { css } from 'styled-components';
import { prop, ifProp, switchProp } from 'styled-tools';
import mobileDevice from 'ismobilejs';
import { convertHexToRgba, getImageDimensions } from '../../utils/helpers';
import { IS_MOBILE_WIDTH } from 'config/constants';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;
let _floatingButtonDimensionsRequestInProgress = false;
/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{color: (*|string)}}
   */
  getColor() {
    const { settings = {} } = this;
    return {
      color: settings.sb_text_color || settings.cb_title_text_color || '#444444'
    };
  }

  getBgColor() {
    const { settings = {} } = this;
    let bckColor = settings.cb_background_color;
    let opacity = +settings.cb_background_transparency;

    if (!opacity) opacity = 90;
    if (isPhone) opacity = 100;
    if (!bckColor) bckColor = '#232323';

    const cbBGColor = convertHexToRgba(bckColor, opacity);
    const bgColor = settings.sb_background_color || cbBGColor || '#eff3f6';
    return { 'background-color': bgColor };
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  getFloatingButton(props = {}) {
    let data;
    const { direction, mode } = props;
    const { settings = {}, parentState = {} } = this;
    const useOnlineButton = isPhone
      ? settings.fb_mobile_online_button === 'on'
      : settings.fb_online_button === 'on';
    const useOfflineButton = isPhone
      ? settings.fb_mobile_offline_button === 'on'
      : settings.fb_offline_button === 'on';
    const isHorizontal = direction === 'horizontal';
    const isVertical = direction === 'vertical';
    const keyPrefix = mode === 'offline' && useOfflineButton ? '_offline' : '';
    const floatingButtonPath =
      (() => {
        if (settings[`fb${keyPrefix}_standard_button`] === 'on') {
          return (
            settings[`fb${keyPrefix}_standard_button_modified`] ||
            settings[`fb${keyPrefix}_standard_button_type`]
          );
        } else {
          return settings[`fb${keyPrefix}_custom_button`];
        }
      })() || '';

    if (!useOnlineButton && !useOfflineButton) return;
    if (keyPrefix === '' && !useOnlineButton) return;
    if (keyPrefix === 'offline' && !useOfflineButton) return;

    if (
      floatingButtonPath.length > 0 &&
      floatingButtonPath.substr(-4, 4) === '.svg'
    ) {
      if (isPhone && settings[`fb${keyPrefix}_mobile_size`]) {
        if (isHorizontal) {
          data = {
            width: `${Math.floor(
              (2 *
                settings[`fb${keyPrefix}_mobile_size`] *
                settings[`fb${keyPrefix}_svg_width`]) /
                100
            )}px`
          };
        }
        if (isVertical) {
          data = {
            height: `${Math.floor(
              (2 *
                settings[`fb${keyPrefix}_mobile_size`] *
                settings[`fb${keyPrefix}_svg_height`]) /
                100
            )}px`
          };
        }
      } else if (settings[`fb${keyPrefix}_size`]) {
        if (isHorizontal) {
          data = {
            width: `${Math.floor(
              (2 *
                settings[`fb${keyPrefix}_size`] *
                settings[`fb${keyPrefix}_svg_width`]) /
                100
            )}px`
          };
        }
        if (isVertical) {
          data = {
            height: `${Math.floor(
              (2 *
                settings[`fb${keyPrefix}_size`] *
                settings[`fb${keyPrefix}_svg_height`]) /
                100
            )}px`
          };
        }
      } else {
        if (isHorizontal) {
          data = { width: `${settings[`fb${keyPrefix}_svg_width`]}px` };
        }
        if (isVertical) {
          data = { height: `${settings[`fb${keyPrefix}_svg_height`]}px` };
        }
      }
    } else {
      const dimensions = parentState.get().floatingButtonDimensions;
      if (_floatingButtonDimensionsRequestInProgress) return;
      if (!dimensions) {
        _floatingButtonDimensionsRequestInProgress = true;
        getImageDimensions(floatingButtonPath).then(dimensions => {
          _floatingButtonDimensionsRequestInProgress = false;
          parentState.set({ floatingButtonDimensions: dimensions }); // async operation
        });
        return;
      }

      if (isPhone && settings[`fb${keyPrefix}_mobile_size`]) {
        if (isHorizontal) {
          data = {
            width: `${Math.floor(
              (2 * settings[`fb${keyPrefix}_mobile_size`] * dimensions.width) /
                100
            )}px`
          };
        }
        if (isVertical) {
          data = {
            height: `${Math.floor(
              (2 * settings[`fb${keyPrefix}_mobile_size`] * dimensions.height) /
                100
            )}px`
          };
        }
      } else if (settings[`fb${keyPrefix}_size`]) {
        if (isHorizontal) {
          data = {
            width: `${Math.floor(
              (2 * settings[`fb${keyPrefix}_size`] * dimensions.width) / 100
            )}px`
          };
        }
        if (isVertical) {
          data = {
            height: `${Math.floor(
              (2 * settings[`fb${keyPrefix}_size`] * dimensions.height) / 100
            )}px`
          };
        }
      } else {
        if (isHorizontal) {
          data = { width: `${Math.floor(dimensions.width)}px` };
        }
        if (isVertical) {
          data = { height: `${Math.floor(dimensions.height)}px` };
        }
      }
    }

    return data;
  }

  /**
   *
   * @returns {{"border-color": (*|string|string)}}
   */
  getBorderColor() {
    const { settings = {} } = this;
    let bckColor = settings.cb_background_color;
    let opacity = +settings.cb_background_transparency;

    if (!opacity) opacity = 90;
    if (isPhone) opacity = 100;
    if (!bckColor) bckColor = '#232323';

    const cbBGColor = convertHexToRgba(bckColor, opacity);
    return {
      'border-color':
        settings.sb_border_color ||
        settings.sb_background_color ||
        cbBGColor ||
        '#444444'
    };
  }

  /**
   *
   * @returns {*}
   */
  getPosition() {
    const { settings = {}, operatorOnline } = this;
    const keyPrefix = !operatorOnline ? '_offline' : '';
    return settings[`fb${keyPrefix}_alignment_x`];
  }

  getCloseButtonColor() {
    const { settings = {} } = this;
    const closeButtonColor = settings.cta_close_button_color || '#000000';
    return { color: closeButtonColor };
  }
}

/**
 *
 * @param props
 * @returns {{} & {_color: {color: (*|string)}, _bgColor: {"background-color"}, _height: *, _borderColor: {"border-color": (*|string)}, _position: *}}
 */
export const ctaWrapperFormatProps = (props = {}) => {
  const { settings, operatorOnline, parentState } = props;
  const Styles = new StylesConstructor({
    settings,
    parentState,
    operatorOnline
  });

  const internalProps = {
    _color: Styles.getColor(),
    _bgColor: Styles.getBgColor(),
    _borderColor: Styles.getBorderColor(),
    _position: Styles.getPosition()
  };

  return Object.assign({}, internalProps);
};

/**
 *
 * @param props
 * @returns {{} & {_position: *}}
 */
export const contentWrapperFormatProps = (props = {}) => {
  const { settings, operatorOnline } = props;
  const Styles = new StylesConstructor({ settings, operatorOnline });

  const internalProps = {
    _position: Styles.getPosition()
  };

  return Object.assign({}, internalProps);
};

/**
 *
 * @param props
 * @returns {{} & {_position: *}}
 */
export const closeBtnFormatProps = (props = {}) => {
  const { settings, operatorOnline } = props;
  const Styles = new StylesConstructor({ settings, operatorOnline });

  const internalProps = {
    _active: settings.sb_close_button_enabled === 'on',
    _position: Styles.getPosition(),
    _color: Styles.getCloseButtonColor()
  };

  return Object.assign({}, internalProps);
};

/**
 *
 * @param props
 * @returns {{ _src: *}}
 */
export const imgFormatProps = (props = {}) => {
  const { settings } = props;
  const Styles = new StylesConstructor({ settings });
  return {
    _src: settings.sb_image,
    _border: settings.sb_image
      ? {
          ...Styles.getBorderColor(),
          'border-width': '1px',
          'border-style': 'solid'
        }
      : {}
  };
};

export const CtaWrapper = styled.div`
  ${prop('_color')};
  ${prop('_bgColor')};
  ${switchProp('_position', {
    left: css`
      border-right: 3px solid;
      padding-left: 5px;
    `,
    right: css`
      border-left: 3px solid;
      padding-right: 5px;
    `,
    middle: css`
      border-left: 3px solid;
      padding-right: 5px;
    `
  })};
  ${prop('_borderColor')};

  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
`;

export const ContentWrapper = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
`;

export const TextWrapper = styled.div`
  width: calc(100% - 80px);
  margin: 5px;
`;

export const TextTitle = styled.p`
  letter-spacing: -0.1px;
  font-size: 13px;
  font-family: Arial, serif;
`;

export const TextContent = styled.p`
  font-size: 11px;
  margin-top: 5px;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
`;

export const Img = styled.div`
  ${ifProp(
    '_src',
    css`
      background-image: url(${prop('_src')});
    `
  )};
  ${prop('_border')};
  width: 48px;
  height: 48px;
  margin: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 4px;
`;

export const CloseBtn = styled.div`
  ${ifProp(
    '_active',
    css`
      display: block;
    `,
    css`
      display: none;
    `
  )};
  ${prop('_color')};
  right: 8px;
  cursor: pointer;
  position: absolute;
  font-size: 22px;
  width: 12px;
  height: 12px;
  opacity: 0.3;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;
