import React, { Fragment } from 'react';
import SVG from 'react-inlinesvg';

import { getRooms } from './getVCSettings';
import { getItem } from './localStorage';
import store from '../store';
import logger from '../utils/logger';

// TODO: [VC-200] Sort this whole file out into something more manageable
const _state = {
  promises: {}
};

/*
 *
 */
const parseUrl = function(url) {
  const a = document.createElement('a');
  const urlBeforeSearch = url.substring(
    0,
    url.indexOf('?') > 0 ? url.indexOf('?') : url.length
  );
  a.url = url;

  return {
    origin:
      a.origin ||
      a.protocol +
        '//' +
        a.hostname +
        (a.port && /:[0-9]+/.test(urlBeforeSearch) ? `:${a.port}` : ''),
    protocol: a.protocol,
    host: /:[0-9]+/.test(urlBeforeSearch) ? a.host : a.hostname,
    hostname: a.hostname,
    port: a.port,
    pathname: a.pathname,
    hash: a.hash,
    search: a.search
  };
};

/**
 * Convert hex for RGBA
 * @param hexString
 * @param opacity
 * @return {string}
 */
const convertHexToRgba = function(hexString, opacity) {
  let result = 'rgba(0,0,0,1)';
  if (hexString) {
    const hex = hexString.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    result =
      typeof opacity === 'number'
        ? `rgba(${r},${g},${b},${opacity / 100})`
        : `rgb(${r},${g},${b})`;
  }

  return result;
};

/**
 * Convert gradient to string
 * @param colors
 * @param direction
 */
const gradientToString = function(colors, direction) {
  // If array, needs to be cast to an object
  if (colors[0].color === undefined) {
    const tmp = [];
    colors.forEach(color => {
      tmp.push({ color });
    });
    colors = tmp;
  }

  // build the string
  let gradientString = `linear-gradient(to ${direction}`;

  colors.forEach(color => {
    const substring = color.percent
      ? `, ${color.color} ${color.percent}%`
      : `, ${color.color}`;
    gradientString += substring;
  });

  gradientString += ')';
  return gradientString;
};

/**
 *
 * @param color
 * @param amount
 * @return {string}
 */
const lightenDarkenColor = function(color, amount) {
  var usePound = false;
  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  var num = parseInt(color, 16);
  var r = (num >> 16) + amount;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amount;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amount;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  var result = (g | (b << 8) | (r << 16)).toString(16);
  return (usePound ? '#' : '') + ('000000' + result).slice(-6);
};

/**
 *
 * @param hShadow
 * @param vShadow
 * @param blur
 * @param spread
 * @return {{top: number, right: number, bottom: number, left: number}}
 */
const getBoxShadowOffset = function({
  hShadow = 0,
  vShadow = 0,
  blur = 0,
  spread = 0
}) {
  const top = +(spread - vShadow + 0.6 * blur).toFixed(2);
  const right = +(spread + hShadow + 0.6 * blur).toFixed(2);
  const bottom = +(spread + vShadow + 0.6 * blur).toFixed(2);
  const left = +(spread - hShadow + 0.6 * blur).toFixed(2);

  return { top, right, bottom, left };
};

/**
 *
 * @param hexString
 * @return {boolean}
 */
const getLightOrDarkByHex = function(hexString = '') {
  const hex = hexString.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return r * 0.299 + g * 0.587 + b * 0.114 > 186; // true - light; false - dark
};

/*
 *
 */
const isWindowEmbedded = function() {
  if (window.top !== window.window) return true;
  const r = new RegExp('electron', 'i');
  return r.test(navigator.userAgent.toLowerCase());
};

/**
 * @params params.url, params.method, params.success, params.data
 * @returns {doAjax}
 */
var ajax = function(params) {
  // Common function to initialize XML Http Request object
  function getHttpRequestObject() {
    // Define and initialize as false
    var xmlHttpRequst = false;

    // Mozilla/Safari/Non-IE
    if (window.XMLHttpRequest) {
      xmlHttpRequst = new XMLHttpRequest();
    }
    // IE
    else if (window.ActiveXObject) {
      // eslint-disable-next-line no-undef
      xmlHttpRequst = new ActiveXObject('Microsoft.XMLHTTP');
    }
    return xmlHttpRequst;
  }

  function serialize(obj) {
    var str = [];
    for (var p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }

  function doAjax(options) {
    // Set the variables
    var _url = options.url || '';
    var _method = options.method || 'GET';
    var _async = Object.prototype.hasOwnProperty.call(options, 'async')
      ? !!options.async
      : true;
    var _successHandler = options.success || function() {};
    var _errorHandler = options.error || function() {};
    var _data = options.data || null;
    var _formatJson = Object.prototype.hasOwnProperty.call(
      options,
      'formatJson'
    )
      ? !!options.formatJson
      : false;
    var _timeout = options.timeout || 5000;

    var xmlHttpRequst = getHttpRequestObject();

    // If AJAX supported
    if (xmlHttpRequst !== false) {
      var TO = setTimeout(function() {
        xmlHttpRequst.abort();
        _errorHandler(xmlHttpRequst, 'Request timeout');
      }, _timeout);

      var url = _url;
      var data = _data;
      if (_method === 'GET') {
        if (data) {
          url += '?' + serialize(data);
        }
        data = null;
      }
      xmlHttpRequst.open(_method, url, _async);
      if (_method === 'POST') {
        xmlHttpRequst.setRequestHeader('Content-Type', 'application/json');
      }
      xmlHttpRequst.onreadystatechange = e => {
        if (xmlHttpRequst.readyState === 4) {
          clearTimeout(TO);

          var responseRawData = (e.target || e.currentTarget).response || '';
          var response;
          if (_formatJson) {
            try {
              response = responseRawData ? JSON.parse(responseRawData) : {};
            } catch (e) {
              _errorHandler(xmlHttpRequst, 'Invalid request format');
              return;
            }
          } else {
            response = responseRawData;
          }
          if (xmlHttpRequst.status === 200) {
            _successHandler(response, xmlHttpRequst);
          } else {
            _errorHandler(xmlHttpRequst, xmlHttpRequst.statusText);
          }
        }
      };
      xmlHttpRequst.send(data);
    } else {
      _errorHandler(xmlHttpRequst, 'Browser don`t support Ajax');
    }
  }

  return doAjax(params);
};

/**
 * Check is empty value
 * @param val
 * @return {boolean}
 */
function isEmpty(val) {
  return val === undefined || val == null || val.length <= 0;
}

const getCurrentTime = () => {
  const utcDate = new Date();

  const singleDate = date => {
    const stringDate = date.toString();
    return stringDate.length === 1 ? '0' + date : date;
  };

  const h = utcDate.getHours();
  const m = utcDate.getMinutes();
  const s = utcDate.getSeconds();

  return `${singleDate(h)}:${singleDate(m)}:${singleDate(s)}`;
};

/**
 *
 * @param props
 */
const stylesAlignment = (props = {}) => {
  const { x, y, hOffset = '0', vOffset = '0', element = {} } = props;
  const { width = '100%', height = '100%' } = element;
  const widthInt =
    typeof width === 'string' ? +width.replace(/[^0-9]/g, '') : width;
  const heightInt =
    typeof height === 'string' ? +height.replace(/[^0-9]/g, '') : height;
  const hOffsetInt =
    typeof hOffset === 'string' ? +hOffset.replace(/[^0-9]/g, '') : hOffset;
  let styles = {};
  if (x === 'left' && y === 'top') {
    styles = { left: hOffset, right: 'auto', top: vOffset, bottom: 'auto' };
  } else if (x === 'left' && y === 'center') {
    if (width === '100%') {
      styles = { left: hOffset, right: 'auto', top: vOffset, bottom: vOffset };
    } else {
      const vOffsetInt = +vOffset.replace(/[^0-9]/g, '');
      const top =
        window.innerHeight > heightInt + vOffsetInt
          ? `calc(50% - ${heightInt / 2}px)`
          : vOffset;
      styles = { left: hOffset, right: 'auto', top, bottom: vOffset };
    }
  } else if (x === 'left' && y === 'bottom') {
    styles = { left: hOffset, right: 'auto', top: 'auto', bottom: vOffset };
  } else if (x === 'middle' && y === 'top') {
    if (width === '100%') {
      styles = {
        left: hOffset,
        right: hOffset,
        top: vOffset,
        bottom: 'auto',
        'text-align': 'center'
      };
    } else {
      styles = {
        left: `calc(50% - ${widthInt / 2 + hOffsetInt}px)`,
        top: vOffset,
        bottom: 'auto',
        'text-align': 'center'
      };
    }
  } else if (x === 'middle' && y === 'center') {
    if (width === '100%') {
      styles = {
        left: hOffset,
        right: hOffset,
        top: vOffset,
        bottom: vOffset,
        'text-align': 'center'
      };
    } else {
      const vOffsetInt = +vOffset.replace(/[^0-9]/g, '');
      const top =
        window.innerHeight > heightInt + vOffsetInt
          ? `calc(50% - ${heightInt / 2}px)`
          : vOffset;
      styles = {
        left: `calc(50% - ${widthInt / 2 + hOffsetInt}px)`,
        top,
        bottom: vOffset,
        'text-align': 'center'
      };
    }
  } else if (x === 'middle' && y === 'bottom') {
    if (width === '100%') {
      styles = {
        left: hOffset,
        right: hOffset,
        top: 'auto',
        bottom: vOffset,
        'text-align': 'center'
      };
    } else {
      styles = {
        left: `calc(50% - ${widthInt / 2 + hOffsetInt}px)`,
        top: 'auto',
        bottom: vOffset,
        'text-align': 'center'
      };
    }
  } else if (x === 'right' && y === 'top') {
    styles = { left: 'auto', right: hOffset, top: vOffset, bottom: 'auto' };
  } else if (x === 'right' && y === 'center') {
    if (width === '100%') {
      styles = { left: 'auto', right: hOffset, top: vOffset, bottom: vOffset };
    } else {
      const vOffsetInt = +vOffset.replace(/[^0-9]/g, '');
      const top =
        window.innerHeight > heightInt + vOffsetInt
          ? `calc(50% - ${heightInt / 2}px)`
          : vOffset;
      styles = { left: 'auto', right: hOffset, top, bottom: vOffset };
    }
  } else if (x === 'right' && y === 'bottom') {
    styles = { left: 'auto', right: hOffset, top: 'auto', bottom: vOffset };
  }
  return styles;
};

/**
 *
 * @param url
 * @returns {Promise<any>}
 */
const getImageDimensions = url => {
  if (url && _state.promises[url]) {
    return _state.promises[url];
  }
  _state.promises[url] = new Promise(resolve => {
    const img = new Image();
    img.onload = function() {
      resolve({
        width: this.width,
        height: this.height
      });
    };
    img.src = url;
  });
  return _state.promises[url];
};

/**
 *
 * @param string
 * @returns {*}
 */
const nl2br = string => {
  if (typeof string !== 'string') return string;
  if (string.length === 0) return string;
  return string.split(/\\n/g).reduce((accumulator, textChunk, index, list) => {
    accumulator.push(
      <Fragment key={index}>
        {textChunk}
        {list.length > 1 && <br />}
      </Fragment>
    );
    return accumulator;
  }, []);
};

/**
 *
 * @param src
 * @param defaultImg
 * @param settings
 * @returns {*}
 */
const getImgOrSvg = (src, defaultImg, settings, processSVG) => {
  const koef = (settings && settings.sb2_coef) || 1;
  let img = defaultImg;

  if (!src) return img;
  if (/\.svg$/.test(src)) {
    img = <SVG koef={koef} src={src} processSVG={processSVG} />;
  } else {
    img = <img koef={koef} src={src} />;
  }

  return img;
};

/**
 * Console Debugger (`localStorage.setItem('vc_debugger', 'on');` or change "window.vc_settings")
 * localStorage - ON
 * localStorage.setItem('vc_debugger', 'on');
 * localStorage - OFF
 * localStorage.setItem('vc_debugger', 'off');
 * window.vc_settings - ON
 * window.vc_settings.vc_debugger = 'on'
 * window.vc_settings - OFF
 * window.vc_settings.vc_debugger = 'off'
 * @returns {*}
 */
const useDebugger = () => {
  const vcSettings = getRooms();
  if (getItem('vc_debugger') === 'on') return true;
  return (Array.isArray(vcSettings) ? vcSettings : [vcSettings]).reduce(
    (accumulator, currentSettings) => {
      if (accumulator) return accumulator;
      if (!currentSettings) return false;
      return currentSettings.vc_debugger === 'on';
    },
    false
  );
};

/**
 * Browser check for Internet Explorer
 * @returns {boolean}
 */
const browserIsIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  return msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./);
};

/**
 * Generate random string
 * @param length
 * @returns {string}
 */
const getRandomString = (length = 5) => {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let str = '';

  for (let i = 0; i < length; i++) {
    str += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return str;
};

/**
 * Implementation of lodash.get function
 * https://gist.github.com/harish2704/d0ee530e6ee75bad6fd30c98e5ad9dab
 * @param object
 * @param keys
 * @param defaultVal
 * @returns {*}
 */
function getProp(object = {}, keys, defaultVal) {
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1));
  }
  return object === undefined ? defaultVal : object;
}

/**
 * Parses a string argument and returns an integer
 * @param string
 * @param base
 * @returns {number}
 */
function propParseInt(string, base) {
  const parsed = parseInt(string, base);
  if (isNaN(parsed)) {
    return 0;
  }
  return parsed;
}

/**
 * Parse event source
 * @param {object} event - event data
 */
function eventParser(event) {
  if (getProp(event, 'data') && typeof event.data === 'string') {
    try {
      const packet =
        typeof event.data === 'object' ? JSON.parse(event.data) : event.data;
      if (packet.cmd === 'getUrl') {
        event.source.postMessage(
          JSON.stringify({
            cmd: 'setUrl',
            data: { url: window.location.href }
          }),
          '*'
        );
      }
    } catch (e) {
      logger.error(`Error occurred: ${e.toString()}`);
    }
  }
}

/**
 * Some kind of cash storage
 * @param initial
 * @returns {{setValue: setValue, getHeap: (function(): any)}}
 */
const heap = Object.create(null);
function useHeap() {
  function setValue(key, value) {
    Object.defineProperty(heap, key, {
      value: value,
      configurable: true,
      enumerable: true,
      writable: true
    });
  }

  function resetHeap() {
    Object.keys(heap).forEach(key => delete heap[key]);
  }

  function getHeap() {
    return heap;
  }

  return {
    setValue,
    getHeap,
    resetHeap
  };
}

/**
 * Get the active room name
 * @returns {string}
 */
function getRoomNameFromLocation() {
  const state = store.getState();
  const { global } = state;
  const { meta, chosenWebSettingsIndex, webSettings } = global;
  const chosenLocalSettings = webSettings[chosenWebSettingsIndex];
  return (
    getProp(meta, 'domain', null) ||
    getProp(chosenLocalSettings, 'domainId', null)
  );
}

/**
 * Get the room name without special symbols
 * @param room
 * @returns {*}
 */
function getClearRoomName(room) {
  if (typeof room !== 'string') {
    return undefined;
  }
  return room.replace(/\W/g, '');
}

export {
  nl2br,
  parseUrl,
  isWindowEmbedded,
  gradientToString,
  convertHexToRgba,
  getLightOrDarkByHex,
  getBoxShadowOffset,
  lightenDarkenColor,
  ajax,
  isEmpty,
  getCurrentTime,
  stylesAlignment,
  getImageDimensions,
  getImgOrSvg,
  useDebugger,
  browserIsIE,
  getRandomString,
  getProp,
  propParseInt,
  useHeap,
  eventParser,
  getRoomNameFromLocation,
  getClearRoomName
};
