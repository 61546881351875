import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const InputWrapper = styled.div`
  width: 100%;
  ${prop('style')}

  input {
    ${ifProp(
      'hasError',
      css`
        box-shadow: 0 0 3px 3px rgba(255, 0, 0, 0.4);
      `
    )};
    background-color: ${prop('bgColor')};
    overflow: auto;
    box-sizing: border-box;
    padding-left: 12px;
    border: none;
    outline: none;
    resize: none;
    border-radius: 5px;
    transition: background-color 300ms;
    height: 34px;
    font-size: 16px;
    font-style: normal;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: white;
    width: 100%;

    :focus {
      color: black;
      background-color: white;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: #cccccc;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: #cccccc;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: #cccccc;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: #cccccc;
    }

    ::placeholder {
      color: #cccccc;
    }

    &:focus::-webkit-input-placeholder {
      color: transparent !important;
    }

    &:focus::-moz-placeholder {
      color: transparent !important;
    }

    &:focus:-moz-placeholder {
      color: transparent !important;
    }
  }
`;

const ChatBoxField = ({
  placeholder,
  wrapper,
  autoFocus = false,
  input,
  bgColor,
  error,
  customInput,
  required
}) => {
  return (
    <InputWrapper {...wrapper} hasError={!!error} bgColor={bgColor}>
      {customInput || (
        <input
          {...input}
          type="text"
          placeholder={placeholder}
          autoFocus={autoFocus}
          required={required}
        />
      )}
    </InputWrapper>
  );
};

ChatBoxField.propTypes = {
  autoFocus: PropTypes.bool,
  bgColor: PropTypes.string,
  customInput: PropTypes.object,
  error: PropTypes.bool,
  input: PropTypes.object,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  wrapper: PropTypes.object
};

export { ChatBoxField };
