import { getProp, getClearRoomName } from 'utils/helpers';
import {
  GLOBAL__SET_WEB_SETTINGS_INDEX,
  GLOBAL__CONNECT,
  GLOBAL__DISCONNECT,
  GLOBAL__INIT_APP,
  GLOBAL__SET_ACTIVE_ROOM,
  GLOBAL__SET_USER_ID,
  GLOBAL__UI_LOCK,
  GLOBAL__UI_UNLOCK,
  GLOBAL__UI_HIDE,
  GLOBAL__UI_SHOW,
  ROOM__SET_OPERATOR_INFO,
  GLOBAL__STORE_CHAT_ID,
  SYSTEM__CLEAR
} from '../actionConstants';

const updateState = function(state = {}, newState = {}) {
  return Object.assign({}, state, newState);
};

const init = {
  init: false,
  settings: {},
  webSettings: [],
  meta: {},
  chosenWebSettingsIndex: null,
  connection: false,
  activeRoom: null,
  userId: null,
  storedChatIds: {},
  wasStored: false,
  uiLocked: false,
  uiHidden: false,
  operators: {}
};

export default function(state = init, { type, payload }) {
  switch (type) {
    case GLOBAL__SET_WEB_SETTINGS_INDEX:
      return updateState(state, {
        chosenWebSettingsIndex: payload.index
      });

    case GLOBAL__INIT_APP:
      return updateState(state, {
        init: true,
        settings: payload.settings,
        meta: payload.meta,
        webSettings: payload.webSettings
      });

    case GLOBAL__CONNECT:
      return updateState(state, { connection: true });

    case GLOBAL__DISCONNECT:
      return updateState(state, { connection: false });

    case GLOBAL__SET_ACTIVE_ROOM:
      return updateState(state, {
        activeRoom: payload.activeRoom
      });

    case GLOBAL__UI_LOCK:
      return updateState(state, { uiLocked: true });

    case GLOBAL__UI_UNLOCK:
      return updateState(state, { uiLocked: false });

    case GLOBAL__SET_USER_ID:
      return updateState(state, payload);

    case GLOBAL__STORE_CHAT_ID: {
      let storedChatIds = { ...state.storedChatIds };

      if (payload.room) {
        const formattedRoomKey = getClearRoomName(payload.room);

        if (!payload.chatId) {
          delete storedChatIds[formattedRoomKey];
        } else {
          storedChatIds = {
            ...storedChatIds,
            ...{ [formattedRoomKey]: payload.chatId }
          };
        }
      }

      return updateState(state, { storedChatIds });
    }

    case ROOM__SET_OPERATOR_INFO:
      if (!getProp(payload, 'operatorInfo.operatorId')) {
        return state;
      }

      return updateState(state, {
        operators: {
          ...state.operators,
          [`${getProp(payload, 'operatorInfo.operatorId')}`]: {
            name: getProp(payload, 'operatorInfo.name'),
            avatar: getProp(payload, 'operatorInfo.avatar')
          }
        }
      });

    case GLOBAL__UI_HIDE:
      return updateState(state, { uiHidden: true });

    case GLOBAL__UI_SHOW:
      return updateState(state, { uiHidden: false });

    case SYSTEM__CLEAR:
      return updateState(state, init);

    default:
      return state;
  }
}
