import React from 'react';
import mobileDevice from 'ismobilejs';
//
import { CarTemplate } from './templates/carTemplate';
import { GirlTemplate } from './templates/girlTemplate';
import { CustomTemplate } from './templates/customTemplate';
import { SB_WIDTH, SB_HEIGHT } from './frameWrapper';
import SETTING from '../../helpers/mobile-desktop-settings';
import { IS_MOBILE_WIDTH } from 'config/constants';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;

export function getContainerSettings(settings) {
  const isUseCBColor = settings[`sb2${isPhone ? '_mobile' : ''}_cb_color`];
  const bgColorFrom = isUseCBColor
    ? settings.cb_color_from
    : SETTING('SB2_CB_COLOR_FROM', settings) || '#5870f2';
  const bgColorTo = isUseCBColor
    ? settings.cb_color_to
    : SETTING('SB2_CB_COLOR_TO', settings) || '#81a7f8';
  const sb2FontColor = SETTING('SB2_FONT_COLOR', settings);

  // replace 2 lines with backend settings
  const isImageInSettings = false;
  const imageUrl =
    'https://images.pexels.com/photos/371589/pexels-photo-371589.jpeg';

  const background = isImageInSettings
    ? `background-image: url('${imageUrl}')`
    : `background: linear-gradient(${bgColorFrom}, ${bgColorTo})`;

  return {
    koef: settings.sb2_coef || 1,
    color: { color: sb2FontColor || '#ffffff' },
    background
  };
}

export function getTemplate(theme, settings) {
  let component = null;

  switch (theme) {
    case 'car':
      component = <CarTemplate koef={settings.sb2_coef} />;
      break;
    case 'girl':
      component = <GirlTemplate koef={settings.sb2_coef} />;
      break;
    case 'custom':
      component = (
        <CustomTemplate
          url={SETTING('SB2_IMAGE_RIGHT', settings)}
          koef={settings.sb2_coef}
        />
      );
      break;
  }
  return component;
}

/**
 *
 * @param settings
 * @param offset
 * @returns {{width: string, height: string}}
 */
export function calculateSBDimensions(
  settings,
  offset = { height: 0, width: 0 }
) {
  const k = settings.sb2_coef;
  return {
    width: `${(SB_WIDTH + offset.width) * k}px`,
    height: `${(SB_HEIGHT + offset.height) * k}px`
  };
}

// 50 = 100%
export function calculateKoef(size = 50) {
  const normSize = size > 100 ? 100 : size < 30 ? 30 : size;
  return normSize / 50;
}
