import app from './ducks/global/app.reducer';
import rooms from './ducks/global/rooms.reducer';
import speechBubble from './ducks/speechBubble/speechBubble.reducer';
import floatingButton from './ducks/floatingButton/floatingButton.reducer';
import callToAction from './ducks/callToAction/callToAction.reducer';
import chatBox from './ducks/chatBox/chatBox.reducer';
import roomsSelector from './ducks/roomsSelector/roomsSelector.reducer';
import contactVia from './ducks/contactVia/contactVia.reducer';

import { combineReducers } from 'redux';

export default combineReducers({
  global: app,
  rooms,
  speechBubble,
  floatingButton,
  callToAction,
  chatBox,
  roomsSelector,
  contactVia
});
