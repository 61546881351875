import {
  CB__OPEN,
  CB__INIT,
  CB__CLOSE,
  CB__ACTIVATE,
  CB__DEACTIVATE,
  CB__TRANSITION_CHANGE,
  CB__CLEAR,
  MB__OPEN,
  // SB__SEND_MESSAGE,
  SYSTEM__CLEAR,
  CONTACT_VIA__SET_SHOW_MODE,
  CB_SET_IS_SHOWING_LAM
} from '../actionConstants';
import { CONTACT_VIA_SHOW_MODES } from '../../config/constants';

const updateState = function(state = {}, newState = {}) {
  return Object.assign({}, state, newState);
};

const init = {
  active: false,
  open: false,
  onceOpened: false,
  mbOnceOpened: false,
  onceOpenedInSession: false,
  stage: null,
  isShowingLam: false
};

export default function(state = init, action) {
  switch (action.type) {
    case CB__INIT:
      return updateState(state, {
        open: action.payload.open
      });

    case CB__ACTIVATE:
      return updateState(state, {
        active: true
      });

    case CB__DEACTIVATE:
      return updateState(state, {
        active: false
      });

    case CB__OPEN:
      if (state.active) {
        return updateState(state, {
          open: true,
          onceOpenedInSession: true,
          onceOpened: true
        });
      }
      return state;

    case CB__CLOSE:
      return updateState(state, {
        open: false
      });

    case CB__TRANSITION_CHANGE:
      return updateState(state, {
        stage: action.payload.stage
      });

    // case SB__SEND_MESSAGE:
    //   return updateState(state, {
    //     active: true,
    //     open: true
    //   });

    case MB__OPEN:
      return updateState(state, {
        mbOnceOpened: true
      });

    case CB__CLEAR:
      return updateState(state, init);

    case CONTACT_VIA__SET_SHOW_MODE:
      if (action.payload.showMode === CONTACT_VIA_SHOW_MODES.INITIAL) {
        return state;
      }
      if (!state.active || state.open) {
        return state;
      }
      return updateState(state, {
        open: true
      });

    case CB_SET_IS_SHOWING_LAM:
      return updateState(state, {
        isShowingLam: action.payload.active,
        canGoBack: action.payload.canGoBack
      });

    case SYSTEM__CLEAR:
      return updateState(state, init);

    default:
      return state;
  }
}
