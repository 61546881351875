import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
//
import { Form, SendMessageWrap, FieldWrapper } from './leaveMessageStyle.js';
import { LmSendMsgField } from '../common/TextareaField';
import SendBtn from '../common/SendBtn';
import { ChatBoxField } from '../common/ChatBoxField';
import { getProp } from 'utils/helpers';
import { getMediator } from 'services/mediator';
import { phoneMask, emailMask, emailRegex } from 'lib/validation';

export const COMPONENT_NAME = 'chatBox:leaveMessageForm';
const INITIAL_STATE = {
  nameFocused: false,
  phoneFocused: false,
  emailFocused: false,
  name: '',
  email: '',
  phone: '',
  message: '',
  nameError: false,
  emailError: false,
  phoneError: false,
  messageError: false
};

class LeaveMessageForm extends Component {
  constructor(props) {
    super(props);
    this._componentName = props.componentName || COMPONENT_NAME;
    this.state = Object.assign({}, INITIAL_STATE);
    this._hasFocus = false;
  }

  componentDidMount() {
    getMediator().attachComponent(this._componentName, this);
  }

  componentWillUnmount() {
    getMediator().detachComponent(this._componentName);
  }

  /**
   * Incoming events handler
   * @param props
   */
  subscriber = (props = {}) => {
    const { action = {} } = props;

    if (action.type === 'reset') {
      this.reset();
    }
  };

  handleKeyDown = (e, cb) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  };

  handleFocus = type => this.setState(state => ({ [type]: !state[type] }));
  handleNameFocus = () => this.handleFocus('nameFocused');
  handlePhoneFocus = () => this.handleFocus('phoneFocused');
  handleEmailFocus = () => this.handleFocus('emailFocused');

  focusHandler = e => {
    this._hasFocus = true;
    this.props.onFocus(e);
  };

  blurHandler = e => {
    this._hasFocus = false;
    this.props.onBlur(e);
  };

  changeHandler = (e, fieldName) => {
    const value = getProp(e, 'target.value');
    const updateData = {
      [fieldName]: value
    };

    if (value && this.state[`${fieldName}Error`]) {
      updateData[`${fieldName}Error`] = false;
    }

    this.setState(updateData);
  };

  validateHandler = () => {
    const { name, phone, email, message } = this.state;
    const emailError = typeof email !== 'string' || !emailRegex.test(email);
    const anError = !name || !phone || emailError || !message;

    this.setState({ nameError: !name });
    this.setState({ phoneError: !phone });
    this.setState({ emailError });
    this.setState({ messageError: !message });

    return anError;
  };

  onSubmitHandler = (e = {}) => {
    if (typeof e.preventDefault === 'function') {
      e.preventDefault();
    }

    if (this.validateHandler()) {
      return;
    }

    const { name, phone, email, message } = this.state;
    const values = { name, phone, email, message };

    this.props.onSubmit({ values });
  };

  reset = () => {
    this.setState(Object.assign({}, INITIAL_STATE));
  };

  render() {
    const { bgColor, placeholder, stateFocus, settings } = this.props;
    const {
      nameFocused,
      phoneFocused,
      emailFocused,
      name,
      phone,
      email,
      message,
      nameError,
      phoneError,
      emailError,
      messageError
    } = this.state;

    return (
      <Form
        onSubmit={this.onSubmitHandler}
        onKeyDown={e => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            this.onSubmitHandler();
          }
        }}
      >
        <FieldWrapper>
          <ChatBoxField
            name="name"
            type="text"
            placeholder="Ваше имя"
            bgColor={nameFocused ? 'white' : bgColor}
            error={nameError}
            wrapper={{
              style: { width: '95%', marginTop: '20px' }
            }}
            input={{
              onFocus: this.handleNameFocus,
              onBlur: this.handleNameFocus,
              onChange: e => this.changeHandler(e, 'name'),
              value: name
            }}
          />
          <ChatBoxField
            name="phone"
            type="text"
            bgColor={phoneFocused ? 'white' : bgColor}
            error={phoneError}
            wrapper={{
              style: { width: '95%', marginTop: '20px' }
            }}
            customInput={
              <MaskedInput
                mask={phoneMask}
                className={'form-control form-control-phone'}
                placeholder="Номер телефона"
                guide={false}
                id={'my-phone-id'}
                onFocus={this.handlePhoneFocus}
                onBlur={this.handlePhoneFocus}
                onChange={e => this.changeHandler(e, 'phone')}
                value={phone || ''}
              />
            }
          />
          <ChatBoxField
            name="email"
            type="text"
            bgColor={emailFocused ? 'white' : bgColor}
            error={emailError}
            wrapper={{
              style: { width: '95%', marginTop: '20px' }
            }}
            customInput={
              <MaskedInput
                mask={emailMask}
                className={'form-control form-control-email'}
                placeholder="Email"
                guide={false}
                id={'my-email-id'}
                onFocus={this.handleEmailFocus}
                onBlur={this.handleEmailFocus}
                onChange={e => this.changeHandler(e, 'email')}
                value={email || ''}
              />
            }
          />
        </FieldWrapper>
        <SendMessageWrap
          bgColor={stateFocus ? 'white' : bgColor}
          color={stateFocus ? 'black' : 'white'}
          onFocus={this.focusHandler}
          onBlur={this.blurHandler}
          hasError={!!messageError}
        >
          <LmSendMsgField
            name="lmSendMsgField"
            type="text"
            placeholder={placeholder}
            koef={1}
            wrapper={{
              width: '80%'
            }}
            input={{
              onChange: e => this.changeHandler(e, 'message'),
              value: message
            }}
          />
          <SendBtn
            type="submit"
            alt="Отправить"
            settings={settings}
            hasFocus={this._hasFocus}
          />
        </SendMessageWrap>
      </Form>
    );
  }
}

LeaveMessageForm.propTypes = {
  action: PropTypes.object,
  bgColor: PropTypes.string,
  componentName: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  settings: PropTypes.object,
  stateFocus: PropTypes.bool
};

export default LeaveMessageForm;
