import styled from 'styled-components';
import { prop } from 'styled-tools';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  max-width: 32px;
  max-height: 32px;
  border: 1px solid #a6a6a6;
  border-radius: 5px;
  text-align: center;
  ${prop('style')}
`;
