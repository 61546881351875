export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * The phone mask
 * @type {RegExp[]}
 */
export const phoneMask = [
  /(\+)|(\()|(\))|(\d)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\()|(\))|(\d)|(-)/,
  /(\))|(\d)/
];

/**
 * The email mask creator
 * @type {{pipe: (function(*): *), mask: (function(*=): Array)}}
 */
export const emailMask = {
  mask: (value = '') => {
    const strLength = value.length;
    const mask = [];

    for (let i = 0; i <= strLength; i++) {
      if (strLength < 2 || (value.match(/@/g) || []).length > 1) {
        mask.push(/[^<>()\[\]\\,;:\s@"]/);
      } else {
        mask.push(/[^<>()\[\]\\,;:\s"]/);
      }
    }

    return mask;
  },
  pipe: value => value
};
