import React from 'react';
import { Footer } from './footerStyle';
import { getLightOrDarkByHex } from 'utils/helpers';

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   */
  getIsLight() {
    const { settings = {} } = this;
    const bckColor = settings.cb_background_color;
    return getLightOrDarkByHex(bckColor);
  }

  /**
   *
   * @returns {*}
   */
  getTheme() {
    const { settings = {} } = this;
    return settings.Theme;
  }
}

const index = (props = {}) => {
  const { settings = {}, privacyUrl = undefined } = props;
  const Styles = new StylesConstructor({ settings });
  const internalProps = {
    _isLight: Styles.getIsLight(),
    theme: Styles.getTheme()
  };

  return (
    <Footer {...internalProps}>
      {' '}
      <a
        href={'https://leaderchat.ru'}
        target={'_blank'}
        className={'privacy-url'}
        rel="noreferrer"
      >
        Leader Chat
      </a>
    </Footer>
  );
};

export default index;
