import styled, { keyframes } from 'styled-components';

const cloudMove = keyframes`
  0% {
    transform: translate3d(-15px, 0px, 0px);
  }
  50% {
    transform: translate3d(0px, 0px, 0px);
  }
  100% {
    transform: translate3d(-15px, 0px, 0px);
  }
`;

const Cloud1 = styled.img`
  width: ${props => 26 * props.koef}px;
  position: absolute;
  right: ${props => 50 * props.koef}px;
  bottom: ${props => 100 * props.koef}px;
  z-index: 2;
  animation: ${cloudMove} 2s linear infinite;
  animation-fill-mode: forwards;
`;

const Cloud2 = styled.img`
  width: ${props => 50 * props.koef}px;
  position: absolute;
  left: ${props => 20 * props.koef}px;
  bottom: ${props => 120 * props.koef}px;
  z-index: 2;
  animation: ${cloudMove} infinite 3.5s ease-in-out;
  animation-fill-mode: forwards;
`;

export { Cloud1, Cloud2 };
