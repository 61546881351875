import React, { Component } from 'react';
import { prop, ifProp } from 'styled-tools';
import styled, { css } from 'styled-components';
//
import { getImgOrSvg, getProp } from 'utils/helpers';

export const DEFAULT_SVG_WIDTH = 25;
export const DEFAULT_SVG_HEIGHT = 22;

const Button = styled.button`
  width: auto;
  height: 50px;
  max-width: 50px;
  margin: 0 ${props => 10 * props.koef}px;
  cursor: pointer;
  border: none;
  opacity: 0.75;
  transition: opacity 300ms ease-in-out;
  background-color: transparent;
  background-size: cover;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    cursor: not-allowed;
  }

  & > .isvg {
    display: inline-block;
    position: relative;

    &:after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  svg {
    ${prop('svgStyle')}

    & > path {
      ${ifProp(
        'color',
        css`
          fill: ${prop('color')};
        `
      )};
    }

    & > g {
      ${ifProp(
        'color',
        css`
          fill: ${prop('color')};
        `
      )};
    }
  }

  img {
    width: ${props => 25 * props.koef}px;
    height: ${props => 22 * props.koef}px;
    user-drag: none;
    user-select: none;
  }
`;

export default class SendBtn extends Component {
  render() {
    const {
      settings,
      hasFocus,
      koef = 1,
      svgStyle = {},
      defaultColor,
      disabled
    } = this.props;
    const color = hasFocus
      ? getProp(settings, 'mb_button_color')
      : defaultColor;
    let imgPath = hasFocus
      ? settings.mb_active_button_image
      : settings.mb_inactive_button_image;
    // hardcoded img url
    if (/arrow_with_shadow.svg$/.test(imgPath)) {
      svgStyle['-webkit-filter'] = 'drop-shadow(0 6px 2px rgba(0,0,0,0.5))';
      svgStyle.filter = 'drop-shadow(0 6px 2px rgba(0,0,0,0.5))';
      imgPath = imgPath.replace(/arrow_with_shadow.svg$/, 'arrow.svg');
    }

    function processSVG(textContent) {
      let svgContent = (textContent.match(/<svg([\s\S]*?)>/g) || [])[0];
      if (!svgContent) {
        return textContent;
      }
      if (!/width\=/.test(svgContent)) {
        svgContent = svgContent.replace(/width="([0-9,a-z]*)"/i, '');
        svgContent = svgContent.replace(
          /<svg\s/,
          `<svg width="${DEFAULT_SVG_WIDTH}" height="${DEFAULT_SVG_HEIGHT}" `
        );
      }

      textContent = textContent.replace(/<svg([\s\S]*?)>/, svgContent);
      return textContent;
    }

    const buttonImage = getImgOrSvg(imgPath, null, settings, processSVG);

    return (
      <Button
        imgIsExists={!!buttonImage}
        koef={koef}
        color={color}
        svgStyle={svgStyle}
        disabled={disabled}
      >
        {buttonImage}
      </Button>
    );
  }
}
