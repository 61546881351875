import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

export const ContentWrapper = styled.div`
  opacity: ${prop('visible')};
  width: 100%;
  height: 100%;
  transition: opacity 1s linear;
  top: 0;
  position: absolute;
  display: flex;
  border-radius: 6px;
  -webkit-border-radius: 6px;
`;

export const UnreadIcon = styled.p`
  cursor: pointer;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  top: -6px;
  left: -5px;
  margin: 0;
  z-index: 1;
  min-width: 15px;
  border: 2px solid #fff;
  color: #fff;
  background: #f00;
  border-radius: 13px;
  font-weight: 600;
  text-align: center;
  font-family: sans-serif;
  font-size: 1.1em;
  line-height: 24px;
  height: 24px;
  width: 24px;
  padding: 0;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ButtonImage = styled.img`
  ${ifProp(
    'borderColor',
    css`
      border: 2px solid;
      ${prop('borderColor')}
    `
  )};
  ${prop('backgroundImg')};
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  -webkit-border-radius: 6px;
`;
