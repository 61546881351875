import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

export const FrameWrapper = styled.div`
  width: 100%;
  height: 100%;
  transition: opacity 1s linear;
  top: 0;
  position: absolute;
  display: flex;
  border-radius: 6px;
  -webkit-border-radius: 6px;
`;

export const ButtonImage = styled.img`
  ${prop('borderColor')};
  ${prop('backgroundColor')};
  ${prop('backgroundImg')};
  box-sizing: border-box;
  cursor: pointer;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  -webkit-border-radius: 6px;
`;
