import { setItem, getItem } from './localStorage';

const PREFIX = 'vc';
const KEYS = {
  STORE: `${PREFIX}_$`
};

/**
 *
 * @param store
 */
export const saveStore = (store = {}) => {
  setItem(KEYS.STORE, JSON.stringify(store));
};

/**
 *
 */
export const loadStore = () => {
  let store = {};
  const data = getItem(KEYS.STORE);
  if (!data) return store;
  store = JSON.parse(data);
  return store;
};
