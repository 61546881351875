import React from 'react';
import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';

const List = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  & > ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }

  & > select {
    width: calc(100% - 24px);
    margin: 30% auto 0;
    text-align: center;
    display: block;
    text-align-last: center;
    color: #000000;
    font-size: 16px;
    padding: 12px 6px;
    background-color: #ffffff;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-button {
    width: 13px;
    height: 13px;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: #666;
    border: 0 none #fff;
    border-radius: 50px;
  }

  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0 none #fff;
    border-radius: 50px;
  }

  ${prop('style')};
`;

const Item = styled.li`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  line-height: 40px;
  text-align: left;
  padding: 0 12px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
  position: relative;
  
  &:hover {
    &:before {
      animation: fade 2.5s infinite;
      opacity: 1;
    }
  }
  
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(0,0,0,0.2);
    
    @keyframes fade {
      from { opacity: 1.0; }
      50% { opacity: 0; }
      to { opacity: 1.0; }
    }
  
  &:not(:last-child) {
    border-bottom-color: rgba(255,255,255,0.4);
  }
  
  &:hover {
    ${ifProp(
      'hoverColor',
      css`
        background-color: ${prop('hoverColor')};
      `
    )}
  }
  
  ${prop('style')}
`;

const SelectList = (props = {}) => {
  const { settings, items = [], onSelect, listStyle = {}, isMobile } = props;
  if (!items.length) {
    return null;
  }
  const itemStyle = {
    color: settings.cb_popup_text_color || '#ffffff'
  };
  const itemHoverColor = settings.cb_selection_color;
  // event.target.selectedIndex
  if (isMobile) {
    return (
      <List style={listStyle}>
        <select
          defaultValue="placeholder"
          onChange={event =>
            onSelect({
              index: event.target.selectedIndex - 1,
              sourceEvent: event,
              room: items[event.target.selectedIndex - 1].name
            })
          }
        >
          <option value="placeholder" disabled>
            Click here to select
          </option>
          {items.map(({ name }, index) => (
            <option key={index} name={name} value={index}>
              {name}
            </option>
          ))}
        </select>
      </List>
    );
  }

  return (
    <List style={listStyle}>
      <ul>
        {items.map(({ name }, index) => (
          <Item
            key={index}
            style={itemStyle}
            hoverColor={itemHoverColor}
            onClick={event =>
              onSelect({ index, sourceEvent: event, room: items[index].name })
            }
            tabIndex={0}
            onKeyDown={e =>
              e.key === 'Enter'
                ? onSelect({ index, sourceEvent: e, room: items[index].name })
                : true
            }
          >
            {name}
          </Item>
        ))}
      </ul>
    </List>
  );
};

export default SelectList;
