import styled, { css } from 'styled-components';
import React, { Component, Fragment } from 'react';
import { ifProp, prop } from 'styled-tools';
import {
  domainQualificationAnswerClicked,
  domainQualificationCompleted,
  domainQualificationPushQuestion,
  domainQualificationReset,
  sendMessage,
  setOperatorTyping,
  unsetOperatorTyping,
  updateRoom
} from '../../../../ducks/global/rooms.action';
import store from '../../../../store';
import { MessageStylesConstructor } from './messageComp';
import { messageStatuses } from '../../../../config/constants';
import { getCurrentTime } from '../../../../utils/helpers';
import { setIsShowingLam } from '../../../../ducks/chatBox/chatBox.action';

const QualificationProcessWrapper = styled.div`
  width: calc(100% - 30px);
  transition: 0.12s padding ease-out;
`;

const Wrapper = styled.div`
  font-size: 14px;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  width: 100%;
  ${prop('style')};
`;

const Message = styled.div`
  border: 1px solid #4d4d4d;
  margin-bottom: 5px;
  position: relative;
  display: inline-block;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 90%;
  word-break: break-word;
  overflow: visible;

  &:after {
    content: ' '; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  ${prop('style')};
`;

const AnswerMessageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => props.answersPerRow},
    minmax(0, 1fr)
  );
  width: 100%;
  border: 0;
  gap: 10px;
  margin-right: 0;
  padding: 0;

  ${ifProp(
    { answersPerRow: 1 },
    css`
      max-width: 80%;
    `,
    css`
      max-width: 90%;
    `
  )}
`;

const MessageText = styled.p`
  text-align: left;
`;

const ClickableAnswer = styled.div`
  background-color: #e6fdff;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 10px;
  padding: 7px 10px;
  color: #4b6a91;
  cursor: pointer;
  border-radius: 5px;
  margin: 0;

  div:first-of-type {
    display: flex;
    align-items: center;
  }

  div.offline {
    margin-top: 5px;
    font-size: 90%;
    color: black;
  }

  background-color: ${props => props.bgColor};
  color: ${props => props.textColor};

  &:hover {
    background-color: ${props => props.hoverBgColor};
    color: ${props => props.hoverTextColor};
  }

  ${ifProp(
    'borderColor',
    css`
      border: 1px solid ${props => props.borderColor};
    `
  )}
`;

const ClickableAnswerImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 2px;
  margin: 0 7px 0 0;

  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
`;

const StartOver = styled.div`
  display: inline-block;
  color: #8e8e8e;
  cursor: pointer;
  font-size: 90%;
  grid-column: 1 / -1;
  margin-right: 0;

  &:hover {
    color: #b6b6b6;
  }
`;

class DomainQualificationProcessComp extends Component {
  constructor(props) {
    super(props);

    this.initialise();
  }

  initialise() {
    const { qualificationProcess, questions } = this.props;
    if (!qualificationProcess.history.length) {
      // Get the initial question to show to the visitor.
      const initialQuestion = questions.find(question => question.initial);
      this.loadQuestion(initialQuestion);
    }
  }

  resetQuestions() {
    const { room } = this.props;

    store.dispatch(setOperatorTyping(room));
    store.dispatch(domainQualificationReset(room));
    this.initialise();
    setTimeout(() => {
      store.dispatch(unsetOperatorTyping(room));
    }, 1000);
  }

  loadQuestion(nextQuestion) {
    const { questions, handleSocketGetTeamOnlineStatus, room } = this.props;
    // Send event for getting online status of teams if we have any.
    const teamIds = [];
    questions.forEach(question => {
      question.answers.forEach(answer => {
        if (
          answer.account_team_id !== null &&
          teamIds.indexOf(answer.account_team_id) === -1
        ) {
          teamIds.push(answer.account_team_id);
          handleSocketGetTeamOnlineStatus(answer.account_team_id, room);
        }
      });
    });

    // Mimic the operator typing and then display the next question.
    store.dispatch(setOperatorTyping(room));
    setTimeout(() => {
      store.dispatch(domainQualificationPushQuestion(room, nextQuestion));
      store.dispatch(unsetOperatorTyping(room));
    }, Math.floor(Math.random() * (1000 - 500) + 500));
  }

  qualificationComplete() {
    const { settings, room } = this.props;
    // Set the qualification process as complete.
    new Promise(resolve => {
      store.dispatch(domainQualificationCompleted(room));
      resolve();
    }).then(() => {
      // If we have an initial message, then display this.
      if (
        settings.mb_initial_message_enabled === 'on' &&
        settings.mb_initial_message
      ) {
        store.dispatch(setOperatorTyping(room));
        setTimeout(() => {
          store.dispatch(
            sendMessage(
              room,
              {
                author: 'operator',
                date: getCurrentTime(),
                text: settings.mb_initial_message,
                status: messageStatuses.MSG_STATUS_SENT,
                isSystem: true
              },
              { isFake: true }
            )
          );
          store.dispatch(
            updateRoom(room, { mbInitialMessage: settings.mb_initial_message })
          );
          store.dispatch(unsetOperatorTyping(room));
        }, Math.floor(Math.random() * (1000 - 500) + 500));
      }
    });
  }

  clickAnswerHandler(answer) {
    if (!this.isTeamOnline(answer.account_team_id)) {
      store.dispatch(setIsShowingLam(true));

      return;
    }

    const { questions, room } = this.props;
    new Promise(resolve => {
      store.dispatch(domainQualificationAnswerClicked(room, answer));
      resolve();
    }).then(() => {
      // If linked question is null then continue to the chat.
      if (answer.linked_question_id === null) {
        this.qualificationComplete();

        return;
      }

      // Else prepare the next qualification question.
      const nextQuestion = questions.find(
        question => question.id === answer.linked_question_id
      );
      if (nextQuestion) {
        this.loadQuestion(nextQuestion);
      }
    });
  }

  isTeamOnline(teamId) {
    if (teamId === null) {
      return true;
    }

    const { qualificationProcess } = this.props;
    const team = qualificationProcess.teams.find(team => team.id === teamId);

    return team && team.online;
  }

  getClickableMessageStyle() {
    const { settings } = this.props;

    return {
      bgColor: settings.qualification_process_message_bg_color || '#e6fdff',
      textColor: settings.qualification_process_message_text_color || '#4b6a91',
      hoverBgColor:
        settings.qualification_process_message_bg_hover_color || '#c1c1c1',
      borderColor: settings.qualification_process_message_border_color || null
    };
  }

  getClickableOfflineMessageStyle() {
    const { settings } = this.props;

    return {
      bgColor:
        settings.qualification_process_message_offline_bg_color || '#c1c1c1',
      textColor:
        settings.qualification_process_message_offline_text_color || '#6b6b6b',
      hoverBgColor:
        settings.qualification_process_message_offline_bg_hover_color ||
        '#a2a1a1',
      borderColor:
        settings.qualification_process_message_offline_border_color || null
    };
  }

  messageHistory() {
    const { settings, qualificationProcess } = this.props;
    const Styles = new MessageStylesConstructor({ settings });

    return qualificationProcess.history.map((question, qIndex) => {
      const isAnswer = question.name !== undefined;
      const messageStyle = {
        ...Styles.getBackground(isAnswer),
        ...Styles.getBorder(isAnswer),
        ...Styles.getColor(isAnswer)
      };

      let answers = null;
      // If this is a question and this is the last in the history then display the answer options.
      const showAnswers = qualificationProcess.history.length === qIndex + 1;
      if (!isAnswer && showAnswers) {
        const answersArray = [];
        question.answers.forEach(answer => answersArray.push(answer));
        answers = answersArray
          .sort((a, b) => a.order - b.order)
          .map((answer, aIndex) => {
            const hasImage =
              answer.image_path_public !== undefined &&
              answer.image_path_public.length;
            const isTeamOnline = this.isTeamOnline(answer.account_team_id);
            const clickableAnswerStyles = isTeamOnline
              ? this.getClickableMessageStyle()
              : this.getClickableOfflineMessageStyle();

            return (
              <ClickableAnswer
                key={aIndex}
                onClick={() => this.clickAnswerHandler(answer)}
                onKeyDown={({ key }) =>
                  key === 'Enter' ? this.clickAnswerHandler(answer) : true
                }
                tabIndex={0}
                {...clickableAnswerStyles}
              >
                <div>
                  {hasImage && (
                    <ClickableAnswerImage>
                      <img src={answer.image_path_public} alt={answer.name} />
                    </ClickableAnswerImage>
                  )}
                  <span style={{ margin: 0 }}>{answer.name}</span>
                </div>
                {!isTeamOnline && (
                  <div className={'offline'}>currently offline</div>
                )}
              </ClickableAnswer>
            );
          });

        // If this is not the first question then append a start over option.
        if (qIndex !== 0) {
          answers.push(
            <StartOver onClick={() => this.resetQuestions()} key={'start-over'}>
              start over?
            </StartOver>
          );
        }
      }

      return (
        <Fragment key={qIndex}>
          <Wrapper style={{ textAlign: isAnswer ? 'right' : 'left' }}>
            <Message style={messageStyle}>
              <MessageText>
                {isAnswer ? question.name : question.question}
              </MessageText>
            </Message>
          </Wrapper>
          {showAnswers && answers !== null && answers.length && (
            <Wrapper style={{ textAlign: 'left' }}>
              <AnswerMessageWrapper
                answersPerRow={parseInt(
                  settings.qualification_process_answers_per_row || 1
                )}
              >
                {answers}
              </AnswerMessageWrapper>
            </Wrapper>
          )}
        </Fragment>
      );
    });
  }

  render() {
    const {
      questions,
      qualificationProcess,
      hasConnectedToOperator
    } = this.props;

    if (!qualificationProcess.active || !questions.length) {
      return <Fragment />;
    }

    return (
      <QualificationProcessWrapper>
        {this.messageHistory()}
        {qualificationProcess.completed && !hasConnectedToOperator && (
          <Wrapper style={{ textAlign: 'right', marginBottom: '10px' }}>
            <StartOver onClick={() => this.resetQuestions()} key={'start-over'}>
              start over?
            </StartOver>
          </Wrapper>
        )}
      </QualificationProcessWrapper>
    );
  }
}

export { DomainQualificationProcessComp };
