import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Transition } from 'react-transition-group';

import { FrameWrapper, frameWrapperFormatProps } from './frameWrapper';
import FramedComponent from '../Common/framedComponent';
import { IoIosClose } from 'react-icons/io';
import {
  closeCta,
  transitionChangeCTA
} from '../../ducks/callToAction/callToAction.action';
import {
  CtaWrapper,
  ctaWrapperFormatProps,
  closeBtnFormatProps,
  contentWrapperFormatProps,
  imgFormatProps,
  TextContent,
  TextTitle,
  Img,
  CloseBtn,
  TextWrapper,
  ContentWrapper
} from './contentWrapper';
import { UnreadIcon } from '../FloatingButton/contentWrapper';
import { transitionStages } from '../../config/constants';
import { getProp } from 'utils/helpers';
import { fbAdhoc } from 'utils/adhoc';
import { getVcSetting, hasVcSettings } from '../../utils/getVCSettings';
class CallToAction extends PureComponent {
  timer = null;

  constructor(props) {
    super(props);
    this.state = {
      isClosedByClick: false
    };
    this._timer = null;
    this._fbAdhoc = fbAdhoc();
  }

  refreshState = () => {
    this.setState({
      isClosedByClick: false
    });
  };

  parentState() {
    return {
      get: () => this.state,
      set: newState => {
        this.setState(newState);
      }
    };
  }

  onClickClose(e, options = {}) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (options.hideOnly) {
      this.setState({ isClosedByClick: true }, () =>
        this.props.closeCta({ ...e, ...options })
      );
    } else {
      this.props.closeCta({ ...e, ...options });
    }
  }

  transitionEnterHandler = () => {
    this.props.transitionChangeCTA({ stage: transitionStages.ENTER });
    clearTimeout(this.timer);
  };

  transitionEnteredHandler = () => {
    this.props.transitionChangeCTA({ stage: transitionStages.ENTERED });
  };

  transitionExitHandler = (props = {}) => {
    this.props.transitionChangeCTA({ stage: transitionStages.EXIT });
    if (props.timer) {
      this.timer = setTimeout(
        () => (this._frameWrapper.style.display = 'none'),
        props.timer
      );
    }
  };

  transitionExitedHandler = () => {
    this.props.transitionChangeCTA({
      hideOnly: this.state.isClosedByClick,
      stage: transitionStages.EXITED
    });
    this.refreshState();
  };

  render() {
    const {
      global = {},
      rooms = [],
      callToAction = {},
      speechBubble = {},
      chatBox = {},
      floatingButton = {}
    } = this.props;
    const { settings, uiLocked } = global;
    const activeRoomInfo =
      rooms.find(({ room }) => room === global.activeRoom) || {};
    const { operatorOnline } = activeRoomInfo;
    const unreadMessages = this._fbAdhoc.getUnreadMessagesLength(
      activeRoomInfo
    );
    const frameWrapperProps = (anyProps = {}) => {
      return frameWrapperFormatProps({
        settings,
        parentState: this.parentState(),
        operatorOnline,
        speechBubble,
        element: this._frameWrapper,
        isClosedByClick: this.state.isClosedByClick,
        uiLocked,
        ...anyProps
      });
    };
    const ctaWrapperProps = (() => {
      return ctaWrapperFormatProps({
        settings,
        parentState: this.parentState(),
        operatorOnline
      });
    })();
    const contentWrapperProps = (() => {
      return contentWrapperFormatProps({
        settings,
        operatorOnline
      });
    })();
    const imgProps = (() => {
      return imgFormatProps({
        settings
      });
    })();
    const DEFAULT_DELAY = 500;
    const timeoutEnterExit = operatorOnline
      ? +getProp(settings, 'fb_move_speed', '0') || DEFAULT_DELAY
      : +getProp(settings, 'fb_offline_move_speed', '0') || DEFAULT_DELAY;

    let title = settings.sb_offline_text_title;
    let content = settings.sb_offline_text;
    if (operatorOnline) {
      title = settings.sb_text_title;
      content = settings.sb_text;
      if (hasVcSettings()) {
        const overriddenTitle = getVcSetting('ctaTitle');
        if (overriddenTitle) {
          title = overriddenTitle;
        }

        const overriddenText = getVcSetting('ctaText');
        if (overriddenText) {
          content = overriddenText;
        }
      }
    }

    const isFbCtaClosed = !speechBubble.open && !floatingButton.open;
    const showUnreadMessage =
      !isFbCtaClosed &&
      !speechBubble.open &&
      !chatBox.open &&
      unreadMessages > 0;
    const transitionIsAvailable = (() => {
      let statement = callToAction.open;
      if (!operatorOnline && settings.sb_when_offline !== 'on')
        statement = false;
      if (!callToAction.active) statement = false;
      return statement;
    })();

    return (
      <Transition
        in={transitionIsAvailable}
        appear
        unmountOnExit
        onEnter={this.transitionEnterHandler}
        onEntered={this.transitionEnteredHandler}
        onExit={() =>
          this.transitionExitHandler({ timer: timeoutEnterExit - 50 })
        }
        onExited={this.transitionExitedHandler}
        timeout={{ enter: timeoutEnterExit, exit: timeoutEnterExit }}
      >
        {transitionState => (
          <FrameWrapper
            ref={el => (this._frameWrapper = el)}
            {...frameWrapperProps({ transitionState })}
          >
            {showUnreadMessage && <UnreadIcon>{unreadMessages}</UnreadIcon>}
            <FramedComponent title={'VC Call To Action'}>
              <CtaWrapper
                onClick={e => this.onClickClose(e)}
                tabIndex={0}
                onKeyDown={e =>
                  e.key === 'Enter' ? this.onClickClose(e) : true
                }
                {...ctaWrapperProps}
              >
                <ContentWrapper {...contentWrapperProps}>
                  <Img {...imgProps} />
                  <TextWrapper>
                    <TextTitle>{title}</TextTitle>
                    <TextContent>{content}</TextContent>
                  </TextWrapper>
                </ContentWrapper>
                <CloseBtn
                  onClick={e => this.onClickClose(e, { hideOnly: true })}
                  tabIndex={0}
                  onKeyDown={e =>
                    e.key === 'Enter'
                      ? this.onClickClose(e, { hideOnly: true })
                      : true
                  }
                  {...closeBtnFormatProps({ operatorOnline, settings })}
                >
                  <IoIosClose color={'##00ff00'} title={'Close'} />
                </CloseBtn>
              </CtaWrapper>
            </FramedComponent>
          </FrameWrapper>
        )}
      </Transition>
    );
  }
}

export default connect(
  ({ global, floatingButton, rooms, callToAction, speechBubble, chatBox }) => {
    return {
      global,
      floatingButton,
      rooms,
      callToAction,
      speechBubble,
      chatBox
    };
  },
  dispatch => {
    return {
      closeCta: bindActionCreators(closeCta, dispatch),
      transitionChangeCTA: bindActionCreators(transitionChangeCTA, dispatch)
    };
  }
)(CallToAction);
