import { FrameWrapper } from './frameWrapper';
import React, { Component } from 'react';

export const FrameWrapedHOC = WrappedComponent => {
  return class extends Component {
    isPositionCalculated = false;

    componentDidUpdate() {
      if (
        this.props._align &&
        this.props._btnWidth &&
        !this.isPositionCalculated
      ) {
        this.isPositionCalculated = true;
        const {
          _btnWidth,
          _btnHeight,
          sbParentState,
          _align,
          setDimensionSb
        } = this.props;

        sbParentState.set(
          () => ({
            fbAlign: _align,
            dimension: { ..._btnWidth, ..._btnHeight }
          }),
          setDimensionSb
        );
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export const WrappedFrame = FrameWrapedHOC(FrameWrapper);
