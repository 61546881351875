import {
  CTA__ACTIVATE,
  CTA__CLOSE,
  CTA__DEACTIVATE,
  CTA__OPEN,
  CTA__TRANSITION_CHANGE,
  SYSTEM__CLEAR
} from '../actionConstants';

const updateState = function(state = {}, newState = {}) {
  return Object.assign({}, state, newState);
};

const init = {
  active: false,
  open: false,
  showPermanently: false,
  onceClosedByForce: false,
  onceOpened: false,
  ctaNextOpenDate: false,
  stage: null
};

export default function(state = init, action) {
  const { type, payload = {} } = action;

  switch (type) {
    case CTA__ACTIVATE:
      return updateState(state, {
        active: true,
        showPermanently: payload.showPermanently
      });

    case CTA__DEACTIVATE:
      return updateState(state, {
        active: false
      });

    case CTA__OPEN:
      if (state.active) {
        return updateState(state, {
          onceOpened: true,
          open: true
        });
      }
      return state;

    case CTA__CLOSE:
      return updateState(state, {
        open: false,
        onceClosedByForce: !!payload.onceClosedByForce
      });

    case CTA__TRANSITION_CHANGE:
      return updateState(state, {
        stage: action.payload.stage
      });

    case SYSTEM__CLEAR:
      return updateState(state, init);

    default:
      return state;
  }
}
