import styled, { css } from 'styled-components';
import { prop, ifProp } from 'styled-tools';
import mobileDevice from 'ismobilejs';
import { getLightOrDarkByHex } from '../../utils/helpers';
import { IS_MOBILE_WIDTH } from 'config/constants';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;

/**
 *
 * @returns {string}
 */
function getHeight(settings = {}) {
  return isPhone ? '100%' : `${settings.cb_size_height || 600}px`;
}

/**
 *
 * @returns {boolean}
 */
function getIsLight(settings = {}) {
  const bckColor = settings.cb_background_color;
  return getLightOrDarkByHex(bckColor);
}

const contentWrapperFormatProps = (props = {}) => {
  const { settings } = props;
  const internalProps = {
    _height: getHeight({ settings }),
    _isLight: getIsLight({ settings }),
    theme: settings.Theme
  };

  return Object.assign({}, internalProps);
};

const ContentWrapper = styled.div`
  ${ifProp(
    '_isLight',
    'color: rgba(0,0,0,0.7)',
    'color: rgba(255,255,255,0.4)'
  )};
  ${ifProp(
    '_height',
    css`
      height: ${prop('_height')};
    `
  )};
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;

  img,
  svg,
  span,
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
`;

export { contentWrapperFormatProps, ContentWrapper };
