import { openCb, openMb } from '../ducks/chatBox/chatBox.action';
import { openFb } from '../ducks/floatingButton/floatingButton.action';
import { onOpenTimerSb } from '../ducks/speechBubble/speechBubble.action';
import {
  GLOBAL__INIT_APP,
  GLOBAL__CONNECT,
  SYSTEM__CLEAR
} from '../ducks/actionConstants';
import SETTING from '../helpers/mobile-desktop-settings';
import { MB_OPEN_TIMEOUT } from '../config/constants';
import { setMbOpenTimerDate } from '../ducks/global/rooms.action';

const state = {
  isInitialized: false
};

/**
 * Setting global timeout for frame elements.
 */
export default store => next => action => {
  switch (action.type) {
    case GLOBAL__CONNECT:
      _GLOBAL__CONNECT({ store, next, action });
      break;
    case GLOBAL__INIT_APP:
      _GLOBAL__INIT_APP({ store, next, action });
      break;
    case SYSTEM__CLEAR:
      _SYSTEM__CLEAR({ store, next, action });
      break;
    default:
      return next(action);
  }
};

/**
 *
 * @param store
 * @param next
 * @param action
 * @returns {*}
 * @private
 */
function _GLOBAL__INIT_APP({ store, next, action }) {
  if (!state.isInitialized) {
    state.isInitialized = true;

    clearTimeout(window.openCTATimeout);
    clearTimeout(window.openCBTimeout);
    clearTimeout(window.openSBTimeoutShort);
    clearTimeout(window.openSBTimeoutLong);
    clearTimeout(window.closeSBTimeout);

    window.openCTATimeout = null;
    window.openCBTimeout = null;
    window.openSBTimeoutShort = null;
    window.openSBTimeoutLong = null;
    window.closeSBTimeout = null;
  }

  return next(action);
}

/**
 * Clear cached data of middleware
 * @param store
 * @param next
 * @param action
 * @returns {*}
 * @private
 */
function _SYSTEM__CLEAR({ store, next, action }) {
  window.openCTATimeout = null;
  window.openCBTimeout = null;
  state.isInitialized = false;
  state.isOnceConnected = false;
  return next(action);
}

/**
 * Opening 'Floating Button' component on 'ROOM__CONNECTED' event
 * @param store
 * @param next
 * @param action
 * @returns {*}
 * @private
 */
function _GLOBAL__CONNECT({ store, next, action }) {
  componentsShowManager({ store, action });
  mbInitOpenTimerListener({ store, action });
  store.dispatch(onOpenTimerSb());
  return next(action);
}

/**
 *
 * @param store
 * @param next
 * @param action
 */
function componentsShowManager({ store, next, action }) {
  const {
    chatBox,
    speechBubble,
    floatingButton,
    roomsSelector
  } = store.getState();

  if (roomsSelector.active) return;
  if (
    chatBox.open ||
    speechBubble.open ||
    floatingButton.open ||
    roomsSelector.open
  )
    return;

  store.dispatch(openFb());
}

/**
 * MessageBox: Timers turn on
 * @param store
 * @param action
 * @returns {*}
 * @private
 */
function mbInitOpenTimerListener({ store, action }) {
  const { chatBox, global, rooms } = store.getState();
  const { settings, activeRoom } = global;
  const isMBEnabled = SETTING('mb_enabled', settings) === 'on';

  if (!isMBEnabled || !chatBox.active) {
    if (window.openMBTimeoutShort) {
      mbDeactivateOpenTimerListener();
    }
    return;
  }

  const cbSlideOnDelayIsNumber = /^\d+$/g.test(settings.mb_delay);
  const delay = cbSlideOnDelayIsNumber ? +settings.mb_delay : -1;
  const currentRoom = rooms.find(({ room }) => room === activeRoom) || {};
  let { mbNextOpenDate } = currentRoom;

  // Time handler
  function timeFadeInHandler(force) {
    const { type } = this;
    const {
      chatBox,
      roomsSelector,
      speechBubble,
      global,
      rooms
    } = store.getState();
    const { activeRoom } = global;
    const currentRoom = rooms.find(({ room }) => room === activeRoom) || {};
    const { operatorOnline } = currentRoom;

    if (chatBox.open) {
      return;
    }
    if (chatBox.mbOnceOpened) {
      return;
    }
    if (chatBox.onceOpenedInSession) {
      return;
    }
    if (!operatorOnline) {
      return;
    }
    if (roomsSelector.active) {
      return;
    }
    if (roomsSelector.open) return;
    if (speechBubble.open) return;

    if (type === 'short') {
      if (!force && !window.openMBTimeoutShort) {
        return;
      }
      store.dispatch(openMb());
      store.dispatch(openCb({ eventSource: 'openMBTimeoutShort' }));
    }

    if (type === 'long') {
      if (!force && !window.openMBTimeoutLong) {
        return;
      }
      store.dispatch(openMb());
      store.dispatch(openCb({ eventSource: 'openMBTimeoutLong' }));
    }
  }

  if (delay > 0 && !window.openMBTimeoutShort) {
    mbDeactivateOpenTimerListener();
    window.openMBTimeoutShort = setTimeout(
      timeFadeInHandler.bind({ type: 'short' }),
      1000 * delay
    );
  }

  if (!currentRoom.mbNextOpenDate || currentRoom.mbNextOpenDate < Date.now()) {
    mbNextOpenDate = Date.now() + MB_OPEN_TIMEOUT;
    store.dispatch(
      setMbOpenTimerDate({ room: activeRoom.room, mbNextOpenDate })
    );
  }

  if (!window.openMBTimeoutLong && mbNextOpenDate - Date.now() > 0) {
    window.openMBTimeoutLong = setTimeout(
      timeFadeInHandler.bind({ type: 'long' }),
      mbNextOpenDate - Date.now()
    );
  }
}

/**
 * MessageBox: Timers turn off
 * @returns {*}
 * @private
 */
function mbDeactivateOpenTimerListener() {
  clearTimeout(window.openMBTimeoutShort);
  clearTimeout(window.openMBTimeoutLong);
  window.openMBTimeoutShort = null;
  window.openMBTimeoutLong = null;
}
