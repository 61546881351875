import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Transition } from 'react-transition-group';
import Draggable from 'react-draggable';
import mobileDevice from 'ismobilejs';
//
import { FrameWrapper, frameWrapperFormatProps } from './frameWrapper';
import FakeDraggableArea from './fakeDraggableArea';
import FramedComponent from '../Common/framedComponent';
import {
  setWebSettingsIndex,
  setActiveRoom
} from '../../ducks/global/app.action';
import {
  closeRs,
  transitionChangeRs,
  deactivateRs
} from '../../ducks/roomsSelector/roomsSelector.action';
import { ContentWrapper, contentWrapperFormatProps } from './contentWrapper';
import Header from './components/Header/headerCom';
import Footer from '../ChatBox/components/Footer/footerCom';
import SelectList from './components/SelectList';
import { IS_MOBILE_WIDTH, transitionStages } from '../../config/constants';
import { getProp } from 'utils/helpers';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;
const isTablet = mobileDevice.tablet;
const MAX_HEIGHT_IN_TEN_ROWS = 410;
const HEADER_HEIGHT = 55;
const FOOTER_HEIGHT = 30;

class RoomsSelector extends PureComponent {
  attachNodeHandler = (element, name, settings = {}) => {
    if (name === 'framed' && element) {
      const node = element.node;
      const body = getProp(element, 'node.contentDocument.body');
      if (node && body) {
        const fullDesktopHeight =
          getProp(
            settings,
            'cb_size_height',
            MAX_HEIGHT_IN_TEN_ROWS + HEADER_HEIGHT + FOOTER_HEIGHT
          ) + 'px';
        const height = isPhone
          ? '100%'
          : `${
              body.scrollHeight > MAX_HEIGHT_IN_TEN_ROWS
                ? fullDesktopHeight
                : body.scrollHeight
            }px`;
        const overflow = isPhone
          ? body.scrollHeight > window.innerHeight
            ? 'scroll'
            : 'visible'
          : body.scrollHeight > MAX_HEIGHT_IN_TEN_ROWS
          ? 'scroll'
          : 'visible';

        node.style.height = height;
        node.style.overflowY = overflow;
      }
    }

    this[`_${name}`] = element;
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const prevIndex = getProp(prevProps, 'global.chosenWebSettingsIndex');
    const actualIndex = getProp(this, 'props.global.chosenWebSettingsIndex');

    if (typeof prevIndex !== 'number' && actualIndex !== prevIndex) {
      this.appStarter();
    }
  }

  // With selected room
  appStarter = () => {
    this.props.handleRunApp();
  };

  /**
   *
   * @param index
   * @param room
   */
  selectHandler = ({ index, room } = {}) => {
    this.props.setActiveRoom(room);
    this.props.setWebSettingsIndex({ index });
    this.props.closeRs();
    this.props.deactivateRs();
  };

  /**
   * Special view for mobiles and tablets
   * @returns {*}
   */
  getMobileTabletSettingsList = () => {
    const { global } = this.props;
    const { settings = {}, webSettings = [] } = global;
    return (
      <SelectList
        settings={settings}
        isMobile={true}
        items={webSettings}
        onSelect={this.selectHandler}
      />
    );
  };

  /**
   * Special view for desktops
   * @returns {*}
   */
  getDesktopSettingsList = () => {
    const { global } = this.props;
    const { settings = {}, webSettings = [] } = global;
    const listStyle = {};
    const MAX_VISIBLE_ITEMS = 10;

    if (this._framed) {
      const node = this._framed.node;
      const body = getProp(this._framed, 'node.contentDocument.body');
      if (node && body) {
        const height =
          getProp(settings, 'cb_size_height', MAX_HEIGHT_IN_TEN_ROWS) + 'px';
        const overflow =
          webSettings.length > MAX_VISIBLE_ITEMS ? 'scroll' : 'visible';

        listStyle.height = height;
        listStyle.overflowY = overflow;
      }
    }

    return (
      <SelectList
        settings={settings}
        listStyle={listStyle}
        items={webSettings}
        onSelect={this.selectHandler}
      />
    );
  };

  getSettingsList = () => {
    if (isTablet || isPhone) {
      return this.getMobileTabletSettingsList();
    }

    return this.getDesktopSettingsList();
  };

  transitionExitedHandler = () =>
    this.props.transitionChangeRs({ stage: transitionStages.EXITED });

  transitionExitHandler = (props = {}) => {
    this.props.transitionChangeRs({ stage: transitionStages.EXIT });

    if (props.timer) {
      this.timer = setTimeout(
        () => (this._frameWrapper.style.display = 'none'),
        props.timer
      );
    }
  };

  render() {
    const { global, roomsSelector, closeRs } = this.props;
    const { settings } = global;
    const DEFAULT_DELAY = 500;
    const timeoutEnterExit =
      +getProp(settings, 'cb_move_speed', '0') || DEFAULT_DELAY;
    const frameWrapperProps = (anyProps = {}) => {
      return frameWrapperFormatProps({ settings, ...anyProps });
    };
    const headerProps = {
      settings,
      onClose: closeRs
    };
    const footerProps = {
      settings
    };
    const rsWrapperStyle = () => contentWrapperFormatProps({ settings });
    const transitionIsAvailable = (() => {
      let statement = roomsSelector.open;
      if (!roomsSelector.active) statement = false;
      return statement;
    })();

    return (
      <Transition
        in={transitionIsAvailable}
        appear
        unmountOnExit
        onExit={() =>
          this.transitionExitHandler({ timer: timeoutEnterExit - 50 })
        }
        onExited={this.transitionExitedHandler}
        timeout={{ enter: timeoutEnterExit, exit: timeoutEnterExit }}
      >
        {transitionState => (
          <Draggable bounds="body" disabled={isPhone}>
            <FrameWrapper
              ref={el => this.attachNodeHandler(el, 'frameWrapper', settings)}
              {...frameWrapperProps({
                transitionState,
                element: this._frameWrapper
              })}
            >
              <FakeDraggableArea />
              <FramedComponent
                title={'VC Room Selector'}
                attachNode={el => this.attachNodeHandler(el, 'framed')}
              >
                <ContentWrapper {...rsWrapperStyle()}>
                  <Header {...headerProps} />
                  {this.getSettingsList()}
                  <Footer {...footerProps} />
                </ContentWrapper>
              </FramedComponent>
            </FrameWrapper>
          </Draggable>
        )}
      </Transition>
    );
  }
}

const mapStateToProps = state => {
  const { global, roomsSelector } = state;
  return { global, roomsSelector };
};

const mapDispatchToProps = dispatch => {
  return {
    closeRs: bindActionCreators(closeRs, dispatch),
    transitionChangeRs: bindActionCreators(transitionChangeRs, dispatch),
    deactivateRs: bindActionCreators(deactivateRs, dispatch),
    setActiveRoom: bindActionCreators(setActiveRoom, dispatch),
    setWebSettingsIndex: bindActionCreators(setWebSettingsIndex, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomsSelector);
