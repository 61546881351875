import React, { Fragment } from 'react';
import { ContentWrapper, ButtonImage } from './contentWrapper';
import StylesConstructor from './StylesConstructor';

const FloatingButtonComm = ({ settings, operatorOnline, closeFb }) => {
  const Styles = new StylesConstructor({ settings });
  const fbImgStyle = Styles.getFBImg({ operatorOnline });

  return (
    <Fragment>
      <ContentWrapper
        onClick={closeFb}
        tabIndex={0}
        onKeyDown={e => (e.key === 'Enter' ? closeFb(e) : true)}
      >
        <ButtonImage
          {...fbImgStyle}
          src={fbImgStyle.imgSrc}
          alt="Open Live Chat"
        />
      </ContentWrapper>
    </Fragment>
  );
};

export { FloatingButtonComm };
