import React from 'react';
import PropTypes from 'prop-types';

import { CONTACT_VIA_SHOW_MODES } from 'config/constants';
import { ComponentWrapper, Footer } from './contactViaStyle';
import CustomizedContainer from 'components/Common/CustomizedContainer';
import Sms from './components/sms/smsComp';
import Whatsapp from './components/whatsapp/whatsappComp';
import FacebookButton from './components/facebook/facebookButton';
import SmsButton from './components/sms/smsButton';
import WhatsappButton from './components/whatsapp/whatsappButton';

/**
 * Contact Via component
 * @param style
 * @param showMode
 * @param onChangeShowMode
 * @param parentTarget
 * @returns {*}
 */
export default function ContactViaComp({
  style,
  showMode = CONTACT_VIA_SHOW_MODES.INITIAL,
  onChangeShowMode,
  parentTarget,
  contactOptions
} = {}) {
  const toggleMode = mode => onChangeShowMode(mode);

  // Only special for the component Speech Bubble
  if (parentTarget === 'speechBubble') {
    return (
      <ComponentWrapper style={style}>
        <SmsButton
          width="100%"
          onClick={() => toggleMode(CONTACT_VIA_SHOW_MODES.SMS)}
        />
      </ComponentWrapper>
    );
  }
  // Otherwise
  if (showMode === CONTACT_VIA_SHOW_MODES.INITIAL) {
    const numOptions = contactOptions ? Object.keys(contactOptions).length : 1;
    const width = `${100 / numOptions}%`;
    const isMulti = numOptions > 1;
    return (
      <ComponentWrapper style={style}>
        {contactOptions.sms ? (
          <SmsButton
            isMulti={isMulti}
            width={width}
            onClick={() => toggleMode(CONTACT_VIA_SHOW_MODES.SMS)}
          />
        ) : null}
        {contactOptions.whatsapp ? (
          <WhatsappButton
            isMulti={isMulti}
            width={width}
            onClick={() => toggleMode(CONTACT_VIA_SHOW_MODES.WHATSAPP)}
          />
        ) : null}
        {contactOptions.facebook ? (
          <FacebookButton isMulti={isMulti} width={width} />
        ) : null}
      </ComponentWrapper>
    );
  }

  if (
    showMode === CONTACT_VIA_SHOW_MODES.SMS ||
    showMode === CONTACT_VIA_SHOW_MODES.WHATSAPP
  ) {
    return (
      <ComponentWrapper style={style}>
        <CustomizedContainer style={{ width: '100%' }}>
          {showMode === CONTACT_VIA_SHOW_MODES.SMS ? (
            <Sms />
          ) : (
            <Whatsapp number={contactOptions.whatsapp} />
          )}
        </CustomizedContainer>
        <Footer
          width="100%"
          onClick={() => toggleMode(CONTACT_VIA_SHOW_MODES.INITIAL)}
          tabIndex={0}
          onKeyDown={({ key }) =>
            key === 'Enter' ? toggleMode(CONTACT_VIA_SHOW_MODES.INITIAL) : true
          }
        >
          Return to chat
        </Footer>
      </ComponentWrapper>
    );
  }
}

ContactViaComp.propTypes = {
  style: PropTypes.object,
  showMode: PropTypes.string,
  onChangeShowMode: PropTypes.func,
  parentTarget: PropTypes.string,
  contactOptions: PropTypes.object
};
