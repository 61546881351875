import { FB__CLOSE, FB__OPEN, FB__TRANSITION_CHANGE } from '../actionConstants';

export function openFb(payload = {}) {
  return dispatch => {
    dispatch({
      type: FB__OPEN,
      payload
    });
  };
}

export function closeFb(payload) {
  return dispatch => {
    dispatch({
      type: FB__CLOSE,
      payload
    });
  };
}

export function transitionChangeFb(payload = {}) {
  return dispatch => {
    dispatch({
      type: FB__TRANSITION_CHANGE,
      payload
    });
  };
}
