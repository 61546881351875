import {
  CONTACT_VIA__ACTIVATE,
  CONTACT_VIA__DEACTIVATE,
  CONTACT_VIA__SET_SHOW_MODE
} from '../actionConstants';

export function activateContactVia() {
  return dispatch => {
    dispatch({
      type: CONTACT_VIA__ACTIVATE
    });
  };
}

export function deactivateContactVia() {
  return dispatch => {
    dispatch({
      type: CONTACT_VIA__DEACTIVATE
    });
  };
}

export function setShowMode(showMode) {
  return dispatch => {
    dispatch({
      type: CONTACT_VIA__SET_SHOW_MODE,
      payload: { showMode }
    });
  };
}
