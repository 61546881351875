import { openCb } from '../ducks/chatBox/chatBox.action';
import { openSb, closeSb } from '../ducks/speechBubble/speechBubble.action';
import { openFb, closeFb } from '../ducks/floatingButton/floatingButton.action';
import { openCta, closeCta } from '../ducks/callToAction/callToAction.action';
import { openRs } from '../ducks/roomsSelector/roomsSelector.action';
import {
  sendMessage,
  setMbInitialMessageTimestamp,
  deleteFakeMessages,
  setSbOpenTimerDate,
  setCtaOpenTimerDate,
  sendCbInitialMessage,
  deleteMessagesWithText,
  initialiseQualificationProcess,
  updateRoom
} from '../ducks/global/rooms.action';
import {
  CB__OPEN,
  CB__CLOSE,
  FB__OPEN,
  FB__CLOSE,
  CTA__CLOSE,
  CB__TRANSITION_CHANGE,
  FB__TRANSITION_CHANGE,
  SB__OPEN,
  SB__CLOSE,
  SB__CLOSE_TIMER_ON,
  SB__CLOSE_TIMER_OFF,
  SB__OPEN_TIMER_ON,
  SB__OPEN_TIMER_OFF,
  SB__SEND_MESSAGE,
  RS__TRANSITION_CHANGE,
  RS__OPEN,
  ROOM__OPERATOR_OFFLINE,
  ROOM__OPERATOR_ONLINE,
  ROOM__OPERATOR_ASSIGNED_CHAT,
  ROOM__SAVE_SB_OPEN_TIMER_DATE,
  SB__TRANSITION_CHANGE,
  SMS__OPEN
} from '../ducks/actionConstants';
import {
  transitionStages,
  statementsInIntegers,
  messageStatuses,
  SB_OPEN_TIMEOUT,
  CTA_OPEN_TIMEOUT,
  MB_INITIAL_MESSAGE_TIMEOUT,
  CONTACT_VIA_SHOW_MODES
} from '../config/constants';
import { fbAdhoc, chatAdHoc } from 'utils/adhoc';
import { getCurrentTime, getProp } from 'utils/helpers';
import SETTING from '../helpers/mobile-desktop-settings';
import logger from '../utils/logger';
import { setShowMode } from '../ducks/contactVia/contactVia.action';

const DEFAULT_DELAY = 50;
const localEvents = [];
const {
  fakeMessageIsExists,
  operatorMessageIsExists,
  onlyFakeMessagedIsExists,
  clientMessageIsExists
} = chatAdHoc();

/**
 * Setting global timeout for frame elements.
 */
export default store => next => action => {
  const { type, payload = {} } = action;

  switch (type) {
    case CB__OPEN:
      cbOpenListener({ store, next, action, type });
      break;

    case CB__TRANSITION_CHANGE:
      if (payload.stage === transitionStages.EXITED) {
        cbTransitionExitedListener({ store, next, action });
      }
      next(action);
      break;

    case FB__CLOSE:
      fbCloseListener({ store, next, action });
      next(action);
      break;

    case FB__OPEN:
      fbOpenListener({ store, next, action });
      break;

    case FB__TRANSITION_CHANGE:
      if (payload.stage === transitionStages.EXITED) {
        fbTransitionExitedListener({ store, next, action });
      } else if (payload.stage === transitionStages.ENTERED) {
        fbTransitionEnteredListener({ store, next, action });
      }
      next(action);
      break;

    case CTA__CLOSE:
      ctaCloseListener({ store, action });
      next(action);
      break;

    case SB__OPEN:
      sbOpenListener({ store, action });
      next(action);
      break;

    case SMS__OPEN:
      cbOpenListener({ store, next, action, type });
      next(action);
      break;

    case SB__CLOSE:
      sbCloseListener({ store, action });
      next(action);
      break;

    case SB__CLOSE_TIMER_ON:
      sbInitCloseTimerListener({ store, action });
      next(action);
      break;

    case SB__CLOSE_TIMER_OFF:
      sbDeactivateCloseTimerListener({ store, action });
      next(action);
      break;

    case SB__OPEN_TIMER_OFF:
      sbDeactivateOpenTimerListener();
      next(action);
      break;

    case SB__OPEN_TIMER_ON:
      sbInitOpenTimerListener({ store, next, action });
      next(action);
      break;

    case SB__SEND_MESSAGE:
      sbSendMessageListener({ store, action });
      next(action);
      break;

    case SB__TRANSITION_CHANGE:
      if (payload.stage === transitionStages.EXITED) {
        sbTransitionExitedListener({ store, next, action });
      }
      next(action);
      break;

    case ROOM__OPERATOR_OFFLINE:
      rmOperatorOffline({ store, action });
      next(action);
      break;

    case ROOM__OPERATOR_ONLINE:
      rmOperatorOnline({ store, action });
      next(action);
      break;

    case RS__OPEN:
      rsOpenListener({ store, next, action });
      break;

    case RS__TRANSITION_CHANGE:
      if (payload.stage === transitionStages.EXITED) {
        rsTransitionExitedListener({ store, action });
      }
      next(action);
      break;

    case ROOM__OPERATOR_ASSIGNED_CHAT:
      checkCBInitialMessage({ store, action });
      next(action);
      break;

    case ROOM__SAVE_SB_OPEN_TIMER_DATE:
      roomSaveSbOpenTimerDateListener({ store, action });
      next(action);
      break;

    default:
      return next(action);
  }
};

/**
 * ChatBox: OPEN
 * @param store
 * @param next
 * @param action
 * @returns {*}
 * @private
 */
function cbOpenListener({ store, next, action, type }) {
  const { global, rooms, floatingButton, speechBubble } = store.getState();
  const activeRoomInfo =
    rooms.find(({ room }) => room === global.activeRoom) || {};
  const { getFBAvailability } = fbAdhoc();
  const fbIsAvailable = getFBAvailability({ store });

  if (type === SMS__OPEN) {
    store.dispatch(setShowMode(CONTACT_VIA_SHOW_MODES.SMS));
  } else {
    store.dispatch(setShowMode(CONTACT_VIA_SHOW_MODES.INITIAL));
  }

  clearTimeout(window.openCTATimeout);
  clearTimeout(window.openSBTimeoutShort);
  clearTimeout(window.openSBTimeoutLong);
  window.openCTATimeout = null;
  window.openSBTimeoutShort = null;
  window.openSBTimeoutLong = null;

  if (activeRoomInfo.operatorOnline) {
    cbOpenOperatorOnline({ store, action });
  }

  if (!fbIsAvailable) {
    return next(action);
  }

  // Open Chat Box only after Floating Button will be closed - if it's open
  if (floatingButton.open) {
    closeFBHelper({ store, action });
  } else {
    if (speechBubble.open) {
      eventsQueueGuard(SB__CLOSE, () =>
        store.dispatch(closeSb({ openCb: true }))
      );
    }

    return next(action);
  }
}

/**
 *
 * @param {object} store
 * @return void
 */
function cbOpenOperatorOnline({ store }) {
  const { global, rooms } = store.getState();
  const { settings, activeRoom } = global;
  const { mb_initial_message, mb_initial_message_enabled } = settings;
  const activeRoomInfo = rooms.find(({ room }) => room === activeRoom) || {};

  if (
    mb_initial_message &&
    mb_initial_message_enabled === 'on' &&
    !activeRoomInfo.unreadInitialMessage &&
    fakeMessageIsExists(activeRoomInfo.chatListMessages)
  ) {
    store.dispatch(setMbInitialMessageTimestamp(activeRoom));
  }
}

/**
 * ChatBox: transition state is exited
 * @param store
 * @param next
 * @param action
 * @returns {*}
 */
function cbTransitionExitedListener({ store, next, action }) {
  openFBHelper({ store, afterSB: false });
}

/**
 * ChatBox: transition state is exited
 * @param store
 * @param next
 * @param action
 * @returns {*}
 */
function sbTransitionExitedListener({ store, next, action }) {}

/**
 * FloatingButton: CLOSE
 * @param store
 * @param action
 * @returns {*}
 * @private
 */
function fbCloseListener({ store, action }) {
  closeCTAHelper({ store });

  const { payload = {} } = action;
  const { global, rooms, callToAction } = store.getState();
  const { activeRoom } = global;
  const activeRoomInfo = rooms.find(({ room }) => room === activeRoom) || {};
  const { eventSource } = payload;
  const isManualOrigin = Object.prototype.hasOwnProperty.call(
    payload,
    'target'
  );
  const hasOperatorMessages = operatorMessageIsExists(
    activeRoomInfo.chatListMessages
  );
  const hasClientMessages = clientMessageIsExists(
    activeRoomInfo.chatListMessages
  );

  if (
    isManualOrigin ||
    (eventSource && /^openMBTimeout/.test(eventSource)) ||
    !activeRoomInfo.operatorOnline ||
    hasOperatorMessages ||
    hasClientMessages
  ) {
    return;
  }

  if (!callToAction.open) {
    openSBHelper({ store });
  }
}

/**
 * FloatingButton: OPEN
 * @param store
 * @param next
 * @param action
 * @returns {*}
 * @private
 */
function fbOpenListener({ store, next, action }) {
  const { roomsSelector } = store.getState();
  if (roomsSelector.open) {
    return;
  }
  next(action);
}

/**
 * RoomsSelector: OPEN
 * @param store
 * @param next
 * @param action
 * @returns {*}
 * @private
 */
function rsOpenListener({ store, next, action }) {
  const { chatBox } = store.getState();

  if (chatBox.open) {
    eventsQueueGuard(CB__CLOSE, () => next(action));
  } else {
    next(action);
  }
}

/**
 * FloatingButton: transition state is exited
 * @param store
 * @param next
 * @param action
 * @returns {*}
 */
function fbTransitionExitedListener({ store, next, action }) {
  const { speechBubble, roomsSelector } = store.getState();

  if (roomsSelector.active) {
    openRSHelper({ store });
  } else {
    if (!speechBubble.open) {
      openCBHelper({ store });
    }
  }
}

function fbTransitionEnteredListener({ store, next, action }) {
  openCTAHelper({ store, action });
}

/**
 * CallToAction: CLOSE
 * @param store
 * @param action
 * @returns {*}
 * @private
 */
function ctaCloseListener({ store, action }) {
  const { global } = store.getState();
  const { activeRoom } = global;
  const nextTime = Date.now() + CTA_OPEN_TIMEOUT;
  logger.debug(`ctaTimeout: ${CTA_OPEN_TIMEOUT}, at time ${nextTime}`);
  store.dispatch(
    setCtaOpenTimerDate({
      room: activeRoom,
      ctaNextOpenDate: nextTime
    })
  );

  if (!getProp(action, 'payload.hideOnly')) {
    closeFBHelper({ store, action });
  }
}

/**
 * SpeechBubble: OPEN
 * @param store
 * @param action
 * @returns {*}
 * @private
 */
function sbOpenListener({ store, action }) {
  sbInitCloseTimerListener({ store, action });
}

/**
 * SpeechBubble: CLOSE
 * @param store
 * @param action
 * @returns {*}
 * @private
 */
function sbCloseListener({ store, action }) {
  const { payload } = action;
  const { global } = store.getState();
  const { activeRoom } = global;

  store.dispatch(
    setSbOpenTimerDate({
      room: activeRoom,
      sbNextOpenDate: Date.now() + SB_OPEN_TIMEOUT
    })
  );

  if (payload && payload.openCb) {
    openCBHelper({ store });
  } else {
    openFBHelper({ store, afterSB: true });
  }
}

/**
 * SpeechBubble: SB__CLOSE_TIMER_ON
 * @param store
 * @param action
 * @returns {*}
 * @private
 */
function sbInitCloseTimerListener({ store, action }) {
  const { global } = store.getState();
  const { settings } = global;
  const delay = +SETTING('SB2_DISAPPEAR_DELAY', settings);

  function fadeOutHandler(force) {
    const { speechBubble } = store.getState();
    if (!force && !window.closeSBTimeout) return;

    if (
      !speechBubble.open ||
      speechBubble.active !== statementsInIntegers.ENABLED
    )
      return;
    eventsQueueGuard(SB__CLOSE, () => store.dispatch(closeSb()));
  }

  if (delay !== 0) {
    clearTimeout(window.closeSBTimeout);
    window.closeSBTimeout = setTimeout(fadeOutHandler, 1000 * delay);
  }

  if (window.openCTATimeout) clearTimeout(window.openCTATimeout);
  window.openCTATimeout = null;
}

function checkCBInitialMessage({ store, action }) {
  const { connect } = window.vcConfigurator;
  const { global, rooms = [] } = store.getState();
  const { room, operatorIsAssigned = false, lastMessageSocketId } =
    action.payload || {};
  const { settings } = global;
  const activeRoomInfo = rooms.find(r => r.room === room) || {};
  const { cbInitialMessageIsSent, chatListMessages = [] } = activeRoomInfo;
  const isCBMessage =
    settings.cb_initial_message_enabled === 'on' && settings.cb_initial_message;
  const hasOperatorMessages = operatorMessageIsExists(chatListMessages);
  const hasFakeMessages = fakeMessageIsExists(chatListMessages);
  const hasSameSocketId = connect.socket.id === lastMessageSocketId;
  const isShouldSendCBFirstMessage = !!(
    room &&
    operatorIsAssigned &&
    isCBMessage &&
    !cbInitialMessageIsSent &&
    !hasOperatorMessages &&
    !hasFakeMessages &&
    hasSameSocketId
  );

  if (!isShouldSendCBFirstMessage) return;

  connect._sendMessage(settings.cb_initial_message, true);
  store.dispatch(sendCbInitialMessage({ room }));
}

/**
 * SpeechBubble: SB__CLOSE_TIMER_OFF
 * @param store
 * @param action
 * @returns {*}
 * @private
 */

function sbDeactivateCloseTimerListener({ store, action }) {
  clearTimeout(window.closeSBTimeout);
  window.closeSBTimeout = null;
}

/**
 * SpeechBubble: run the initialization timer
 * @param store
 * @param action
 * @returns {*}
 * @private
 */
function sbInitOpenTimerListener({ store, action }) {
  const { global, rooms } = store.getState();
  const { settings, activeRoom } = global;
  const delay = +SETTING('SB2_APPEAR_DELAY', settings);
  const currentRoom = rooms.find(({ room }) => room === activeRoom) || {};
  const { sbNextOpenDate } = currentRoom;

  // Reset timers
  sbDeactivateOpenTimerListener();

  // Set the short timer
  if (delay > 0) {
    window.openSBTimeoutShort = setTimeout(
      () => sbShortTimeFadeInHandler(store),
      1000 * delay
    );
  }

  // Set the long timer
  if (typeof sbNextOpenDate !== 'number') {
    store.dispatch(
      setSbOpenTimerDate({
        room: activeRoom,
        sbNextOpenDate: Date.now() + SB_OPEN_TIMEOUT
      })
    );
  } else {
    window.openSBTimeoutLong = setTimeout(
      () => sbLongTimeFadeInHandler(store),
      sbNextOpenDate - Date.now()
    );
  }
}

/**
 * Speech Bubble: Short time handler
 * @param store
 * @param force
 */
function sbShortTimeFadeInHandler(store, force) {
  const { chatBox, speechBubble, global, rooms } = store.getState();

  if (
    chatBox.open ||
    chatBox.onceOpenedInSession ||
    speechBubble.onceOpened ||
    (!force && !window.openSBTimeoutShort)
  ) {
    return;
  }

  const { activeRoom } = global;
  const currentRoom = rooms.find(({ room }) => room === activeRoom) || {};
  const { operatorOnline } = currentRoom;

  if (
    !speechBubble.open &&
    speechBubble.active === statementsInIntegers.ENABLED
  ) {
    const activeRoomInfo = rooms.find(r => r.room === activeRoom) || {};
    const onlyFakeMessages = onlyFakeMessagedIsExists(
      activeRoomInfo.chatListMessages
    );

    if (
      operatorOnline &&
      (onlyFakeMessages || activeRoomInfo.chatListMessages.length === 0)
    ) {
      // Speech Bubble should be open only if chat hasn't any messages
      store.dispatch(closeFb());
    }
  }
}

/**
 * Speech Bubble: Long time handler
 * @param store
 * @param force
 */
function sbLongTimeFadeInHandler(store, force) {
  clearTimeout(window.openSBTimeoutShort);
  window.openSBTimeoutShort = null;

  const { chatBox, speechBubble, global, rooms } = store.getState();
  const { activeRoom } = global;
  const currentRoom = rooms.find(({ room }) => room === activeRoom) || {};
  const { operatorOnline } = currentRoom;

  if (
    chatBox.open ||
    chatBox.onceOpenedInSession ||
    (!force && !window.openSBTimeoutLong)
  ) {
    return;
  }

  if (
    !speechBubble.open &&
    speechBubble.active === statementsInIntegers.ENABLED
  ) {
    const activeRoomInfo = rooms.find(r => r.room === activeRoom) || {};
    const onlyFakeMessages = onlyFakeMessagedIsExists(
      activeRoomInfo.chatListMessages
    );

    if (
      operatorOnline &&
      (onlyFakeMessages || activeRoomInfo.chatListMessages.length === 0)
    ) {
      // Speech Bubble should be open only if chat hasn't any messages
      store.dispatch(closeFb());
    }
  }
}

/**
 * SpeechBubble: stop the initialization timer
 * @returns {*}
 * @private
 */

function sbDeactivateOpenTimerListener() {
  clearTimeout(window.openSBTimeoutShort);
  clearTimeout(window.openSBTimeoutLong);
  window.openSBTimeoutShort = null;
  window.openSBTimeoutLong = null;
}

/**
 * On change the open timer for Speech Bubble
 * @param store
 * @param action
 */
function roomSaveSbOpenTimerDateListener({ store, action }) {
  const sbNextOpenDate = getProp(
    action,
    'payload.sbNextOpenDate',
    Date.now() + SB_OPEN_TIMEOUT
  );
  clearTimeout(window.openSBTimeoutLong);
  window.openSBTimeoutLong = null;
  window.openSBTimeoutLong = setTimeout(
    () => sbLongTimeFadeInHandler(store),
    sbNextOpenDate - Date.now()
  );
}

/**
 * SpeechBubble: SB__SEND_MESSAGE
 * @param store
 * @param next
 * @param action
 * @returns {*}
 * @private
 */
function sbSendMessageListener({ store, next, action }) {
  store.dispatch(openCb());
}

/**
 * RoomsSelector: transition state is exited
 * @param store
 * @param action
 * @returns {*}
 */
function rsTransitionExitedListener({ store, action }) {
  const { global } = store.getState();
  const { chosenWebSettingsIndex } = global;

  if (chosenWebSettingsIndex !== null) {
    openCBHelper({ store });
  } else {
    openFBHelper({ store, afterSB: false });
  }
}

/**
 * Check availability and open Chat Box
 * @param store
 */
function openCBHelper({ store }) {
  const { chatBox } = store.getState();
  if (chatBox.active && !chatBox.open) {
    eventsQueueGuard(CB__OPEN, () => store.dispatch(openCb()));
  }
}

/**
 * Check availability and open Floating Button
 * @param store
 * @param {boolean} afterSB
 */
function openFBHelper({ store, afterSB }) {
  const { floatingButton, chatBox } = store.getState();
  const { getFBAvailability } = fbAdhoc();
  const fbIsAvailable = getFBAvailability({ store });

  if (fbIsAvailable && !floatingButton.open && !chatBox.open) {
    if (window.openCTATimeout) clearTimeout(window.openCTATimeout);
    window.openCTATimeout = null;
    eventsQueueGuard(FB__OPEN, () => store.dispatch(openFb({ afterSB })));
  }
}

/**
 * Check availability and close Floating Button
 * @param store
 * @param action
 */
function closeFBHelper({ store, action }) {
  const { floatingButton } = store.getState();

  if (floatingButton.open) {
    eventsQueueGuard(FB__CLOSE, () =>
      store.dispatch(closeFb(getProp(action, 'payload')))
    );
  }
}

/**
 * Check availability and open Call To Action
 * @param store
 */
function openCTAHelper({ store }) {
  const { global, chatBox, callToAction, rooms } = store.getState();
  const { settings, activeRoom } = global;
  const slideOnDelay = +getProp(settings, 'sb_delay', '0');
  const delay = slideOnDelay > 0 ? slideOnDelay * 1000 : 0;
  const { getFBAvailability } = fbAdhoc();
  const fbIsAvailable = getFBAvailability({ store });
  const currentRoom = rooms.find(({ room }) => room === activeRoom) || {};
  const { ctaNextOpenDate } = currentRoom;
  logger.debug(`In room: ${JSON.stringify(currentRoom)}`);
  logger.debug(`${ctaNextOpenDate}: ${Date.now()}`);
  if (
    (Date.now() < ctaNextOpenDate && !callToAction.showPermanently) ||
    !fbIsAvailable ||
    !callToAction.active ||
    (chatBox.onceOpened && !callToAction.showPermanently)
  )
    return;

  function fadeInHandler(force) {
    const storeSnapshot = store.getState();
    if (
      (!force && !window.openCTATimeout) ||
      storeSnapshot.callToAction.open ||
      storeSnapshot.speechBubble.open ||
      storeSnapshot.roomsSelector.open
    )
      return;
    eventsQueueGuard(CTA__CLOSE, () => store.dispatch(openCta()));
  }

  if (delay === 0) {
    fadeInHandler(true);
  } else {
    window.openCTATimeout = setTimeout(fadeInHandler, delay);
  }
}

/**
 * Check availability and close Call To Action
 * @param store
 */
function closeCTAHelper({ store }) {
  const { callToAction } = store.getState();
  if (window.openCTATimeout) clearTimeout(window.openCTATimeout);
  window.openCTATimeout = null;
  if (callToAction.open) {
    eventsQueueGuard(CTA__CLOSE, () => store.dispatch(closeCta()));
  }
}

/**
 * Check availability and open Speech Bubble
 * @param store
 */
function openSBHelper({ store }) {
  const { speechBubble } = store.getState();
  if (
    speechBubble.active === statementsInIntegers.ENABLED &&
    !speechBubble.open
  ) {
    if (window.openCTATimeout) clearTimeout(window.openCTATimeout);
    window.openCTATimeout = null;
    eventsQueueGuard(SB__OPEN, () => store.dispatch(openSb()));
  }
}

/**
 * Check availability and open Rooms Selector
 * @param store
 */
function openRSHelper({ store }) {
  const { roomsSelector } = store.getState();
  if (roomsSelector.active && !roomsSelector.open) {
    eventsQueueGuard(RS__OPEN, () => store.dispatch(openRs()));
  }
}

/**
 * Handler what helps solve problem with async repeats of actions
 * @param eventName
 * @param callback
 */
function eventsQueueGuard(eventName, callback) {
  const eventIndex = localEvents.indexOf(eventName);
  if (eventIndex === -1) {
    localEvents.push(eventName);
    if (typeof callback === 'function') {
      callback({ eventName });
    }
    setTimeout(() => localEvents.splice(eventIndex, 1), DEFAULT_DELAY);
  }
}

/**
 * Close SB when operator gets offline
 * @param store
 * @param action
 */

function rmOperatorOffline({ store, action }) {
  const { speechBubble } = store.getState();
  if (
    speechBubble.active === statementsInIntegers.ENABLED &&
    speechBubble.open
  ) {
    eventsQueueGuard(SB__CLOSE, () => store.dispatch(closeSb()));
  }
  deleteFakeMessagesHandler({ store, action });
}

function deleteFakeMessagesHandler({ store, action }) {
  const {
    rooms,
    global: { activeRoom }
  } = store.getState();
  const { payload: { room } = {} } = action;
  const currentRoom = room || activeRoom;
  const roomInfo = rooms.find(r => r.room === currentRoom);

  store.dispatch(deleteFakeMessages(roomInfo.room));
}

function rmOperatorOnline({ store, action }) {
  const { payload: { room } = {} } = action;
  const { chatBox, global, rooms } = store.getState();
  const { settings, activeRoom } = global;
  const { qualification_questions } = settings;
  const activeRoomInfo = rooms.find(({ room }) => room === activeRoom) || {};

  // Determine if we have any qualification questions to answer.
  // And there are no previous messages (i.e. not already an active chat).
  if (
    !activeRoomInfo.chatListMessages.length &&
    qualification_questions.length &&
    !activeRoomInfo.qualificationProcess.active &&
    !activeRoomInfo.qualificationProcess.completed
  ) {
    // Get the available team endpoints.
    const teams = {};
    qualification_questions.forEach(question => {
      question.answers.forEach(answer => {
        if (
          answer.account_team_id !== null &&
          teams[answer.account_team_id] === undefined
        ) {
          teams[answer.account_team_id] = {
            id: answer.account_team_id,
            online: null
          };
        }
      });
    });
    store.dispatch(
      initialiseQualificationProcess(activeRoom, Object.values(teams))
    );
  } else {
    mbInitialMessageHandler({ store, room });
  }

  if (chatBox.open) {
    cbOpenOperatorOnline({ store, action });
  }
}

/**
 * Initial message from Message Box
 * @param {object} param
 * @param {object} param.store
 * @param {string} param.room
 */
function mbInitialMessageHandler({
  store,
  room,
  qualificationCompleted = false
}) {
  const { global } = store.getState();
  const { settings, activeRoom } = global;

  if (activeRoom !== room) return;

  const { rooms } = store.getState();
  const roomInfo = rooms.find(r => r.room === room);
  const delay = +getProp(settings, 'mb_initial_message_delay', '0');
  const mbInitialMessageEnabled = settings.mb_initial_message_enabled === 'on';
  const mbInitialMessage = settings.mb_initial_message;
  let { mbInitialMessageTimestamp } = roomInfo;

  if (!mbInitialMessageEnabled || !mbInitialMessage) return;

  // Check if we are in the qualification question process.
  if (
    !qualificationCompleted &&
    settings.qualification_questions.length &&
    !roomInfo.qualificationProcess.completed
  ) {
    return;
  }

  // Short timer handler
  function shortTimeHandler() {
    const { rooms } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);

    if (roomInfo.chatListMessages.length > 0) return;
    if (!roomInfo.operatorOnline) return;

    store.dispatch(
      sendMessage(
        roomInfo.room,
        {
          author: 'operator',
          date: getCurrentTime(),
          text: mbInitialMessage,
          status: messageStatuses.MSG_STATUS_SENT,
          isSystem: true
        },
        { isFake: true }
      )
    );
    store.dispatch(updateRoom(room, { mbInitialMessage }));
  }

  // Long timer handler
  function longTimeHandler() {
    store.dispatch(deleteMessagesWithText(room, mbInitialMessage));

    setTimeout(shortTimeHandler, 200);
  }

  if (delay > 0 && !window.mbInitialMessageTimeoutShort) {
    window.mbInitialMessageTimeoutShort = setTimeout(
      shortTimeHandler,
      1000 * delay
    );
  }

  if (
    !mbInitialMessageTimestamp ||
    (mbInitialMessageTimestamp && mbInitialMessageTimestamp < Date.now())
  ) {
    mbInitialMessageTimestamp = Date.now() + MB_INITIAL_MESSAGE_TIMEOUT;
    store.dispatch(setMbInitialMessageTimestamp(activeRoom));
  }

  if (
    !window.mbInitialMessageTimeoutLong &&
    mbInitialMessageTimestamp - Date.now() > 0
  ) {
    window.mbInitialMessageTimeoutLong = setTimeout(
      longTimeHandler,
      mbInitialMessageTimestamp - Date.now()
    );
  }
}
