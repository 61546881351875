import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import setInitTimeoutMiddleware from './middlewares/setInitTimeoutMiddleware';
import preloadsMiddleware from './middlewares/preloadsMiddleware';
import unreadMessageMiddleware from './middlewares/unreadMessageMiddleware';
import toggleMiddleware from './middlewares/toggleMiddleware';
import globalEventsTriggerMiddleware from './middlewares/globalEventsTriggerMiddleware';
import debuggerMiddleware from './middlewares/debuggerMiddleware';
import uiLockMiddleware from './middlewares/uiLockMiddleware';
import { loadStore } from './utils/storage';
import { useDebugger } from './utils/helpers';

const initialState = loadStore();
const enhancers = [];
const middleware = [
  thunk,
  setInitTimeoutMiddleware,
  preloadsMiddleware,
  unreadMessageMiddleware,
  uiLockMiddleware,
  toggleMiddleware,
  globalEventsTriggerMiddleware
];

if (process.env.NODE_ENV !== 'production') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

if (useDebugger()) {
  middleware.push(debuggerMiddleware);
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

export default createStore(rootReducer, initialState, composedEnhancers);
