import mobileDevice from 'ismobilejs';
import { IS_MOBILE_WIDTH } from '../config/constants';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;

// Specialized utils functions for `chat` essence
function chatAdHoc() {
  /**
   *
   * @param messages
   * @returns {boolean}
   */
  function clientMessageIsExists(messages = []) {
    return messages.some(
      message => message._isClientOwner || message.author === 'visitor'
    );
  }

  /**
   *
   * @param messages
   * @returns {boolean}
   */
  function serverMessageIsExists(messages = []) {
    return messages.some(message => message._isServerOwner);
  }

  /**
   *
   * @param messages
   * @returns {boolean}
   */
  function operatorMessageIsExists(messages = []) {
    return messages.some(
      message =>
        (message.operatorId || message.author === 'operator') && !message.isFake
    );
  }

  /**
   *
   * @param messages
   * @returns {boolean}
   */
  function fakeMessageIsExists(messages = []) {
    return messages.some(message => message.isFake);
  }

  /**
   *
   * @param messages
   * @returns {boolean}
   */
  function onlyFakeMessagedIsExists(messages = []) {
    return messages.every(message => message.isFake);
  }

  return {
    clientMessageIsExists,
    serverMessageIsExists,
    fakeMessageIsExists,
    operatorMessageIsExists,
    onlyFakeMessagedIsExists
  };
}

// Specialized utils functions for `Floating Button` essence
function fbAdhoc() {
  /**
   *
   * @param props
   * @returns {boolean}
   */
  function getFBAvailability(props = {}) {
    const { store, global, rooms } = props;

    if (global && rooms) {
      const { settings } = global;
      const activeRoomInfo =
        rooms.find(({ room }) => room === global.activeRoom) || {};
      const { operatorOnline } = activeRoomInfo;
      const fbKeyPlatform = isPhone ? '_mobile' : '';
      const fbKeyStatus = operatorOnline ? '_online' : '_offline';
      return settings[`fb${fbKeyPlatform}${fbKeyStatus}_button`] === 'on';
    } else if (typeof store.getState === 'function') {
      const { global, rooms } = store.getState();
      const { settings } = global;
      const activeRoomInfo =
        rooms.find(({ room }) => room === global.activeRoom) || {};
      const { operatorOnline } = activeRoomInfo;
      const fbKeyPlatform = isPhone ? '_mobile' : '';
      const fbKeyStatus = operatorOnline ? '_online' : '_offline';
      return settings[`fb${fbKeyPlatform}${fbKeyStatus}_button`] === 'on';
    } else {
      return false;
    }
  }

  /**
   * Get available length of the unread messages
   * @param {object} roomInfo - room data
   * @returns {number}
   */
  function getUnreadMessagesLength(roomInfo = {}) {
    const { chatListMessages = [], lastReadMessagesLength = 0 } = roomInfo;
    let unreadMessagesLength = 0;

    if (lastReadMessagesLength > 0 && chatListMessages.length > 0) {
      unreadMessagesLength = chatListMessages.length - lastReadMessagesLength;

      if (unreadMessagesLength < 0) {
        unreadMessagesLength = 0;
      }
    } else if (chatListMessages.length > 0) {
      unreadMessagesLength = chatListMessages.length;
    }

    return unreadMessagesLength;
  }

  return {
    getFBAvailability,
    getUnreadMessagesLength
  };
}

export { chatAdHoc, fbAdhoc };
