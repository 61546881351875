import {
  FB__TRANSITION_CHANGE,
  CB__TRANSITION_CHANGE,
  CTA__TRANSITION_CHANGE,
  SB__TRANSITION_CHANGE
} from '../ducks/actionConstants';
import { transitionStages } from '../config/constants';
import { uiLock, uiUnlock } from '../ducks/global/app.action';

export default store => next => action => {
  switch (action.type) {
    case FB__TRANSITION_CHANGE:
    case CB__TRANSITION_CHANGE:
    case CTA__TRANSITION_CHANGE:
    case SB__TRANSITION_CHANGE:
      ongoingAnimationHandler(action, store);
      return next(action);

    default:
      return next(action);
  }
};

/**
 * Check components for animation
 * @param action
 * @param store
 */
function ongoingAnimationHandler(action, store) {
  const { global } = store.getState();
  const actionWithEndedAnimation =
    action.payload.stage === transitionStages.ENTERED ||
    action.payload.stage === transitionStages.EXITED;

  if (!actionWithEndedAnimation) {
    if (!global.uiLocked) {
      store.dispatch(uiLock());
    }
  } else {
    store.dispatch(uiUnlock());
  }
}
