import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import logger from '../../../utils/logger';
import { FrameWrapper, ButtonImage } from './contentWrapper';
import SETTING from 'helpers/mobile-desktop-settings';
import { gradientToString } from 'utils/helpers';

/**
 *
 * @param props
 */
function getFBImg(props = {}) {
  const { settings = {} } = props;
  const data = {};
  const backgroundImg = SETTING('SB2_FB_SVG_COLOR', settings);
  const backgroundColor = (() => {
    let colorFrom;
    let colorTo;
    if (SETTING('SB2_MOBILE_FB_CB_COLOR', settings) === 'on') {
      colorFrom = settings.cb_color_from;
      colorTo = settings.cb_color_to;
    } else {
      colorFrom = SETTING('SB2_FB_CB_COLOR_FROM', settings);
      colorTo = SETTING('SB2_FB_CB_COLOR_TO', settings);
    }

    if (!colorFrom || !colorTo) {
      return false;
    }

    return gradientToString([colorFrom, colorTo], 'top');
  })();
  const borderColor = SETTING('SB2_FB_BORDER_COLOR', settings);

  data.imgSrc =
    (() => {
      if (SETTING('SB2_FB_STANDARD_BUTTON', settings) === 'on') {
        return (
          SETTING('SB2_FB_STANDARD_BUTTON_MODIFIED', settings) ||
          SETTING('SB2_FB_STANDARD_BUTTON_TYPE', settings)
        );
      } else {
        return SETTING('SB2_FB_CUSTOM_BUTTON', settings);
      }
    })() || '';

  if (backgroundColor) {
    data.backgroundColor = `background: ${backgroundColor}`;
  }

  if (backgroundImg) {
    data.backgroundImg = `background: ${backgroundImg};`;
  }

  data.borderColor = borderColor ? `border: 2px solid ${borderColor}` : null;

  logger.debug(`Setting fbImage settings as: ${JSON.stringify(data)}`);
  return data;
}

const FloatingButtonComm = ({ settings, closeSb }) => {
  const fbImgStyle = getFBImg({ settings });

  return (
    <Fragment>
      <FrameWrapper
        onClick={() => closeSb({ openCb: true })}
        tabIndex={0}
        onKeyDown={({ key }) =>
          key === 'Enter' ? closeSb({ openCb: true }) : true
        }
      >
        <ButtonImage {...fbImgStyle} src={fbImgStyle.imgSrc} alt="" />
      </FrameWrapper>
    </Fragment>
  );
};

FloatingButtonComm.propTypes = {
  closeSb: PropTypes.func,
  operatorOnline: PropTypes.bool,
  settings: PropTypes.object
};

export { FloatingButtonComm };
