import React, { Fragment } from 'react';
//
import {
  Header,
  Operator,
  OperatorAvatar,
  OperatorName,
  Title,
  CloseBtn,
  Message,
  ImageWrapper,
  TitleBlock,
  TitleBlockInner,
  HeaderImg,
  MessageIconWrapper,
  BackBtn,
  HeaderWrapper,
  RoomName,
  GoBackButton
} from './headerStyle';
import { gradientToString, getImgOrSvg, getProp } from 'utils/helpers';
import { BuiltInIcon } from 'common/svg';
import store from '../../../../store';
import { setIsShowingLam } from '../../../../ducks/chatBox/chatBox.action';

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{"background-image": string, background: string}}
   */
  getBackgroundColor() {
    const { settings } = this;
    if (
      !(
        settings.cb_title_background_color_from &&
        settings.cb_title_background_color_to
      )
    )
      return;
    const cbColorV = gradientToString(
      [
        settings.cb_title_background_color_to,
        settings.cb_title_background_color_from
      ],
      'top'
    );
    return { 'background-image': cbColorV, background: cbColorV };
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  getBackgroundImage(props) {
    const { operatorOnline } = props;
    const { settings } = this;
    return {
      backgroundImage: `url(${
        operatorOnline
          ? settings.cb_header_image
          : settings.cb_header_offline_image
      })`
    };
  }

  /**
   *
   * @returns {*}
   */
  getTheme() {
    const { settings = {} } = this;
    return settings.Theme;
  }
}

/**
 *
 * @param props
 * @param backwardElement - additional element for rendering into `operator block`
 * @returns {*}
 */
const getOperatorBlock = (props = {}, backwardElement) => {
  const { settings = {}, operatorInfo, operatorIsAssigned } = props;
  const operator = operatorIsAssigned ? operatorInfo || {} : {};
  const opName = operator.userName || operator.name || '';

  if (
    !operatorIsAssigned ||
    getProp(settings, 'cb_operator_icon_hide', 'off') === 'on'
  ) {
    return <Operator>{backwardElement}</Operator>;
  }

  return (
    <Operator>
      <OperatorAvatar src={operator.avatar} noDefault={false} />
      {opName ? (
        <OperatorName
          className="text-ellipsis"
          style={{ color: settings.cb_title_text_color }}
        >
          {opName}
        </OperatorName>
      ) : null}
    </Operator>
  );
};

/**
 *
 * @param props
 * @returns {*}
 */
const getHeaderBody = (props = {}) => {
  const { settings = {}, operatorOnline } = props;
  const Styles = new StylesConstructor({ settings });
  const headerTitleText = settings.header_text;
  const titleStyle = {
    color: settings.header_text_color
  };
  const headerStyle = {
    ...Styles.getBackgroundImage({ operatorOnline })
  };
  return (
    <ImageWrapper>
      {headerTitleText && <Title style={titleStyle}>{headerTitleText}</Title>}
      <HeaderImg style={headerStyle} />
    </ImageWrapper>
  );
};

/**
 *
 * @param operatorOnline
 * @param chatListMessages
 * @param settings
 * @param dealerName
 * @param advertName
 * @param timer
 * @param icon
 * @returns {*}
 */
const getMessageText = (
  { operatorOnline, chatListMessages, settings, dealerName, advertName, timer },
  icon = null
) => {
  const dealerNameAdvertNameShowEnabled =
    getProp(settings, 'mb_dealer_name_show', 'off') === 'on';
  let msgDefault = null;
  let msgDealer = null;

  if (operatorOnline) {
    if (dealerNameAdvertNameShowEnabled && (dealerName || advertName)) {
      msgDealer = (
        <Fragment>
          {dealerName && <p className="dealername">{dealerName}</p>}
          {icon}
          {advertName && <p className="advertname">{advertName}</p>}
        </Fragment>
      );
    }
    if (
      settings.mb_initial_message_enabled !== 'on' &&
      chatListMessages.length === 0 &&
      settings.mb_title
    ) {
      msgDefault = (
        <p>{getProp(settings, 'mb_title', '').replace(/\\n/g, '<br>')}</p>
      );
    }
  } else if (settings.offline_text) {
    msgDefault = <p>{settings.offline_text}</p>;
  }

  if (timer && settings.eoc_text) {
    msgDefault = <p>{settings.eoc_text}</p>;
  }

  if (!msgDealer && !msgDefault) {
    return null;
  }

  return (
    <Fragment>
      {msgDefault}
      {msgDealer}
    </Fragment>
  );
};

const getMessageIcon = ({ settings }) => {
  const messageIcon = ((src, type, options = {}) => {
    const { color } = options;
    if (type === 'built-in') {
      return <BuiltInIcon color={color} />;
    } else if (!src || src.length === 0) {
      return null;
    }
    return getImgOrSvg(src);
  })(settings.cb_location_icon, settings.cb_location_icon_type, {
    color: settings.cb_title_text_color
  });

  if (!messageIcon) {
    return null;
  }

  return (
    <MessageIconWrapper color={settings.cb_title_text_color}>
      {messageIcon}
    </MessageIconWrapper>
  );
};

/**
 * Header room name
 * @param chosenWebSettingsIndex
 * @param webSettings
 * @returns {*}
 */
const getHeaderRoomName = ({ chosenWebSettingsIndex, webSettings } = {}) => {
  if (typeof chosenWebSettingsIndex !== 'number') {
    return null;
  }
  const name = (webSettings[chosenWebSettingsIndex] || {}).name;
  if (!name) {
    return null;
  }
  return <RoomName>Room: {name}</RoomName>;
};

/**
 * Header message block
 * @param showMessage
 * @param messageText
 * @param headerTextProps
 * @returns {*}
 */
const getHeaderMessage = ({
  showMessage,
  messageText,
  headerTextProps
} = {}) => {
  if (!showMessage || !messageText) {
    return;
  }

  return <Message {...headerTextProps}>{messageText}</Message>;
};

/**
 *
 * @param props
 * @returns {*}
 */
const index = (props = {}) => {
  const {
    settings,
    operatorInfo,
    operatorOnline,
    closeCb,
    waitingOperator,
    timer,
    dealerName,
    advertName,
    chosenWebSettingsIndex,
    chatListMessages = [],
    webSettings = [],
    onBack,
    isShowingLam,
    resetOnBack
  } = props;
  const Styles = new StylesConstructor({ settings });
  const textColor = settings.header_text_color;
  const headerStyle = {
    _backgroundColor: Styles.getBackgroundColor(),
    theme: Styles.getTheme()
  };
  const backBtnProps = {
    onClick: onBack,
    webSettings,
    color: textColor
  };
  const closeBtnProps = {
    onClick: closeCb,
    active: getProp(settings, 'cb_close_icon_hide', 'off') !== 'on',
    _width: 19,
    _color: textColor
  };
  const headerTextProps = {
    _color: settings.cb_title_text_color
  };
  const icon = getMessageIcon({ settings });
  const messageText = getMessageText(
    {
      operatorOnline,
      chatListMessages,
      settings,
      dealerName,
      advertName,
      timer
    },
    icon
  );
  const dealerNameAdvertNameShowEnabled =
    getProp(settings, 'mb_dealer_name_show', 'off') === 'on';
  const showMessage = (() => {
    let statement = false;
    if (!dealerNameAdvertNameShowEnabled) {
      statement = !waitingOperator && !operatorInfo;
    }
    if (messageText) {
      statement = true;
    }
    return statement;
  })();

  const hideLamHandler = () => {
    store.dispatch(setIsShowingLam(false));
    if (resetOnBack && typeof resetOnBack === 'function') {
      resetOnBack();
    }
  };
  const hideLamOnKeyDown = ({ key }) =>
    key === 'Enter' ? hideLamHandler() : true;

  return (
    <Header {...headerStyle}>
      <HeaderWrapper>
        <TitleBlock>
          <TitleBlockInner>
            <GoBackButton
              show={isShowingLam}
              onClickHandler={hideLamHandler}
              onKeyDown={hideLamOnKeyDown}
              color={textColor}
            />
            {!isShowingLam &&
              getOperatorBlock(props, <BackBtn {...backBtnProps} />)}
            {getHeaderBody(props)}
            <CloseBtn {...closeBtnProps} />
          </TitleBlockInner>
        </TitleBlock>
        {getHeaderRoomName({ chosenWebSettingsIndex, webSettings })}
        {getHeaderMessage({ showMessage, messageText, headerTextProps })}
      </HeaderWrapper>
    </Header>
  );
};

export default index;
