import {
  RS__ACTIVATE,
  RS__DEACTIVATE,
  RS__OPEN,
  RS__CLOSE,
  RS__TRANSITION_CHANGE,
  SYSTEM__CLEAR
} from '../actionConstants';

const updateState = function(state = {}, newState = {}) {
  return Object.assign({}, state, newState);
};

const init = {
  active: false,
  open: false,
  stage: null
};

export default function(state = init, action) {
  switch (action.type) {
    case RS__ACTIVATE:
      return updateState(state, {
        active: true
      });

    case RS__DEACTIVATE:
      return updateState(state, init);

    case RS__OPEN:
      if (state.active) {
        return updateState(state, {
          open: true
        });
      }
      return state;

    case RS__CLOSE:
      return updateState(state, {
        open: false
      });

    case RS__TRANSITION_CHANGE:
      return updateState(state, {
        stage: action.payload.stage
      });

    case SYSTEM__CLEAR:
      return updateState(state, init);

    default:
      return state;
  }
}
