import styled from 'styled-components';
import { prop } from 'styled-tools';

const Form = styled.form`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  width: 100%;
  margin-left: 0;
`;

const Input = styled.input`
  color: #555860;
  padding: 5px 10px;
  font-size: 15px;
  width: calc(100% - 144px);
  box-shadow: none;
  border-radius: 5px;
  &:focus {
    outline: none;
  }
`;

const SendBtn = styled.button`
  width: 110px;
  white-space: nowrap;
  float: right;
  padding: 4px 10px;
  line-height: 24px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 12px;
  ${prop('style')}
`;

export { Form, Input, SendBtn };
