import styled, { css } from 'styled-components';
import { prop, ifProp, ifNotProp } from 'styled-tools';

const UlComp = styled.ul`
  position: absolute;
  width: 45px;
  top: 0;
  left: -45px;
  border-right: 6px solid #f2c704;
  list-style: none;
  padding: 0;
  margin: 0;
  &:before {
    position: absolute;
    right: 0;
    top: 3px;
    height: calc(100% - 6px);
    width: 0;
    content: '';
    box-shadow: -8px 19px 34px 8px rgba(0, 0, 0, 0.22);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  ${prop('style')};
`;

const LiComp = styled.li`
  vertical-align: middle;
  display: inline-block;
  float: right;
  width: 21px;
  height: 65px;
  font-size: 20px;
  color: #212325;
  line-height: 20px;
  padding-left: 7px;
  cursor: pointer;
  background: #ebebeb;
  background: -moz-linear-gradient(
    left,
    #ebebeb 0%,
    #ebebeb 13%,
    #d3d3d3 45%,
    #a2a3a3 100%
  );
  background: -webkit-linear-gradient(
    left,
    #ebebeb 0%,
    #ebebeb 13%,
    #d3d3d3 45%,
    #a2a3a3 100%
  );
  background: linear-gradient(
    to right,
    #ebebeb 0%,
    #ebebeb 13%,
    #d3d3d3 45%,
    #a2a3a3 100%
  );
  filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#ebebeb', endColorstr='#a2a3a3',GradientType=1 )";
  border-top-left-radius: 10%;
  border-bottom-left-radius: 10%;
  position: relative;
  transition: width 0.25s ease-in-out;

  ${prop('style')};

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.4);
    }
    70% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }

  ${ifProp(
    'active',
    css`
      ${prop('activeButtonBackground')};
      ${prop('closeButtonColor')};
    `
  )};

  & > .icon_up {
    position: relative;
    right: 2px;
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &.tab_not-first:not(.active) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  & > .label {
    position: relative;
    right: 2px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 80%;
    height: 80%;
    z-index: -1;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 10%;
    margin: auto;
    ${ifProp(
      'isNew',
      css`
        box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
        animation: pulse 2s infinite;
        border-radius: 1px;
      `
    )};
  }

  &:hover {
    & > .close {
      opacity: 0.5;
      animation: none;
    }
    &:after {
      animation: none;
    }
    ${ifNotProp(
      'active',
      css`
        width: 25px;
      `
    )}
  }

  &:focus {
    outline: none;
  }
`;

export { UlComp, LiComp };
