export const CTA__OPEN = 'CTA__OPEN';
export const CTA__CLOSE = 'CTA__CLOSE';
export const CTA__ACTIVATE = 'CTA__ACTIVATE';
export const CTA__DEACTIVATE = 'CTA__DEACTIVATE';
export const CTA__TRANSITION_CHANGE = 'CTA__TRANSITION_CHANGE';

export const CB__INIT = 'CB__INIT';
export const CB__OPEN = 'CB__OPEN';
export const CB__CLOSE = 'CB__CLOSE';
export const CB__ACTIVATE = 'CB__ACTIVATE';
export const CB__DEACTIVATE = 'CB__DEACTIVATE';
export const CB_SET_CLOSING_TIMER = 'CB_SET_CLOSING_TIMER';
export const CB__TRANSITION_CHANGE = 'CB__TRANSITION_CHANGE';
export const CB__CLEAR = 'CB__CLEAR';
export const CB_SET_IS_SHOWING_LAM = 'CB_SET_IS_SHOWING_LAM';

export const SMS__OPEN = 'SMS__OPEN';
export const MB__OPEN = 'MB__OPEN';
export const FB__OPEN = 'FB__OPEN';
export const FB__CLOSE = 'FB__CLOSE';
export const FB__TRANSITION_CHANGE = 'FB__TRANSITION_CHANGE';

export const GLOBAL__SET_WEB_SETTINGS_INDEX = 'GLOBAL__SET_WEB_SETTINGS_INDEX';
export const GLOBAL__INIT_APP = 'GLOBAL__INIT_APP';
export const GLOBAL__CONNECT = 'GLOBAL__CONNECT';
export const GLOBAL__DISCONNECT = 'GLOBAL__DISCONNECT';
export const GLOBAL__SET_ACTIVE_ROOM = 'GLOBAL__SET_ACTIVE_ROOM';
export const GLOBAL__SET_USER_ID = 'GLOBAL__SET_USER_ID';
export const GLOBAL__STORE_CHAT_ID = 'GLOBAL__STORE_CHAT_ID';
export const GLOBAL__UI_LOCK = 'GLOBAL__UI_LOCK';
export const GLOBAL__UI_UNLOCK = 'GLOBAL__UI_UNLOCK';
export const GLOBAL__UI_HIDE = 'GLOBAL__UI_HIDE';
export const GLOBAL__UI_SHOW = 'GLOBAL__UI_SHOW';

export const ROOM__ADDED = 'ROOM__ADDED';
export const ROOM__UPDATE = 'ROOM__UPDATE';
export const ROOM__DELETED = 'ROOM__DELETED';
export const ROOM__REINIT_DATA = 'ROOM__REINIT_DATA';
export const ROOM__LOAD_HISTORY = 'ROOM__LOAD_HISTORY';
export const ROOM__CONNECTED = 'ROOM__CONNECTED';
export const ROOM__DISCONNECTED = 'ROOM__DISCONNECTED';
export const ROOM__OPERATOR_ONLINE = 'ROOM__OPERATOR_ONLINE';
export const ROOM__OPERATOR_OFFLINE = 'ROOM__OPERATOR_OFFLINE';
export const ROOM__SET_READ_MESSAGES_LENGTH = 'ROOM__SET_READ_MESSAGES_LENGTH';
export const ROOM__SEND_MESSAGE = 'ROOM__SEND_MESSAGE';
export const ROOM__OPERATOR_ASSIGNED_CHAT = 'ROOM__OPERATOR_ASSIGNED_CHAT';
export const ROOM__OPERATOR_UNASSIGNED_CHAT = 'ROOM__OPERATOR_UNASSIGNED_CHAT';
export const ROOM__OPERATOR_CLOSED_CHAT = 'ROOM__OPERATOR_CLOSED_CHAT';
export const ROOM__CLEAR_CLOSING_CHAT_TIMER = 'ROOM__CLEAR_CLOSING_CHAT_TIMER';
export const ROOM__SET_OPERATOR_INFO = 'ROOM__SET_OPERATOR_INFO';
export const ROOM__SET_OPERATOR_TYPING = 'ROOM__SET_OPERATOR_TYPING';
export const ROOM__SET_USER_TYPING = 'ROOM__SET_USER_TYPING';
export const ROOM__UNSET_OPERATOR_TYPING = 'ROOM__UNSET_OPERATOR_TYPING';
export const ROOM__UNSET_USER_TYPING = 'ROOM__UNSET_USER_TYPING';
export const ROOM__STATUS_CHANGED = 'ROOM__STATUS_CHANGED';
export const ROOM__CHAT_CHANGED = 'ROOM__CHAT_CHANGED';
export const ROOM__SHOW_SEND_TRANSCRIPT_FORM =
  'ROOM__SHOW_SEND_TRANSCRIPT_FORM';
export const ROOM__SET_VISIBILITY = 'ROOM__SET_VISIBILITY';
export const ROOM__SET_NEED_ANSWER = 'ROOM__SET_NEED_ANSWER';
export const ROOM__SET_MB_INITIAL_MESSAGE_TIMESTAMP =
  'ROOM__SET_MB_INITIAL_MESSAGE_TIMESTAMP';
export const ROOM__DELETE_FAKE_MESSAGES = 'ROOM__DELETE_FAKE_MESSAGES';
export const ROOM__DELETE_MESSAGES_WITH_TEXT =
  'ROOM__DELETE_MESSAGES_WITH_TEXT';
export const ROOM__SAVE_SB_OPEN_TIMER_DATE = 'ROOM__SAVE_SB_OPEN_TIMER_DATE';
export const ROOM__SAVE_MB_OPEN_TIMER_DATE = 'ROOM__SAVE_MB_OPEN_TIMER_DATE';
export const ROOM__SAVE_CTA_OPEN_TIMER_DATE = 'ROOM__SAVE_CTA_OPEN_TIMER_DATE';
export const ROOM__CHANGE_MESSAGE_STATUS = 'ROOM__CHANGE_MESSAGE_STATUS';
export const ROOM__CB_INITIAL_MESSAGE_IS_SENT =
  'ROOM__CB_INITIAL_MESSAGE_IS_SENT';
export const ROOM__DETAILS_FORM_SEND = 'ROOM__DETAILS_FORM_SEND';
export const ROOM__INITIALISE_QUALIFICATION_PROCESS =
  'ROOM__INITIALISE_QUALIFICATION_PROCESS';
export const ROOM__QUALIFICATION_PUSH_HISTORY =
  'ROOM__QUALIFICATION_PUSH_HISTORY';
export const ROOM__QUALIFICATION_COMPLETED = 'ROOM__QUALIFICATION_COMPLETED';
export const ROOM__QUALIFICATION_RESET = 'ROOM__QUALIFICATION_RESET';
export const ROOM__QUALIFICATION_TEAM_STATUS =
  'ROOM__QUALIFICATION_TEAM_STATUS';
export const ROOM__SHOW_LAM_TIMER = 'ROOM__SHOW_LAM_TIMER';
export const ROOM__CLEAR_LAM_TIMER = 'ROOM__CLEAR_LAM_TIMER';

export const SB__INIT = 'SB__INIT';
export const SB__OPEN = 'SB__OPEN';
export const SB__CLOSE = 'SB__CLOSE';
export const SB__ACTIVATE = 'SB__ACTIVATE';
export const SB__DEACTIVATE = 'SB__DEACTIVATE';
export const SB__SEND_MESSAGE = 'SB__SEND_MESSAGE';
export const SB__CLOSE_TIMER_ON = 'SB__CLOSE_TIMER_ON';
export const SB__CLOSE_TIMER_OFF = 'SB__CLOSE_TIMER_OFF';
export const SB__OPEN_TIMER_ON = 'SB__OPEN_TIMER_ON';
export const SB__OPEN_TIMER_OFF = 'SB__OPEN_TIMER_OFF';

export const SB__TRANSITION_CHANGE = 'SB__TRANSITION_CHANGE';
export const SB__DIMENSIONS_SET = 'SB__DIMENSIONS_SET';
export const SB__DIMENSIONS_RESET = 'SB__DIMENSIONS_RESET';

export const RS__ACTIVATE = 'RS__ACTIVATE';
export const RS__DEACTIVATE = 'RS__DEACTIVATE';
export const RS__OPEN = 'RS__OPEN';
export const RS__CLOSE = 'RS__CLOSE';
export const RS__TRANSITION_CHANGE = 'RS__TRANSITION_CHANGE';

export const CONTACT_VIA__SET_SHOW_MODE = 'CONTACT_VIA__SET_SHOW_MODE';
export const CONTACT_VIA__ACTIVATE = 'CONTACT_VIA__ACTIVATE';
export const CONTACT_VIA__DEACTIVATE = 'CONTACT_VIA__DEACTIVATE';

export const SYSTEM__CLEAR = 'SYSTEM__CLEAR';
