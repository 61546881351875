import styled from 'styled-components';
import { ifProp } from 'styled-tools';

const Footer = styled.div`
  height: 30px;
  width: 100%;
  position: relative;
  text-align: center;
  display: inline-block;
  margin: 9px 0 0 0;
  color: #ffffff;
  text-decoration: none;
  font-size: 10px;
  opacity: 0.5;

  a:visited {
    ${ifProp(
      'isLight',
      'color: rgba(0,0,0, 0.7)',
      'color: rgba(255,255,255, 0.4)'
    )};
  }

  a:hover {
    opacity: 1;
  }

  a {
    transition: opacity 300ms;
  }

  a.privacy-url {
    color: #ffffff;
    left: 9px;
  }
`;

export { Footer };
