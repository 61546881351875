import {
  GLOBAL__SET_WEB_SETTINGS_INDEX,
  GLOBAL__CONNECT,
  GLOBAL__DISCONNECT,
  GLOBAL__INIT_APP,
  GLOBAL__SET_ACTIVE_ROOM,
  GLOBAL__SET_USER_ID,
  GLOBAL__STORE_CHAT_ID,
  GLOBAL__UI_LOCK,
  GLOBAL__UI_UNLOCK,
  GLOBAL__UI_HIDE,
  GLOBAL__UI_SHOW,
  SYSTEM__CLEAR
} from '../actionConstants';

export function setWebSettingsIndex({ index } = {}) {
  return dispatch => {
    dispatch({
      type: GLOBAL__SET_WEB_SETTINGS_INDEX,
      payload: {
        index
      }
    });
  };
}

export function initApp(props = {}) {
  const { settings, webSettings, meta } = props;
  return dispatch => {
    dispatch({
      type: GLOBAL__INIT_APP,
      payload: { settings, webSettings, meta }
    });
  };
}

export function connect() {
  return dispatch => {
    dispatch({
      type: GLOBAL__CONNECT
    });
  };
}

export function disconnect() {
  return dispatch => {
    dispatch({
      type: GLOBAL__DISCONNECT
    });
  };
}

export function setActiveRoom(activeRoom, eventSource) {
  return dispatch => {
    dispatch({
      type: GLOBAL__SET_ACTIVE_ROOM,
      payload: { activeRoom, eventSource }
    });
  };
}

export function setUserId(userId) {
  return dispatch => {
    dispatch({
      type: GLOBAL__SET_USER_ID,
      payload: { userId }
    });
  };
}

export function storeChatId({ room, chatId } = {}) {
  return dispatch => {
    dispatch({
      type: GLOBAL__STORE_CHAT_ID,
      payload: { room, chatId }
    });
  };
}

export function uiLock() {
  return dispatch => {
    dispatch({
      type: GLOBAL__UI_LOCK
    });
  };
}

export function uiUnlock() {
  return dispatch => {
    dispatch({
      type: GLOBAL__UI_UNLOCK
    });
  };
}

export function uiHide() {
  return dispatch => {
    dispatch({
      type: GLOBAL__UI_HIDE
    });
  };
}

export function uiShow() {
  return dispatch => {
    dispatch({
      type: GLOBAL__UI_SHOW
    });
  };
}

export function clearStore() {
  return dispatch => {
    dispatch({
      type: SYSTEM__CLEAR
    });
  };
}
