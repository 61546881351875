import React, { useState } from 'react';
//
import { CheckmarkIcon } from 'common/svg';
import { Container } from './index.style';

/**
 *
 * @param props = {
 *   width:     integer,
 *   height:    integer,
 *   color:     string,
 *   onChange:  function
 *  }
 * @returns {*}
 */
export default function(props = {}) {
  const { width = 16, height = 16, color, onChange } = props;
  const [checked, setChecked] = useState(props.checked || false);
  const handleOnClick = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <Container
      className="uie-radio-button"
      style={{ width: `${width}px`, height: `${height}px` }}
      onClick={handleOnClick}
      tabIndex={0}
      onKeyDown={({ key }) => (key === 'Enter' ? handleOnClick() : true)}
    >
      {checked && <CheckmarkIcon size={height - 2} color={color} />}
      {!checked && null}
    </Container>
  );
}
