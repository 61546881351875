import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
//
import { Wrapper } from './style';
import Configurator from '../../services/Configurator';
import CallToAction from '../../components/CallToAction';
import FloatingButton from '../../components/FloatingButton';
import ChatBox from '../../components/ChatBox';
import SpeechBubble from '../../components/SpeechBubble';
import RoomsSelector from '../../components/RoomsSelector';
import * as globalFunctions from './globalFunctions';
import logger from '../../utils/logger';
class App extends Component {
  constructor(props) {
    super(props);
    this.conf = new Configurator();
    window.vcConfigurator = this.conf;
  }

  componentDidMount() {
    this.attachGlobalFunctions();
  }

  attachGlobalFunctions = () => {
    window.clearStore = window.VisitorChat_ClearStore = () =>
      globalFunctions.clearStore(this.props.dispatch);
    window.VisitorChatInit = () =>
      globalFunctions.VisitorChatInit({ configurator: this.conf });
    window.VisitorChatDestroy = () =>
      globalFunctions.VisitorChatDestroy({ configurator: this.conf });
    window.VisitorChatOpen = () =>
      globalFunctions.VisitorChatOpen(this.props.dispatch);
    window.VisitorChatClose = () =>
      globalFunctions.VisitorChatClose(this.props.dispatch);
    window.VisitorChatGetVersion = () =>
      globalFunctions.VisitorChatGetVersion();
    window.VisitorChatGetBuildDate = () =>
      globalFunctions.VisitorChatGetBuildDate();
    window.VisitorChatOpenSms = () =>
      globalFunctions.VisitorChatSms(this.props.dispatch);
    window.VisitorChatSbOpen = () =>
      globalFunctions.VisitorChatSbOpen(this.props.dispatch);
    window.VisitorChatSbClose = () =>
      globalFunctions.VisitorChatSbClose(this.props.dispatch);
    window.VisitorChatCtaOpen = () =>
      globalFunctions.VisitorChatCtaOpen(this.props.dispatch);
    window.VisitorChatCtaClose = () =>
      globalFunctions.VisitorChatCtaClose(this.props.dispatch);
    // Hack for legacy stuff
    window.VisitorChat_Init = () =>
      globalFunctions.VisitorChatInit({ configurator: this.conf });
    window.VisitorChat_OpenSms = () =>
      globalFunctions.VisitorChatSms(this.props.dispatch);
    window.VisitorChat_Open = () =>
      globalFunctions.VisitorChatOpen(this.props.dispatch);
    window.VisitorChat_Close = () =>
      globalFunctions.VisitorChatClose(this.props.dispatch);

    this.fireGlbFuncEvent();
  };

  // Fire an event when the global functions have all been set up
  fireGlbFuncEvent() {
    logger.debug('Adding global functions');
    const globalFuncInit = new CustomEvent('vcGlobalFuncRdy');
    document.dispatchEvent(globalFuncInit);
  }

  handleSocketChatBoxOpened = room => {
    if (!this.conf.connect) return;
    this.conf.connect._chatBoxOpened(room);
  };

  handleSocketChatBoxClosed = room => {
    if (!this.conf.connect) return;
    this.conf.connect._chatBoxClosed(room);
  };

  handleSocketSpeechBubbleOpened = room => {
    if (!this.conf.connect) return;
    this.conf.connect._speechBubbleOpened(room);
  };

  handleSocketSetTypingState = (obj, state) => {
    if (!this.conf.connect) return;
    this.conf.connect._setTypingState(obj, state);
  };

  handleSocketSendMessage = message => {
    if (!this.conf.connect) return;
    this.conf.connect._sendMessage(message);
  };

  handleSocketSendOfflineMessage = message => {
    if (!this.conf.connect) return;
    this.conf.connect._sendOfflineMessage(message);
  };

  handleSocketChatHistoryEnd = message => {
    if (!this.conf.connect) return;
    this.conf.connect._sendChatHistoryMessage(message);
  };

  handleSocketSendDetailsMessage = message => {
    if (!this.conf.connect) return;
    this.conf.connect._sendDetailsFormMessage(message);
  };

  handleSocketGetTeamOnlineStatus = (teamId, room) => {
    if (!this.conf.connect) return;
    this.conf.connect._getTeamOnlineStatus({ teamId, room });
  };

  handleSocketChatNoAnswerTimeout = room => {
    if (!this.conf.connect) return;
    this.conf.connect._chatNoAnswerTimeout({ room });
  };

  handleSocketResetRoom = room => {
    if (!this.conf.connect) return;
    this.conf.connect._resetRoom(room);
  };

  handleRunApp = async () => {
    await this.conf.init();
  };

  handleCloseRoom = room => {
    if (!this.conf.connect) return;
    this.conf.connect._closeChat(room);
  };

  handleDisconnectApp = () => {
    this.conf.disconnect();
  };

  render() {
    const { global } = this.props;
    const { init, uiHidden } = global;
    if (!init || uiHidden) return null;

    return (
      <Wrapper>
        <NotificationContainer />
        <Fragment>
          <CallToAction />
          <FloatingButton />
          <ChatBox
            handleCloseRoom={this.handleCloseRoom}
            handleSocketChatHistoryEnd={this.handleSocketChatHistoryEnd}
            handleSocketChatBoxOpened={this.handleSocketChatBoxOpened}
            handleSocketChatBoxClosed={this.handleSocketChatBoxClosed}
            handleSocketSetTypingState={this.handleSocketSetTypingState}
            handleSocketSendMessage={this.handleSocketSendMessage}
            handleSocketSendOfflineMessage={this.handleSocketSendOfflineMessage}
            handleSocketSendDetailsMessage={this.handleSocketSendDetailsMessage}
            handleDisconnectApp={this.handleDisconnectApp}
            handleSocketGetTeamOnlineStatus={
              this.handleSocketGetTeamOnlineStatus
            }
            handleSocketChatNoAnswerTimeout={
              this.handleSocketChatNoAnswerTimeout
            }
            handleSocketResetRoom={this.handleSocketResetRoom}
          />
          <SpeechBubble
            handleSocketSendMessage={this.handleSocketSendMessage}
            handleSocketSpeechBubbleOpened={this.handleSocketSpeechBubbleOpened}
          />
          <RoomsSelector handleRunApp={this.handleRunApp} />
        </Fragment>
      </Wrapper>
    );
  }
}

export default connect(
  (state = {}) => {
    const { global } = state;
    return {
      global
    };
  },
  dispatch => ({ dispatch })
)(App);
