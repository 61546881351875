import React, { Component } from 'react';
//
import store from 'store';
import { sendSMS } from 'utils/requests';
import SmsForm from './smsForm';
import { StatusPanel } from './smsStyle';
import { MessageSuccessIcon, MessageFailedIcon } from 'common/svg';
import { getLightOrDarkByHex, lightenDarkenColor } from 'utils/helpers';

class SmsComp extends Component {
  static STATUS_FAILED = -1;
  static STATUS_INITIAL = 0;
  static STATUS_SUCCESS = 1;

  constructor(props) {
    super(props);

    const bckColor = SmsComp.getBackgroundColor();
    const bgIsLight = getLightOrDarkByHex(bckColor);

    this.state = {
      msgInputFocus: false,
      bgColor: lightenDarkenColor(bckColor, bgIsLight ? -55 : -35),
      completedWith: SmsComp.STATUS_INITIAL
    };
  }

  static getBackgroundColor() {
    const { global } = store.getState();
    const { settings = {} } = global;
    return settings.cb_background_color || '#232323';
  }

  handleMsgInputFocus = () => {
    this.setState({
      msgInputFocus: !this.state.msgInputFocus
    });
  };

  handleRetry = () => {
    this.setState({ completedWith: SmsComp.STATUS_INITIAL });
  };

  handleSubmit = async ({ values } = {}) => {
    const { global } = store.getState();
    const { meta } = global;
    // eslint-disable-next-line camelcase
    const { domain_id } = meta;
    const { phone, message } = values;
    const response = await sendSMS(domain_id, phone, message);

    if (response.status === 200) {
      this.setState({ completedWith: SmsComp.STATUS_SUCCESS });
    } else {
      this.setState({ completedWith: SmsComp.STATUS_FAILED });
    }
  };

  render() {
    const { global } = store.getState();
    const { settings = {} } = global;
    const { bgColor, completedWith } = this.state;

    if (completedWith === SmsComp.STATUS_INITIAL) {
      return (
        <SmsForm
          onSubmit={this.handleSubmit}
          onFocus={this.handleMsgInputFocus}
          onBlur={this.handleMsgInputFocus}
          stateFocus={this.state.msgInputFocus}
          placeholder={this.state.msgInputFocus ? null : 'your message'}
          bgColor={bgColor}
          settings={settings}
        />
      );
    }

    if (completedWith === SmsComp.STATUS_SUCCESS) {
      return (
        <StatusPanel>
          <MessageSuccessIcon />
          <p className="text">
            Thank you, your message has been sent. <br />
            Someone will be in touch shortly.
          </p>
        </StatusPanel>
      );
    }

    if (completedWith === SmsComp.STATUS_FAILED) {
      return (
        <StatusPanel>
          <MessageFailedIcon />
          <p className="text">
            Message could not be sent. <br />
            Please try again or contact us via chat.
          </p>
          <span className="navigation" onClick={this.handleRetry}>
            try again
          </span>
        </StatusPanel>
      );
    }

    return null;
  }
}

export default SmsComp;
