import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

import { InputWrapper, FieldWrapper } from './detailsStyle';
import { ChatBoxField } from '../common/ChatBoxField';
import {
  getLightOrDarkByHex,
  getProp,
  lightenDarkenColor
} from 'utils/helpers';
import { getMediator } from 'services/mediator';
import { phoneMask, emailMask } from 'lib/validation';

export const COMPONENT_NAME = 'chatBox:detailsMessageForm';

class DetailsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this._componentName = props.componentName || COMPONENT_NAME;

    const bckColor = this.getBackgroundColor();
    const bgIsLight = getLightOrDarkByHex(bckColor);

    this.backgroundColor = lightenDarkenColor(bckColor, bgIsLight ? -55 : -35);
  }

  componentDidMount() {
    getMediator().attachComponent(this._componentName, this);
  }

  componentWillUnmount() {
    getMediator().detachComponent(this._componentName);
  }

  /**
   * Incoming events handler
   * @param props
   */
  subscriber = (props = {}) => {
    const { action = {} } = props;

    if (action.type === 'submit') {
      this.submit({ onSuccess: action.onSuccess });
    }

    if (action.type === 'reset') {
      this.reset();
    }
  };

  getBackgroundColor() {
    const { settings = {} } = this.props;
    return settings.cb_background_color || '#232323';
  }

  changeHandler = (e, fieldName) => {
    const value = getProp(e, 'target.value');
    const updateData = {
      [fieldName]: value
    };

    if (value && this.state[`${fieldName}Error`]) {
      updateData[`${fieldName}Error`] = false;
    }

    this.setState(updateData);
  };

  getFields = () => {
    const { fields = [] } = this.props;
    const style = {
      backgroundColor: this.backgroundColor
    };

    return fields.map((field, index) => {
      const customFieldTypes = ['phone', 'email'];

      if (customFieldTypes.indexOf(field.type) !== -1) {
        const mask = (type => {
          if (type === 'phone') {
            return phoneMask;
          }
          if (type === 'email') {
            return emailMask;
          }
          return undefined;
        })(field.type);

        return (
          <InputWrapper _style={style} key={index}>
            <ChatBoxField
              name={field.name}
              type="text"
              error={this.state[`${field.name}Error`]}
              required
              customInput={
                <MaskedInput
                  mask={mask}
                  className={`form-control form-control-${field.name}`}
                  placeholder={field.placeholder}
                  guide={false}
                  id={`my-${field.name}-id`}
                  onChange={e => this.changeHandler(e, field.name)}
                  value={this.state[field.name] || ''}
                />
              }
            />
          </InputWrapper>
        );
      }

      return (
        <InputWrapper _style={style} key={index}>
          <ChatBoxField
            name={field.name}
            type="text"
            required={field.required}
            error={this.state[`${field.name}Error`]}
            placeholder={field.placeholder}
            input={{
              onChange: e => this.changeHandler(e, field.name),
              value: this.state[field.name] || ''
            }}
          />
        </InputWrapper>
      );
    });
  };

  validateHandler = () => {
    const { fields = [] } = this.props;
    const updateData = {};
    let anError = false;

    fields.forEach(field => {
      const isInvalid =
        (field.required && !this.state[field.name]) ||
        (typeof field.validator === 'function' &&
          !field.validator(this.state[field.name]));
      if (isInvalid && !anError) {
        anError = true;
      }
      updateData[`${field.name}Error`] = isInvalid;
    });

    this.setState(updateData);

    return anError;
  };

  onSubmitHandler = (props = {}) => {
    const { fields = [] } = this.props;
    const { onSuccess } = props;

    if (this.validateHandler()) {
      return;
    }

    const values = fields.reduce((accumulator, field) => {
      accumulator[field.name] = this.state[field.name];
      return accumulator;
    }, {});

    onSuccess(values);
  };

  submit = (props = {}) => {
    this.onSubmitHandler(props);
  };

  reset = () => {
    const { fields = [] } = this.props;
    const updateData = {};

    fields.forEach(field => {
      updateData[field.name] = '';
      updateData[`${field.name}Error`] = false;
    });

    this.setState(updateData);
  };

  render() {
    const style = {
      height: 'auto'
    };

    return (
      <form style={style} onSubmit={this.onSubmitHandler}>
        <FieldWrapper>{this.getFields()}</FieldWrapper>
      </form>
    );
  }
}

DetailsForm.propTypes = {
  action: PropTypes.object,
  componentName: PropTypes.string,
  fields: PropTypes.array,
  onSuccess: PropTypes.func,
  settings: PropTypes.object
};

export default DetailsForm;
