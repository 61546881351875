import styled, { css, keyframes } from 'styled-components';
import { prop, switchProp } from 'styled-tools';
import mobileDevice from 'ismobilejs';

import {
  convertHexToRgba,
  getImageDimensions,
  isEmpty,
  stylesAlignment,
  getProp
} from 'utils/helpers';
import { FETCH_STATE, IS_MOBILE_WIDTH } from 'config/constants';

const DEFAULT_DELAY = 500;
const CTA_WIDTH = 230;
const PADDING = 7;
const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;
const _speechBubbleFloatingButtonDimensionsRequest = {
  path: null,
  inProgress: false
};
const _floatingButtonDimensionsRequest = {
  path: null,
  state: null
};

const open = () => {
  return keyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  `;
};

const close = () => {
  return keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  `;
};

const onEntering = (props = {}) => {
  if (!props._slideOn) return;
  return css`
    animation: ${open()} ${props._slideSpeed}ms linear;
  `;
};

const onEntry = () => {
  return css`
    transform: translateY(0) translateX(0);
  `;
};

const onExiting = (props = {}) => {
  if (!props._slideOff) return;
  return css`
    animation: ${close()} ${props._slideSpeed}ms linear;
  `;
};

/**
 * Styles constructor
 */
class StylesConstructor {
  constructor(props = {}) {
    Object.keys(props).forEach(propKey => (this[propKey] = props[propKey]));
  }

  /**
   *
   * @returns {{"box-shadow": string}}
   */
  getShadow() {
    const { settings = {}, operatorOnline } = this;
    const color = settings.cb_shadow_color;
    const keyPrefix = !operatorOnline ? '_offline' : '';
    const shadow = settings[`fb${keyPrefix}_shadow`]
      ? +settings[`fb${keyPrefix}_shadow`]
      : 0;
    return { 'box-shadow': `0 0 ${shadow}px ${convertHexToRgba(color, 80)}` };
  }

  /**
   *
   * @returns {*}
   */
  getHeight() {
    const { height = 'auto' } = this._getFloatingButtonReact();
    return { height };
  }

  /**
   *
   * @param props
   * @returns {*}
   * @private
   */
  _getFloatingButton(props = {}) {
    let data;
    const { direction, mode } = props;
    const { settings = {}, parentState = {} } = this;
    const useOnlineButton = isPhone
      ? settings.fb_mobile_online_button === 'on'
      : settings.fb_online_button === 'on';
    const useOfflineButton = isPhone
      ? settings.fb_mobile_offline_button === 'on'
      : settings.fb_offline_button === 'on';
    const isHorizontal = direction === 'horizontal';
    const isVertical = direction === 'vertical';
    const keyPrefix = mode === 'offline' && useOfflineButton ? '_offline' : '';
    const floatingButtonPath =
      (() => {
        if (settings[`fb${keyPrefix}_standard_button`] === 'on') {
          return (
            settings[`fb${keyPrefix}_standard_button_modified`] ||
            settings[`fb${keyPrefix}_standard_button_type`]
          );
        } else {
          return settings[`fb${keyPrefix}_custom_button`];
        }
      })() || '';

    if (mode !== 'offline' && !useOnlineButton) return;
    if (mode === 'offline' && !useOfflineButton) return;

    let dimensions;
    if (/\.svg$/.test(floatingButtonPath)) {
      const defaultSvgWidth = 100;
      const defaultSvgHeight = 100;
      dimensions = {
        width: Number(settings[`fb${keyPrefix}_svg_width`]) || defaultSvgWidth,
        height:
          Number(settings[`fb${keyPrefix}_svg_height`]) || defaultSvgHeight
      };
    } else {
      dimensions = parentState.get().floatingButtonDimensions;
      if (
        _floatingButtonDimensionsRequest.state === FETCH_STATE.LOADING &&
        _floatingButtonDimensionsRequest.path === floatingButtonPath
      )
        return;
      if (
        !dimensions ||
        _floatingButtonDimensionsRequest.path !== floatingButtonPath
      ) {
        _floatingButtonDimensionsRequest.path = floatingButtonPath;
        _floatingButtonDimensionsRequest.state = FETCH_STATE.LOADING;
        getImageDimensions(floatingButtonPath).then(dimensions => {
          _floatingButtonDimensionsRequest.state = FETCH_STATE.SUCCESS;
          parentState.set({ floatingButtonDimensions: dimensions }); // async operation
        });
        return;
      }
    }

    if (isPhone && settings[`fb${keyPrefix}_mobile_size`]) {
      if (isHorizontal) {
        data = {
          width: `${Math.floor(
            (2 * settings[`fb${keyPrefix}_mobile_size`] * dimensions.width) /
              100
          )}px`
        };
      }
      if (isVertical) {
        data = {
          height: `${Math.floor(
            (2 * settings[`fb${keyPrefix}_mobile_size`] * dimensions.height) /
              100
          )}px`
        };
      }
    } else if (settings[`fb${keyPrefix}_size`]) {
      if (isHorizontal) {
        data = {
          width: `${Math.floor(
            (2 * settings[`fb${keyPrefix}_size`] * dimensions.width) / 100
          )}px`
        };
      }
      if (isVertical) {
        data = {
          height: `${Math.floor(
            (2 * settings[`fb${keyPrefix}_size`] * dimensions.height) / 100
          )}px`
        };
      }
    } else {
      if (isHorizontal) {
        data = { width: `${Math.floor(dimensions.width)}px` };
      }
      if (isVertical) {
        data = { height: `${Math.floor(dimensions.height)}px` };
      }
    }

    return data;
  }

  /**
   *
   * @param props
   * @returns {*}
   * @private
   */
  _getSpeechBubbleFloatingButton(props = {}) {
    let data;
    const { direction } = props;
    const { settings = {}, parentState = {} } = this;
    const useSpeechBubbleButton = settings.sb2_enabled === 'on';
    const useSpeechBubbleMobileButton =
      isPhone && settings.fb_mobile_online_button === 'on';
    const isHorizontal = direction === 'horizontal';
    const isVertical = direction === 'vertical';
    const keyPrefix = useSpeechBubbleMobileButton ? '_mobile' : '';
    const speechBubbleFloatingButtonPath =
      (() => {
        if (settings[`sb2${keyPrefix}_fb_standard_button`] === 'on') {
          return (
            settings[`sb2${keyPrefix}_fb_standard_button_modified`] ||
            settings[`sb2${keyPrefix}_fb_standard_button_type`]
          );
        } else {
          return settings[`sb2${keyPrefix}_fb_custom_button`];
        }
      })() || '';

    if (!useSpeechBubbleButton && !useSpeechBubbleMobileButton) return;

    if (
      speechBubbleFloatingButtonPath.length > 0 &&
      speechBubbleFloatingButtonPath.substr(-4, 4) === '.svg'
    ) {
      if (settings[`sb2${keyPrefix}_fb_size`]) {
        if (isHorizontal) {
          data = {
            width:
              Math.floor(
                (2 *
                  settings[`sb2${keyPrefix}_fb_size`] *
                  settings[`sb2${keyPrefix}_fb_svg_width`]) /
                  100
              ) + 'px'
          };
        }
        if (isVertical) {
          data = {
            height:
              Math.floor(
                (2 *
                  settings[`sb2${keyPrefix}_fb_size`] *
                  settings[`sb2${keyPrefix}_fb_svg_height`]) /
                  100
              ) + 'px'
          };
        }
      } else {
        if (isHorizontal) {
          data = { width: settings[`sb2${keyPrefix}_fb_svg_width`] + 'px' };
        }
        if (isVertical) {
          data = { height: settings[`sb2${keyPrefix}_fb_svg_height`] + 'px' };
        }
      }
    } else {
      const dimensions = parentState.get().speechBubbleFloatingButtonDimensions;
      if (
        _speechBubbleFloatingButtonDimensionsRequest.inProgress &&
        _speechBubbleFloatingButtonDimensionsRequest.path ===
          speechBubbleFloatingButtonPath
      )
        return;
      if (
        !dimensions ||
        _speechBubbleFloatingButtonDimensionsRequest.path !==
          speechBubbleFloatingButtonPath
      ) {
        _speechBubbleFloatingButtonDimensionsRequest.path = speechBubbleFloatingButtonPath;
        _speechBubbleFloatingButtonDimensionsRequest.inProgress = true;
        getImageDimensions(speechBubbleFloatingButtonPath).then(dimensions => {
          _speechBubbleFloatingButtonDimensionsRequest.inProgress = false;
          parentState.set({ speechBubbleFloatingButtonDimensions: dimensions }); // async operation
        });
        return;
      }

      if (settings[`${keyPrefix}sb2_fb_size`]) {
        if (isHorizontal) {
          data = {
            width:
              Math.floor(
                (2 * settings[`sb2${keyPrefix}_fb_size`] * dimensions.width) /
                  100
              ) + 'px'
          };
        }
        if (isVertical) {
          data = {
            height:
              Math.floor(
                (2 * settings[`sb2${keyPrefix}_fb_size`] * dimensions.height) /
                  100
              ) + 'px'
          };
        }
      } else {
        if (isHorizontal) {
          data = { width: `${Math.floor(dimensions.width)}px` };
        }
        if (isVertical) {
          data = { height: `${Math.floor(dimensions.height)}px` };
        }
      }
    }

    return data;
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  _getFloatingButtonWidth(props = {}) {
    const options = {
      direction: 'horizontal'
    };
    const defaultData = { width: 0 };
    const { settings = {} } = this;
    const { open, operatorOnline } = props;
    const isPathInactive =
      settings.sb2_fb_standard_button !== 'on' &&
      isEmpty(settings.sb2_fb_custom_button);

    if (!((open && !isPathInactive) || operatorOnline)) {
      options.mode = 'offline';
    }
    const data = this._getFloatingButton(options);
    return data || defaultData;
  }

  /**
   *
   * @param props
   * @returns {*}
   */
  _getFloatingButtonHeight(props = {}) {
    const options = {
      direction: 'vertical'
    };
    const defaultData = { height: 0 };
    const { settings = {} } = this;
    const { open, operatorOnline } = props;
    const isPathInactive =
      settings.sb2_fb_standard_button !== 'on' &&
      isEmpty(settings.sb2_fb_custom_button);

    if (!((open && !isPathInactive) || operatorOnline)) {
      options.mode = 'offline';
    }
    const data = this._getFloatingButton(options);
    return data || defaultData;
  }

  /**
   *
   * @param props
   * @returns {{}}
   * @private
   */
  getAlignment(props = {}) {
    const { settings = {} } = this;
    const { mode, width = 0, height = 0 } = props;
    const keyPrefix = mode === 'offline' ? '_offline' : '';
    const widthInt =
      typeof width === 'string' ? +width.replace(/[^0-9]/g, '') : width;
    if (
      !(
        (settings[`fb${keyPrefix}_alignment_x`] || settings.cb_alignment_x) &&
        (settings[`fb${keyPrefix}_alignment_y`] || settings.cb_alignment_y)
      )
    )
      return;

    const x = settings[`fb${keyPrefix}_alignment_x`] || settings.cb_alignment_x;
    const y = settings[`fb${keyPrefix}_alignment_y`] || settings.cb_alignment_y;
    let hOffset = 0;
    let vOffset = 0;

    if (isPhone) {
      if (settings[`fb_mobile${keyPrefix}_offset_horizontal`]) {
        hOffset = settings[`fb_mobile${keyPrefix}_offset_horizontal`];
      }
      if (settings[`fb_mobile${keyPrefix}_offset_vertical`]) {
        vOffset = settings[`fb_mobile${keyPrefix}_offset_vertical`];
      }
    } else {
      if (settings[`fb${keyPrefix}_offset_horizontal`]) {
        hOffset =
          settings[`fb${keyPrefix}_offset_horizontal`] ||
          settings.cb_offset_horizontal;
      }
      if (settings[`fb${keyPrefix}_offset_vertical`]) {
        vOffset =
          settings[`fb${keyPrefix}_offset_vertical`] ||
          settings.cb_offset_vertical;
      }
    }

    if (x === 'left') {
      hOffset = +hOffset + widthInt + PADDING;
    } else if (x === 'right') {
      hOffset = +hOffset + widthInt + PADDING;
    } else {
      // 'middle'
      hOffset = Math.round((CTA_WIDTH + PADDING) / 2);
    }
    return stylesAlignment({
      x,
      y,
      hOffset: `${hOffset}px`,
      vOffset: `${vOffset}px`,
      element: { width, height }
    });
  }

  /**
   *
   * @returns {*}
   */
  getSlideOn() {
    const { settings = {}, operatorOnline } = this;
    const keyPrefix = !operatorOnline ? '_offline' : '';
    return settings[`fb${keyPrefix}_slide_on`];
  }

  /**
   *
   * @returns {*}
   */
  getSlideOff() {
    const { settings = {}, operatorOnline } = this;
    const keyPrefix = !operatorOnline ? '_offline' : '';
    return settings[`fb${keyPrefix}_slide_off`];
  }

  /**
   *
   * @returns {*}
   */
  getMoveSpeed() {
    const { settings = {}, operatorOnline } = this;
    const keyPrefix = !operatorOnline ? '_offline' : '';
    return +settings[`fb${keyPrefix}_move_speed`] || DEFAULT_DELAY;
  }

  /**
   *
   * @returns {{"background-color": (*|string|string)}}
   */
  getBackgroundColor() {
    const { settings = {} } = this;
    let bckColor = settings.cb_background_color;
    let opacity = +settings.cb_background_transparency;

    if (!opacity) opacity = 90;
    if (isPhone) opacity = 100;
    if (!bckColor) bckColor = '#232323';

    const cbBGColor = convertHexToRgba(bckColor, opacity);
    const bgColor = settings.sb_background_color || cbBGColor || '#eff3f6';
    return { 'background-color': bgColor };
  }
}

const frameWrapperFormatProps = (props = {}) => {
  const {
    settings,
    operatorOnline,
    transitionState,
    parentState,
    speechBubble,
    isClosedByClick,
    element,
    uiLocked
  } = props;
  const Styles = new StylesConstructor({
    settings,
    parentState,
    operatorOnline,
    speechBubble
  });
  const _floatingButtonWidth = Styles._getFloatingButtonWidth({
    open: speechBubble.open,
    operatorOnline
  });
  const _floatingButtonHeight = Styles._getFloatingButtonHeight({
    open: speechBubble.open,
    operatorOnline
  });
  const _align = Styles.getAlignment({
    mode: !operatorOnline ? 'offline' : '',
    width: _floatingButtonWidth.width,
    height: _floatingButtonHeight.height
  });
  const dimensionsDone =
    _floatingButtonDimensionsRequest.state !== FETCH_STATE.LOADING;
  const style = {
    ...(uiLocked ? { pointerEvents: 'none' } : {}),
    ...(!dimensionsDone ? { visibility: 'hidden' } : {})
  };
  const internalProps = {
    _align,
    _shadow: Styles.getShadow(),
    _height: _floatingButtonHeight,
    _slideOn: Styles.getSlideOn(),
    _slideOff: Styles.getSlideOff(),
    _slideSpeed: Styles.getMoveSpeed(),
    _backgroundColor: Styles.getBackgroundColor(),
    ctaDelay: +getProp(settings, 'sb_delay', '0'),
    isClosedByClick,
    element,
    style
  };

  return Object.assign({}, { transitionState }, internalProps);
};

const FrameWrapper = styled.div`
  width: ${CTA_WIDTH}px;
  min-height: 40px;
  border-radius: 6px;
  position: fixed;
  overflow: visible;
  z-index: 2147483647 !important;

  ${prop('_shadow')};
  ${prop('_height')};
  ${prop('_align')};
  ${prop('_color')};
  ${prop('_backgroundColor')}
  ${switchProp('transitionState', {
    entered: props => onEntry(),
    entering: props => onEntering(props),
    exited: props => onEntry(),
    exiting: props => onExiting(props)
  })};
  ${prop('style')}
`;

export { frameWrapperFormatProps, FrameWrapper, CTA_WIDTH, PADDING };
