import * as appActions from '../../ducks/global/app.action';
import * as chatBoxActions from '../../ducks/chatBox/chatBox.action';
import { VERSION, BUILD_DATE } from '../../config/constants';
import { closeSb, openSb } from '../../ducks/speechBubble/speechBubble.action';
import {
  closeCta,
  openCta
} from '../../ducks/callToAction/callToAction.action';

/**
 * Clear Redux store
 * @param dispatch
 */
function clearStore(dispatch) {
  dispatch(appActions.clearStore());
  setTimeout(() => location.reload(), 250);
}

/**
 * Initialize Application
 * @param configurator
 * @constructor
 */
function VisitorChatInit({ configurator }) {
  configurator.init();
}

/**
 * Destroy Application
 * @param configurator
 * @constructor
 */
function VisitorChatDestroy({ configurator }) {
  configurator.destroyApp();
}

/**
 * Open Chat Box
 * @param dispatch
 * @constructor
 */
function VisitorChatOpen(dispatch) {
  dispatch(chatBoxActions.openCb({ target: {} }));
}

/**
 * Open Chat Box
 * @param dispatch
 * @constructor
 */
function VisitorChatSms(dispatch) {
  dispatch(chatBoxActions.openSms());
}

/**
 * Close Chat Box
 * @param dispatch
 * @constructor
 */
function VisitorChatClose(dispatch) {
  dispatch(chatBoxActions.closeCb());
}

/**
 * Get environment variable `VERSION`
 * @returns {string}
 * @constructor
 */
function VisitorChatGetVersion() {
  return `v.${VERSION}`;
}

/**
 * Get environment variable `BUILD_DATE`
 * @returns {string}
 * @constructor
 */
function VisitorChatGetBuildDate() {
  return `build_date: ${BUILD_DATE}`;
}

function VisitorChatSbOpen(dispatch) {
  dispatch(openSb());
}

function VisitorChatSbClose(dispatch) {
  dispatch(closeSb());
}

function VisitorChatCtaOpen(dispatch) {
  dispatch(openCta());
}

function VisitorChatCtaClose(dispatch) {
  dispatch(closeCta());
}

export {
  clearStore,
  VisitorChatInit,
  VisitorChatDestroy,
  VisitorChatOpen,
  VisitorChatClose,
  VisitorChatGetVersion,
  VisitorChatGetBuildDate,
  VisitorChatSms,
  VisitorChatSbOpen,
  VisitorChatSbClose,
  VisitorChatCtaOpen,
  VisitorChatCtaClose
};
