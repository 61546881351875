import { getProp, propParseInt } from 'utils/helpers';

const slicedToArray = (function() {
  function sliceIterator(arr, i) {
    const _arr = [];
    let _n = true;
    let _d = false;
    let _e;

    try {
      for (
        let _i = arr[Symbol.iterator](), _s;
        !(_n = (_s = _i.next()).done);
        _n = true
      ) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i.return) _i.return();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  return function(arr, i) {
    if (Array.isArray(arr)) {
      return arr;
    } else if (Symbol.iterator in Object(arr)) {
      return sliceIterator(arr, i);
    } else {
      throw new TypeError(
        'Invalid attempt to destructure non-iterable instance'
      );
    }
  };
})();

/**
 *
 */
class Draggable {
  _dragging = false;
  _dragged = false;
  _position = {
    x: 0,
    y: 0,
    slackX: 0,
    slackY: 0
  };

  constructor(props = {}) {
    this.applyProperties(props);
  }

  applyProperties = (props = {}) => {
    this.props = { ...this.props, ...props };
  };

  reset = () => {
    this._dragging = false;
    this._dragged = false;
    this._position = {
      x: 0,
      y: 0,
      slackX: 0,
      slackY: 0
    };
  };

  /**
   *
   * @param node
   * @returns {number}
   */
  draggableInnerWidth = node => {
    let width = node.clientWidth;
    const computedStyle = node.ownerDocument.defaultView.getComputedStyle(node);
    width -= propParseInt(computedStyle.paddingLeft);
    width -= propParseInt(computedStyle.paddingRight);
    return width;
  };

  /**
   *
   * @param node
   * @returns {number}
   */
  draggableInnerHeight = node => {
    let height = node.clientHeight;
    const computedStyle = node.ownerDocument.defaultView.getComputedStyle(node);
    height -= propParseInt(computedStyle.paddingTop);
    height -= propParseInt(computedStyle.paddingBottom);
    return height;
  };

  /**
   *
   * @param node
   * @returns {number}
   */
  draggableOuterWidth = node => {
    // This is deliberately excluding margin for our calculations, since we are using
    // offsetLeft which is including margin. See getBoundPosition
    let width = node.clientWidth;
    const computedStyle = node.ownerDocument.defaultView.getComputedStyle(node);
    width += propParseInt(computedStyle.borderLeftWidth);
    width += propParseInt(computedStyle.borderRightWidth);
    return width;
  };

  /**
   *
   * @param node
   * @returns {number}
   */
  draggableOuterHeight = node => {
    // This is deliberately excluding margin for our calculations, since we are using
    // offsetTop which is including margin. See getBoundPosition
    let height = node.clientHeight;
    const computedStyle = node.ownerDocument.defaultView.getComputedStyle(node);
    height += propParseInt(computedStyle.borderTopWidth);
    height += propParseInt(computedStyle.borderBottomWidth);
    return height;
  };

  /**
   * @param node
   * @param position
   */
  getBoundPosition = (node, position) => {
    if (!this.props.limiter) return;

    let { x, y } = position;
    const ownerDocument = node.ownerDocument;
    const ownerWindow = ownerDocument.defaultView;
    const boundNode = ownerDocument.querySelector(this.props.limiter);
    if (!(boundNode instanceof ownerWindow.HTMLElement)) {
      throw new Error('Bounds selector could not find an element.');
    }
    const nodeStyle = ownerWindow.getComputedStyle(node);
    const boundNodeStyle = ownerWindow.getComputedStyle(boundNode);

    const boundsLimitRight = getProp(this, 'props.limiterBounds.right');
    const boundsLimitBottom = getProp(this, 'props.limiterBounds.bottom');
    const boundsLimitLeft = getProp(this, 'props.limiterBounds.left');
    const boundsLimitTop = getProp(this, 'props.limiterBounds.top');

    const limitRight = boundsLimitRight || this.draggableInnerWidth(boundNode);
    const limitBottom =
      boundsLimitBottom || this.draggableInnerHeight(boundNode);
    const limitLeft =
      boundsLimitLeft || propParseInt(boundNodeStyle.paddingLeft);
    const limitTop = boundsLimitTop || propParseInt(boundNodeStyle.paddingTop);

    // Compute bounds. This is a pain with padding and offsets but this gets it exactly right.
    const bounds = {
      left: -node.offsetLeft + limitLeft + propParseInt(nodeStyle.marginLeft),
      top: -node.offsetTop + limitTop + propParseInt(nodeStyle.marginTop),
      right:
        limitRight -
        this.draggableOuterWidth(node) -
        node.offsetLeft +
        propParseInt(boundNodeStyle.paddingRight) -
        propParseInt(nodeStyle.marginRight),
      bottom:
        limitBottom -
        this.draggableOuterHeight(node) -
        node.offsetTop +
        propParseInt(boundNodeStyle.paddingBottom) -
        propParseInt(nodeStyle.marginBottom)
    };

    // Keep x and y below right and bottom limits...
    if (typeof bounds.right === 'number') x = Math.min(x, bounds.right);
    if (typeof bounds.bottom === 'number') y = Math.min(y, bounds.bottom);

    // But above left and top limits.
    if (typeof bounds.left === 'number') x = Math.max(x, bounds.left);
    if (typeof bounds.top === 'number') y = Math.max(y, bounds.top);

    return [x, y];
  };

  /**
   *
   * @param coreData
   * @returns {{node: *, x: *, y: *, deltaX: number, deltaY: number, lastX: *, lastY: *}}
   */
  createDraggableData(coreData) {
    const { x, y } = this._position;
    const scale = this.props.scale || 1;
    return {
      node: coreData.node,
      x: x + coreData.deltaX / scale,
      y: y + coreData.deltaY / scale,
      deltaX: coreData.deltaX / scale,
      deltaY: coreData.deltaY / scale,
      lastX: x,
      lastY: y
    };
  }

  /**
   *
   * @param event
   * @param coreData
   */
  onStart = (event, coreData) => {
    this._dragging = true;
    this._dragged = true;
  };

  /**
   *
   * @param event
   * @param coreData
   */
  onDrag = (event, coreData) => {
    if (!this._dragging) return false;

    const uiData = this.createDraggableData(coreData);
    const position = {
      x: uiData.x,
      y: uiData.y
    };

    if (this.props.limiter) {
      const _x = position.x;
      const _y = position.y;

      position.x += this._position.slackX;
      position.y += this._position.slackY;

      const boundPosition = this.getBoundPosition(coreData.node, {
        x: position.x,
        y: position.y
      });
      const boundPositionB = slicedToArray(boundPosition, 2);
      const newPositionX = boundPositionB[0];
      const newPositionY = boundPositionB[1];

      position.x = newPositionX;
      position.y = newPositionY;

      // Recalculate slack by noting how much was shaved by the boundPosition handler.
      position.slackX = this._position.slackX + (_x - position.x);
      position.slackY = this._position.slackY + (_y - position.y);

      // Update the event we fire to reflect what really happened after bounds took effect.
      uiData.x = position.x;
      uiData.y = position.y;
      uiData.deltaX = position.x - this._position.x;
      uiData.deltaY = position.y - this._position.y;
    }

    this._position = { ...position };

    this.reDraw(coreData.node, {
      ...this.props.onDragStyle,
      transform: `translate(${position.x}px,${position.y}px)`
    });
  };

  /**
   *
   * @param event
   * @param coreData
   * @returns {*}
   */
  onStop = (event, coreData) => {
    if (!this._dragging) return false;
    this._dragging = false;
    this._dragged = false;

    this.reDraw(coreData.node, {
      ...this.props.onStopStyle
    });
  };

  /**
   *
   * @param node
   * @param style
   */
  reDraw = (node, style = {}) => {
    Object.keys(style).forEach(propName => {
      node.style[propName] = style[propName];
    });
  };
}

export default Draggable;
