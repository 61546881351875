import React, { Component, Fragment } from 'react';
import mobileDevice from 'ismobilejs';
import PropTypes from 'prop-types';
//
import SendMessageForm from './sendMessageForm';
import ContactViaComp from 'components/ContactVia/contactViaComp';
import { getLightOrDarkByHex, lightenDarkenColor } from 'utils/helpers';
import { CONTACT_VIA_SHOW_MODES, IS_MOBILE_WIDTH } from 'config/constants';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;
export default class SendMessageComp extends Component {
  constructor(props) {
    super(props);
    this.timerId = null;
    this.typingObj = {};

    const bckColor = this.getBackgroundColor();
    const bgIsLight = getLightOrDarkByHex(bckColor);

    this.state = {
      bckColor,
      bgIsLight,
      msgInputFocus: false
    };
  }

  handleMsgInputFocus = e => {
    if (e.type === 'focus') {
      this.setState({
        msgInputFocus: true
      });
      if (isPhone) {
        this.props.chatListScrollTo({ offsetY: -window.innerHeight });
        this.props.chatListScrollTo({ delay: 300 });
      }
    } else if (e.type === 'blur') {
      this.setState({
        msgInputFocus: false
      });
    }
  };

  sendStopTypingEvent = () => this.props.handleUserTyping(this.typingObj);

  handleTAChange = value => {
    const { global = {}, activeRoomInfo = {} } = this.props;

    if (this.timerId) {
      clearTimeout(this.timerId);
    }

    this.typingObj = {
      chatId: activeRoomInfo.chatId,
      room: activeRoomInfo.room,
      clientId: global.userId,
      message: value
    };

    this.props.handleUserTyping(this.typingObj, true);
    this.timerId = setTimeout(this.sendStopTypingEvent, 1500);
  };

  getBackgroundColor() {
    const { global = {} } = this.props;
    const { settings = {} } = global;
    return settings.cb_background_color || '#232323';
  }

  checkFormVisibility = () => {
    const { contactViaIsActive, contactViaShowMode } = this.props;
    if (
      contactViaIsActive &&
      contactViaShowMode !== CONTACT_VIA_SHOW_MODES.INITIAL
    )
      return false;
    return true;
  };

  checkContactViaComponentVisibility = () => {
    const { contactViaIsActive, showFacebookButton } = this.props;
    return contactViaIsActive || showFacebookButton;
  };

  getContactOptions = () => {
    const {
      contactViaIsActive,
      showFacebookButton,
      whatsappNumber
    } = this.props;
    const contactOptions = {};
    if (contactViaIsActive) contactOptions.sms = true;
    if (showFacebookButton) contactOptions.facebook = true;
    if (whatsappNumber) contactOptions.whatsapp = whatsappNumber;
    return contactOptions;
  };

  render() {
    const {
      handleSubmit,
      global = {},
      activeRoomInfo = {},
      contactViaShowMode,
      contactViaSetShowMode
    } = this.props;
    const {
      operatorInfo,
      operatorOnline,
      qualificationProcess
    } = activeRoomInfo;
    const { bckColor, bgIsLight } = this.state;
    const { settings } = global;
    const inputMessage = !operatorInfo
      ? settings.mb_invitation_text
      : settings.invitation_text;
    const contactOptions = this.getContactOptions();

    return (
      <Fragment>
        {this.checkFormVisibility() && (
          <SendMessageForm
            componentName={this.props.componentName}
            onSubmit={handleSubmit}
            bgColor={
              this.state.msgInputFocus
                ? 'white'
                : lightenDarkenColor(bckColor, bgIsLight ? -55 : -35)
            }
            onFocus={this.handleMsgInputFocus}
            onBlur={this.handleMsgInputFocus}
            onTAChange={this.handleTAChange}
            message={this.state.msgInputFocus ? null : inputMessage}
            operatorOnline={operatorOnline}
            settings={settings}
            qualificationProcess={qualificationProcess}
          />
        )}
        {this.checkContactViaComponentVisibility() && (
          <ContactViaComp
            style={{ top: '-2px' }}
            showMode={contactViaShowMode}
            onChangeShowMode={contactViaSetShowMode}
            contactOptions={contactOptions}
            parentTarget="chatBox"
          />
        )}
      </Fragment>
    );
  }
}

SendMessageComp.propTypes = {
  activeRoomInfo: PropTypes.object,
  chatListScrollTo: PropTypes.func,
  componentName: PropTypes.any,
  contactViaIsActive: PropTypes.bool,
  contactViaSetShowMode: PropTypes.func,
  contactViaShowMode: PropTypes.string,
  global: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleUserTyping: PropTypes.func,
  showFacebookButton: PropTypes.string,
  whatsappNumber: PropTypes.number
};
