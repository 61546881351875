import React, { Component } from 'react';
//
import { Form } from './sendMessageStyle';
import { CbSendMsgField } from '../common/TextareaField';
import SendBtn from '../common/SendBtn';
import { getProp } from 'utils/helpers';
import { getMediator } from 'services/mediator';
import throttle from 'lodash/throttle';

export const COMPONENT_NAME = 'chatBox:sendMessageForm';

export default class SendMessageForm extends Component {
  constructor(props) {
    super(props);

    this._componentName = props.componentName || COMPONENT_NAME;
    this.state = {
      value: ''
    };
    this.sendTextChange = throttle(this.sendTextChange, 500);
  }

  componentDidMount() {
    getMediator().attachComponent(this._componentName, this);
  }

  componentWillUnmount() {
    getMediator().detachComponent(this._componentName);
  }

  /**
   * Incoming events handler
   * @param props
   */
  subscriber = (props = {}) => {
    const { subject, object, action = {} } = props;

    if (action.type === 'reset') {
      this.reset();
    }
  };

  focusHandler = e => {
    this._hasFocus = true;
    this.props.onFocus(e);
  };

  blurHandler = e => {
    this._hasFocus = false;
    this.props.onBlur(e);
  };

  changeHandler = e => {
    const { onTAChange } = this.props;
    const value = getProp(e, 'target.value');
    this.setState({ value });
    this.sendTextChange(value, onTAChange);
  };

  sendTextChange(value, onTAChange) {
    onTAChange(value);
  }

  reset = () => {
    this.setState({ value: '' });
  };

  render() {
    const {
      onSubmit,
      bgColor,
      onTAResize,
      message,
      settings,
      koef = 1,
      qualificationProcess
    } = this.props;

    const value = this.state.value;
    const disabled =
      qualificationProcess &&
      qualificationProcess.active &&
      !qualificationProcess.completed;

    return (
      <Form
        onSubmit={(e = {}) => {
          e.preventDefault();
          onSubmit({ value });
        }}
        bgColor={bgColor}
        onFocus={this.focusHandler}
        onBlur={this.blurHandler}
        hasFocus={this._hasFocus}
        onKeyDown={e => {
          if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            onSubmit({ value });
          }
        }}
      >
        <CbSendMsgField
          name="cbMessage"
          maxRows={4}
          placeholder={message}
          koef={koef}
          onResize={onTAResize}
          wrapper={{
            width: '80%'
          }}
          input={{
            onChange: this.changeHandler,
            value: this.state.value
          }}
          disabled={disabled}
        />
        <SendBtn
          koef={koef}
          type="submit"
          alt="Send"
          settings={settings}
          hasFocus={this._hasFocus}
          disabled={disabled}
          tabIndex={0}
        />
      </Form>
    );
  }
}
