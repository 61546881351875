import React, { Component } from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-size: 12px;
  padding: 5px 0;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
`;

export default class TimerWrapper extends Component {
  static defaultProps = {
    interval: 1000,
    afterComplete: null,
    style: {},
    children: null
  };

  constructor(props, ...args) {
    super(props, ...args);
    this._isMount = false;
    this.timerId = null;
    this.state = {
      remaining: 0
    };
  }

  componentDidMount() {
    const currentTime = Date.now();

    if (this.props.remaining > currentTime) {
      this.setState({ remaining: this.props.remaining - Date.now() });
      this.timerId = setInterval(
        this.handleTick.bind(this),
        this.props.interval
      );
    }

    this._isMount = true;
  }

  componentWillUnmount() {
    this.clearTimer();
    this._isMount = false;
  }

  clearTimer() {
    clearInterval(this.timerId);
    this.timerId = null;
  }

  handleTick() {
    const currentTime = Date.now();
    const nextRemaining = this.props.remaining - currentTime;

    if (nextRemaining <= 0) {
      if (this._isMount) {
        this.setState({ remaining: 0 });
      }
    } else {
      if (this._isMount) {
        this.setState({ remaining: nextRemaining });
      }
    }
  }

  render() {
    const { style, children, text } = this.props;
    const { remaining } = this.state;
    const date = new Date(remaining);
    const seconds = date.getUTCSeconds();
    const echoText = (remaining > 0 ? text.upcoming : text.coming).replace(
      '{time}',
      seconds
    );
    return (
      <div style={style}>
        {children}
        <Text>{echoText}</Text>
      </div>
    );
  }
}
