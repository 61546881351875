import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 0 auto;
  font-size: 14px;
  font-style: normal;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #000;

  button,
  input,
  optgroup,
  select,
  textarea {
    color: #000;
    font: inherit;
    margin: 0;
  }
  textarea {
    overflow: auto;
  }

  *,
  *:before,
  *:after {
    -moz-box-sizing: inherit;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
  }
`;

export { Wrapper };
